import { Input } from "antd";
import "../styles/components.scss";

export default function InputComponent(props) {
    switch (props.type) {
        case "password":
            return <Input.Password size="large" className="input-component" {...props} />;

        default:
            return <Input size="large" className="input-component" {...props} />;
    }
}
