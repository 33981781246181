import { useState, useEffect } from "react";

import { PageComponent } from "../../components";
import useTranslate from "../../hooks/translator";

import VPNComponent from "./components/VPNComponent";
import Packages from "./components/Packages";
import PaymentButtons from "./components/PaymentButtons";

import "./styles/buy_credit_page.scss";
import InfoSector from "./components/InfoSector";

export default function BuyCreditPage() {
  const translate = useTranslate();
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const isMobile = windowWidth < 767;
  useEffect(() => {
    const windowSize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", windowSize);

    return () => window.removeEventListener("resize", windowSize);
  }, []);

  return (
    <PageComponent title={translate("Buy Credit")}>
      <div className="buy-credit-page">
        <VPNComponent />

        <Packages getSelectedPackages={setSelectedPackage} isMobile={isMobile}/>

        <PaymentButtons selectedPackage={selectedPackage} isMobile={isMobile}/>

        <InfoSector />
      </div>
    </PageComponent>
  );
}
