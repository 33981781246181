import { Popover } from "antd";
import React from "react";

export default function LongText({ children, style }) {
    let text = "";

    if (typeof children === "string") {
        text = children.replace(/(<([^>]+)>|&nbsp;)/gi, "");
    }
    const content = (
        <div>
            <p>{children}</p>
        </div>
    );
    return (
        <Popover content={content}>
            <p style={style} className="long-text">
                {text}
            </p>
        </Popover>
    );
}
