import { createSlice } from "@reduxjs/toolkit";

const profileSlice = createSlice({
  name: "profile",
  initialState: {
    profile: null,
    appBranding: null,
    isRefreshBillingHistory: false,
    selectedLanguage: localStorage.getItem("leng") || "en"
  },
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
    },

    setAppBranding: (state, action) => {
      state.appBranding = action.payload;
    },
    setIsRefreshBillingHistory: (state, action) => {
      state.isRefreshBillingHistory = action.payload;
    },
    setSelectedLanguage: (state, action) => {
      state.selectedLanguage = action.payload;
    }
  }
});
export const {
  setProfile,
  setAppBranding,
  setIsRefreshBillingHistory,
  setSelectedLanguage
} = profileSlice.actions;

export default profileSlice.reducer;
