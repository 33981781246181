import { APP_BRANDING_BACKGROUNDS } from "../../../config/data";
import ICONS from "../../../config/icons";
import { ImageUpload } from "../../../components";

export default function BackgroundList({
  setBackgroundImage,
  selectedBg,
  setSelectedBg,
}) {
  const onSelected = (val) => {
    setSelectedBg(val);
  };

  return (
    <div className="backgrounds-list">
      <ImageUpload
        setFileUrl={setBackgroundImage}
        fileUrl={null}
        width="50px"
        height="50px"
        maskClosable={false}
      />
      {APP_BRANDING_BACKGROUNDS.map((el, index) => (
        <div
          key={el.id}
          onClick={() => onSelected(el.src)}
          className="bg-img"
          style={{ backgroundImage: `url(${el.src})` }}
        >
          {selectedBg == el.src && <span>{ICONS.TRUE}</span>}
        </div>
      ))}
    </div>
  );
}
