import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Form, Button } from "antd";

import useTranslate from "../../hooks/translator";
import { PATHS } from "../../config";
import  ICONS  from "../../config/icons";
import {
  InputComponent,
  ButtonComponent,
  MessageComponent
} from "../../components";
import REQUESTS from "../../api/requests";

export default function PasswordConfirm({
  code,
  setStep,
  setPercent,
  getData,
  onCancel
}) {

  const navigate = useNavigate();

  const translate = useTranslate();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [messageErr, setMessageErr] = useState("");
  
  const onPrevious = () => {
    setPercent(0);
    setStep(1);
  };
  
  const onFinish = (values) => {
    setLoading(true);
    const body = {
      code: code,
      password: values.password
    };

    REQUESTS.MULTI_ACCOUNT.POST(body)
      .then((res) => {
        setLoading(false);
        setPercent(100);
        localStorage.setItem("TOKEN", res.token);
        getData();
        navigate(PATHS.DASHBOARD);
        form.resetFields();
        onCancel();
      })
      .catch((error) => {
        setLoading(false);
        setMessageErr(error);
      });
  };
  
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      form.submit()
    }
  };

  return (
    <>
      <div className="account-text-title">
        <h2>{translate("Password")}</h2>
        <p>
          {translate(
            "Please enter the password of the account you want to attach."
          )}
        </p>
      </div>
      <div className="account-form-wrap">
      <Form 
        name="password" 
        layout="vertical" 
        form={form} 
        onFinish={onFinish}
        onKeyPress={(e) => handleKeyPress(e)}
      >
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: translate("Please input your password")
            },
            {
              min: 8,
              message: translate("Password must be minimum 8 characters")
            }
          ]}
        >
          <InputComponent
            type="password"
            prefix={ICONS.PASSWORD}
            placeholder={translate("Password")}
          />
        </Form.Item>

        <MessageComponent message={messageErr} />

        <Form.Item>
          <div className="accounts-confirmation-btns">
            <Button onClick={() => onPrevious()} className="previous">
              {translate("Previous step")}
            </Button>
            <ButtonComponent
              type="primary"
              onClick={() => form.submit()}
              loading={loading}
            >
              {translate("Confirm")}
            </ButtonComponent>
          </div>
        </Form.Item>
      </Form>
      </div>
     
    </>
  );
}
