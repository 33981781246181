import { Button } from "antd";

import { useNavigate } from "react-router-dom";

import { PATHS } from "../config";

import useTranslate from "../hooks/translator";

export default function Credits() {
  const translate = useTranslate();
  const navigate = useNavigate();

  return (
    <div className="credit-sector">
      <Button
        type="default"
        className="buy-credit-btn"
        onClick={() => navigate(PATHS.BUY_CREDIT)}
      >
        {translate("Buy Credit")}
      </Button>
    </div>
  );
}
