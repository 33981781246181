import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import REQUESTS from "../../api/requests";
import { Empty } from "antd";
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
} from "recharts";
import useTranslate from "../../hooks/translator";
import { themesForProvider, APP_FOR } from "../../config/themesConfigs";

export default function RegisteredDevicesChart() {
  const { profile } = useSelector((state) => state.profile);

  const translate = useTranslate();

  const [chartData, setChartData] = useState([]);

  const asyncFetch = () => {
    setChartData([]);
    REQUESTS.REGISTERED_CHART().then((data) => {
      let vals_sum = data?.map((el) => el?.count).reduce((a, b) => a + b, 0);
      if (vals_sum !== 0) {
        setChartData(data);
      }
    });
  };

  useEffect(() => {
    asyncFetch();
  }, [profile]);

  return (
    <div className="registered-devices-chart">
      <h3 style={{ marginBottom: 20 }}>{translate("Registered Devices")}</h3>
      {chartData?.length > 0 ? (
        <ResponsiveContainer width="100%" height={300}>
          <ComposedChart
            width={730}
            height={250}
            data={chartData}
            margin={{ top: 15, right: 30, left: -20, bottom: 0 }}
          >
            <XAxis dataKey="month" />
            <YAxis allowDecimals={false} />
            <Tooltip />
            <Legend />
            <Bar
              dataKey="count"
              barSize={15}
              fill={themesForProvider[APP_FOR].btnColor}
              background={{ fill: "#173e4b1c" }}
              radius={[10, 10, 0, 0]}
            />
          </ComposedChart>
        </ResponsiveContainer>
      ) : (
        <div className="empty-data">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </div>
      )}
    </div>
  );
}
