import React, { useState, useEffect } from "react";
import { Drawer, Form, Input, Checkbox, Tag, message } from "antd";
import { useSelector } from "react-redux";

import REQUESTS from "../../api/requests";
import useTranslate from "../../hooks/translator";
import { ButtonComponent } from "../../components";

export default function SubresellerDrawer({
  open,
  onClose,
  getData,
  currentData,
}) {
  const translate = useTranslate();

  const [form] = Form.useForm();

  const { profile } = useSelector((store) => store.profile);

  const [loading, setLoading] = useState(false);

  const validateInput = (rule, value, callback) => {
    // Regular expression to check for only alphanumeric characters
    const regex = /^[a-zA-Z0-9]+$/;

    // Check if the value is a string or a number and contains only alphanumeric characters
    if (
      (typeof value === "string" || typeof value === "number") &&
      regex.test(value)
    ) {
      callback();
    } else {
      callback(translate("Brand name must include only letters or numbers"));
    }
  };

  useEffect(() => {
    if (open) {
      if (currentData) {
        form.setFields([{ name: "brand_name", value: currentData.brand_name }]);
        form.setFields([{ name: "name", value: currentData.name }]);
        form.setFields([{ name: "surname", value: currentData.surname }]);
        form.setFields([{ name: "email", value: currentData.email }]);
        form.setFields([{ name: "branding", value: currentData.branding }]);
        form.setFields([{ name: "password", value: currentData.password }]);
        form.setFields([{ name: "approved", value: currentData.approved }]);
      }
    } else {
      form.resetFields();
      setLoading(false);
    }
  }, [open, currentData]);

  const onFinish = (values) => {
    setLoading(true);
    if (!currentData) {
      values.approved = true;
    }

    if (!currentData && !values.branding) {
      values.branding = false;
    }

    if (currentData) {
      const body = {
        id: currentData.id,
      };
      if (values.name) {
        body.name = values.name;
      }
      if (values.surname) {
        body.surname = values.surname;
      }
      if (values.branding) {
        body.branding = values.branding;
      }
      REQUESTS.SUBRESELLERS.PUT(body)
        .then((res) => {
          setLoading(false);
          onClose(false);
          getData();
          message.success(res);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error);
        });
    } else {
      REQUESTS.SUBRESELLERS.POST(values)
        .then((res) => {
          setLoading(false);
          onClose();
          getData();
          message.success(res);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error);
        });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      form.submit();
    }
  };

  return (
    <Drawer
      forceRender
      open={open}
      onClose={onClose}
      placement="right"
      title={
        currentData
          ? translate("Edit Sub-Reseller")
          : translate("Add New Sub-Reseller")
      }
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        autoComplete={"off"}
        initialValues={{
          branding: profile?.branding || false,
        }}
        onKeyPress={(e) => handleKeyPress(e)}
      >
        <Form.Item
          label={translate("Brand name")}
          name="brand_name"
          messageVariables={{ name: "Brand name" }}
          style={{ width: "100%" }}
          rules={[
            {
              required: currentData ? false : true,
              message: translate("Please input your brand name"),
            },
            {
              validator: validateInput,
            },
          ]}
        >
          <Input placeholder={translate("Brand name")} disabled={currentData} />
        </Form.Item>

        <Form.Item
          label={translate("Name")}
          name="name"
          style={{ width: "100%" }}
          rules={[
            {
              required: false,
              message: translate("Please input your name"),
            },
          ]}
        >
          <Input placeholder={translate("Name")} />
        </Form.Item>

        <Form.Item
          label={translate("Surname")}
          name="surname"
          style={{ width: "100%" }}
          rules={[
            {
              required: false,
              message: translate("Please input your surname"),
            },
          ]}
        >
          <Input placeholder={translate("Surname")} />
        </Form.Item>

        <Form.Item
          label={translate("Email")}
          name="email"
          messageVariables={{ name: "Email" }}
          style={{ width: "100%" }}
          validateFirst
          rules={[
            !currentData && {
              type: "email",
              message: translate("E-mail isn't valid"),
            },
            {
              required: !currentData,
              message: translate("Please input your email"),
            },
          ]}
        >
          <Input
            placeholder={translate("Email address")}
            disabled={currentData}
          />
        </Form.Item>
        {!currentData && (
          <Form.Item
            label={translate("Password")}
            name="password"
            messageVariables={{ name: "Password" }}
            rules={[
              {
                required: true,
                message: translate("Please input your password"),
              },
              {
                min: 8,
                message: translate("Password must be minimum 8 characters"),
              },
            ]}
          >
            <Input placeholder={translate("Password")} disabled={currentData} />
          </Form.Item>
        )}

        {profile?.branding && (
          <Form.Item
            name="branding"
            valuePropName="checked"
            style={{ marginBottom: "-5px" }}
          >
            <Checkbox>{translate("Branding")}</Checkbox>
          </Form.Item>
        )}

        {!profile?.branding && (
          <Tag
            color="gold"
            style={{
              textAlign: "center",
              width: "100%",
              marginTop: 20,
              padding: 10,
            }}
          >
            {translate("You cannot use branding")}
            <br />
            {translate("Your administrator restricted your permissions")}
          </Tag>
        )}
        <Form.Item>
          <ButtonComponent
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
            style={{ marginTop: "20px" }}
          >
            {translate("Save")}
          </ButtonComponent>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
