import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import REQUESTS from "../../api/requests";

import { PageComponent } from "../../components";

import GuideSector from "./components/GuideSector";
import AddNewDevice from "./components/AddNewDevice.jsx";

import Geolocation from "./Geolocation.jsx";

import PlatformsStatistics from "./PlatformsStatistics.jsx";
import RegisteredDevicesChart from "./RegisteredDevicesChart";
import LivesStatisticsChart from "./LivesStatisticsChart.jsx";
import MoviesStatisticsChart from "./MoviesStatisticsChart.jsx";
import SeriesStatisticsChart from "./SeriesStatisticsChart";

import "../../styles/dashboard.scss";

export default function DashboardPage() {
  const [platforms, setPlatforms] = useState([]);
  const [availablePlatforms, setAvailablePlatforms] = useState([]);
  const [displayAvailablePlatforms, setDisplayAvailablePlatforms] =
    useState(false);
  const { profile } = useSelector((state) => state.profile);

  useEffect(() => {
    getPlatformsStatistics();
    if (profile) {
      if (profile?.is_partner || profile?.parent_id) {
        getAvailablePlatforms();
      } else {
        setDisplayAvailablePlatforms(false);
      }
    } else {
      setDisplayAvailablePlatforms(false);
    }
  }, [profile]);

  const getPlatformsStatistics = () => {
    setPlatforms([]);
    if (profile) {
      REQUESTS.PLATFORMS_STATISTICS()
        .then((data) => {
          setPlatforms(data);
        })
        .catch((err) => {});
    }
  };

  function getAvailablePlatforms() {
    REQUESTS.AVAILABLE_OS()
      .then((res) => {
        setAvailablePlatforms(res);
        setDisplayAvailablePlatforms(true);
      })
      .catch((err) => {});
  }

  const isShowGuide = () => {
    if (profile?.is_partner) {
      return false;
    }

    if (profile?.parent_id) {
      return false;
    }

    return true;
  };

  return (
    <>
      {profile && isShowGuide() && <GuideSector />}

      <PageComponent>
        {profile && (
          <div
            className="dashboard-content"
            style={{
              paddingTop: isShowGuide() ? "170px" : "0px",
            }}
          >
            {displayAvailablePlatforms ? (
              <PlatformsStatistics
                platforms={platforms}
                availablePlatforms={availablePlatforms}
              />
            ) : (
              <PlatformsStatistics platforms={platforms} />
            )}
            <AddNewDevice />
            <Geolocation />
            <div className="charts">
              <RegisteredDevicesChart />
            </div>
            <div className="movies-lives-series-startistics-charts">
              <LivesStatisticsChart />
              <MoviesStatisticsChart />
              <SeriesStatisticsChart />
            </div>
          </div>
        )}
      </PageComponent>
    </>
  );
}
