import { useSelector } from "react-redux";

import {
  AmeriabankPayment,
  CoinbaseButton,
  PixButton,
} from "../../../components";

function PaymentButtons({ selectedPackage, isMobile }) {
  const { profile } = useSelector((state) => state.profile);

  return (
    <div className="payment-buttons">
      <div className="payment-buttons-body">
        <AmeriabankPayment
          credit={selectedPackage?.credit}
          price={selectedPackage?.price}
          provider_id={profile?.id}
          isMobile={isMobile}
        />

        <CoinbaseButton
          package_id={selectedPackage?.id}
          isMobile={isMobile}
          credit={selectedPackage?.credit}
          price={selectedPackage?.price}
          provider_id={profile?.id}
        />

        <PixButton package_id={selectedPackage?.id} isMobile={isMobile} />
      </div>
    </div>
  );
}

export default PaymentButtons;
