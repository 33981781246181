import { UndoOutlined } from "@ant-design/icons";
import { top_movies, top_series } from "../../../../config/data";
import IMAGES from "../../../../config/images";

import ListsMedia from "../ListsMedia";
import MenuRmsButtons from "./MenuRmsButtons";

import "../../styles/menu-rms.scss";

export default function MenuRms({ colors, logo, backgroundImage, menu }) {
  return (
    <div
      className="menu-page-two"
      style={{
        backgroundImage: `url(${backgroundImage.url})`,
        backgroundColor: "#000",
      }}
    >
      <div className="menu-page-wrap">
        <div className="left-section">
          <div className="logo" style={{ marginBottom: "30px" }}>
            <img
              src={logo.url}
              onError={(e) => {
                e.target.src = IMAGES.ERROR;
              }}
              alt="logo"
              style={{ width: logo.size ? `${logo.size * 5}px` : "50px" }}
            />
          </div>
          <MenuRmsButtons
            primaryColor={colors.primary_color}
            secondaryColor={colors.secondary_color}
            menu={menu}
          />
          <div
            className="reload-app"
            style={{
              border: `1.3px solid rgb(112, 112, 113)`,
              marginLeft: "53px",
              marginTop: "15px",
            }}
          >
            <UndoOutlined
              style={{
                color:
                  menu.menu_icons_collection == 1
                    ? "#fff"
                    : menu.menu_icons_color,
              }}
            />
            <span
              style={{
                color:
                  menu.menu_icons_collection == 1
                    ? "#fff"
                    : menu.menu_icons_color,
              }}
            >
              Reload app
            </span>
          </div>
        </div>
        <div className="right-section">
          <ListsMedia
            data={top_movies}
            title="10 Recently Added Movies"
            secondaryColor={colors.secondary_color}
            isScale={true}
          />
          <ListsMedia
            data={top_series}
            title="10 Recently Added Series"
            secondaryColor="transparent"
          />
        </div>
      </div>
      <div className="menu-page-two-tv-footer">
        <p>
          <span> Device Key: 3GK689</span>
        </p>
        <p>App version: 1.1.5</p>
      </div>
    </div>
  );
}
