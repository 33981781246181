import Logo from "./Logo";
import Background from "./Background";
import GeneralColors from "./GeneralColors";

export default function GeneralInfo({
  logo,
  setLogo,
  backgroundImage,
  setBackgroundImage,
  colors,
  setColors,
  selectedBg,
  setSelectedBg,
}) {
  return (
    <>
      <div className="horizontal">
        <Logo logo={logo} setLogo={setLogo} />
        <GeneralColors colors={colors} setColors={setColors} />
      </div>
      <div className="mtop">
        <Background
          backgroundImage={backgroundImage}
          setBackgroundImage={setBackgroundImage}
          selectedBg={selectedBg}
          setSelectedBg={setSelectedBg}
        />
      </div>
    </>
  );
}
