import React, { useState } from "react";
import { Button, message } from "antd";
import REQUESTS from "../api/requests";

import useTranslate from "../hooks/translator";

import { ICONS } from "../config";

export default function PixButton({ package_id, isMobile }) {
  const translate = useTranslate();

  const [loadingCard, setLoadingCard] = useState(false);

  const onSend = () => {
    setLoadingCard(true);

    const body = {};

    if (package_id) {
      body.package_id = package_id;
    }

    REQUESTS.PAYMENT_PIX(body)
      .then((res) => {
        if (res.url) {
          window.open(res.url, "_self");
        }

        if (res.message) {
          message.error(res.message);
        }

        if (typeof res == "string") {
          message.info(res);
        }
        setLoadingCard(false);
      })
      .catch((err) => {
        message.error(err);
        setLoadingCard(false);
      });
  };
  return isMobile ? (
    <Button size="large" className="pix" onClick={onSend} loading={loadingCard}>
      <span>
        {ICONS.PIX} {ICONS.CARD}
      </span>
      <span>
      {translate("Pay with PIX card")}
      </span>
    </Button>
  ) : (
    <Button size="large" className="pix" onClick={onSend} loading={loadingCard}>
      {ICONS.PIX} {ICONS.CARD} {translate("Pay with PIX card")}
    </Button>
  );
}
