import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Drawer, Form, Input, message, Checkbox } from "antd";

import REQUESTS from "../../api/requests";

import useTranslate from "../../hooks/translator";

import { ButtonComponent, InfoPopover } from "../../components";

import DNSInfo from "./DNSInfo";

export default function ServerHostsDrawer({
  open,
  setOpen,
  selected,
  getData,
}) {
  const translate = useTranslate();

  const { profile } = useSelector((state) => state.profile);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) {
      if (selected) {
        form.setFields([{ name: "name", value: selected.name }]);
        form.setFields([{ name: "host", value: selected.host }]);
        form.setFields([
          {
            name: "open_for_subresellers",
            value: selected.open_for_subresellers,
          },
        ]);
        form.setFields([
          { name: "open_for_devices", value: selected.open_for_devices },
        ]);
      }
    } else {
      form.resetFields();
      setLoading(false);
    }
  }, [open, selected]);

  const onFinish = (values) => {
    setLoading(true);

    const body = {
      name: values.name?.trim(),
      open_for_devices: values.open_for_devices,
    };

    if (profile?.is_partner) {
      body.open_for_subresellers = values.open_for_subresellers;
    }

    if (!selected) {
      body.host = values.host;
    }

    if (selected) {
      body.id = selected.id;
      REQUESTS.SERVER_HOSTS.PUT(body)
        .then((res) => {
          setOpen(false);
          getData();
          setLoading(false);
          message.success(res);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error);
        });
    } else {
      REQUESTS.SERVER_HOSTS.POST(body)
        .then((res) => {
          setOpen(false);
          getData();
          setLoading(false);
          message.success(res);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error);
        });
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      form.submit();
    }
  };

  return (
    <Drawer
      forceRender
      open={open}
      onClose={setOpen}
      placement="right"
      title={
        <>
          {selected ? translate("Edit DNS") : translate("Add New DNS")}
          <InfoPopover
            content={<DNSInfo />}
            title={translate("Add new DNS configurations")}
          />
        </>
      }
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        autoComplete={"off"}
        onKeyPress={(e) => handleKeyPress(e)}
        initialValues={{
          open_for_subresellers: true,
          open_for_devices: true,
        }}
      >
        <Form.Item
          label={translate("Name")}
          name="name"
          rules={[
            {
              required: selected ? false : true,
              message: translate("Please input name"),
            },
          ]}
        >
          <Input placeholder={translate("Input name")} />
        </Form.Item>
        <Form.Item
          label={
            <span>
              {translate("Host")}{" "}
              <span style={{ color: "grey" }}>(http://example.com)</span>
            </span>
          }
          name="host"
          rules={[
            {
              required: selected ? false : true,
              message: translate("Please input Host"),
            },
            {
              type: "url",
              message: translate("That's not a valid host"),
            },
          ]}
        >
          <Input
            placeholder={translate("Input Host")}
            type="url"
            disabled={selected}
          />
        </Form.Item>

        <Form.Item label="" name="open_for_devices" valuePropName="checked">
          <Checkbox>{translate("Open for devices")}</Checkbox>
        </Form.Item>

        {profile?.is_partner && (
          <Form.Item
            label=""
            name="open_for_subresellers"
            valuePropName="checked"
          >
            <Checkbox>
              {translate("Allow resellers to make use of DNS")}
            </Checkbox>
          </Form.Item>
        )}
        <Form.Item>
          <ButtonComponent
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
          >
            {translate("Save")}
          </ButtonComponent>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
