import React, { useEffect, useState } from "react";
import REQUESTS from "../../../api/requests";

import MobilePackage from "./MobilePackage";
import Package from "./Package";
import "../styles/packages.scss";
import "../styles/mobile_packages.scss";

export default function Packages({ getSelectedPackages, isMobile }) {
    const [packagesOptions, setPackagesOptions] = useState([]);

    const [selected, setSelected] = useState(null);

    useEffect(() => {
        getPackages();
    }, []);

    const onSelect = (el) => {
        getSelectedPackages(el);
        setSelected(el);
    };

    const getPackages = () => {
        REQUESTS.PACKAGES()
            .then((data) => {
                if (data) {
                    const sortedData = data.sort((a, b) => a.price - b.price);
                    setPackagesOptions(sortedData);
                    const popularData = data.filter((el) => el.is_popular == true);
                    if (popularData.length > 0) {
                        setSelected(popularData[0]);
                        getSelectedPackages(popularData[0]);
                    } else {
                        setSelected(data?.[0]);
                        getSelectedPackages(data?.[0]);
                    }
                }
            })
            .catch((err) => {});
    };

    return (
        packagesOptions && (
            <div className="packages-list">
                {isMobile
                    ? packagesOptions.map((item, index) => (
                          <MobilePackage
                              item={item}
                              isTranding={index === 2}
                              isSelected={selected?.id === item.id}
                              onSelect={() => onSelect(item)}
                          />
                      ))
                    : packagesOptions.map((item, index) => (
                          <Package
                              item={item}
                              isTranding={index === 2}
                              isSelected={selected?.id === item.id}
                              onSelect={() => onSelect(item)}
                          />
                      ))}
            </div>
        )
    );
}
