import React, { useState, useEffect } from "react";
import { Chart } from "react-google-charts";

import { useSelector } from "react-redux";

import REQUESTS from "../../api/requests";

import CountryCard from "./components/CountryCard";

export default function Geolocation() {
  const { profile } = useSelector((state) => state.profile);

  const [dataList, setDataList] = useState([]);

  const originalWarn = console.warn;

  console.warn = function (...args) {
    const arg = args && args[0];

    if (arg && arg.includes("Attempting to load version '51' of Google Charts"))
      return;

    originalWarn(...args);
  };

  const getGeologation = () => {
    setDataList([]);
    REQUESTS.COUNTRY_LIST().then((data) => {
      data?.sort((a, b) => b.count - a.count);
      setDataList(data);
    });
  };

  const geolocationData = [["Country", "Devices"]];

  dataList.map((item) => {
    geolocationData.push([item.country, item.count]);
  });

  useEffect(() => {
    getGeologation();
  }, [profile]);

  return (
    <div className="geo-section">
      <Chart
        chartEvents={[
          {
            eventName: "select",
            callback: ({ chartWrapper }) => {
              const chart = chartWrapper.getChart();
              const selection = chart.getSelection();
              if (selection.length === 0) return;
              const region = geolocationData[selection[0].row + 1];
            },
          },
        ]}
        chartType="GeoChart"
        width="100%"
        height="400px"
        data={geolocationData}
       
        options={{
          colorAxis: {
            colors: ["#57929f", "#165664"],

          },
          datalessRegionColor: "#cfe3e7",
          defaultColor: "#f5f5f5",
          legend: "none",
        }}
      />
      <CountryCard dataList={dataList} />
    </div>
  );
}
