import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { Divider, Switch, message } from "antd";

import useTranslate from "../../../hooks/translator";
import EmailStep from "./EmailStep";
import CodeStep from "./CodeStep";
import REQUESTS from "../../../api/requests";

export default function OTPConfigs({ getProfile }) {
  const translate = useTranslate();

  const { profile } = useSelector((state) => state.profile);

  const [step, setStep] = useState("email");

  const onEnablingTwoFA = () => {
    REQUESTS.OTP.TWO_FA_PUT()
      .then((res) => {
        setStep("disabledTwoFa");
        message.success(res);
      })
      .catch((err) => {
        message.err(err);
      });
  };

  useEffect(() => {
    if (step == "disabledTwoFa") {
      getProfile();
      setStep("email");
    }
  }, [step]);

  return (
    <>
      <Divider orientation="left" style={{ fontSize: "15px" }}>
        <div style={{ display: "flex", gap: "12px", lineHeight: 1.5 }}>
          <h4> {translate("Enable Two-Factor Authentication")}</h4>
          {profile && (
            <Switch
              disabled={!profile.enable_two_fa}
              checked={profile?.enable_two_fa}
              onChange={onEnablingTwoFA}
            />
          )}
        </div>
      </Divider>
      {profile && !profile.enable_two_fa && (
        <div className="wrap-steps-config">
          {step == "email" && <EmailStep setStep={setStep} />}
          {step == "code" && <CodeStep setStep={setStep} />}
        </div>
      )}
    </>
  );
}
