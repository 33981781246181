import { Link } from "react-router-dom";
import useTranslate from "../../hooks/translator";
import { APP_VERSION, WEB_HOST } from "../../config/config";

export default function Footer() {
  const translate = useTranslate();

  return (
    <div className="footer-login">
      <span>
        <Link to={`${WEB_HOST}/privacy-policy`} target="_blank">
          {translate("Privacy policy")} |
        </Link>
        <Link to={`${WEB_HOST}/refund`} target="_blank">
          {translate("Refund policy")} |
        </Link>
        <Link to={`${WEB_HOST}/terms`} target="_blank">
          {translate("Terms of usage")} |
        </Link>
        <Link to={`${WEB_HOST}/device-activation`} target="_blank">
          {translate("How to activate")}{" "}
        </Link>
      </span>
      <p><Link to={WEB_HOST} target="_blank">xtream.cloud </Link> © {new Date().getFullYear()} | {translate("All Rights Reserved")}</p>
      <p>
        {translate("App version")}|{APP_VERSION}
      </p>
    </div>
  );
}
