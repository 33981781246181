import React, { useState, useEffect } from "react";
import { Drawer, Form, InputNumber, message } from "antd";

import {setProfile} from "../../store/features/profileSlice";
import { useDispatch } from "react-redux";

import REQUESTS from "../../api/requests";
import useTranslate from "../../hooks/translator";
import { ButtonComponent } from "../../components";

export default function CreditDrawer({ open, onClose, getData, by }) {
  const translate = useTranslate();

  const dispatch = useDispatch();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const minValue = (open.type == "Add Credits" && by == "by sub") ?  5  :  open.type == "Add Credits" ? 20 : 1 ;

  useEffect(() => {
    if (open.show) {
      form.resetFields();
      setLoading(false);
    }
  }, [open.show]);

  const  validateCredits = (rule, value, callback) => {
    if (value < minValue) {
      callback(`${translate("Credits count must be greater than")} ${minValue}`)
    } else {
      callback();
    }
  };

  const getProfile  = () => {
    REQUESTS.PROFILE()
      .then((data) => {
        dispatch(setProfile(data));
      })
      .catch((err) => {});
  };

  const onFinish = (values) => {
    setLoading(true);
    const body = {
      id: open.item.id,
      ...values
    };
    function resCallback(res) {
      setLoading(false);
      onClose(false);
      getData();
      message.success(res);
    }
    function errCallback(error) {
      setLoading(false);
      message.error(error);
    }

    if (open.type == "Add Credits") {
      if(by == "by sub"){
        REQUESTS.SUB_ADD_CREDIT_BY_SUB(body)
        .then((res) => {
          resCallback(res);
          getProfile();
        })
        .catch((error) => {
          errCallback(error);
        });
      }else{
        REQUESTS.SUBRESELLERS.ADD_CREDIT(body)
          .then((res) => {
            resCallback(res);
            getProfile();
          })
          .catch((error) => {
            errCallback(error);
          });
        }
    }
    

    if (open.type == "Take Credits") {
      
      if(by == "by sub"){
        REQUESTS.SUB_TAKE_CREDIT_BY_SUB(body)
        .then((res) => {
          resCallback(res);
          getProfile();
        })
        .catch((error) => {
          errCallback(error);
        });
      }else{
        REQUESTS.SUBRESELLERS.TAKE_CREDIT(body)
          .then((res) => {
            resCallback(res);
            getProfile();
          })
          .catch((error) => {
            errCallback(error);
          });
        
      }
    }
  };
  
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      form.submit();
    }
  };

  return (
    <Drawer
      forceRender
      open={open.show}
      onClose={onClose}
      placement="right"
      title={open.type}
    >
      <Form
        layout="vertical"
        form={form}
        onFinish={onFinish}
        autoComplete={"off"}
        initialValues={{
          branding: false,
          credit: minValue
        }}
        onKeyPress={(e) => handleKeyPress(e)}
      >
        <Form.Item
          label={translate("Count")}
          name="credit"
          rules={[
            {
              required: true,
              message: ""
            },
            {
              validator: validateCredits,
            },
          ]}
        >
          <InputNumber controls={false} min={minValue} style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item>
          <ButtonComponent
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
          >
            {translate("Save")}
          </ButtonComponent>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
