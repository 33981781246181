import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import REQUESTS from "../api/requests";

export function useHostsOptions(open) {
  const { profile } = useSelector((state) => state.profile);

  const needBuy =
    profile?.parent_id && profile?.status === "NEED_BUY_PARTNER_CREDITS";

  const canUseFree =
    profile?.parent_id && profile?.status === "CAN_USE_FREE_PARTNER_CREDITS";

  const [hosts, setHosts] = useState([]);
  const [hostsForSub, setHostsForSub] = useState([]);
  const [hostsNeedBuy, setHostsNeedBuy] = useState([]);

  useEffect(() => {
    if (profile?.parent_id) {
      REQUESTS.SERVER_HOSTS_FOR_SUB({ query: JSON.stringify({ limit: 10000 }) })
        .then((data) => {
          setHostsForSub(
            data?.rows?.map((el) => {
                return {
                  label: "DNS: " + el.name,
                  value: el.id,
                  ...el
                };
            })
          );
        })
        .catch((err) => {});
    }

    if (!canUseFree) {
      REQUESTS.SERVER_HOSTS.GET({ query: JSON.stringify({ limit: 10000 }) })
        .then((data) => {
          setHosts(
            data?.rows?.map((el) => {
              if (el?.host?.endsWith("/")) {
                let host = el?.host?.slice(0, -1);
                return {
                  label: el.name + " " + `(${host})`,
                  value: el.id,
                  ...el
                };
              } else {
                return {
                  label: el.name + " " + `(${el.host})`,
                  value: el.id,
                  ...el
                };
              }
            })
          );
        })
        .catch((err) => {});
    }

    if (needBuy && (hosts.length > 0 || hostsForSub.length > 0)) {
      const data = [...hosts, ...hostsForSub];
      setHostsNeedBuy(data);
    }
  }, [profile, hosts.length, hostsForSub.length]);

  if (needBuy) {
    return hostsNeedBuy;
  } else if (canUseFree) {
    return hostsForSub;
  } else {
    return hosts;
  }
}


