import { useState, useEffect } from "react";
import { Typography } from "antd";
import Footer from "./Footer";
import "../../styles/authorization-page.scss";

export default function AuthorizationPage({
  title,
  children,
  primaryColor,
  logo,
  logoSize,
}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const isMobile = windowWidth <= 992;

  useEffect(() => {
    const windowSize = () => setWindowWidth(window.innerWidth);

    window.addEventListener("resize", windowSize);

    return () => window.removeEventListener("resize", windowSize);
  }, []);

  return (
    <div
      className="authorization-page"
      style={{ backgroundColor: isMobile ? primaryColor : "#fff" }}
    >
      <div className="left" style={{ backgroundColor: primaryColor }}>
        <div className="logo-block">
          <img src={logo} alt="logo" style={{ width: `${logoSize * 2}rem` }} />
        </div>

        <Footer />
      </div>

      <div className="right">
        <div className="container">
          <div className="logo-right">
            <img src={logo} alt="logo" style={{ width: `${logoSize}rem` }} />
          </div>
          <Typography.Title
            style={{ marginBottom: "35px", color: primaryColor }}
          >
            {title}
          </Typography.Title>
          {children}
        </div>

        <div className="login-container-footer">
          <Footer />
        </div>
      </div>
    </div>
  );
}
