import React from "react";
import { Avatar, List } from "antd";
export default function CountryCard({ dataList }) {
  const minDataList = dataList?.slice(0, 6);
  return (
    <div className="country-card">
      <List
        dataSource={minDataList}
        renderItem={(item, index) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar src={item.flag} />}
              title={item.country}
            />
            <div>{item.count}</div>
          </List.Item>
        )}
      />
    </div>
  );
}
