import React from "react";
import ColorsPicker from "../../../components/ColorsPicker";
import useTranslate from "../../../hooks/translator";
import "../styles/colors.scss";

export default function GeneralColors({ colors, setColors }) {
  const translate = useTranslate();

  return (
    <div className="wrap-colors">
      <div>
        <p className="lable">{translate("Primary color")}</p>
        <ColorsPicker
          color={colors?.primary_color}
          onChange={(color) =>
            setColors({ ...colors, primary_color: color.hex })
          }
        />
      </div>
      <div>
        <p className="lable">{translate("Secondary color")}</p>
        <ColorsPicker
          color={colors?.secondary_color}
          onChange={(color) =>
            setColors({ ...colors, secondary_color: color.hex })
          }
        />
      </div>
    </div>
  );
}
