import React from "react";
import { useSelector } from "react-redux";

import useTranslate from "../../hooks/translator";
import InfoPopover from "../InfoPopover";

export default function DeviceConfigInfo() {
  const { profile } = useSelector((state) => state.profile);
  const translate = useTranslate();

  const info = (
    <div style={{ width: 300 }}>
      <p>
        {translate(
          "You can make setup for the activation of new devices in this section"
        )}
        <br />
        <span style={{ color: "red" }}> {translate("WARNING")}: </span>
        {translate("Credit will be charged for each activation")}
      </p>
      <p>
        {translate("There are 2 types of activation")}
        <br />
        1.{" "}
        {translate(
          "You can set up devices to be activated at once, after logging in"
        )}
        <br />
        2.{" "}
        {translate(
          "You can give Free trial to your users. Free trial can be between 1 and 7 days"
        )}
      </p>
      <p>{translate("With save button you can save your changes")}</p>
    </div>
  );

  return (
    !profile?.is_partner &&
    !profile?.parent_id && (
      <InfoPopover
        content={info}
        title={translate("New device configurations")}
      />
    )
  );
}
