import React from "react";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { IMAGES } from "../../../config";
import {
  VPN_IOS_HELP_LINK,
  VPN_ANDROID_HELP_LINK,
  VPN_DESKTOP_HELP_LINK,
  VPN_DESKTOP_LINK,
  VPN_IOS_LINK,
  VPN_ANDROID_LINK
} from "../../../config/config";

import "../styles/vpn.scss";

export default function VPNComponent() {
  var userAgent = navigator.userAgent;
  const returnBrowser = () => {
    if (userAgent.match(/Android/i)) {
      return VPN_ANDROID_LINK;
    } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
      return VPN_IOS_LINK;
    } else {
      return VPN_DESKTOP_LINK;
    }
  };

  const returnHelpLink = () => {
    if (userAgent.match(/Android/i)) {
      return VPN_ANDROID_HELP_LINK;
    } else if (userAgent.match(/iPhone|iPad|iPod/i)) {
      return VPN_IOS_HELP_LINK;
    } else {
      return VPN_DESKTOP_HELP_LINK;
    }
  };
  return (
    <div className="vpn-sector">
      <div className="vpn-background">
        <img src={IMAGES.VPN} alt="vpn" />
      </div>
      <div className="vpn-wrap">
        <div className="vpn-info-wrap">
          <span className="vpn-info">
            Se você tiver problema com o pagamento, por favor, instale a
            extensão VPN no seu navegador, mude o país para EUA e prossiga com o
            pagamento.
          </span>
        </div>

        <Button
          size="large"
          shape="default"
          icon={<DownloadOutlined />}
          onClick={() => window.open(returnBrowser())}
        >
          Baixar extensão
        </Button>

        <div className="como-instalar">
          <p onClick={() => window.open(returnHelpLink())}>Como instalar ?</p>
        </div>
      </div>
    </div>
  );
}
