import React, { useState, useEffect } from "react";
import { Form} from "antd";

import REQUESTS from "../../api/requests";

import useTranslate from "../../hooks/translator";

import { ICONS } from "../../config";
import {
  InputComponent,
  ButtonComponent,
  MessageComponent
} from "../../components";

              
  export default function AttachAccount({ setStep, setPercent, email, setEmail}) {
  const translate = useTranslate();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [messageErr, setMessageErr] = useState("");


  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setPercent((prev) => prev + 1);
      }, 10);
    }
  
    if(email){
      form.setFields([{ name: "email", value: email }]);
    }else{
      form.resetFields();
    }
  
  }, []);

  const onFinish = (values) => {
    setLoading(true);
    setEmail(values.email);
    
    const body = {
      email: values.email
    };

    REQUESTS.MULTI_ACCOUNT.CODE(body)
      .then((res) => {
        setLoading(false);
        setStep(1);
      })
      .catch((err) => {
        setLoading(false);
        setStep(0);
        if (typeof err == "string") {
          if(err == "Provider not found"){
            setMessageErr(translate(err))
          }else{
            setMessageErr(err);
          }
        }
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      form.submit()
    }
  };

  return (
    <>
      <div className="account-text-title">
        <h2>{translate("Attach account")}</h2>
        <p>
          {translate(
            "Please note that only previously registered accounts within our system can be linked or attached"
          )}
        </p>
      </div>
      <div className="account-form-wrap">
      <Form
        name="attach account"
        layout="vertical"
        form={form}
        onFinish={onFinish}
        onKeyPress={(e) => handleKeyPress(e)}
      >
        <Form.Item
          name="email"
          rules={[
            {
              type: "email",
              message: translate("E-mail isn't valid")
            },
            {
              required: true,
              message: translate("Please input your E-mail")
            }
          ]}
        >
          <InputComponent
            prefix={ICONS.USER}
            placeholder={translate("Enter your email")}
          />
        </Form.Item>

        <MessageComponent message={messageErr} />

        <Form.Item>
        <div className="accounts-confirmation-btns">
          <ButtonComponent
            type="primary"
            onClick={() => form.submit()}
            loading={loading}
            style={{ marginBottom: "10px" }}
          >
            {translate("Continue")}
          </ButtonComponent>
        </div>
        </Form.Item>
      </Form>
      </div>
   
    </>
  );
}
