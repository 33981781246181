import { useSelector } from "react-redux";
import translations from "../config/translates";

export default function useTranslate() {
  const { selectedLanguage } = useSelector((state) => state.profile);

  const translate = (key) => {
    return translations?.[key]?.[selectedLanguage] || key;
  };

  return translate;
}
