import { InputNumber } from "antd";
import { ImageUpload } from "../../../components";
import useTranslate from "../../../hooks/translator";
import { IMAGES } from "../../../config";

export default function Logo({ logo, setLogo }) {
  const translate = useTranslate();
  return (
    <div className="wrap-logo">
      <div style={{ minWidth: "104px", height: "104px" }}>
        <p className="lable">{translate("Upload logo")}</p>
        <ImageUpload
          setFileUrl={setLogo}
          fileUrl={logo}
          width="104px"
          height="104px"
        />
      </div>
      <div>
        <p className="lable">{translate("Resize logo")}</p>
        <InputNumber
          value={logo?.size}
          onChange={(val) => setLogo({ ...logo, size: val })}
        />
      </div>
    </div>
  );
}
