import { Modal } from "antd";
// import { WarningOutlined } from "@ant-design/icons";
import ICONS from "./icons";

export function allowLoginInfo(translate) {
  Modal.warning({
    width: 510,
    title: "", //translate("WARNING"),
    icon: null, // <WarningOutlined />,
    closable: true,
    content: (
      <div className="allow-login-content">
        <span>{translate("Dear Reseller")}.</span>
        <span> {translate("Your Free Trial Has Ended")}.</span>
        <span>
          {translate(
            "Thank you for trying our service! If you enjoyed your experience and want to continue accessing all premium features, please contact us for more information on our subscription plans. We're here to help you make the most of your IPTV experience"
          )}
        </span>
      </div>
    ),
    okText: `${translate("Contact us on")} Telegram`,
    onOk: () => {
      window.open("https://t.me/xtreamcloud");
    },
    okButtonProps: {
      icon: ICONS.TELEGRAM,
    },
    wrapClassName: "allow-login-info-btn",
  });
}
