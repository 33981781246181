import { Button } from "antd";
import "../styles/components.scss";

export default function ButtonComponent(props) {
  return (
    <Button
      type="primary"
      {...props}
      className={"button-component"}
      ref={props._ref}
    >
      {props.children}
    </Button>
  );
}
