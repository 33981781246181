const translations = {
  //a
  "Are you sure to reset this branding": {
    en: "Are you sure to reset this branding?",
    pt: "Tem certeza de que deseja redefinir esta marca?",
    de: "Sind Sie sicher, dass Sie dieses Branding zurücksetzen möchten?",
    es: "¿Está seguro de que desea restablecer esta marca?",
    fr: "Êtes-vous sûr de vouloir réinitialiser ce branding?",
    it: "Sei sicuro di voler reimpostare questo branding?",
    sq: "A jeni të sigurt që dëshironi të rivendosni këtë markë?",
    tr: "Bu markalamayı sıfırlamak istediğinizden emin misiniz?",
    sw: "Je, uko salama kuanzisha upya chapa hii?",
    am: "እባኮትን ይህን ባህሪ ወደ አዳዲስ ይቀይሩ እንዴት ይመስለዋል?",
    om: "Maaliin qaama hojii kana irra deebiin sirreessuu akka fedhu mirkaneessaa?",
    ig: "Ị bụ onye kwere ka emechaa ahịrị a?",
    so: "Ma hubtaa inaad dib u dejiso astaantan?",
    ha: "Kuna da tabbaci za ku sake saitin wannan alamar?",
    mn: "I ye ni a fɔɔ ni a dɔɔlɔna la?",
    ff: "Nde njiɗɗo, a heɓɓi so yaɓɓitinde ɗum?",
    yo: "Ṣe o dájú lati tun iṣapẹẹrẹ yi ṣe?",
  },

  "Applications default language": {
    en: "Applications default language",
    pt: "Idioma padrão do aplicativo",
    de: "Standard-Sprache der Anwendung",
    es: "Idioma predeterminado de la aplicación",
    fr: "Langue par défaut de l'application",
    it: "Lingua predefinita dell'applicazione",
    sq: "Gjuha e paracaktuar e aplikacionit",
    tr: "Uygulamanın varsayılan dili",
    sw: "Lugha ya default ya programu",
    am: "የመተግበሪያ ዋነኛ ቋንቋ",
    om: "Afaan bu'uuraa applikeeshinii",
    ig: "Asụsụ ndabara nke ngwa",
    so: "Luuqadda aasaasiga ah ee codsiga",
    ha: "Harshe na'ura ta asali",
    mn: "Программын үндсэн хэл",
    ff: "Bingel ngir app ngol fow",
    yo: "Èdá àtọkànwá ti awọn ohun elo",
  },

  "Add Image": {
    en: "Add Image",
    es: "Agregar imagen",
    fr: "Ajouter une image",
    de: "Bild hinzufügen",
    pt: "Adicionar imagem",
    it: "Aggiungi immagine",
    sq: "Shto imazh",
    tr: "Resim Ekle",
    sw: "Ongeza Picha",
    am: "ምስል አክል",
    om: "Suuraa Dabalata",
    ig: "Tinye Ihe Osise",
    so: "Ku dar sawir",
    ha: "Ƙara Hoton",
    mn: "Зураг нэмэх",
    ff: "Nde njiɗɗo, a faamii piche",
    yo: "Fikun aworan",
  },
  "Add Video": {
    en: "Add Video",
    es: "Agregar video",
    fr: "Ajouter une vidéo",
    de: "Video hinzufügen",
    pt: "Adicionar vídeo",
    it: "Aggiungi video",
    sq: "Shto video",
    tr: "Video Ekle",
    sw: "Ongeza Video",
    am: "ቪዲዮ አክል",
    om: "Viidiyoo Dabalata",
    ig: "Tinye Vidio",
    so: "Ku dar video",
    ha: "Ƙara Bidiyo",
    mn: "Видео нэмэх",
    ff: "Nde njiɗɗo, a faamii video",
    yo: "Fikun fidio",
  },
  Active: {
    en: "Active",
    pt: "Ativo",
    de: "Aktiv",
    es: "Activo",
    fr: "Actif",
    it: "Attivo",
    sq: "Aktiv",
    tr: "Aktif",
    sw: "Hai",
    am: "ንቁ",
    om: "Hojii irra jiru",
    ig: "Na-arụ ọrụ",
    so: "Firfircoon",
    ha: "Mafita",
    mn: "Идэвхитэй",
    ff: "Nden, a ye fowru",
    yo: "Iṣe",
  },
  "Activate devices": {
    en: "Activate devices",
    pt: "Ativar dispositivos",
    de: "Geräte aktivieren",
    es: "Activar dispositivos",
    fr: "Activer les appareils",
    it: "Attivare dispositivi",
    sq: "Aktivizoni pajisjet",
    tr: "Cihazları etkinleştir",
    sw: "Fanya vifaa kuwa hai",
    am: "መሳሪያዎችን አነቃቂ",
    om: "Maqaa fiizika qindeessi",
    ig: "Meegharị ngwaọrụ",
    so: "Furaha qalabka",
    ha: "Hactivate na'ura",
    mn: "Төхөөрөмжийг идэвхжүүлэх",
    ff: "Sari njiɗɗo",
    yo: "Mu ṣiṣẹ awọn ẹrọ",
  },
  "Add New Subreseller": {
    en: "Add New Subreseller",
    pt: "Adicionar Novo Subrevendedor",
    de: "Neuen Unterwiederverkäufer hinzufügen",
    es: "Agregar Nuevo Subrevendedor",
    fr: "Ajouter un Nouveau Sous-revendeur",
    it: "Aggiungi Nuovo Subrivenditore",
    sq: "Shtoni Sub-Rivenditorin e Ri",
    tr: "Yeni Alt Bayi Ekle",
    sw: "Ongeza Msubiri Mpya",
    am: "አዲስ አደረጃግን ይጨምሩ",
    om: "Maqaa Guddinaa Haaraa Dabalata",
    ig: "Tinye Nkọwa Ọhụrụ",
    so: "Ku darso Iibiyaha Cusub",
    ha: "Ƙara Sabon Mai sayarwa",
    mn: "Шинэ дэд худалдагч нэмэх",
    ff: "Nde ndokkudo sub-reseller ndiyam",
    yo: "Fi Olubasọrọ Tuntun Kun",
  },
  "Allow resellers to make use of DNS": {
    en: "Allow resellers to make use of DNS",
    pt: "Permitir que os revendedores usem DNS",
    de: "Erlauben Sie Wiederverkäufern, DNS zu verwenden",
    es: "Permitir a los revendedores hacer uso de DNS",
    fr: "Autoriser les revendeurs à utiliser le DNS",
    it: "Consenti ai rivenditori di utilizzare il DNS",
    sq: "Lejo shitësit të përdorin DNS",
    tr: "Bayiilerin DNS kullanmasına izin ver",
    sw: "Ruhusu wauzaji wa bidhaa kutumia DNS",
    am: "እባኮት የተወዳዳሪዎችን የDNS እንዲጠቀሙ ይፈቀዱ",
    om: "Guddina kanneen faayida DNS fayyadamuuf hayyamu",
    ig: "Kwe ka ndị na-ere ahịa jiri DNS",
    so: "U ogola iibiyeyaasha inay isticmaali DNS",
    ha: "Bari masu sayarwa suyi amfani da DNS",
    mn: "Дэд худалдагчдад DNS ашиглахыг зөвшөөрнө үү",
    ff: "Nde njalɗe e resellers ɗe waɗi DNS",
    yo: "Jẹ́ kí awọn olùtajà lo DNS",
  },
  "All Rights Reserved": {
    en: "All Rights Reserved",
    pt: "Todos os Direitos Reservados",
    de: "Alle Rechte vorbehalten",
    es: "Todos los derechos reservados",
    fr: "Tous droits réservés",
    it: "Tutti i diritti riservati",
    sq: "Të gjitha të drejtat e rezervuara",
    tr: "Tüm Hakları Saklıdır",
    sw: "Haki Zote Zimehifadhiwa",
    am: "ሁሉም መสิይ ተይዕታማ",
    om: "Mirga hundi ni eeggataa",
    ig: "Ndị niile nwere ikike",
    so: "Dhammaan Xuquuqda Waa La Haystaa",
    ha: "Duk hakki ana riƙe",
    mn: "Бүх эрх хадгалагдсан",
    ff: "Dewal hakkunde e ngam",
    yo: "Gbogbo awọn ẹtọ ni a fipamọ",
  },
  All: {
    en: "All",
    pt: "Todos",
    de: "Alle",
    es: "Todos",
    fr: "Tous",
    it: "Tutti",
    sq: "Të gjitha",
    tr: "Hepsi",
    sw: "Yote",
    am: "ሁሉም",
    om: "Hunda",
    ig: "Ndị niile",
    so: "Dhammaan",
    ha: "Duka",
    mn: "Бүгд",
    ff: "Fii",
    yo: "Gbogbo",
  },
  adults: {
    en: "adults",
    pt: "adultos",
    de: "Erwachsene",
    es: "adultos",
    fr: "adultes",
    it: "adulti",
    sq: "Të rriturit",
    tr: "yetişkinler",
    sw: "Watu wazima",
    am: "ታዋቂዎች",
    om: "Dargaggoota",
    ig: "Nd成年人",
    so: "Dadka waaweyn",
    ha: "Manyan",
    mn: "Насанд хүрэгчид",
    ff: "Njeɗɗo",
    yo: "Agba",
  },
  Amount: {
    en: "Amount",
    pt: "Quantidade",
    de: "Betrag",
    es: "Cantidad",
    fr: "Montant",
    it: "Importo",
    sq: "Shuma",
    tr: "Miktar",
    sw: "Kiasi",
    am: "ብር",
    om: "Baay'ina",
    ig: "Ọnụọgụ",
    so: "Tirada",
    ha: "Adadin",
    mn: "Тоон",
    ff: "Jummere",
    yo: "Iye",
  },
  Actives: {
    en: "Actives",
    pt: "Ativos",
    de: "Aktiv",
    es: "Activos",
    fr: "Actifs",
    it: "Attivi",
    sq: "Aktivë",
    tr: "Aktifler",
    sw: "Wanaofanya kazi",
    am: "እንቅስቃሴዎች",
    om: "Hojii",
    ig: "Ọrụ",
    so: "Hawlaha",
    ha: "Masu Aiki",
    mn: "Гүйцэтгэгчид",
    ff: "Jigijigi",
    yo: "Ise",
  },
  "Activated by": {
    en: "Activated by",
    pt: "Ativado por",
    de: "Aktiviert durch",
    es: "Activado por",
    fr: "Activé par",
    it: "Attivato da",
    sq: "Aktivuar nga",
    tr: "Tarafından etkinleştirildi",
    sw: "Imewezeshwa na",
    am: "በዚህ ተቋቋም",
    om: "Keenya ijaqaa",
    ig: "Nkwusịrị site na",
    so: "Waxaa hawlgalay",
    ha: "An yi",
    mn: "Идэвхжүүлсэн хүн",
    ff: "Jigaado,",
    yo: "Ti a mu ṣiṣẹ nipasẹ",
  },
  Action: {
    en: "Action",
    pt: "Ação",
    de: "Aktion",
    es: "Acción",
    fr: "Action",
    it: "Azione",
    sq: "Veprim",
    tr: "Eylem",
    sw: "Kitendo",
    am: "እንቅስቃሴ",
    om: "Hojii",
    ig: "Akwukwo",
    so: "Tallaabo",
    ha: "Aiki",
    mn: "Үйлдэл",
    ff: "Feere",
    yo: "Iṣe",
  },
  Activate: {
    en: "Activate",
    pt: "Ativar",
    de: "Aktivieren",
    es: "Activar",
    fr: "Activer",
    it: "Attivare",
    sq: "Aktivizo",
    tr: "Etkinleştir",
    sw: "Fanya kazi",
    am: "እንቅስቃሴ",
    om: "Keenya",
    ig: "Gba ọsọ",
    so: "Hawlgali",
    ha: "Aiki",
    mn: "Идэвхжүүлэх",
    ff: "Hakkil",
    yo: "Mu ṣiṣẹ",
  },
  Activation: {
    en: "Activation",
    pt: "Ativação",
    de: "Aktivierung",
    es: "Activación",
    fr: "Activation",
    it: "Attivazione",
    sq: "Aktivizim",
    tr: "Aktivasyon",
    sw: "Uanzishaji",
    am: "እንቅስቃሴ",
    om: "Hojii",
    ig: "Ịgba ọsọ",
    so: "Hawlgallinta",
    ha: "Aiki",
    mn: "Идэвхжүүлэлт",
    ff: "Wujjinde",
    yo: "Iṣe",
  },
  "Activate now or add free trial to device": {
    en: "Activate now or add free trial to device",
    pt: "Ativar agora ou adicionar teste gratuito ao dispositivo",
    de: "Jetzt aktivieren oder kostenlose Testversion zum Gerät hinzufügen",
    es: "Activar ahora o agregar prueba gratuita al dispositivo",
    fr: "Activer maintenant ou ajouter un essai gratuit au périphérique",
    it: "Attiva ora o aggiungi una prova gratuita al dispositivo",
    sq: "Aktivo tani ose shtoni provë falas në pajisje",
    tr: "Şimdi etkinleştir veya cihaza ücretsiz deneme ekle",
    sw: "Washa sasa au ongeza majaribio ya bure kwenye kifaa",
    am: "አሁን አንቀበል ወይም እንደ ነፃ ሙከራ ወደ መሣሪያ ያክል",
    om: "Amma ammasi karaa yookaan dabalata yaadannoo bilisaa dhaaf meeshaa",
    ig: "Mezuru ugbu a ma ọ bụ tinye nnwale efu na ngwaọrụ",
    so: "Hadda u firfircoonaan ama ku dar tijaabo bilaash ah qalabka",
    ha: "Kunna kunna yanzu ko ƙara gwajin kyauta zuwa na'ura",
    mn: "Одоо идэвхжүүлж эсвэл төхөөрөмжид үнэгүй туршилт нэмэх",
    ff: "Ammay, njiɗɗo ku waɗi seɗa fowru e ciyal",
    yo: "Muṣiṣẹpọ ni bayi tabi fi idanwo ọfẹ kun ẹrọ",
  },
  Approve: {
    en: "Approve",
    pt: "Aprovar",
    de: "Genehmigen",
    es: "Aprobar",
    fr: "Approuver",
    it: "Approvare",
    sq: "Mirato",
    tr: "Onayla",
    sw: "Thibitisha",
    am: "እንዲያውም",
    om: "Eeyyamuu",
    ig: "Kwadoro",
    so: "Aqbala",
    ha: "Amince",
    mn: "Зөвшөөрөх",
    ff: "Tawfe",
    yo: "Fọwọsi",
  },
  "App name": {
    en: "App name",
    pt: "Nome do aplicativo",
    de: "App-Name",
    es: "Nombre de la aplicación",
    fr: "Nom de l'application",
    it: "Nome dell'app",
    sq: "Emri i aplikacionit",
    tr: "Uygulama adı",
    sw: "Jina la programu",
    am: "የመተግበሪያ ስም",
    om: "Maqaa App",
    ig: "Aha ngwa",
    so: "Magaca app",
    ha: "Sunan aikace-aikace",
    mn: "Программын нэр",
    ff: "Innde app",
    yo: "Orukọ app",
  },
  Version: {
    en: "Version",
    es: "Versión",
    fr: "Version",
    de: "Version",
    pt: "Versão",
    it: "Versione",
    sq: "Versioni",
    tr: "Sürüm",
    sw: "Toleo",
    am: "ትርጉም",
    om: "Baafata",
    ig: "Nsụgharị",
    so: "Nooca",
    ha: "Sigar",
    mn: "Хувилбар",
    ff: "Janngo",
    yo: "Ẹya",
  },
  "App version": {
    en: "App version",
    pt: "Versão do aplicativo",
    de: "App-Version",
    es: "Versión de la aplicación",
    fr: "Version de l'application",
    it: "Versione dell'app",
    sq: "Versioni i aplikacionit",
    tr: "Uygulama sürümü",
    sw: "Toleo la programu",
    am: "የመተግበሪያ ትርጉም",
    om: "Baafata applikeeshinii",
    ig: "Nsụgharị ngwa",
    so: "Nooca barnaamijka",
    ha: "Sigar manhaja",
    mn: "Программын хувилбар",
    ff: "Janngo le app",
    yo: "Ẹya ohun elo",
  },
  "API key": {
    en: "API key",
    pt: "Chave da API",
    de: "API-Schlüssel",
    es: "Clave de API",
    fr: "Clé API",
    it: "Chiave API",
    sq: "Çelësi API",
    tr: "API anahtarı",
    sw: "Funguo la API",
    am: "የAPI ቁልፍ",
    om: "Balaa API",
    ig: "Igodo API",
    so: "Furaha API",
    ha: "Mabuɗin API",
    mn: "API түлхүүр",
    ff: "Goo API",
    yo: "Bọtini API",
  },
  "Access code": {
    en: "Access code",
    pt: "Código de acesso",
    de: "Zugangscode",
    es: "Código de acceso",
    fr: "Code d'accès",
    it: "Codice di accesso",
    sq: "Kodi i hyrjes",
    tr: "Erişim kodu",
    sw: "Nambari ya ufikiaji",
    am: "የግባት ቁጥር",
    om: "Koodii seensa",
    ig: "Koodu nnweta",
    so: "Koodhka gelitaanka",
    ha: "Lambar shiga",
    mn: "Хандалтын код",
    ff: "Lodda njang",
    yo: "Koodu iraye",
  },
  "Auto renew": {
    en: "Auto renew",
    pt: "Renovação automática",
    de: "Automatische Verlängerung",
    es: "Renovación automática",
    fr: "Renouvellement automatique",
    it: "Rinnovo automatico",
    sq: "Rinovim automatik",
    tr: "Otomatik yenileme",
    sw: "Kujiendesha kiotomatiki",
    am: "አውቶ ይሻሽ",
    om: "Keenya deebi'uu",
    ig: "Mmelite akpọrọ",
    so: "Dib u cusboonaysiin otomaatik ah",
    ha: "Sake sabunta ta atomatik",
    mn: "Автоматаар шинэчлэх",
    ff: "Jung ngooro",
    yo: "Tuntun aifọwọyi",
  },
  "Add Credits": {
    en: "Add Credits",
    pt: "Adicionar créditos",
    de: "Guthaben hinzufügen",
    es: "Agregar créditos",
    fr: "Ajouter des crédits",
    it: "Aggiungi crediti",
    sq: "Shtoni kredite",
    tr: "Kredi ekle",
    sw: "Ongeza Mikopo",
    am: "ክሬዲት ያክሉ",
    om: "Krediti dabalataa",
    ig: "Tinye Kredits",
    so: "Ku dar Kharash",
    ha: "Ƙara Kiredit",
    mn: "Кредит нэмэх",
    ff: "Ngoodi njiɗɗo",
    yo: "Fi kun awọn Kirẹditi",
  },
  "Auto approve": {
    en: "Auto approve",
    pt: "Aprovação automática",
    de: "Automatische Genehmigung",
    es: "Aprobación automática",
    fr: "Approbation automatique",
    it: "Approvazione automatica",
    sq: "Mirato automatikisht",
    tr: "Otomatik onayla",
    sw: "Idhini Kiotomatiki",
    am: "አውቶ አድርግ",
    om: "Akkam Jiru Beeksisa",
    ig: "Nye nkwado akpa",
    so: "Otomaatik u ogolaansho",
    ha: "Amincewa ta atomatik",
    mn: "Автомат зөвшөөрөл",
    ff: "Amin wade njiɗɗo",
    yo: "Aṣeyọri laifọwọyi",
  },
  "Activated from parent": {
    en: "Activated from parent",
    pt: "Ativado pelo pai",
    de: "Aktiviert vom Elternteil",
    es: "Activado desde el padre",
    fr: "Activé depuis le parent",
    it: "Attivato dal genitore",
    sq: "Aktivare nga prindërit",
    tr: "Ebeveyn tarafından etkinleştirildi",
    sw: "Imewashwa kutoka kwa mzazi",
    am: "ከወላጅ ተነቀሳ",
    om: "Abbaa irraa hojiirra oole",
    ig: "Emechara site na nne",
    so: "Ka laga soo bilaabay waalid",
    ha: "Anya daga iyaye",
    mn: "Эцгээс идэвхжүүлсэн",
    ff: "A heɓɓi nder njiɗɗo",
    yo: "Ti wa ni ṣiṣiṣẹ lati ọdọ obi",
  },
  "activation info": {
    en: "If you will not choose any types of activation the device will be automatically activated and the credit will be charged.",
    pt: "Se você não escolher nenhum tipo de ativação, o dispositivo será ativado automaticamente e o crédito será cobrado.",
    de: "Wenn Sie keine Aktivierungsart auswählen, wird das Gerät automatisch aktiviert und Ihr Guthaben wird belastet.",
    es: "Si no elige ningún tipo de activación, el dispositivo se activará automáticamente y se le cobrará el crédito.",
    fr: "Si vous ne choisissez pas de type d'activation, le dispositif sera activé automatiquement et le crédit sera débité.",
    it: "Se non scegli alcun tipo di attivazione, il dispositivo verrà attivato automaticamente e il credito verrà addebitato.",
    sq: "Nëse nuk zgjedhni asnjë lloj aktivizimi, pajisja do të aktivizohet automatikisht dhe do të ngarkohet krediti.",
    tr: "Herhangi bir etkinleştirme türü seçmezseniz, cihaz otomatik olarak etkinleştirilecek ve kredi hesabınızdan düşülecektir.",
    sw: "taarifa ya uanzishaji",
    am: "መረጃ አንድ",
    om: "Odeeffannoo Aangoo",
    ig: "Ozi gbasara ịmepụta",
    so: "Macluumaadka firfircoonida",
    ha: "Bayanin kunna",
    mn: "Идэвхжүүлэх мэдээлэл",
    ff: "Njiɗɗo nder njiɗɗo",
    yo: "Alaye muṣiṣẹ",
  },
  "Activated from device": {
    en: "Activated from device",
    pt: "Ativado a partir do dispositivo",
    de: "Aktiviert vom Gerät",
    es: "Activado desde el dispositivo",
    fr: "Activé depuis l'appareil",
    it: "Attivato dal dispositivo",
    sq: "Aktivizuar nga pajisja",
    tr: "Cihazdan etkinleştirildi",
    sw: "Imeanzishwa kutoka kwa kifaa",
    am: "ከመሣሪያ ወደ ተነሣ",
    om: "Maqaalee irraa deebi'ee",
    ig: "Kachasị site na ngwaọrụ",
    so: "Ka dhaqaajiyey qalab",
    ha: "An yi daga na'ura",
    mn: "Төхөөрөмжөөс идэвхжүүлсэн",
    ff: "Njiɗɗo e njiɗɗo",
    yo: "Muṣiṣẹ lati ẹrọ",
  },
  "Activated from panel": {
    en: "Activated from panel",
    pt: "Ativado a partir do painel",
    de: "Aktiviert vom Panel",
    es: "Activado desde el panel",
    fr: "Activé depuis le panneau",
    it: "Attivato dal pannello",
    sq: "Aktivizuar nga paneli",
    tr: "Panelden etkinleştirildi",
    sw: "Imeanzishwa kutoka kwenye paneli",
    am: "ከመዋቅር ወደ ተነሣ",
    om: "Maqaa laa'aa irraa deebi'ee",
    ig: "Kachasị site na panel",
    so: "Ka dhaqaajiyey ka panel",
    ha: "An yi daga fayil",
    mn: "Панелаас идэвхжүүлсэн",
    ff: "Njiɗɗo e manti",
    yo: "Muṣiṣẹ lati panẹli",
  },
  "Activate device": {
    en: "Activate device",
    pt: "Ativar dispositivo",
    de: "Gerät aktivieren",
    es: "Activar dispositivo",
    fr: "Activer l'appareil",
    it: "Attiva dispositivo",
    sq: "Aktivizo pajisjen",
    tr: "Cihazı etkinleştir",
    sw: "Fanya kazi kifaa",
    am: "መሣሪያ አንድ ይቀይሩ",
    om: "Maqaa laa'aa deebi'ee",
    ig: "Na-eme ka ngwaọrụ",
    so: "Kicitaan caddayso",
    ha: "Kafa na'ura",
    mn: "Барагт идэвхжүүлэх",
    ff: "Dewal e njiɗɗo",
    yo: "Muṣiṣẹ ẹrọ",
  },

  "Account Status": {
    en: "Account Status",
    pt: "Status da conta",
    de: "Kontostatus",
    es: "Estado de la cuenta",
    fr: "État du compte",
    it: "Stato dell'account",
    sq: "Statusi i Llogarisë",
    tr: "Hesap Durumu",
    sw: "Hali ya Akaunti",
    am: "የአካውንት ሁኔታ",
    om: "Haala Akaawuntii",
    ig: "Ọnọdụ Akaụntụ",
    so: "Xaaladda Akowntiga",
    ha: "Matsayin Asusun",
    mn: "Хэрэглэгчийн статус",
    ff: "Bara haala ɗaɓɓita",
    yo: "Ipo Iṣowo",
  },
  "Add new device": {
    en: "Add New Device",
    pt: "Adicionar Novo Dispositivo",
    de: "Neues Gerät Hinzufügen",
    es: "Agregar Nuevo Dispositivo",
    fr: "Ajouter Un Nouveau Dispositif",
    it: "Aggiungi Nuovo Dispositivo",
    sq: "Shto Pajisje Të Reja",
    tr: "Yeni Cihaz Ekle",
    sw: "Ongeza kifaa kipya",
    am: "መሣሪያ አዲስ ይጨምሩ",
    om: "Qalbi haaraa dabalataa",
    ig: "Tinye ngwá ọrụ ọhụrụ",
    so: "Ku darso qalab cusub",
    ha: "Sabon na'ura ƙara",
    mn: "Шинэ төхөөрөмж нэмэх",
    ff: "Ɓaɗo njijji to",
    yo: "Fi ẹrọ tuntun kun",
  },
  "Add New DNS": {
    en: "Add New DNS",
    pt: "Adicionar Novo DNS",
    de: "Neue DNS Hinzufügen",
    es: "Agregar Nuevo DNS",
    fr: "Ajouter Un Nouveau DNS",
    it: "Aggiungi Nuovo DNS",
    sq: "Shto DNS Të Reja",
    tr: "Yeni DNS Ekle",
    sw: "Ongeza DNS Mpya",
    am: "አዲስ DNS ይጨምሩ",
    om: "DNS Haaraa dabalataa",
    ig: "Tinye DNS Ọhụrụ",
    so: "Ku darso DNS cusub",
    ha: "Sabon DNS ƙara",
    mn: "Шинэ DNS нэмэх",
    ff: "Ɓaɗo DNS ɓiɓɓe",
    yo: "Fi DNS tuntun kun",
  },
  "All devices": {
    en: "All devices",
    pt: "Todos os dispositivos",
    de: "Alle Geräte",
    es: "Todos los dispositivos",
    fr: "Tous les appareils",
    it: "Tutti i dispositivi",
    sq: "Të gjitha pajisjet",
    tr: "Tüm cihazlar",
    sw: "Vifaa vyote",
    am: "መሣሪያዎች ሁሉ",
    om: "Qabiyyee hunda",
    ig: "Ngwa niile",
    so: "Dhammaan qalabka",
    ha: "Dukkan na'urori",
    mn: "Бүх төхөөрөмж",
    ff: "Ceeso e ɗiɗi",
    yo: "Gbogbo awọn ẹrọ",
  },
  Approved: {
    en: "Approved",
    pt: "Aprovado",
    de: "Genehmigt",
    es: "Aprobado",
    fr: "Approuvé",
    it: "Approvato",
    sq: "Miratuar",
    tr: "Onaylandı",
    sw: "Imeidhinishwa",
    am: "ተጠቅሟል",
    om: "Eeyyamame",
    ig: "Nkwenye",
    so: "La oggolaaday",
    ha: "An yi amincewa",
    mn: "Батлагдсан",
    ff: "A jooɗi",
    yo: "Fọwọsi",
  },
  "Activated devices": {
    en: "Activated devices",
    pt: "Dispositivos ativados",
    de: "Aktivierte Geräte",
    es: "Dispositivos activados",
    fr: "Appareils activés",
    it: "Dispositivi attivati",
    sq: "Pajisje të aktivizuara",
    tr: "Etkinleştirilen cihazlar",
    sw: "Vifaa vilivyowashwa",
    am: "እንቅስቃሴ የተደረገ መሳሪያዎች",
    om: "Qabiyyeen hojii jalqabame",
    ig: "Ngwa arụnyere",
    so: "Qalabka la hawlgeliyey",
    ha: "Na'ura da aka kunna",
    mn: "Идэвхжүүлсэн төхөөрөмжүүд",
    ff: "Ciwri e a jiɗɗo",
    yo: "Awọn ẹrọ ti a mu ṣiṣẹ",
  },
  "Allow app branding": {
    en: "Allow app branding",
    pt: "Permitir marca de aplicativo",
    de: "App-Branding zulassen",
    es: "Permitir marca de la aplicación",
    fr: "Autoriser la marque de l'application",
    it: "Consenti il branding dell'app",
    sq: "Lejo brandingun e aplikacionit",
    tr: "Uygulama markalamasına izin ver",
    sw: "Ruhusu chapa ya programu",
    am: "መተግበሪያ ባህሪ ይፈቀድ",
    om: "App brandii hayyama",
    ig: "Kwe ka ngwa a nwee ụdị",
    so: "U ogola astaanta barnaamijka",
    ha: "Bada alamar aikace-aikacen",
    mn: "Програмын брэндийг зөвшөөрөх",
    ff: "A heɓɓi alama e safa",
    yo: "Gba laaye ami ohun elo",
  },
  "Activation type": {
    en: "Activation type",
    pt: "Tipo de ativação",
    de: "Aktivierungstyp",
    es: "Tipo de activación",
    fr: "Type d'activation",
    it: "Tipo di attivazione",
    sq: "Lloji i aktivizimit",
    tr: "Aktivasyon türü",
    sw: "Aina ya uanzishaji",
    am: "የአቀፍ ዓይነት",
    om: "Noon ammayaa",
    ig: "Udi nke mmalite",
    so: "Nooca dhaqaajinta",
    ha: "Nau'in kunna",
    mn: "Идэвхжүүлэлтийн төрөл",
    ff: "Nooji faamita",
    yo: "Iru iṣapẹrẹ",
  },
  "Add notification": {
    en: "Add notification",
    pt: "Adicionar notificação",
    de: "Benachrichtigung hinzufügen",
    es: "Agregar notificación",
    fr: "Ajouter une notification",
    it: "Aggiungi notifica",
    sq: "Shto njoftim",
    tr: "Bildirim ekle",
    sw: "Ongeza arifa",
    am: "ማሳወቂያ ጨምር",
    om: "Yaada dabalataa",
    ig: "Tinye nkw уведомление",
    so: "Kudarso ogeysiis",
    ha: "Ƙara sanarwa",
    mn: "Мэдэгдэл нэмэх",
    ff: "Ndo gɓa yaaƴi",
    yo: "Ṣe afikun iwifunni",
  },
  "Add New Sub-Reseller": {
    en: "Add New Sub-Reseller",
    pt: "Adicionar Novo Sub-Revendedor",
    de: "Neuen Unterwiederverkäufer hinzufügen",
    es: "Agregar Nuevo Subrevendedor",
    fr: "Ajouter un Nouveau Sous-revendeur",
    it: "Aggiungi Nuovo Sub-Rivenditore",
    sq: "Shtoni Sub-Rishitës të Ri",
    tr: "Yeni Alt Bayi Ekle",
    sw: "Ongeza Muuzaji Mpya",
    am: "አዲስ አቃባቢ ያክሉ",
    om: "Gara gurgurtaa haaraa dabalata",
    ig: "Tinye ndị na-ere ahịa ọhụrụ",
    so: "Ku dar sub-iibiyaha cusub",
    ha: "Ƙara sabon mai sayarwa",
    mn: "Шинэ дэд борлуулагч нэмэх",
    ff: "Ndo gɓa sub-ɓaaba gberre",
    yo: "Ṣe afikun sub-reseeler tuntun",
  },
  "Admin Panel Login Page": {
    en: "Admin Panel Login Page",
    pt: "Página de Login do Painel de Administração",
    de: "Anmeldeseite des Admin-Panels",
    es: "Página de Inicio de Sesión del Panel de Administración",
    fr: "Page de Connexion du Panneau d'Administration",
    it: "Pagina di Accesso al Pannello di Amministrazione",
    sq: "Faqe Hyrjeje në Panelin e Adminitratitës",
    tr: "Yönetici Paneli Giriş Sayfası",
    sw: "Ukurasa wa Kuingia wa Bodi ya Wasimamizi",
    am: "የአስተዳዳሪ ገፅ ወይት ገበታ ወይም ይገባ",
    om: "Seensa Paanela Abbaa Taayitaa",
    ig: "Ihu ọma nbanye onye nchịkwa",
    so: "Bogga Galitaanka Maamulka",
    ha: "Shafin Shiga Panel na Admin",
    mn: "Администратору панелийн нэвтрэх хуудас",
    ff: "Dewoy walli kaɓe ɓiɗɗo aɓi",
    yo: "Oju-iwe Wọle Panẹli Alakoso",
  },
  "Add new DNS configurations": {
    en: "Add new DNS configurations",
    pt: "Adicionar novas configurações de DNS",
    de: "Neue DNS-Konfigurationen hinzufügen",
    es: "Agregar nuevas configuraciones de DNS",
    fr: "Ajouter de nouvelles configurations DNS",
    it: "Aggiungi nuove configurazioni DNS",
    sq: "Shto konfigurime të reja të DNS",
    tr: "Yeni DNS yapılandırmaları ekle",
    sw: "Ongeza usanidi mpya wa DNS",
    am: "አዲስ ዲኤንኤስ ኮንፊግሬሽን ያክሉ",
    om: "DNS haala haaraa dabalataa",
    ig: "Tinye usoro DNS ọhụrụ",
    so: "Ku dar config cusub oo DNS ah",
    ha: "Ƙara sababbin tsare-tsaren DNS",
    mn: "Шинэ DNS тохиргоог нэмэх",
    ff: "Njiɗɗo konfigiriyo ɗiɗi maɓɓe DNS",
    yo: "Fikun-un awọn iṣeto DNS tuntun",
  },
  "Activate without free trial": {
    en: "Activate without free trial",
    pt: "Ativar sem teste gratuito",
    de: "Aktivieren ohne kostenlose Testversion",
    es: "Activar sin prueba gratuita",
    fr: "Activer sans essai gratuit",
    it: "Attiva senza prova gratuita",
    sq: "Aktivo pa provë falasë",
    tr: "Ücretsiz deneme olmadan etkinleştir",
    sw: "Sasisha bila majaribio bure",
    am: "ከነፃ ግምገማ ውስጥ አንድ እንደዚህ አቀርባለሁ",
    om: "Maqaa haaraa malee faayidaa bilisaa",
    ig: "Na-emegharị na-enweghị nnwale efu",
    so: "Hawashada iyada oo aan tijaabo bilaash ah",
    ha: "A kunna tare da gwaji kyauta",
    mn: "Төлбөргүй туршилтгүй идэвхжүүлэх",
    ff: "A heɓɓi njiɗɗo, a faami soɓɓa nder kadi",
    yo: "Muu ṣiṣẹ laisi idanwo ọfẹ",
  },
  "Auto renew when device expired": {
    en: "Auto renew when device expired",
    pt: "Renovação automática quando o dispositivo expirar",
    de: "Automatische Verlängerung, wenn das Gerät abgelaufen ist",
    es: "Renovación automática cuando el dispositivo haya caducado",
    fr: "Renouvellement automatique lorsque l'appareil a expiré",
    it: "Rinnova automaticamente quando il dispositivo è scaduto",
    sq: "Rinovohet automatikisht kur pajisja skadon",
    tr: "Cihazın süresi dolduğunda otomatik yenile",
    sw: "Ili kusasisha kiotomatiki wakati kifaa kimemalizika",
    am: "መሳሪያው እንደወቅቱ ተወው በሚገኙበት ወቅት ይቀየራል",
    om: "Qabiyyee yeroon meeshaa akka cufamu banu",
    ig: "Na-emegharị nkeji mgbe ngwaọrụ furu efu",
    so: "Si loo otomaatig ah marka qalabku dhamaado",
    ha: "Auto sabunta idan an ƙare na'urar",
    mn: "Төхөөрөмж дуусахад автомат шинэчлэх",
    ff: "A heɓɓa njiɗɗo, a newa ko fotiɗe taɓo",
    yo: "Tunṣe laifọwọyi nigbati ẹrọ ba pari",
  },
  "Are you sure you want to": {
    en: "Are you sure you want to",
    pt: "Tem certeza de que deseja",
    de: "Sind Sie sicher, dass Sie möchten",
    es: "¿Estás seguro de que quieres",
    fr: "Êtes-vous sûr de vouloir",
    it: "Sei sicuro di voler",
    sq: "A jeni të sigurtë që doni të",
    tr: "Bunu yapmak istediğinize emin misiniz",
    sw: "Je, uko salama unataka",
    am: "እባኮትን ይህን እቅድ አድርጉ",
    om: "Maaliin siif haa ta’u",
    ig: "Ị bụ onye kwere ka",
    so: "Ma hubtaa inaad",
    ha: "Kuna da tabbaci kana son",
    mn: "Сан энэ алхмыг хийхийг хүсч байна уу",
    ff: "Nde njiɗɗo a waɗɗi",
    yo: "Ṣe o dájú pe o fẹ lati",
  },
  "Are you sure you want to delete": {
    en: "Are you sure you want to delete?",
    pt: "Tem certeza de que deseja excluir?",
    de: "Sind Sie sicher, dass Sie löschen möchten?",
    es: "¿Estás seguro de que quieres borrar?",
    fr: "Êtes-vous sûr de vouloir supprimer ?",
    it: "Sei sicuro di voler eliminare?",
    sq: "A jeni të sigurtë që doni ta fshijeni?",
    tr: "Silmek istediğinize emin misiniz?",
    sw: "Je, uko salama unataka kufuta",
    am: "እባኮት ይህን ማስወግድ ትፈልጋለህ?",
    om: "Maaliin haaraa kana haqachuu barbaaddaa?",
    ig: "Ị bụ onye kwere iwepụ?",
    so: "Ma hubtaa inaad tirtirto?",
    ha: "Kuna da tabbaci kuna son goge?",
    mn: "I ye ni a fɔɔ ni a dɔɔlɔna la?",
    ff: "Nde njiɗɗo, a heɓɓi so njiɗɗitinde?",
    yo: "Ṣe o dájú pe o fẹ lati pa",
  },

  "Allow users to login from application": {
    en: "Allow users to login from application",
    pt: "Permitir que os usuários façam login pelo aplicativo",
    de: "Erlauben Sie Benutzern, sich von der Anwendung aus anzumelden",
    es: "Permitir que los usuarios inicien sesión desde la aplicación",
    fr: "Autoriser les utilisateurs à se connecter depuis l'application",
    it: "Consenti agli utenti di effettuare l'accesso dall'applicazione",
    sq: "Lejo përdoruesve të hyjnë në llogari nga aplikacioni",
    tr: "Kullanıcıların uygulamadan giriş yapmasına izin ver",
    sw: "Ruhusu watumiaji kuingia kutoka kwenye programu",
    am: "ተጠቃሚዎች ከመተግበሪያ መግባት ይፈቀድ",
    om: "Ijaawwan banuu teessoo iddoo isaa galuuf hayyamu",
    ig: "Kwadoro ndị ọrụ ka ha banye site na ngwa",
    so: "U ogol yahay in isticmaaleyaashu ay ka galaan codsiga",
    ha: "Kuna da tabbaci masu amfani suna iya shiga daga aikace-aikacen",
    mn: "Koma i yera e dɔɔlɔ la ni na kɔ a yɛ la?",
    ff: "A jabi, njiɗɗo naaniiji lan hoɗi app lan?",
    yo: "Ṣe o gba awọn olumulo laaye lati wọle lati inu ohun elo",
  },

  "An unknown error occurred": {
    en: "An unknown error occurred",
    pt: "Ocorreu um erro desconhecido",
    de: "Ein unbekannter Fehler ist aufgetreten",
    es: "Ocurrió un error desconocido",
    fr: "Une erreur inconnue s'est produite",
    it: "Si è verificato un errore sconosciuto",
    sq: "Ndodhi një gabim i panjohur",
    tr: "Bilinmeyen bir hata oluştu",
    sw: "Kosa kisichojulikana kilitokea",
    am: "ታዋቂ የማይታወቅ ድህረ ምስጢር ተከስቷል",
    om: "Dogoggora hin beekamne uumame",
    ig: "Mpekere na-adịghị ama mere",
    so: "Khalad aan la garanayn ayaa dhacday",
    ha: "An yiwuwar kuskure na rashin sani ya faru",
    mn: "Баталгаагүй алдаа гарлаа",
    ff: "Gollo, koy ɗiɗo jogi e foti?",
    yo: "Aṣiṣe aimọ kan ṣẹlẹ",
  },

  "Are you sure you want to remove this banner ad": {
    en: "Are you sure you want to remove this banner ad?",
    es: "¿Estás seguro de que deseas eliminar este banner?",
    fr: "Êtes-vous sûr de vouloir supprimer cette bannière publicitaire ?",
    de: "Sind Sie sicher, dass Sie dieses Banner-Werbung entfernen möchten?",
    pt: "Tem certeza de que deseja remover este banner?",
    it: "Sei sicuro di voler rimuovere questo banner?",
    sq: "Jeni të sigurt që dëshironi të hiqni këtë reklamë banner?",
    tr: "Bu banner reklamayı kaldırmak istediğinize emin misiniz?",
    sw: "Je, uko salama kuondoa tangazo hili la banner?",
    am: "እባኮትን ይህ ባነር ማስታወቂያ እንደ ምርጫ ማስወጣት ይፈልጋሉ?",
    om: "Maaliin, banarri kana haquu barbaaddaa?",
    ig: "Ị bụ onye kwere ka ewepu mgbasa ozi a?",
    so: "Ma hubtaa inaad ka saarto xayaysiiska bannerkan?",
    ha: "Kuna tabbaci za ku cire wannan tallan banner?",
    mn: "Энэ баннер зарын устгахыг хүсч байна уу?",
    ff: "A ye njiɗɗo, a heɓɓi ɓe na ko aɗa jooɗi a!",
    yo: "Ṣe o dájú pe o fẹ yọ́ ìpolówó àkọ́sílẹ̀ yìí?",
  },

  "Are you sure you want to approve this sub-reseller": {
    en: "Are you sure you want to approve this sub-reseller?",
    pt: "Tem certeza de que deseja aprovar este sub-revendedor?",
    de: "Sind Sie sicher, dass Sie diesen Unterwiederverkäufer genehmigen möchten?",
    es: "¿Estás seguro de que quieres aprobar a este subrevendedor?",
    fr: "Êtes-vous sûr de vouloir approuver ce sous-revendeur ?",
    it: "Sei sicuro di voler approvare questo sub-rivenditore?",
    sq: "A jeni të sigurtë që doni ta miratoni këtë nën-rishitësen?",
    tr: "Bu alt bayi onaylamak istediğinize emin misiniz?",
    sw: "Je, uko salama kuidhinisha msuba huu?",
    am: "እባኮትን ይህን የአካውንት ተመላሽ ይቀበሉ?",
    om: "Maaliin, msubba kana ni eeyyamuu barbaaddaa?",
    ig: "Ị bụ onye kwere ka kwadoro sub-reseller a?",
    so: "Ma hubtaa inaad ogolaato sub-reseller-kan?",
    ha: "Kuna tabbaci za ku amince da wannan sub-reseller?",
    mn: "Энэ дэд борлуулалтанд зөвшөөрөл олгохыг хүсч байна уу?",
    ff: "A ye njiɗɗo, a heɓɓi ɓe na ko aɗa jooɗi a!",
    yo: "Ṣe o dájú pé o fẹ́ gba àṣẹ fún olùtajà abẹ́ yìí?",
  },

  "Are you sure you want to cancel this sub-reseller": {
    en: "Are you sure you want to cancel this sub-reseller?",
    pt: "Tem certeza de que deseja cancelar este sub-revendedor?",
    de: "Sind Sie sicher, dass Sie diesen Unterwiederverkäufer stornieren möchten?",
    es: "¿Estás seguro de que quieres cancelar a este subrevendedor?",
    fr: "Êtes-vous sûr de vouloir annuler ce sous-revendeur ?",
    it: "Sei sicuro di voler annullare questo sub-rivenditore?",
    sq: "A jeni të sigurtë që doni ta anuloni këtë nën-rishitësen?",
    tr: "Bu alt bayi iptal etmek istediğinize emin misiniz?",
    sw: "Je, uko salama kufuta msuba huu?",
    am: "እባኮትን ይህን የአካውንት ተመላሽ ይሰርዝ?",
    om: "Maaliin, msubba kana ni cufaa barbaaddaa?",
    ig: "Ị bụ onye kwere ka wepụ sub-reseller a?",
    so: "Ma hubtaa inaad kansasho sub-reseller-kan?",
    ha: "Kuna tabbaci za ku soke wannan sub-reseller?",
    mn: "Энэ дэд борлуулалтанд цуцлахыг хүсч байна уу?",
    ff: "A ye njiɗɗo, a heɓɓi aɗa foti ko aɗa saafara sub-reseller ɗum?",
    yo: "Ṣe o dájú pé o fẹ́ fagile olùtajà abẹ́ yìí?",
  },

  "Attach account": {
    en: "Attach account",
    pt: "Anexar conta",
    de: "Konto anhängen",
    es: "Adjuntar cuenta",
    fr: "Attacher un compte",
    it: "Allega account",
    sq: "Bashkëngjit llogarinë",
    tr: "Hesabı ekle",
    sw: "Unganisha akaunti",
    am: "አካውንት ይገናኙ",
    om: "Akaawuntii walitti hidhadhu",
    ig: "Jikọọ akaụntụ",
    so: "Xir xisaab",
    ha: "Hada akaunti",
    mn: "Холбоосын данс",
    ff: "Ndi njiɗɗo, a heɓɓi njoɓdi akawunti ɗum?",
    yo: "So awọn iroyin pọ",
  },

  "Are you sure to delete this account": {
    en: "Are you sure to delete this account?",
    pt: "Tem certeza de que deseja excluir esta conta?",
    de: "Sind Sie sicher, dieses Konto zu löschen?",
    es: "¿Está seguro de que desea eliminar esta cuenta?",
    fr: "Êtes-vous sûr de vouloir supprimer ce compte?",
    it: "Sei sicuro di voler eliminare questo account?",
    sq: "A jeni të sigurtë që doni ta fshijeni këtë llogari?",
    tr: "Bu hesabı silmek istediğinize emin misiniz?",
    sw: "Je, uko salama kufuta akaunti hii?",
    am: "እባኮትን ይህን አካውንት ይጠፋ?",
    om: "Maaliin akawuntii kana dhabamsiisuuf mirkanaa'aa?",
    ig: "Ị bụ onye kwere ka ehichapụ akaụntụ a?",
    so: "Ma hubtaa inaad tirtirto akoonkan?",
    ha: "Kuna da tabbaci za ku share wannan asusun?",
    mn: "Энэ дансыг устгахыг хүсч байна уу?",
    ff: "Nde njiɗɗo, a heɓɓi njoɓdi akawunti ɗum?",
    yo: "Ṣe o dájú lati pa iroyin yii?",
  },

  "App reset": {
    en: "App reset",
    pt: "Reiniciar aplicativo",
    de: "App zurücksetzen",
    es: "Restablecer aplicación",
    fr: "Réinitialiser l'application",
    it: "Ripristina app",
    sq: "Riniapplikoni",
    tr: "Uygulamayı sıfırla",
    sw: "Kurejesha programu",
    am: "መተግበሪያ መቀየር",
    om: "Deebii App",
    ig: "Ndozi ngwa",
    so: "Dib u cusbooneysiin",
    ha: "Sake saitin app",
    mn: "Аппыг дахин тохируулах",
    ff: "Beyde app",
    yo: "Tun iṣapẹẹrẹ app",
  },

  "Allow users to reset the application": {
    en: "Allow users to reset the application",
    pt: "Permitir que os usuários resetem o aplicativo",
    de: "Benutzern ermöglichen, die App zurückzusetzen",
    es: "Permitir a los usuarios restablecer la aplicación",
    fr: "Autoriser les utilisateurs à réinitialiser l'application",
    it: "Consentire agli utenti di ripristinare l'applicazione",
    sq: "Lejoju përdoruesit të rivendosin aplikacionin",
    tr: "Kullanıcıların uygulamayı sıfırlamasına izin ver",
    sw: "Ruhusu watumiaji kurejesha programu",
    am: "ተጠቃሚዎች መተግበሪያን ወደ አዳዲስ መቀየር ይፈቀድ",
    om: "Taja hojii app'ii akka deebisaniif",
    ig: "Kwekọrịta ka ndị ọrụ nwee ike idozi ngwa ahụ",
    so: "U ogola isticmaalayaasha in ay dib u dejiso codsiga",
    ha: "Ba da izinin masu amfani su sake saitin aikace-aikacen",
    mn: "Хэрэглэгчдэд програмыг дахин тохируулахыг зөвшөөрнө үү",
    ff: "Njaɓɓitini no ɓe njiɗɗo ɓe heɓɓa ɓe nafoore app",
    yo: "Jẹ ki awọn olumulo tun ohun elo naa ṣe",
  },
  "Available Credits": {
    en: "Available Credits",
    pt: "Créditos Disponíveis",
    de: "Verfügbare Credits",
    es: "Créditos Disponibles",
    fr: "Crédits Disponibles",
    it: "Crediti Disponibili",
    sq: "Kreditet e disponueshme",
    tr: "Mevcut Krediler",
    sw: "Mikopo Inayopatikana",
    am: "እንደ አይነት ባለቤት ከተገኙ አይነት",
    om: "Qabiyyee Kredii",
    ig: "Ego dịnụ",
    so: "Deeqooyinka La Heli Karo",
    ha: "Kudaden Shafii",
    mn: "Боломжтой кредитүүд",
    ff: "Sifaaji nawi",
    yo: "Awọn Kirẹditi Ti o Wa",
  },
  Available: {
    en: "Available",
    pt: "Disponível",
    de: "Verfügbar",
    es: "Disponible",
    fr: "Disponible",
    it: "Disponibile",
    sq: "E disponueshme",
    tr: "Mevcut",
    sw: "Inapatikana",
    am: "ይገኛል",
    om: "Argamaa",
    ig: "Dịnụ",
    so: "La Heli Karo",
    ha: "Ana Samu",
    mn: "Боломжтой",
    ff: "Wali",
    yo: "Wa",
  },
  "Available credits": {
    en: "Available credits",
    pt: "Créditos disponíveis",
    de: "Verfügbare Guthaben",
    es: "Créditos disponibles",
    fr: "Crédits disponibles",
    it: "Crediti disponibili",
    sq: "Kreditet e disponueshme",
    tr: "Mevcut krediler",
    sw: "Mikopo inapatikana",
    am: "ይገኛል ክሬዲት",
    om: "Qorannoo argamaa",
    ig: "Ego dịnụ",
    so: "Khadar la heli karo",
    ha: "Ana Samu kirediti",
    mn: "Боломжтой кредит",
    ff: "Njiɗɗo wali",
    yo: "Awọn kirẹditi ti o wa",
  },
  "Are you sure to export data": {
    en: "Are you sure to export data?",
    pt: "Tem certeza de que deseja exportar os dados?",
    de: "Möchten Sie die Daten wirklich exportieren?",
    es: "¿Estás seguro de exportar los datos?",
    fr: "Êtes-vous sûr de vouloir exporter les données?",
    it: "Sei sicuro di voler esportare i dati?",
    sq: "Jeni të sigurt që doni të eksportoni të dhënat?",
    tr: "Verileri dışa aktarmak istediğinizden emin misiniz?",
    sw: "Je, uko salama kuhamasisha data?",
    am: "ዳታን እንደዚህ መላክ ይቻላል?",
    om: "Daataa akka baasuuf mirkaneessa?",
    ig: "Ị bụ onye kwere ka ebudata data?",
    so: "Ma hubtaa inaad dhoofiso xogta?",
    ha: "Kuna da tabbaci za ku fitar da bayanai?",
    mn: "Судалгаа гаргахдаа та итгэлтэй байна уу?",
    ff: "Nde njiɗɗo, a heɓɓi so ɓeydude data?",
    yo: "Ṣe o dájú lati gbe data jade?",
  },
  "Add New Banner": {
    en: "Add New Banner",
    es: "Agregar nuevo banner",
    fr: "Ajouter une nouvelle bannière",
    de: "Neues Banner hinzufügen",
    pt: "Adicionar novo banner",
    it: "Aggiungi nuovo banner",
    sq: "Shto një banner të ri",
    tr: "Yeni Afiş Ekle",
    sw: "Ongeza Bango Jipya",
    am: "አዲስ ባንነር ጨምር",
    om: "Bana Haaraa Dabalata",
    ig: "Tinye Banner Ohuru",
    so: "Kudarso Banner Cusub",
    ha: "Ƙara Sabon Banner",
    mn: "Шинэ баннер нэмэх",
    ff: "Nde njiɗɗo, a heɓɓi so ɓeydude banner ma?",
    yo: "Ṣe afikun Banner Tuntun",
  },
  //b
  "Best regards, XCloud Support team": {
    en: "Best regards, XCloud Support team.",
    pt: "Atenciosamente, equipe de suporte XCloud.",
    de: "Mit freundlichen Grüßen, XCloud-Supportteam.",
    es: "Saludos cordiales, equipo de soporte de XCloud.",
    fr: "Cordialement, l'équipe de support XCloud.",
    it: "Cordiali saluti, il team di supporto XCloud.",
    sq: "Me respekt, Ekipi i mbështetjes së XCloud.",
    tr: "Saygılarımızla, XCloud Destek Ekibi.",
    sw: "Kwa heri, Timu ya Msaada ya XCloud",
    am: "እንኳን ወደ አማካሪ ቡድን ይደርሳሉ፣ XCloud",
    om: "Galatoomaa, XCloud Gargaarsa Koottaa",
    ig: "Ndo, Otu Nkwado XCloud",
    so: "Iyada wanaagsan, Kooxda Taageerada XCloud",
    ha: "Da fatan alheri, Ƙungiyar Tallafin XCloud",
    mn: "Хүндэтгэсэн, XCloud дэмжлэгийн баг",
    ff: "Suuɗo, Ɓandu Jeyaaɗo XCloud",
    yo: "Ẹ kú oríre, Ẹgbẹ́ Atilẹyin XCloud",
  },
  "background image size range": {
    en: "The minimal and maximal background image sizes in pixels must be 1280x720 to 3840x2160",
    es: "Los tamaños mínimos y máximos de la imagen de fondo en píxeles deben ser de 1280x720 a 3840x2160",
    fr: "Les tailles minimales et maximales de l'image d'arrière-plan en pixels doivent être de 1280x720 à 3840x2160",
    de: "Die minimalen und maximalen Hintergrundbildgrößen in Pixeln müssen zwischen 1280x720 und 3840x2160 liegen",
    pt: "Os tamanhos mínimos e máximos da imagem de fundo em pixels devem ser de 1280x720 a 3840x2160",
    it: "Le dimensioni minime e massime dell'immagine di sfondo in pixel devono essere da 1280x720 a 3840x2160",
    sq: "Përmasat minimale dhe maksimale të imazhit të sfondit në piksel duhet të jenë nga 1280x720 deri në 3840x2160",
    tr: "Arka plan resim boyutlarının minimum ve maksimum boyutları piksel cinsinden 1280x720 ile 3840x2160 olmalıdır",
    sw: "kasi ya picha ya nyuma",
    am: "የኋላ ምስል መጠን እንደሆነ አካባቢ",
    om: "Fageenya suuraa duuba",
    ig: "ogologo mbadamba ndabere",
    so: "cabbirka sawirka gadaal",
    ha: "matsakaicin girman hoton baya",
    mn: "Арын зургийн хэмжээ",
    ff: "nangnjan fota konngal",
    yo: "ibiti iwọn aworan abẹ́lẹ̀",
  },
  "Banner AD": {
    en: "Banner AD",
    es: "Anuncio de banner",
    fr: "Annonce bannière",
    de: "Bannerwerbung",
    pt: "Anúncio de banner",
    it: "Annuncio banner",
    sq: "Reklamë banner",
    tr: "Afiş Reklamı",
    sw: "Tangazo la Bango",
    am: "ባንነር አድ",
    om: "Banaara AD",
    ig: "Banna AD",
    so: "Bannarka AD",
    ha: "Tallan AD",
    mn: "Баннер зар",
    ff: "Banni AD",
    yo: "Afiṣa Àkópọ̀",
  },
  Block: {
    en: "Block",
    pt: "Bloquear",
    de: "Blockieren",
    es: "Bloquear",
    fr: "Bloquer",
    it: "Blocca",
    sq: "Blloko",
    tr: "Engelle",
    sw: "Kizuizi",
    am: "አይታይ",
    om: "Bokkuu",
    ig: "Nchekwa",
    so: "Xanniba",
    ha: "Blok",
    mn: "Блок",
    ff: "Bokki",
    yo: "Bọ́ọ̀lù",
  },
  Blocked: {
    en: "Blocked",
    pt: "Bloqueado",
    de: "Blockiert",
    es: "Bloqueado",
    fr: "Bloqué",
    it: "Bloccato",
    sq: "Blokuar",
    tr: "Engellendi",
    sw: "Kimezuiliwa",
    am: "ተከለከለ",
    om: "Bokkan",
    ig: "A na-echebe",
    so: "Xanniba",
    ha: "An toshe",
    mn: "Блокдсон",
    ff: "Bokkito",
    yo: "Ti wa ni idinamọ",
  },
  "Blocked devices": {
    en: "Blocked devices",
    pt: "Dispositivos bloqueados",
    de: "Blockierte Geräte",
    es: "Dispositivos bloqueados",
    fr: "Appareils bloqués",
    it: "Dispositivi bloccati",
    sq: "Pajisje të bllokuara",
    tr: "Engellendi cihazlar",
    sw: "Vifaa vilivyokatiwa",
    am: "ተከለከለ መሣሪያዎች",
    om: "Bokkan meeshaalee",
    ig: "Ngwa ngwa echekwaghị",
    so: "Qalabka la xannibay",
    ha: "Na'urorin da aka toshe",
    mn: "Блокдсон төхөөрөмжүүд",
    ff: "Bokkito dɛn",
    yo: "Awọn ẹrọ ti o ti dina",
  },
  "Blocked date": {
    en: "Blocked date",
    pt: "Data bloqueada",
    de: "Blockiertes Datum",
    es: "Fecha bloqueada",
    fr: "Date bloquée",
    it: "Data bloccata",
    sq: "Data e bllokuar",
    tr: "Engellendi tarih",
    sw: "Tarehe iliyo kakatwa",
    am: "የተከለከለ ቀን",
    om: "Guyyaa kakatame",
    ig: "Ụbọchị a kpochapụrụ",
    so: "Taariikhda la xannibay",
    ha: "Ranar da aka toshe",
    mn: "Блокдсон огноо",
    ff: "Ñalema ɓam",
    yo: "Ọjọ́ tí a dáwọ́lẹ̀",
  },
  Branding: {
    en: "Branding",
    pt: "Marca",
    de: "Branding",
    es: "Marca",
    fr: "Marque",
    it: "Marchio",
    sq: "Brendimi",
    tr: "Markalama",
    sw: "Alama",
    am: "ባህሪ",
    om: "Bara",
    ig: "Ahịa",
    so: "Sumcad",
    ha: "Alama",
    mn: "Брэнд",
    ff: "Jajoo",
    yo: "Iṣapẹẹrẹ",
  },
  "Back Home": {
    en: "Back Home",
    pt: "Voltar para Casa",
    de: "Zurück zur Startseite",
    es: "Volver a Inicio",
    fr: "Retour à l'accueil",
    it: "Torna a Casa",
    sq: "Kthehu në Shtëpi",
    tr: "Ana Sayfaya Dön",
    sw: "Rudi Nyumbani",
    am: "ወደ ቤት ይመለሱ",
    om: "Mana Koti",
    ig: "Lagha n'ụlọ",
    so: "Guriga Ku Laabo",
    ha: "Dawo Gida",
    mn: "Гэртээ буцах",
    ff: "Rana fiyu",
    yo: "Pada si ile",
  },
  "Become a seller": {
    en: "Become a seller",
    pt: "Tornar-se um vendedor",
    de: "Verkäufer werden",
    es: "Convertirse en vendedor",
    fr: "Devenir vendeur",
    it: "Diventa un venditore",
    sq: "Bëhu Shitës",
    tr: "Satıcı Ol",
    sw: "Fanyika muuzaji",
    am: "ሻጭ አድርጉ",
    om: "Bara haa bu'aa",
    ig: "Ghọrọ onye na-ere",
    so: "Noqo iibiye",
    ha: "Kashe kasuwa",
    mn: "Зарлагчаар ажиллах",
    ff: "Kelen to bɛyi",
    yo: "Di olùtajà",
  },
  Buy: {
    en: "Buy",
    pt: "Comprar",
    de: "Kaufen",
    es: "Comprar",
    fr: "Acheter",
    it: "Acquistare",
    sq: "Blej",
    tr: "Satın Al",
    sw: "Nunua",
    am: "ግዛ",
    om: "Bitadhu",
    ig: "Zụta",
    so: "Iibso",
    ha: "Sayayya",
    mn: "Авч",
    ff: "Biy",
    yo: "Ra",
  },
  "Buy Credit": {
    en: "Buy Credit",
    pt: "Comprar créditos",
    de: "Kredit kaufen",
    es: "Comprar crédito",
    fr: "Acheter des crédits",
    it: "Acquista credito",
    sq: "Blej kredi",
    tr: "Kredi satın al",
    sw: "Nunua Mikopo",
    am: "የብድር ግዛ",
    om: "Dhaabbilee Bitadhu",
    ig: "Zụta Ego",
    so: "Iibso Lacag",
    ha: "Sayen Kuɗi",
    mn: "Кредит худалдаж авах",
    ff: "Biy e ziga",
    yo: "Ra Kirẹditi",
  },
  "Brand name": {
    en: "Brand name",
    pt: "Nome da marca",
    de: "Markenname",
    es: "Nombre de la marca",
    fr: "Nom de la marque",
    it: "Nome del marchio",
    sq: "Emri i markës",
    tr: "Marka adı",
    sw: "Jina la Brand",
    am: "የባሕር ስም",
    om: "Maqa Bitaa",
    ig: "Aha Brand",
    so: "Magaca Brand",
    ha: "Sunan Alama",
    mn: "Брэндийн нэр",
    ff: "Biiya sàbà",
    yo: "Orukọ ami",
  },
  "Back To Login": {
    en: "Back To Login",
    pt: "Voltar para o Login",
    de: "Zurück zur Anmeldung",
    es: "Volver a Iniciar Sesión",
    fr: "Retour à la Connexion",
    it: "Torna al Login",
    sq: "Kthehu në Hyrje",
    tr: "Geri Dön Giriş",
    sw: "Rudi Kwenye Kuingia",
    am: "ወደ ግባ",
    om: "Deebii Galme",
    ig: "Lagharịrị Login",
    so: "Ku Laabo Gelitaanka",
    ha: "Dawo zuwa Shiga",
    mn: "Нэвтрэхдээ буцах",
    ff: "Awan gida fow",
    yo: "Pada si Wiwọle",
  },
  "Billing history": {
    en: "Billing history",
    pt: "Histórico de faturamento",
    de: "Abrechnungshistorie",
    es: "Historial de facturación",
    fr: "Historique de facturation",
    it: "Cronologia delle fatture",
    sq: "Historia e faturimit",
    tr: "Fatura geçmişi",
    sw: "Historia ya Malipo",
    am: "የቢላንግ ታሪክ",
    om: "Seenaa Biliingii",
    ig: "Akụkọ Nkwụnye",
    so: "Taariikhda Biilasha",
    ha: "Tarihin Biyan Kuɗi",
    mn: "Төлбөрийн түүх",
    ff: "Tawari fiye",
    yo: "Itan Isanwo",
  },
  "Become A Partner": {
    en: "Become A Partner",
    pt: "Torne-se um Parceiro",
    de: "Werde Partner",
    es: "Conviértete en Socio",
    fr: "Devenir Partenaire",
    it: "Diventa un Partner",
    sq: "Bëhu Partner",
    tr: "Ortak Ol",
    sw: "kuwa mwenza",
    am: "አንድ አካል ማድረግ",
    om: "Hirmaata ta'i",
    ig: " bụrụ onye mmekọ",
    so: "Noqo Wadaag",
    ha: "Zama Abokin Hulda",
    mn: "Хамтрагч болох",
    ff: "Màfaa n'en",
    yo: "Di Ajọṣepọ",
  },

  become_seller_option: {
    en: "Become seller option is giving you opportunity to sell add credits to sub reseller from your activation count balance. You can decide what sub reseller can use your activations and what sub reseller need to buy from you to continue using your service. You will be also able to take back credits from sub reseller.",
    pt: "A opção de se tornar vendedor oferece a você a oportunidade de vender créditos adicionais para sub-revendedores a partir do saldo do seu contador de ativações. Você pode decidir o que o sub-revendedor pode usar de suas ativações e o que o sub-revendedor precisa comprar de você para continuar usando seu serviço. Você também poderá recuperar créditos do sub-revendedor.",
    de: "Die Option, Verkäufer zu werden, bietet Ihnen die Möglichkeit, Guthaben aus Ihrem Aktivierungszähler an Unterverkäufer zu verkaufen. Sie können entscheiden, welche Aktivierungen der Unterverkäufer nutzen kann und was der Unterverkäufer von Ihnen kaufen muss, um Ihren Service weiterhin nutzen zu können. Sie werden auch in der Lage sein, Guthaben vom Unterverkäufer zurückzufordern.",
    es: "La opción de convertirse en vendedor le brinda la oportunidad de vender créditos adicionales a sub revendedores desde el saldo de su contador de activaciones. Usted puede decidir qué puede utilizar el sub revendedor de sus activaciones y qué debe comprar el sub revendedor de usted para seguir utilizando su servicio. También podrá recuperar créditos del sub revendedor.",
    fr: "L'option de devenir vendeur vous offre la possibilité de vendre des crédits supplémentaires aux sous-revendeurs à partir de votre solde de compteur d'activation. Vous pouvez décider ce que le sous-revendeur peut utiliser de vos activations et ce que le sous-revendeur doit acheter auprès de vous pour continuer à utiliser votre service. Vous pourrez également récupérer des crédits auprès du sous-revendeur.",
    it: "L'opzione di diventare venditore ti offre l'opportunità di vendere crediti aggiuntivi a sub rivenditori dal saldo del tuo contatore di attivazioni. Puoi decidere cosa il sub rivenditore può utilizzare delle tue attivazioni e cosa il sub rivenditore deve acquistare da te per continuare a utilizzare il tuo servizio. Sarai inoltre in grado di recuperare i crediti dal sub rivenditore.",
    sq: "Opsioni për të bërë shitës ju jep mundësinë të shisni kredite shtesë në nën-shitës nga balanca e llogaritësit tuaj të aktivizimit. Ju mund të vendosni se çfarë mund të përdorë nën-shitësi nga aktivizimet tuaja dhe se çfarë duhet të blejë nën-shitësi nga ju për të vazhduar përdorimin e shërbimit tuaj. Ju do të mundeni gjithashtu të merrni prapa kreditet nga nën-shitësi.",
    tr: "Satıcı olma seçeneği, aktivasyon sayısı bakiyenizden alt satıcılara ek kredi satma fırsatı sunmaktadır. Hangi alt satıcının aktivasyonlarınızı kullanabileceğine ve hangi alt satıcının hizmetinizi kullanmaya devam etmek için sizden ne satın alması gerektiğine karar verebilirsiniz. Ayrıca alt satıcılardan kredileri geri alma imkanına da sahip olacaksınız.",
    sw: "Chaguo la Kuwa Muuzaji",
    am: "የሚሸጥ አማራጭ",
    om: "Kitaabni Dhiheessaa",
    ig: "Otu ahia ndị na-ere",
    so: "Ikhtiyaarka Iibiyaha",
    ha: "Zaɓin Zama Mai Sayarwa",
    mn: "Зарагдах сонголт",
    ff: "Màfaa e waɗu",
    yo: "Yiyan Di Olutaja",
  },

  "By admin": {
    en: "By admin",
    pt: "Pelo administrador",
    de: "Vom Administrator",
    es: "Por el administrado",
    fr: "Par l'administrateur",
    it: "Da amministratore",
    sq: "Nga administratori",
    tr: "Yönetici tarafından",
    sw: "Na admin",
    am: "በአስተዳዳሪ",
    om: "Ittisa admin",
    ig: "Site na admin",
    so: "By admin",
    ha: "Ta hanyar admin",
    mn: "Админгаар",
    ff: "Nè e admin",
    yo: "Nipa alakoso",
  },

  "By platforms": {
    en: "By platforms",
    pt: "Por plataformas",
    de: "Nach Plattformen",
    es: "Por plataformas",
    fr: "Par plateformes",
    it: "Per piattaforme",
    sq: "Nga platforma",
    tr: "Platformlara göre",
    sw: "Na majukwaa",
    am: "በመድረክ",
    om: "Saa tokko",
    ig: "Site na ikpo",
    so: "By platforms",
    ha: "Ta hanyar dandamali",
    mn: "Платформаар",
    ff: "Nè e platform",
    yo: "Nipa awọn pẹpẹ",
  },

  "By device key": {
    en: "By device key",
    pt: "Por chave do dispositivo",
    de: "Nach Geräteschlüssel",
    es: "Por clave del dispositivo",
    fr: "Par clé de l'appareil",
    it: "Per chiave del dispositivo",
    sq: "Nga çelësi i pajisjes",
    tr: "Cihaz anahtarı ile",
    sw: "Kwa ufunguo wa kifaa",
    am: "በመሣሪያ ቁልፍ",
    om: "Kitaabni moba",
    ig: "Site na igodo ngwaọrụ",
    so: "By device key",
    ha: "Ta hanyar maɓallin na'ura",
    mn: "Төхөөрөмжийн түлхүүрээр",
    ff: "Nè e device key",
    yo: "Nipa bọtini ẹrọ",
  },

  "By registration date": {
    en: "By registration date",
    pt: "Por data de registro",
    de: "Nach Anmeldedatum",
    es: "Por fecha de registro",
    fr: "Par date d'inscription",
    it: "Per data di registrazione",
    sq: "Nga data e regjistrimit",
    tr: "Kayıt tarihine göre",
    sw: "Kwa tarehe ya usajili",
    am: "በመመዝገብ ቀን",
    om: "Guyyaa galmeessaa",
    ig: "Site na ụbọchị ndebanye",
    so: "By registration date",
    ha: "Ta hanyar ranar rajista",
    mn: "Бүртгэгдсэн огноогоор",
    ff: "Nè e registration date",
    yo: "Nipa ọjọ iforukọsilẹ",
  },

  "Brand name must include only letters or numbers.": {
    en: "Brand name must include only letters or numbers.",
    pt: "O nome da marca deve conter apenas letras ou números.",
    de: "Der Markenname darf nur Buchstaben oder Zahlen enthalten.",
    es: "El nombre de la marca debe contener solo letras o números.",
    fr: "Le nom de la marque ne doit contenir que des lettres ou des chiffres.",
    it: "Il nome del marchio deve contenere solo lettere o numeri.",
    sq: "Emri i markës duhet të përfshijë vetëm shkronja ose numra.",
    tr: "Marka adı yalnızca harfler veya sayılar içermelidir.",
    sw: "Jina la chapa linapaswa kujumuisha herufi au nambari pekee.",
    am: "የባህር ስም በተለይ የፊደል ወይም ቁጥር ይካተት አለበት።",
    om: "Maqaankee adda bilbilaa fi lakkoofsaa qofa qabaachuu qaba.",
    ig: "Aha ụdị ga-eji naanị mkpụrụedemede ma ọ bụ nọmba.",
    so: "Magaca brandku waa inuu ka kooban yahay xarfo ama tiro kaliya.",
    ha: "Sunan alama dole ne ya ƙunshi haruffa ko lambobi kawai.",
    mn: "Брэндийн нэр зөвхөн үсэг, тоо агуулсан байх ёстой.",
    ff: "Gandol a waɗi ni mu na e ɗe ɓe ɓuri kalima e kadi.",
    yo: "Oruko ami gbodo ni awọn lẹta tabi awọn nọmba nikan.",
  },

  "By clicking on menu you can": {
    en: "By clicking on the 'Action' menu, you can 'Edit' some credentials of the chosen device or 'Deactivate/Activate' the device active/inactive period.",
    pt: "Ao clicar no menu 'Ação', você pode 'Editar' algumas credenciais do dispositivo escolhido ou 'Desativar/Ativar' o período ativo/inativo do dispositivo.",
    de: "Durch Klicken auf das 'Aktion'-Menü können Sie einige Anmeldeinformationen des ausgewählten Geräts 'Bearbeiten' oder das aktive/inaktive Zeitintervall des Geräts 'Deaktivieren/Aktivieren'.",
    es: "Al hacer clic en el menú 'Acción', puedes 'Editar' algunas credenciales del dispositivo seleccionado o 'Desactivar/Activar' el período activo/inactivo del dispositivo.",
    fr: "En cliquant sur le menu 'Action', vous pouvez 'Modifier' certaines informations d'identification du dispositif sélectionné ou 'Désactiver/Activer' la période active/inactive du dispositif.",
    it: "Cliccando sul menu 'Azione', puoi 'Modificare' alcune credenziali del dispositivo scelto o 'Disattivare/Attivare' il periodo attivo/inattivo del dispositivo.",
    sq: "Duke klikuar në meny mundeni",
    tr: "'Eylem' menüsüne tıklayarak, seçilen cihazın bazı kimlik bilgilerini 'Düzenleyebilir' veya cihazın aktif/pasif dönemini 'Devre Dışı Bırakabilir/Aktif Edilebilir' olursunuz.",
    sw: "Kwa kubonyeza kwenye menyu unaweza",
    am: "መነሻ በመጫን ይችላሉ",
    om: "Menyu irratti cuqaasuun ni dandeessa",
    ig: "Site na menu ị nwere ike",
    so: "Adigoo gujinaya menu waxaad",
    ha: "Ta hanyar danna menu za ku iya",
    mn: "Менд хуулж дарж дарж болно",
    ff: "Nde njiɗɗo menu miɗo",
    yo: "Nipa titẹ menu o le",
  },

  "Browser version": {
    en: "Browser version",
    pt: "Versão do navegador",
    de: "Browser-Version",
    es: "Versión del navegador",
    fr: "Version du navigateur",
    it: "Versione del browser",
    sq: "Versioni i shfletuesit",
    tr: "Tarayıcı sürümü",
    sw: "Toleo la kivinjari",
    am: "የአይነት ቪርስዮን",
    om: "Gosa bilbila",
    ig: "Nchịkọta nchọgharị",
    so: "Nooca biraawsarka",
    ha: "Sigar mai bincike",
    mn: "Браузерын хувилбар",
    ff: "Kelel brawza",
    yo: "Ẹya ẹrọ aṣawakiri",
  },

  "Edit New Banner": {
    en: "Edit New Banner",
    es: "Editar nuevo banner",
    fr: "Modifier la nouvelle bannière",
    de: "Neues Banner bearbeiten",
    pt: "Editar novo banner",
    it: "Modifica nuovo banner",
    sq: "Redakto banerin e ri",
    tr: "Yeni Afişi Düzenle",
    sw: "Hariri Bango Jipya",
    am: "አዲስ ባንነር አርትዕ",
    om: "Banner haaraa edit gochuu",
    ig: "Dezie Banner ọhụrụ",
    so: "Tafatir Banner Cusub",
    ha: "Gyara Sabon Tallafi",
    mn: "Шинэ баннер засах",
    ff: "Ɓe naatinde lewel",
    yo: "Ṣatunkọ Ifaworanhan Tuntun",
  },

  "End date": {
    en: "End date",
    es: "Fecha de finalización",
    fr: "Date de fin",
    de: "Enddatum",
    pt: "Data de término",
    it: "Data di fine",
    sq: "Data e përfundimit",
    tr: "Bitiş tarihi",
    sw: "Tarehe ya Mwisho",
    am: "የመጨረሻ ቀን",
    om: "Guyyaa xumura",
    ig: "Ụbọchị njedebe",
    so: "Taariikhda Dhamaadka",
    ha: "Ranar ƙarshe",
    mn: "Дуусах огноо",
    ff: "Hooro ndiyam",
    yo: "Ọjọ ipari",
  },

  "Export CSV": {
    en: "Export CSV",
    pt: "Exportar CSV",
    de: "CSV exportieren",
    es: "Exportar CSV",
    fr: "Exporter CSV",
    it: "Esporta CSV",
    sq: "Eksporto CSV",
    tr: "CSV'yi Dışa Aktar",
    sw: "Safisha CSV",
    am: "CSV እንደ ውሂብ ውስጥ",
    om: "CSV baasii baasaa",
    ig: "Mepụta CSV",
    so: "Dhoofinta CSV",
    ha: "Fitar da CSV",
    mn: "CSV гаргах",
    ff: "Bara foye CSV",
    yo: "Taara CSV",
  },

  "Earn money": {
    en: "Earn money",
    pt: "Ganhar dinheiro",
    de: "Geld verdienen",
    es: "Ganar dinero",
    fr: "Gagner de l'argent",
    it: "Guadagnare denaro",
    sq: "Fitoni para",
    tr: "Para kazan",
    sw: "Pata Pesa",
    am: "ገንዘብ አውጥ",
    om: "Maallaqa argachu",
    ig: "Nweta ego",
    so: "Cashar lacag",
    ha: "Samun kuɗi",
    mn: "Мөнгө олох",
    ff: "Jow tuugol",
    yo: "Jẹ ki owo",
  },
  Episode: {
    en: "Episode",
    pt: "Episódio",
    de: "Folge",
    es: "Episodio",
    fr: "Épisode",
    it: "Episodio",
    sq: "Episod",
    tr: "Bölüm",
    sw: "Sura",
    am: "ክፍል",
    om: "Bara",
    ig: "Nkeji",
    so: "Qeyb",
    ha: "Kashi",
    mn: "Эпизод",
    ff: "Yam",
    yo: "Ise",
  },
  Edit: {
    en: "Edit",
    pt: "Editar",
    de: "Bearbeiten",
    es: "Editar",
    fr: "Modifier",
    it: "Modifica",
    sq: "Ndrysho",
    tr: "Düzenle",
    sw: "Hariri",
    am: "አርትዕ",
    om: "Gochuu",
    ig: "Dezie",
    so: "Tafatir",
    ha: "Gyara",
    mn: "Засах",
    ff: "Naati",
    yo: "Ṣatunkọ",
  },
  "Edit profile": {
    en: "Edit profile",
    pt: "Editar perfil",
    de: "Profil bearbeiten",
    es: "Editar perfil",
    fr: "Modifier le profil",
    it: "Modifica profilo",
    sq: "Ndrysho profilin",
    tr: "Profili düzenle",
    sw: "Hariri wasifu",
    am: "የእንደዚህ መግለጫ",
    om: "Iyyata cufti",
    ig: "Dezie profaịlụ",
    so: "Tafatir xogta",
    ha: "Gyara bayanin",
    mn: "Тойм засах",
    ff: "Naati kadi",
    yo: "Ṣatunkọ profaili",
  },
  Email: {
    en: "Email",
    pt: "Email",
    de: "E-Mail",
    es: "Correo electrónico",
    fr: "E-mail",
    it: "Email",
    sq: "Email",
    tr: "E-posta",
    sw: "Barua pepe",
    am: "ኢሜይል",
    om: "Imeeli",
    ig: "Email",
    so: "Email",
    ha: "Imel",
    mn: "И-мэйл",
    ff: "Imel",
    yo: "Imeeli",
  },
  "Enter your email": {
    en: "Enter your email",
    pt: "Digite seu e-mail",
    de: "Geben Sie Ihre E-Mail ein",
    es: "Ingrese su correo electrónico",
    fr: "Saisissez votre adresse e-mail",
    it: "Inserisci la tua email",
    sq: "Shkruaj email-in tënd (të gjitha shkronjat e vogla)",
    tr: "E-posta adresinizi girin",
    sw: "Ingiza barua pepe yako",
    am: "ኢሜይልዎን ይክፈቱ",
    om: "Imeelii keewwatan",
    ig: "Tinye email gị",
    so: "Gali email-kaaga",
    ha: "Shigar da imel ɗin ku",
    mn: "И-мэйлээ оруулна уу",
    ff: "Jooɗi imel maa",
    yo: "Tẹ imeeli rẹ",
  },
  "Enter 2FA verification code": {
    en: "Enter 2FA verification code",
    pt: "Digite o código de verificação 2FA",
    de: "Geben Sie den 2FA-Verifizierungscode ein",
    es: "Ingrese el código de verificación 2FA",
    fr: "Saisissez le code de vérification 2FA",
    it: "Inserisci il codice di verifica 2FA",
    sq: "Fut kodin e verifikimit 2FA",
    tr: "2FA doğrulama kodunu girin",
    sw: "Ingiza msimbo wa uthibitisho wa 2FA",
    am: "2FA የማረጋገጫ ኮድ ይህ ይግባ",
    om: "Mirkanaa 2FA yaa galata",
    ig: "Tinye koodu nchọpụta 2FA",
    so: "Gali koodhka xaqiijinta 2FA",
    ha: "Shigar da lambar tantancewa ta 2FA",
    mn: "2FA баталгаажуулалтын код оруулна уу",
    ff: "Jooɗi koodal 2FA",
    yo: "Tẹ koodu ìmúdájú 2FA",
  },
  "Enable 2FA": {
    en: "Enable 2FA",
    pt: "Ativar 2FA",
    de: "2FA aktivieren",
    es: "Activar 2FA",
    fr: "Activer 2FA",
    it: "Abilita 2FA",
    sq: "Aktivizo 2FA",
    tr: "2FA'yı etkinleştir",
    sw: "Washa 2FA",
    am: "2FA እንደነገር",
    om: "2FA mirkanaa",
    ig: "Kwado 2FA",
    so: "Fur 2FA",
    ha: "Kunna 2FA",
    mn: "2FA-г идэвхжүүл",
    ff: "Wujjoo 2FA",
    yo: "Mu 2FA ṣiṣẹ",
  },
  "Enter code": {
    en: "Enter code",
    pt: "Digite o código",
    de: "Code eingeben",
    es: "Ingresar código",
    fr: "Entrer le code",
    it: "Inserisci il codice",
    sq: "Fut kodin",
    tr: "Kodu girin",
    sw: "Ingiza msimbo",
    am: "ኮድዎን ይግባ",
    om: "Koodii galchaa",
    ig: "Tinye koodu",
    so: "Gali koodhka",
    ha: "Shigar da lamba",
    mn: "Код оруулна уу",
    ff: "Jooɗi koodal",
    yo: "Tẹ koodu",
  },
  Error: {
    en: "Error",
    pt: "Erro",
    de: "Fehler",
    es: "Error",
    fr: "Erreur",
    it: "Errore",
    sq: "Gabim",
    tr: "Hata",
    sw: "Kosa",
    am: "እርምጃ",
    om: "Dogoggora",
    ig: "Njehie",
    so: "Khalad",
    ha: "Kuskure",
    mn: "Алдаа",
    ff: "Jooɗi",
    yo: "Aṣiṣe",
  },
  "Error logs": {
    en: "Error logs",
    pt: "Registros de erro",
    de: "Fehlerprotokolle",
    es: "Registros de errores",
    fr: "Journaux d'erreurs",
    it: "Log degli errori",
    sq: "Regjistrat e gabimit",
    tr: "Hata günlükleri",
    sw: "Kumbukumbu za makosa",
    am: "የእርምጃ ታቦች",
    om: "Bakka dogoggora",
    ig: "Njehie ndekọ",
    so: "Diiwaanka khaladaadka",
    ha: "Rajistar kuskuren",
    mn: "Алдааны бүртгэл",
    ff: "Bukka ajum",
    yo: "Iwe aṣẹ aṣiṣe",
  },
  "Error count": {
    en: "Error count",
    pt: "Contagem de erros",
    de: "Fehleranzahl",
    es: "Contador de errores",
    fr: "Nombre d'erreurs",
    it: "Conteggio errori",
    sq: "Numri i gabimit",
    tr: "Hata sayısı",
    sw: "Idadi ya makosa",
    am: "የእርምጃ ቁጥር",
    om: "Lakkofsa dogoggora",
    ig: "Ọnụọgụ njehie",
    so: "Tirada khaladaadka",
    ha: "Yawan kuskuren",
    mn: "Алдааны тоо",
    ff: "Ndiid be kaɗi",
    yo: "Iye aṣiṣe",
  },
  "Edit DNS": {
    en: "Edit DNS",
    pt: "Editar DNS",
    de: "DNS bearbeiten",
    es: "Editar DNS",
    fr: "Modifier le DNS",
    it: "Modifica DNS",
    sq: "Modifiko DNS",
    tr: "DNS'yi düzenle",
    sw: "Hariri DNS",
    am: "DNSን አርትዕ",
    om: "DNS fooyya'aa",
    ig: "Dezie DNS",
    so: "Tafatir DNS",
    ha: "Gyara DNS",
    mn: "DNS засах",
    ff: "Naati DNS",
    yo: "Ṣatunkọ DNS",
  },
  "Edit playlist": {
    en: "Edit playlist",
    pt: "Editar lista de reprodução",
    de: "Playlist bearbeiten",
    es: "Editar lista de reproducción",
    fr: "Modifier la playlist",
    it: "Modifica playlist",
    sq: "Modifiko playlistën",
    tr: "Çalma listesini düzenle",
    sw: "Hariri orodha ya nyimbo",
    am: "የዝርዝር አርትዕ",
    om: "Kara playlist",
    ig: "Dezie playlist",
    so: "Tafatir liiska",
    ha: "Gyara jerin waƙoƙi",
    mn: "Плэйлист засах",
    ff: "Naati playlist",
    yo: "Ṣatunkọ akojọ orin",
  },
  "Expired date": {
    en: "Expired date",
    pt: "Data de expiração",
    de: "Ablaufdatum",
    es: "Fecha de vencimiento",
    fr: "Date d'expiration",
    it: "Data di scadenza",
    sq: "Data e skaducimit",
    tr: "Sonuç tarihi",
    sw: "Tarehe iliyokwisha",
    am: "የጊዜ መደብ ቀን",
    om: "Guyyaa xumuraa",
    ig: "Ụbọchị akwụsị",
    so: "Taariikhda dhammaystiran",
    ha: "Ranar karewa",
    mn: "Хугацаа дууссан огноо",
    ff: "Dati da heɓi",
    yo: "Ọjọ ipari",
  },
  Expired: {
    en: "Expired",
    pt: "Expirado",
    de: "Abgelaufen",
    es: "Caducado",
    fr: "Expiré",
    it: "Scaduto",
    sq: "Skadet",
    tr: "Süresi dolmuş",
    sw: "Iliyokwisha",
    am: "ተወው",
    om: "Xumuramee",
    ig: "Akwụsịrị",
    so: "Dhammaystiran",
    ha: "Ya ƙare",
    mn: "Хугацаа дууссан",
    ff: "Heɓi",
    yo: "Ti pari",
  },
  "Email address": {
    en: "Email address",
    pt: "Endereço de e-mail",
    de: "E-Mail-Adresse",
    es: "Dirección de correo electrónico",
    fr: "Adresse e-mail",
    it: "Indirizzo email",
    sq: "Adresa e emailit",
    tr: "E-posta adresi",
    sw: "Anwani ya barua pepe",
    am: "ኢሜይል አድራሻ",
    om: "Teessuma e-mail",
    ig: "Adreesị email",
    so: "Cinwaanka iimayl",
    ha: "Adireshin imel",
    mn: "И-мэйл хаяг",
    ff: "Wakkati e-mail",
    yo: "Adirẹsi imeeli",
  },
  "Export excel": {
    en: "Export excel",
    pt: "Exportar para o Excel",
    de: "Excel exportieren",
    es: "Exportar a Excel",
    fr: "Exporter vers Excel",
    it: "Esporta in Excel",
    sq: "Eksporto Excel",
    tr: "Excel'e aktar",
    sw: "Export excel",
    am: "ኤክሴል ውስጥ ወደ ውጭ ላክ",
    om: "Excel'tti baasu",
    ig: "Mepụta excel",
    so: "Dhoofinta excel",
    ha: "Fitar da excel",
    mn: "Excel экспортлох",
    ff: "Nde anndu e-mail",
    yo: "Gbigbe excel",
  },
  "Edit Sub-Reseller": {
    en: "Edit Sub-Reseller",
    pt: "Editar Sub-Revendedor",
    de: "Unterwiederverkäufer bearbeiten",
    es: "Editar subrevendedor",
    fr: "Modifier le sous-revendeur",
    it: "Modifica sub-rivenditore",
    sq: "Ndrysho Nën-Shitjen",
    tr: "Alt-Bayi düzenle",
    sw: "Hariri Sub-Reseller",
    am: "የታበረውን ዳሰር አርትዕ",
    om: "Sub-Reseller fooyya'aa",
    ig: "Dezie Sub-Reseller",
    so: "Tafatir Sub-Reseller",
    ha: "Gyara Sub-Reseller",
    mn: "Дагуу худалдагч засах",
    ff: "Naati sub-reseller",
    yo: "Ṣatunkọ Sub-Reseller",
  },
  "Enter server host": {
    en: "Enter server host",
    pt: "Digite o host do servidor",
    de: "Server-Host eingeben",
    es: "Introduce el host del servidor",
    fr: "Entrez l'hôte du serveur",
    it: "Inserisci l'host del server",
    sq: "Shkruaj hostin e serverit",
    tr: "Sunucu ana bilgisayarını girin",
    sw: "Ingiza mwenyeji wa seva",
    am: "የአገልግሎት አስተዳዳሪ ይግባ",
    om: "Maqaa server galchaa",
    ig: "Tinye onye host",
    so: "Gali server host",
    ha: "Shigar da uwar garken",
    mn: "Сервер хост оруулна уу",
    ff: "Jooɗi server host",
    yo: "Tẹ orukọ alakoso",
  },
  "Enter playlist URL": {
    en: "Enter playlist URL",
    pt: "Digite a URL da lista de reprodução",
    de: "Playlist-URL eingeben",
    es: "Introduce la URL de la lista de reproducción",
    fr: "Entrez l'URL de la playlist",
    it: "Inserisci l'URL della playlist",
    sq: "Shkruaj URL-në e Playlistës",
    tr: "Çalma listesi URL'sini girin",
    sw: "Ingiza URL ya orodha ya nyimbo",
    am: "የዝርዝር ዩአርኤል ይግባ",
    om: "URL playlist galchaa",
    ig: "Tinye URL playlist",
    so: "Gali URL liiska",
    ha: "Shigar da URL na jerin waƙoƙi",
    mn: "Плэйлистийн URL оруулна уу",
    ff: "Jooɗi URL playlist",
    yo: "Tẹ URL akojọ orin",
  },
  "Enter device key": {
    en: "Enter device key",
    pt: "Digite a chave do dispositivo",
    de: "Geräteschlüssel eingeben",
    es: "Introduce la clave del dispositivo",
    fr: "Entrez la clé de l'appareil",
    it: "Inserisci la chiave del dispositivo",
    sq: "Shkruaj çelësin e pajisjes",
    tr: "Cihaz anahtarını girin",
    sw: "Ingiza funguo la kifaa",
    am: "የመነሻ ቁልፍ ይግባ",
    om: "Maqaa kiyyoo galchaa",
    ig: "Tinye igodo ngwaọrụ",
    so: "Gali furaha qalabka",
    ha: "Shigar da mabudin na'ura",
    mn: "Төхөөрөмжийн түлхүүр оруулна уу",
    ff: "Jooɗi makko mawɗo",
    yo: "Tẹ bọtini ẹrọ",
  },
  "Enabling 2FA": {
    en: "Enabling 2FA",
    pt: "Ativando a autenticação de dois fatores",
    de: "2-Faktor-Authentifizierung aktivieren",
    es: "Activando la autenticación de dos factores",
    fr: "Activation de l'authentification à deux facteurs",
    it: "Abilitazione della doppia autenticazione",
    sq: "Aktivizimi i 2FA",
    tr: "İki faktörlü kimlik doğrulama etkinleştiriliyor",
    sw: "Kuweka 2FA",
    am: "2FA እንደገና ይቀይሩ",
    om: "2FA'ni hojjataa",
    ig: "Na-eme 2FA",
    so: "Furaha 2FA",
    ha: "Kunna 2FA",
    mn: "2FA идэвхжүүлж байна",
    ff: "Hawata 2FA",
    yo: "Mú ṣiṣẹ 2FA",
  },
  "Enter 2FA email address": {
    en: "Enter 2FA email address",
    pt: "Digite o endereço de e-mail para a autenticação de dois fatores",
    de: "Geben Sie die E-Mail-Adresse für die Zwei-Faktor-Authentifizierung ein",
    es: "Ingrese la dirección de correo electrónico para la autenticación de dos factores",
    fr: "Entrez l'adresse e-mail pour l'authentification à deux facteurs",
    it: "Inserisci l'indirizzo email per l'autenticazione a due fattori",
    sq: "Shkruaj adresën email për 2FA",
    tr: "İki faktörlü kimlik doğrulama için e-posta adresini girin",
    sw: "Ingiza anwani ya barua pepe ya 2FA",
    am: "2FA ኢሜይል አድራሻ ይግባ",
    om: "Teessuma e-mail 2FA galchaa",
    ig: "Tinye adreesị email 2FA",
    so: "Gali cinwaanka iimayl 2FA",
    ha: "Shigar da adireshin imel na 2FA",
    mn: "2FA и-мэйл хаяг оруулна уу",
    ff: "Jooɗi wakkati e-mail 2FA",
    yo: "Tẹ adirẹsi imeeli 2FA",
  },
  "E-mail isn't valid": {
    en: "E-mail isn't valid!",
    pt: "E-mail não é válido!",
    de: "E-Mail ist ungültig!",
    es: "¡El correo electrónico no es válido!",
    fr: "L'adresse e-mail n'est pas valide !",
    it: "L'indirizzo email non è valido!",
    sq: "Email-i nuk është valid!",
    tr: "E-posta geçerli değil!",
    sw: "Barua pepe si halali",
    am: "ኢሜይል ወይም ተረጋጋ ይሁን",
    om: "E-mail sirrii miti",
    ig: "E-mail adịghị mma",
    so: "Iimaylku maaha mid sax ah",
    ha: "Imel ba daidai ba ne",
    mn: "И-мэйл буруу байна",
    ff: "E-mail ɗum ɗe njahi",
    yo: "Imeeli naa ko tooto",
  },
  "Expired remaining days": {
    en: "Expired remaining days",
    pt: "Dias restantes de expiração",
    de: "Verbleibende Tage bis zum Ablauf",
    es: "Días restantes de vencimiento",
    fr: "Jours restants avant expiration",
    it: "Giorni rimasti alla scadenza",
    sq: "Ditet e mbetura të skaduara",
    tr: "Son kullanıma kalan günler",
    sw: "Siku zilizobaki zimeisha",
    am: "ቀን ይምጣ",
    om: "Guyyaa hafe dhumate",
    ig: "Ụbọchị fọdụrụ agwụla",
    so: "Maalmaha haray la dhamay",
    ha: "Rana mai saura ta ƙare",
    mn: "Үлдэгдсэн өдөр дууссан",
    ff: "Ñaataaji heɓi ɗum",
    yo: "Awọn ọjọ to ku ti pari",
  },
  "Enable Two-Factor Authentication": {
    en: "Enable Two-Factor Authentication",
    pt: "Ativar Autenticação de Dois Fatores",
    de: "Zwei-Faktor-Authentifizierung aktivieren",
    es: "Habilitar Autenticación de Dos Factores",
    fr: "Activer l'authentification à deux facteurs",
    it: "Abilita l'Autenticazione a Due Fattori",
    sq: "Aktivizo Autentikimin Dy-Faktorial",
    tr: "İki Faktörlü Kimlik Doğrulamayı Etkinleştir",
    sw: "Washa Uthibitishaji wa Hatua Mbili",
    am: "ሁለት የአስተዳደር ማረጋገጫ እንደገና ይቀይሩ",
    om: "Maqaa lama of eeyyama",
    ig: "Na-eme ka njirimara abụọ",
    so: "Furaha Aqoonsiga Labaad",
    ha: "Kunna Tabbatarwa ta Hanyar Biyu",
    mn: "Хоёр дахь баталгаажуулалтыг идэвхжүүлэх",
    ff: "Dawirɗe jawdi baawo",
    yo: "Mu ṣiṣẹ Ìfọwọsi Igbimọ Mẹta",
  },
  //c
  "Choose progress bar color": {
    en: "Choose progress bar color",
    pt: "Escolha a cor da barra de progresso",
    de: "Wählen Sie die Farbe der Fortschrittsanzeige",
    es: "Elige el color de la barra de progreso",
    fr: "Choisissez la couleur de la barre de progression",
    it: "Scegli il colore della barra di progresso",
    sq: "Zgjidhni ngjyrën e shiritit të përparimit",
    tr: "İlerleme çubuğu rengini seçin",
    sw: "Chagua rangi ya kipimo cha maendeleo",
    am: "የእድገት የመስመር ቀለም ይምረጡ",
    om: "Halluu bakka bu'aa filadhu",
    ig: "Họrọ agba nke ọkwa mmepe",
    so: "Dooro midabka bar-barbar",
    ha: "Zaɓi launin sandar ci gaba",
    mn: "Хөгжлийн барын өнгийг сонго",
    ff: "Jooɗi naange baraji",
    yo: "Yan awọ bar ilọsiwaju",
  },
  "Choose items active color": {
    en: "Choose items active color",
    pt: "Escolha a cor ativa dos itens",
    de: "Wählen Sie die aktive Farbe der Elemente",
    es: "Elige el color activo de los elementos",
    fr: "Choisissez la couleur active des éléments",
    it: "Scegli il colore attivo degli elementi",
    sq: "Zgjidhni ngjyrën aktive të artikujve",
    tr: "Öğelerin aktif rengini seçin",
    sw: "Chagua rangi ya shughuli za vitu",
    am: "እባኮትን እትም ከአይነቶች ቀለም ይምረጡ",
    om: "Halluu sochii meeshalee filadhu",
    ig: "Họrọ agba nke ihe ndị ọrụ",
    so: "Dooro midabka firfircoona",
    ha: "Zaɓi launin abubuwan da ke aiki",
    mn: "Идэвхтэй зүйлийн өнгийг сонго",
    ff: "Jooɗi naange ɓiiɓɓe",
    yo: "Yan awọ awọn ohun ti n ṣiṣẹ",
  },
  "Choose icons color": {
    en: "Choose icons color",
    pt: "Escolha a cor dos ícones",
    de: "Wählen Sie die Farbe der Symbole",
    es: "Elige el color de los iconos",
    fr: "Choisissez la couleur des icônes",
    it: "Scegli il colore delle icone",
    sq: "Zgjidhni ngjyrën e ikonave",
    tr: "Simge rengini seçin",
    sw: "Chagua rangi ya ikoni",
    am: "አይነቶች ቀለም ይምረጡ",
    om: "Halluu kaappii filadhu",
    ig: "Họrọ agba nke mkpụrụedemede",
    so: "Dooro midabka astaan",
    ha: "Zaɓi launin alamomi",
    mn: "Иконны өнгийг сонго",
    ff: "Jooɗi naange ɓeŋngal",
    yo: "Yan awọ awọn aami",
  },
  "Choose items box form": {
    en: "Choose items box form",
    pt: "Escolha a forma da caixa de itens",
    de: "Wählen Sie das Kästchenformular",
    es: "Elige la forma de la caja de artículos",
    fr: "Choisissez la forme de la boîte d'articles",
    it: "Scegli la forma della casella degli articoli",
    sq: "Zgjidhni formën e kutisë së artikujve",
    tr: "Öğe kutusu formunu seçin",
    sw: "Chagua fomu ya kisanduku cha vitu",
    am: "የእትም ቦታ እንደእንደ ይምረጡ",
    om: "Halluu bakki meeshalee filadhu",
    ig: "Họrọ fọọm nke igbe ihe",
    so: "Dooro foomka sanduuqa",
    ha: "Zaɓi tsarin akwatin abubuwa",
    mn: "Зүйлийн хайрцагийн формыг сонго",
    ff: "Jooɗi hayre ɓiiɓɓe",
    yo: "Yan fọọmu apoti awọn ohun",
  },
  "Choose menu": {
    en: "Choose menu",
    pt: "Escolher menu",
    de: "Menü auswählen",
    es: "Elegir menú",
    fr: "Choisir le menu",
    it: "Scegli il menu",
    sq: "Zgjidhni menunë",
    tr: "Menü seçin",
    sw: "Chagua menyu",
    am: "መዝገብ ይምረጡ",
    om: "Halluu meesha filadhu",
    ig: "Họrọ menu",
    so: "Dooro menu",
    ha: "Zaɓi menu",
    mn: "Мени дуудаж сонго",
    ff: "Jooɗi menuy",
    yo: "Yan akojọ aṣayan",
  },
  "Choose player": {
    en: "Choose player",
    pt: "Escolha o player",
    de: "Wähle den Player",
    es: "Elige el reproductor",
    fr: "Choisissez le lecteur",
    it: "Scegli il player",
    sq: "Zgjidh lojtarin",
    tr: "Oynatıcıyı seç",
    sw: "Chagua menyu",
    am: "መዝገብ ይምረጡ",
    om: "Halluu meesha filadhu",
    ig: "Họrọ menu",
    so: "Dooro menu",
    ha: "Zaɓi menu",
    mn: "Мени дуудаж сонго",
    ff: "Jooɗi menuy",
    yo: "Yan akojọ aṣayan",
  },
  Collection: {
    en: "Collection",
    pt: "Coleção",
    de: "Sammlung",
    es: "Colección",
    fr: "Collection",
    it: "Collezione",
    sq: "Koleksioni",
    tr: "Koleksiyon",
    sw: "Mkusanyiko",
    am: "ኮሌክሽን",
    om: "Koleksiyoonii",
    ig: "Nzukọ",
    so: "Ururinta",
    ha: "Tarin",
    mn: "Цуглуулга",
    ff: "Dawirɗe",
    yo: "Ikọja",
  },
  "Choose your icons": {
    en: "Choose your icons",
    pt: "Escolha seus ícones",
    de: "Wählen Sie Ihre Symbole",
    es: "Elige tus íconos",
    fr: "Choisissez vos icônes",
    it: "Scegli le tue icone",
    sq: "Zgjidhni ikonat tuaja",
    tr: "Simgelerinizi seçin",
    sw: "Chagua alama zako",
    am: "አይነቶችዎን ይምረጡ",
    om: "Halluu icon keessan filadhu",
    ig: "Họrọ ihe ngosi gị",
    so: "Dooro astaamahaaga",
    ha: "Zaɓi alamomin ku",
    mn: "Таны тэмдэгтийг сонго",
    ff: "Jooɗi ɓeŋngal ma",
    yo: "Yan awọn aami rẹ",
  },
  "Choose items color": {
    en: "Choose items color",
    pt: "Escolher a cor dos itens",
    de: "Wählen Sie die Farbe der Elemente",
    es: "Elegir el color de los elementos",
    fr: "Choisir la couleur des éléments",
    it: "Scegli il colore degli elementi",
    sq: "Zgjidhni ngjyrën e artikujve",
    tr: "Öğelerin rengini seç",
    sw: "Chagua rangi ya vitu",
    am: "እባኮትን እትም የቀለም ይምረጡ",
    om: "Halluu kaappii filadhu",
    ig: "Họrọ agba nke ihe",
    so: "Dooro midabka alaabada",
    ha: "Zaɓi launin abubuwa",
    mn: "Зүйлийн өнгийг сонго",
    ff: "Jooɗi naange ɓiiɓɓe",
    yo: "Yan awọ awọn ohun",
  },
  "Choose background from list": {
    en: "Choose background from list",
    pt: "Escolher plano de fundo da lista",
    de: "Hintergrund aus der Liste auswählen",
    es: "Elegir fondo de la lista",
    fr: "Choisir un arrière-plan dans la liste",
    it: "Scegli sfondo dall'elenco",
    sq: "Zgjidh sfondin nga lista",
    tr: "Listeden arka planı seç",
    sw: "Chagua mandharinyuma kutoka orodha",
    am: "ከዝርዝር ይመረጡ መስክ",
    om: "Halluu asxaa faayila filadhu",
    ig: "Họrọ ndabere si ndepụta",
    so: "Dooro asalka liiska ka",
    ha: "Zaɓi bango daga jerin",
    mn: "Жагсаалтаас арын арын сонго",
    ff: "Jooɗi nder kaŋngol",
    yo: "Yan abẹlẹ lati atokọ",
  },
  "Customize Layout": {
    en: "Customize Layout",
    es: "Personalizar diseño",
    fr: "Personnaliser la mise en page",
    de: "Layout anpassen",
    pt: "Personalizar layout",
    it: "Personalizza layout",
    sq: "Përshtat paraqitjen",
    tr: "Düzeni Özelleştir",
    sw: "Bina Mpangilio",
    am: "የእትም አቀናባሪ ይቀይሩ",
    om: "Ijaaji laayouti",
    ig: "Hazie nhazi",
    so: "Habee qaab-dhismeed",
    ha: "Kafa tsarin",
    mn: "Зураглалыг тохируулах",
    ff: "Mora jooɗi tawti",
    yo: "Ṣatunkọ apẹrẹ",
  },
  company: {
    en: "company",
    pt: "empresa",
    de: "Unternehmen",
    es: "empresa",
    fr: "entreprise",
    it: "azienda",
    sq: "kompani",
    tr: "şirket",
    sw: "kampuni",
    am: "ኩባንያ",
    om: "Kompanii",
    ig: "Ụlọ ọrụ",
    so: "Shirkad",
    ha: "Kamfani",
    mn: "Компани",
    ff: "Golle",
    yo: "Ile-iṣẹ",
  },
  Continue: {
    en: "Continue",
    pt: "Continuar",
    de: "Weiter",
    es: "Continuar",
    fr: "Continuer",
    it: "Continua",
    sq: "Vazhdo",
    tr: "Devam et",
    sw: "Endelea",
    am: "ቀጥለው",
    om: "Dhiibbaa",
    ig: "Gaa n'ihu",
    so: "Sii wad",
    ha: "Ci gaba",
    mn: "Үргэлжлүүлэх",
    ff: "Sukaa",
    yo: "Tesiwaju",
  },
  Card: {
    en: "Card",
    pt: "Cartão",
    de: "Karte",
    es: "Tarjeta",
    fr: "Carte",
    it: "Carta",
    sq: "Kartë",
    tr: "Kart",
    sw: "Kadi",
    am: "ካርድ",
    om: "Kaardii",
    ig: "Kaadị",
    so: "Kaarka",
    ha: "Katunan",
    mn: "Карт",
    ff: "Kardi",
    yo: "Kaadi",
  },
  Confirm: {
    en: "Confirm",
    pt: "Confirmar",
    de: "Bestätigen",
    es: "Confirmar",
    fr: "Confirmer",
    it: "Confermare",
    sq: "Konfirmo",
    tr: "Onayla",
    sw: "Thibitisha",
    am: "እቅፍ",
    om: "Mirga",
    ig: "Kwenye",
    so: "Xaqiiji",
    ha: "Tabbatacce",
    mn: "Баталгаажуулах",
    ff: "Jooɗi",
    yo: "Maa jẹ́rè",
  },
  Contacts: {
    en: "Contacts",
    pt: "Contatos",
    de: "Kontakte",
    es: "Contactos",
    fr: "Contacts",
    it: "Contatti",
    sq: "Kontakte",
    tr: "İletişimler",
    sw: "Mawasiliano",
    am: "እውነተኛ ይወዳድር",
    om: "Kakuu",
    ig: "Kpọtụrụ",
    so: "Xiriir",
    ha: "Tuntuɓi",
    mn: "Холбоо барих",
    ff: "Waawinde",
    yo: "Ibaṣepọ",
  },
  "Contact us on": {
    en: "Contact us on",
    pt: "Contate-nos em",
    de: "Kontaktieren Sie uns unter",
    es: "Contáctenos en",
    fr: "Contactez-nous au",
    it: "Contattaci su",
    sq: "Na kontaktoni në",
    tr: "Bize şu adresten ulaşın",
    sw: "Wasiliana nasi kupitia",
    am: "በዚህ ያነጋግሩን",
    om: "Nuti irratti si dubbadhu",
    ig: "Kpọtụrụ anyị na",
    so: "Nala soo xiriir",
    ha: "Tuntuɓi mu a kan",
    mn: "Бидэнтэй холбоо бариарай",
    ff: "Sukoo wiyde",
    yo: "Kan si ni baṣepọ",
  },
  close: {
    en: "close",
    pt: "fechar",
    de: "schließen",
    es: "cerrar",
    fr: "fermer",
    it: "chiudi",
    sq: "Mbyll",
    tr: "kapat",
    sw: "Funga",
    am: "ዝጋ",
    om: "Cufaa",
    ig: "Mechie",
    so: "Xidho",
    ha: "Rufe",
    mn: "Хаах",
    ff: "Lugge",
    yo: "Pa",
  },
  Count: {
    en: "Count",
    pt: "Contagem",
    de: "Zählen",
    es: "Contar",
    fr: "Compter",
    it: "Conteggio",
    sq: "Llogaritje",
    tr: "Say",
    sw: "Hesabu",
    am: "ቆጥቋ",
    om: "Baay'uu",
    ig: "Mụọ",
    so: "Tirinta",
    ha: "Lissafi",
    mn: "Тоолох",
    ff: "Tawtudo",
    yo: "Iye",
  },
  custom: {
    en: "custom",
    pt: "personalizado",
    de: "individuell",
    es: "personalizado",
    fr: "personnalisé",
    it: "personalizzato",
    sq: "Personalizuar",
    tr: "özel",
    sw: "Desturi",
    am: "ዕድል",
    om: "Miseensa",
    ig: "Omume",
    so: "Caado",
    ha: "Al'ada",
    mn: "Захиалга",
    ff: "Jeele",
    yo: "Adani",
  },
  Credit: {
    en: "Credit",
    pt: "Crédito",
    de: "Guthaben",
    es: "Crédito",
    fr: "Crédit",
    it: "Credito",
    sq: "Kredit",
    tr: "Kredi",
    sw: "Mkopo",
    am: "ድርጅት",
    om: "Qabduu",
    ig: "Krediti",
    so: "Kredi",
    ha: "Bashi",
    mn: "Зээл",
    ff: "Kredi",
    yo: "Kirediti",
  },
  Credits: {
    en: "Credits",
    pt: "Créditos",
    de: "Guthaben",
    es: "Créditos",
    fr: "Crédits",
    it: "Crediti",
    sq: "Kreditet",
    tr: "Krediler",
    sw: "Mikopo",
    am: "ብድር",
    om: "Kreeditii",
    ig: "Krediti",
    so: "Krediyo",
    ha: "Kudade",
    mn: "Зээл",
    ff: "Krediti",
    yo: "Kirediti",
  },
  cancel: {
    en: "cancel",
    pt: "cancelar",
    de: "abbrechen",
    es: "cancelar",
    fr: "annuler",
    it: "annulla",
    sq: "anuloj",
    tr: "iptal",
    sw: "Ghairi",
    am: "ይሰርዝ",
    om: "Haquu",
    ig: "Kwepu",
    so: "Jooji",
    ha: "Soke",
    mn: "Цуцлах",
    ff: "Cakkude",
    yo: "Fagile",
  },
  Cancel: {
    en: "Cancel",
    pt: "Cancelar",
    de: "Abbrechen",
    es: "Cancelar",
    fr: "Annuler",
    it: "Annulla",
    sq: "Anuloj",
    tr: "İptal",
    sw: "Ghairi",
    am: "ይሰርዝ",
    om: "Haquu",
    ig: "Kwepu",
    so: "Jooji",
    ha: "Soke",
    mn: "Цуцлах",
    ff: "Cakkude",
    yo: "Fagile",
  },
  Check: {
    en: "Check",
    pt: "Verificar",
    de: "Überprüfen",
    es: "Comprobar",
    fr: "Vérifier",
    it: "Controlla",
    sq: "Kontrollo",
    tr: "Kontrol Et",
    sw: "Kagua",
    am: "መርመር",
    om: "Qorannoo",
    ig: "Lelee",
    so: "Hubi",
    ha: "Duba",
    mn: "Шалгах",
    ff: "Naatude",
    yo: "Ṣayẹwo",
  },
  Country: {
    en: "Country",
    pt: "País",
    de: "Land",
    es: "País",
    fr: "Pays",
    it: "Paese",
    sq: "Shteti",
    tr: "Ülke",
    sw: "Nchi",
    am: "አገር",
    om: "Biyya",
    ig: "Obodo",
    so: "Dhulka",
    ha: "Kasa",
    mn: "Улс",
    ff: "Diyam",
    yo: "Orilẹ-ede",
  },
  "Coming soon": {
    en: "Coming soon",
    pt: "Em breve",
    de: "Demnächst",
    es: "Próximamente",
    fr: "Bientôt disponible",
    it: "Prossimamente",
    sq: "Së shpejti",
    tr: "Yakında",
    sw: "Inakuja hivi karibuni",
    am: "በማለት በቅርቡ ይመጣል",
    om: "Dhufuu si'a dhiyoo",
    ig: "Na-abịa n'oge na-adịghị anya",
    so: "Soo dhowaan",
    ha: "Zai zo nan ba da jimawa ba",
    mn: "Ирж байна",
    ff: "Njaajiri",
    yo: "N bọ́",
  },
  "custom DNS": {
    en: "custom DNS",
    pt: "DNS personalizado",
    de: "Individuelle DNS",
    es: "DNS personalizado",
    fr: "DNS personnalisé",
    it: "DNS personalizzato",
    sq: "DNS i personalizuar",
    tr: "özel DNS",
    sw: "DNS iliyobinafsishwa",
    am: "በግል የተለያዩ አንባሳት",
    om: "DNS dhaabbataa",
    ig: "DNS ahaziri",
    so: "DNS gaarka ah",
    ha: "DNS na musamman",
    mn: "Засварласан DNS",
    ff: "DNS ɗin na musamman",
    yo: "DNS aṣa",
  },
  "Custom Playlist": {
    en: "Custom Playlist",
    pt: "Lista de Reprodução Personalizada",
    de: "Benutzerdefinierte Wiedergabeliste",
    es: "Lista de Reproducción Personalizada",
    fr: "Playlist Personnalisée",
    it: "Playlist Personalizzata",
    sq: "Lista e Përmbajtjes së Personalizuar",
    tr: "Özel Oynatma Listesi",
    sw: "Orodha ya Nyimbo Iliyobinafsishwa",
    am: "የግል የማዕከለ ዝርዝር",
    om: "Kitaaba Caalma",
    ig: "Playlist ahaziri",
    so: "Liiska Heesaha Gaarka ah",
    ha: "Taron Kayan Gani na Musamman",
    mn: "Засварласан тоглолтын жагсаалт",
    ff: "Njoɓɓi Fala",
    yo: "Atokọ aṣẹ",
  },
  "Created date": {
    en: "Created date",
    pt: "Data de criação",
    de: "Erstellungsdatum",
    es: "Fecha de creación",
    fr: "Date de création",
    it: "Data di creazione",
    sq: "Data e krijimit",
    tr: "Oluşturulma tarihi",
    sw: "Tarehe ya Uumbaji",
    am: "የተፈጥሮ ቀን",
    om: "Guyyaa uumamaa",
    ig: "Ụbọchị a mụrụ",
    so: "Taariikhda la abuuray",
    ha: "Ranar Ƙirƙira",
    mn: "Бүтээсэн огноо",
    ff: "Ngasɗin Ndiyam",
    yo: "Ọjọ́ ìdàpọ̀",
  },

  "Confirmation code": {
    en: "Confirmation code",
    pt: "Código de confirmação",
    de: "Bestätigungscode",
    es: "Código de confirmación",
    fr: "Code de confirmation",
    it: "Codice di conferma",
    sq: "Kodi i konfirmimit",
    tr: "Kod onayı",
    sw: "Nambari ya uthibitisho",
    am: "ኮድ ማረጋገጫ",
    om: "Koodii mirkaneessaa",
    ig: "Koodu nkwenye",
    so: "Koodhka xaqiijinta",
    ha: "Lambar tabbatarwa",
    mn: "Баталгаажуулалтын код",
    ff: "Lamban Njoɓɓi",
    yo: "Koodu ìmúdàgba",
  },
  "Change language": {
    en: "Change language",
    pt: "Alterar idioma",
    de: "Sprache ändern",
    es: "Cambiar idioma",
    fr: "Changer de langue",
    it: "Cambia lingua",
    sq: "Ndrysho gjuhën",
    tr: "Dili değiştir",
    sw: "Badilisha lugha",
    am: "ቋንቋ ይለውጡ",
    om: "Afaan jijjiiru",
    ig: "Gbanwee asụsụ",
    so: "Bedel luqadda",
    ha: "Canza harshe",
    mn: "Хэл солих",
    ff: "Yillude Luɓe",
    yo: "Yipada èdè",
  },
  "Card number": {
    en: "Card number",
    pt: "Número do cartão",
    de: "Kartennummer",
    es: "Número de tarjeta",
    fr: "Numéro de carte",
    it: "Numero di carta",
    sq: "Numri i kartës",
    tr: "Kart numarası",
    sw: "Nambari ya kadi",
    am: "የካርድ ቁጥር",
    om: "Lakkoofsa kaardii",
    ig: "Nọmba kaadị",
    so: "Lambarka kaarka",
    ha: "Lambar kati",
    mn: "Картын дугаар",
    ff: "Njoɓɓe Karde",
    yo: "Nọmba kaadi",
  },
  "Change Parental code": {
    en: "Change Parental code",
    pt: "Alterar código de controle parental",
    de: "Elterncode ändern",
    es: "Cambiar código de control parental",
    fr: "Changer le code parental",
    it: "Cambia codice genitore",
    sq: "Ndrysho kodin prindëror",
    tr: "Ebeveyn kodunu değiştir",
    sw: "Badilisha nambari ya wazazi",
    am: "የአባቶች ኮድ ይለውጡ",
    om: "Koodii maatii jijjiiru",
    ig: "Gbanwee koodu nne na nna",
    so: "Beddel koodhka waalidka",
    ha: "Canza lambar iyaye",
    mn: "Эцэг, эхийн кодыг солих",
    ff: "Yillude Koodal Mande",
    yo: "Yipada koodu ìyá àti bàbá",
  },
  "Credits count must be greater than": {
    en: "Credits count must be greater than",
    pt: "A contagem de créditos deve ser maior que",
    de: "Die Anzahl der Guthaben muss größer sein als",
    es: "El recuento de créditos debe ser mayor que",
    fr: "Le nombre de crédits doit être supérieur à",
    it: "Il conteggio dei crediti deve essere superiore a",
    sq: "Numri i kredive duhet të jetë më i madh se",
    tr: "Kredi sayısı daha büyük olmalıdır",
    sw: "Idadi ya mikopo inapaswa kuwa kubwa zaidi ya",
    am: "የክሬዲት ቆጣር ከዚህ ይበልጥ መሆን አለበት",
    om: "Kudura maallaqaa caalu qabaachuu qaba",
    ig: "Nọmba nkwụnye kwesịrị ịdị elu karịa",
    so: "Tirada deynta waa inay ka weyn tahay",
    ha: "Adadin kuɗi ya zama mafi girma fiye da",
    mn: "Kudura da lannii ɗam waɗete ɗeɓen",
    ff: "Jokku jiɓɓe waɗi lannii ɓen",
    yo: "Nọmba gbese gbọdọ jẹ ti o tobi ju",
  },
  "Choose the server you want to transfer from": {
    en: "Choose the server you want to transfer from",
    pt: "Escolha o servidor de onde deseja transferir",
    de: "Wählen Sie den Server aus, von dem Sie übertragen möchten",
    es: "Elija el servidor del que desea transferir",
    fr: "Choisissez le serveur que vous souhaitez transférer depuis",
    it: "Scegli il server da cui desideri trasferire",
    sq: "Zgjidhni serverin nga i cili dëshironi të transferoni",
    tr: "Transfer etmek istediğiniz sunucuyu seçin",
    sw: "Chagua seva unayotaka kuhamisha kutoka",
    am: "ከወደቀው አገልጋይ ይምረጡ",
    om: "Sargaattii irraa geessuu barbaaddu filadhu",
    ig: "Họrọ savaị ọ ga-esi bufee",
    so: "Xulo server-ka aad rabto inaad ka wareejiso",
    ha: "Zaɓi uwar garke da kake son canjawa daga ciki",
    mn: "Ni siri siga bo son kontoro la?",
    ff: "Yaratila nanaatere nden tooyde e ɗum",
    yo: "Yan olupin ti o fẹ lati gbe lọ lati",
  },
  "Credit will be charged for each activation": {
    en: "1 Credit will be charged for each activation.",
    pt: "1 crédito será cobrado para cada ativação.",
    de: "Für jede Aktivierung wird 1 Guthaben berechnet.",
    es: "Se cobrará 1 crédito por cada activación.",
    fr: "1 crédit sera facturé pour chaque activation.",
    it: "1 credito sarà addebitato per ogni attivazione.",
    sq: "1 Kredi do të tarifohet për çdo aktivizim.",
    tr: "Her aktivasyon için 1 kredi tahsil edilecektir.",
    sw: "Mikopo itatozwa kwa kila uanzishaji",
    am: "እያንዳንዱ እንደገና እንደሚሰጠው ቅድሚያ ይክፈልላል",
    om: "Kireeditii walirra kan kaffalamu yommuu banu kamiyyuu",
    ig: "Ego a ga-akwụ maka nkwenye ọ bụla",
    so: "Deyn ayaa lagu dalaci doonaa firfircooni kasta",
    ha: "Za a caji kuɗin kiredit don kowanne kunna",
    mn: "Kanfin kaara kelen len yan jaradiraw la ni a ban",
    ff: "Nde neɓɓo nanfaama so alaa toondi goɗɗo",
    yo: "Kirediti yoo gba owo fun igbekale kọọkan",
  },
  "Click here to complete your payment": {
    en: "Click here to complete your payment",
    pt: "Clique aqui para concluir o pagamento",
    de: "Klicken Sie hier, um die Zahlung abzuschließen",
    es: "Haga clic aquí para completar su pago",
    fr: "Cliquez ici pour finaliser votre paiement",
    it: "Clicca qui per completare il pagamento",
    sq: "Kliko këtu për të përfunduar pagesën tuaj",
    tr: "Ödemenizi tamamlamak için buraya tıklayın.",
    sw: "Bonyeza hapa kukamilisha malipo yako",
    am: "እባክዎትን እዚህ ጠቅ በማድረግ ክፍያዎን ያጠናቅቁ",
    om: "Asitti cuqaasuun kaffaltii kee xumuri",
    ig: "Pịa ebe a iji mechaa ịkwụ ụgwọ gị",
    so: "Riix halkan si aad u dhammaystirto lacag bixintaada",
    ha: "Danna nan don kammala biyan ka",
    mn: "I ye ni a fɔlɔ fo a ban kɔkalan da ni ka a nyaa kan",
    ff: "Cikkito ɗum se a njumataa balngal maa",
    yo: "Tẹ nibi lati pari owo sisan rẹ",
  },

  //j
  "Join WhatsApp group": {
    en: "Join WhatsApp group",
    pt: "Entrar no grupo do WhatsApp",
    de: "WhatsApp-Gruppe beitreten",
    es: "Unirse al grupo de WhatsApp",
    fr: "Rejoindre le groupe WhatsApp",
    it: "Unisciti al gruppo WhatsApp",
    sq: "Bashkohu në grupin WhatsApp",
    tr: "WhatsApp grubuna katıl",
    sw: "Jiunge na kundi la WhatsApp",
    am: "ወደ ዋትሳፕ ቡድን ይቀላቀሉ",
    om: "Garee WhatsAppitti makami",
    ig: "Jikọọ otu WhatsApp",
    so: "Ku bi kooxda WhatsApp",
    ha: "Shiga rukunin WhatsApp",
    mn: "Sini na WhatsApp dɔɔlɔ la",
    ff: "Nokkitaa cuɓɓal WhatsApp",
    yo: "Darapọ mọ ẹgbẹ WhatsApp",
  },
  "Join Telegram group": {
    en: "Join Telegram group",
    pt: "Participar do grupo no Telegram",
    de: "Dem Telegram-Gruppe beitreten",
    es: "Unirse al grupo de Telegram",
    fr: "Rejoindre le groupe Telegram",
    it: "Unisciti al gruppo Telegram",
    sq: "Bashkohu në grupin Telegram",
    tr: "Telegram grubuna katıl",
    sw: "Jiunge na kundi la Telegram",
    am: "ወደ ተሌግራም ቡድን ይቀላቀሉ",
    om: "Garee Telegramitti makami",
    ig: "Jikọọ otu Telegram",
    so: "Ku bi kooxda Telegram",
    ha: "Shiga rukunin Telegram",
    mn: "Sini na Telegram dɔɔlɔ la",
    ff: "Nokkitaa cuɓɓal Telegram",
    yo: "Darapọ mọ ẹgbẹ Telegram",
  },
  "Join community": {
    en: "Join community",
    pt: "Participe da comunidade",
    de: "Der Community beitreten",
    es: "Únete a la comunidad",
    fr: "Rejoindre la communauté",
    it: "Unisciti alla comunità",
    sq: "Bashkohu në komunitet",
    tr: "Topluluğa katıl",
    sw: "Jiunge na jamii",
    am: "ወደ ማህበረሰብ ይቀላቀሉ",
    om: "Garee hawaasa itti makami",
    ig: "Jikọọ obodo",
    so: "Ku bi bulsho",
    ha: "Shiga al'umma",
    mn: "Sini na hawaasa",
    ff: "Nokkitaa kuɗɗum",
    yo: "Darapọ mọ agbegbe",
  },

  "Join Our Telegram Community": {
    en: "Join Our Telegram Community",
    pt: "Junte-se à Nossa Comunidade no Telegram",
    de: "Treten Sie unserer Telegram-Community bei",
    es: "Únete a nuestra comunidad de Telegram",
    fr: "Rejoignez notre communauté Telegram",
    it: "Unisciti alla nostra comunità Telegram",
    sq: "Bashkohuni me Komunitetin Tonë në Telegram",
    tr: "Telegram Topluluğumuza Katıl",
    sw: "Jiunge na Jamii Yetu ya Telegram",
    am: "ወደ የተሌግራም ማህበረሰብ ይቀላቀሉ",
    om: "Garee hawaasa keenya Telegramitti makami",
    ig: "Jikọọ Ọgbakọ Telegram Anyị",
    so: "Ku bi Bulshada Telegram-keena",
    ha: "Shiga Al'ummar Telegrammu",
    mn: "Sini na hawaasa Telegram keenyaa",
    ff: "Nokkitaa cuɗɗum Telegram ɗinmu",
    yo: "Darapọ mọ Agbegbe Telegram Wa",
  },

  //i

  "If you have selected Collection 1, you cannot select any edit tools. If you wish to use the edit tools, please choose a different collection":
    {
      en: "If you have selected Collection 1, you cannot select any edit tools. If you wish to use the edit tools, please choose a different collection.",
      pt: "Se você selecionou a Coleção 1, não poderá selecionar nenhuma ferramenta de edição. Se deseja usar as ferramentas de edição, por favor, escolha uma coleção diferente.",
      de: "Wenn Sie Sammlung 1 ausgewählt haben, können Sie keine Bearbeitungstools auswählen. Wenn Sie die Bearbeitungstools verwenden möchten, wählen Sie bitte eine andere Sammlung.",
      es: "Si ha seleccionado la Colección 1, no puede seleccionar ninguna herramienta de edición. Si desea usar las herramientas de edición, elija una colección diferente.",
      fr: "Si vous avez sélectionné Collection 1, vous ne pouvez pas sélectionner d'outils d'édition. Si vous souhaitez utiliser les outils d'édition, veuillez choisir une autre collection.",
      it: "Se hai selezionato la Collezione 1, non puoi selezionare alcuno strumento di modifica. Se desideri utilizzare gli strumenti di modifica, scegli un'altra collezione.",
      sq: "Nëse keni zgjedhur Koleksionin 1, nuk mund të zgjidhni asnjë mjet redaktimi. Nëse dëshironi të përdorni mjetet e redaktimit, ju lutemi zgjidhni një koleksion tjetër.",
      tr: "Koleksiyon 1'i seçtiyseniz, herhangi bir düzenleme aracını seçemezsiniz. Düzenleme araçlarını kullanmak istiyorsanız lütfen farklı bir koleksiyon seçin.",
      sw: "Ikiwa umechagua Mkusanyiko 1, huwezi kuchagua zana zozote za kuhariri. Ikiwa unataka kutumia zana za kuhariri, tafadhali chagua mkusanyiko tofauti.",
      am: "የተለያዩ ስም 1 ተመርጧል ከሆነ የማንኛውም የአርቲክ መሳሪያ መርጥ አልቻልኩም። የአርቲክ መሳሪያ እንዲጠቀም ከፈለጉ እባኮትን ሌላ ስብስክ ይምረጡ።",
      om: "Yoo kuusaa 1 filattee, toora qindaa'aa filachuuf hin dandeessu. Toora qindaa'aa fayyadamuu barbaadde, kuusaa gara biraa filadhu.",
      ig: "Ọ bụrụ na ị họrọ Ngwugwu 1, ị不能 họrọ ihe ndị na-eme mgbanwe. Ọ bụrụ na ịchọrọ iji ihe ndị na-eme mgbanwe, biko họrọ ngwugwu ọzọ.",
      so: "Haddii aad dooratay Ururinta 1, ma dooran kartid wax qalab ah oo tafatir. Haddii aad rabto inaad isticmaasho qalabka tafatirka, fadlan dooro ururin kale.",
      ha: "Idan kun zabi Tarin 1, ba za ka iya zabar kowanne kayan gyara ba. Idan kana so ka yi amfani da kayan gyara, don Allah zaɓi wani tarin.",
      mn: "Хэрэв та Цуглуулга 1-ийг сонгосон бол, ямар ч засварын хэрэгсэл сонгож чадахгүй. Хэрэв та засварын хэрэгсэл ашиглахыг хүсвэл, өөр цуглуулга сонгоно уу.",
      ff: "So a zaɓi taron 1, ba za ku iya zaɓar kowanne kayan gyara ba. Idan kuna son amfani da kayan gyara, don Allah ku zaɓi wani taron.",
      yo: "Ti o ba ti yan Ikopọ 1, o ko le yan eyikeyi awọn irinṣẹ ṣiṣatunkọ. Ti o ba fẹ lo awọn irinṣẹ ṣiṣatunkọ, jọwọ yan ikopọ miiran.",
    },
  "Incorrect email or password": {
    en: "Incorrect email or password",
    pt: "E-mail ou senha incorretos",
    de: "Falsche E-Mail oder falsches Passwort",
    es: "Correo electrónico o contraseña incorrectos",
    fr: "E-mail ou mot de passe incorrect",
    it: "E-mail o password errati",
    sq: "Email ose fjalëkalim i pasaktë",
    tr: "E-posta veya şifre yanlış",
    sw: "Barua pepe au nenosiri sio sahihi",
    am: "የተሳሳተ ኢሜይል ወይም የይለፍ ቃል",
    om: "Imeeli yookaan jecha iccitii dogoggora dha",
    ig: "Email ma ọ bụ okwuntughe adịghị mma",
    so: "Iimeyl ama erayga waa qalad",
    ha: "Imel ko kalmar sirri ba daidai ba",
    mn: "Имэйл эсвэл нууц үг буруу байна",
    ff: "Email ko kalmar wuce ba daidai ba",
    yo: "Imeeli tabi ọrọ igbaniwọle ti ko tọ",
  },
  "invalid image file": {
    en: "Invalid image file",
    es: "Archivo de imagen no válido",
    fr: "Fichier image invalide",
    de: "Ungültige Bilddatei",
    pt: "Arquivo de imagem inválido",
    it: "File immagine non valido",
    sq: "Skedar imazhi i pavlefshëm",
    tr: "Geçersiz resim dosyası",
    sw: "Faili ya picha isiyo sahihi",
    am: "የማይረገድ የምስል ፋይል",
    om: "Faayilii suuraa dogoggoraa",
    ig: "Faịlụ onyonyo na-ezighị ezi",
    so: "Faylka sawirka ee aan sax ahayn",
    ha: "Fayil ɗin hoto ba daidai ba ne",
    mn: "Зураг файлын форматы буруу байна",
    ff: "Fayil ɗin hoton ba daidai ba ne",
    yo: "Faili aworan ti ko tọ",
  },
  "Images must be less than": {
    en: "Images must be less than",
    es: "Las imágenes deben ser menos de",
    fr: "Les images doivent être inférieures à",
    de: "Bilder müssen weniger als",
    pt: "As imagens devem ser menos de",
    it: "Le immagini devono essere meno di",
    sq: "Imazhet duhet të jenë më pak se",
    tr: "Resimler daha az olmalıdır",
    sw: "Picha lazima ziwe chini ya",
    am: "ምስሎች ያነሱ ይወስዱ አለባቸው",
    om: "Suuraaleen xiqqaa ta'uu qabu",
    ig: "Foto ga-adịkarịrị ala",
    so: "Sawiradu waa inay ka yar yihiin",
    ha: "Hotuna dole ne suɗa ƙasa da",
    mn: "Зургийн хэмжээ нь бага байх ёстой",
    ff: "Hotunan suna bukatar su zama ƙasa da",
    yo: "Awọn aworan gbọdọ jẹ kere ju",
  },
  "in size": {
    en: "in size",
    es: "en tamaño",
    fr: "en taille",
    de: "in der Größe",
    pt: "em tamanho",
    it: "in dimensione",
    sq: "në madhësi",
    tr: "boyutunda",
    sw: "katika saizi",
    am: "በመጠን",
    om: "guddina",
    ig: "na nha",
    so: "cabbirka",
    ha: "a girma",
    mn: "хэмжээгээр",
    ff: "a girma",
    yo: "ni iwọn",
  },
  image: {
    en: "Image",
    es: "Imagen",
    fr: "Image",
    de: "Bild",
    pt: "Imagem",
    it: "Immagine",
    sq: "Imazh",
    tr: "Görüntü",
    sw: "picha",
    am: "ምስል",
    om: "suuraa",
    ig: "onyonyo",
    so: "sawir",
    ha: "hoto",
    mn: "зураг",
    ff: "hoton",
    yo: "aworan",
  },
  "If this option is enabled, resellers will have the option to automatically register with you as sub resellers. If not, you have to manually accept their requests.":
    {
      en: "If this option is enabled, resellers will have the option to automatically register with you as sub resellers. If not, you have to manually accept their requests.",
      pt: "Se esta opção estiver ativada, os revendedores terão a opção de se registrar automaticamente com você como sub-revendedores. Se não, você terá que aceitar manualmente os pedidos deles.",
      de: "Wenn diese Option aktiviert ist, können Wiederverkäufer sich automatisch bei Ihnen als Unterverkäufer registrieren. Andernfalls müssen Sie ihre Anfragen manuell akzeptieren.",
      es: "Si esta opción está habilitada, los revendedores tendrán la opción de registrarse automáticamente contigo como subrevendedores. Si no, tendrás que aceptar manualmente sus solicitudes.",
      fr: "Si cette option est activée, les revendeurs auront la possibilité de s'inscrire automatiquement chez vous en tant que sous-revendeurs. Sinon, vous devrez accepter manuellement leurs demandes.",
      it: "Se questa opzione è abilitata, i rivenditori avranno la possibilità di registrarsi automaticamente con te come sub-rivenditori. In caso contrario, dovrai accettare manualmente le loro richieste.",
      sq: "Nëse kjo opsion është i aktivizuar, rivendorët do të kenë mundësinë të regjistrohen automatikisht me ju si nën-rivendorë. Nëse jo, duhet të pranoni manualisht kërkesat e tyre.",
      tr: "Bu seçenek etkinleştirildiğinde, bayiilerin sizinle otomatik olarak alt bayi olarak kaydolma seçeneği olacak. Aksi takdirde, taleplerini manuel olarak kabul etmeniz gerekecek.",
      sw: "Ikiwa chaguo hili limewezeshwa, wauzaji wataweza kujiandikisha kiotomati kwako kama wauzaji wa chini. Ikiwa sivyo, unapaswa kukubali maombi yao kwa mikono.",
      am: "እንዲህ ይቀየር በሚለው አማራጭ ይኖርባቸው ከሆነ የእቃ ወንበር ወይም የዚህ ማህበረሰብ አይቀርም። እንዲሁ በተወሰኑ ቀናት አቅርባለሁ ወይም ይቀበሉ።",
      om: "Yoo filannoon kun hojiirra oole, gurgurtoonni si wajjin gurgurtoota gadi fageenyaan akka galma’aniif carraa ni qabu. Yoo akkas hin ta’in, gaafii isaanii harka ofii qabachuu qabda.",
      ig: "Ọ bụrụ na a na-emezi nhọrọ a, ndị na-ere ngwaahịa ga-enwe nhọrọ iji debanye aha na-akpaghị aka dị ka ndị na-ere ngwaahịa ndị ọzọ. Ọ bụrụ na ọ bụghị, ị ga-anabata arịrịọ ha n'ụbọchị.",
      so: "Haddii xulashadan la hawlgeliyo, iibiyeyaashu waxay yeelan doonaan fursad ay si otomaatig ah kuugu diiwaangeliyaan sidii iibiyeyaasha hoosaadka. Haddii kale, waa inaad si gacanta ah u aqbasho codsigooda.",
      ha: "Idan wannan zaɓi an kunna, masu sayarwa za su sami zaɓi don yin rajista da kai a matsayin ƙananan masu sayarwa ta atomatik. Idan ba haka ba, dole ne ku karɓi buƙatunsu da hannu.",
      mn: "Энэ сонголтыг идэвхжүүлбэл, борлуулагчид таны доорхи борлуулагч болж автоматаар бүртгүүлэх боломжтой болно. Хэрэв тийм биш бол та тэдний хүсэлтийг гарын авлагын дагуу хүлээж авах хэрэгтэй.",
      ff: "Idan wannan zaɓi an kunna, masu sayarwa za su sami zaɓi don yin rajista da kai a matsayin ƙananan masu sayarwa ta atomatik. Idan ba haka ba, dole ne ku karɓi buƙatunsu da hannu.",
      yo: "Ti aṣayan yii ba ti wa ni mu ṣiṣẹ, awọn alagbata yoo ni aṣayan lati forukọsilẹ laifọwọyi pẹlu rẹ gẹgẹbi awọn alagbata kekere. Ti kii ba bẹ, o gbọdọ gba awọn ibeere wọn ni ọwọ.",
    },
  Info: {
    en: "Info",
    pt: "Informação",
    de: "Info",
    es: "Información",
    fr: "Info",
    it: "Info",
    sq: "Informacion",
    tr: "Bilgi",
    sw: "Taarifa",
    am: "መረጃ",
    om: "Odeeffannoo",
    ig: "Ozi",
    so: "Macluumaad",
    ha: "Bayani",
    mn: "Мэдээлэл",
    ff: "Bayani",
    yo: "Alaye",
  },

  //d
  "Dear Reseller": {
    en: "Dear Reseller",
    pt: "Caro Revendedor",
    de: "Sehr geehrter Wiederverkäufer",
    es: "Estimado revendedor",
    fr: "Cher revendeur",
    it: "Caro Rivenditore",
    sq: "I dashur Shitës",
    tr: "Sayın Bayi",
    sw: "Mpendwa Muuzaji",
    am: "እቅፍ ወይም ምርት አይችሉ",
    om: "Obsaaf, Gurgurtaa",
    ig: "Ndewo, Onye Na-ere",
    so: "Qaaliga Iibiyaha",
    ha: "Mai sayarwa mai daraja",
    mn: "Хүндэт борлуулагч",
    ff: "Masu sayarwa masu daraja",
    yo: "Olufẹ Alagbata",
  },
  Date: {
    en: "Date",
    es: "Fecha",
    fr: "Date",
    de: "Datum",
    pt: "Data",
    it: "Data",
    sq: "Data",
    tr: "Tarih",
    sw: "Tarehe",
    am: "ቀን",
    om: "Guyyaa",
    ig: "Ụbọchị",
    so: "Taariikh",
    ha: "Kwanan wata",
    mn: "Огноо",
    ff: "Rana",
    yo: "Ọjọ",
  },
  Device: {
    en: "Device",
    pt: "Dispositivo",
    de: "Gerät",
    es: "Dispositivo",
    fr: "Appareil",
    it: "Dispositivo",
    sq: "Pajisje",
    tr: "Cihaz",
    sw: "Kifaa",
    am: "መሣሪያ",
    om: "Meesha",
    ig: "Ngwa",
    so: "Qalabka",
    ha: "Na'ura",
    mn: "Төхөөрөмж",
    ff: "Na'ura",
    yo: "Ẹrọ",
  },
  Devices: {
    en: "Devices",
    pt: "Dispositivos",
    de: "Geräte",
    es: "Dispositivos",
    fr: "Appareils",
    it: "Dispositivi",
    sq: "Pajisje",
    tr: "Cihazlar",
    sw: "Vifaa",
    am: "መሣሪያዎች",
    om: "Meeshaalee",
    ig: "Ngwa",
    so: "Qalabka",
    ha: "Na'urori",
    mn: "Төхөөрөмжүүд",
    ff: "Na'urorin",
    yo: "Ẹrọ",
  },
  Deactive: {
    en: "Deactive",
    pt: "Inativo",
    de: "Inaktiv",
    es: "Inactivo",
    fr: "Inactif",
    it: "Disattivo",
    sq: "Inaktiv",
    tr: "Pasif",
    sw: "Zima",
    am: "አስተውል",
    om: "Hir'ataa",
    ig: "Iwu",
    so: "Ka joojiyay",
    ha: "Kashe",
    mn: "Идэвхгүй",
    ff: "Kashe",
    yo: "Túbọ",
  },

  "Do you want to reset": {
    en: "Do you want to reset?",
    pt: "Deseja redefinir?",
    de: "Möchten Sie zurücksetzen?",
    es: "¿Quieres restablecer?",
    fr: "Voulez-vous réinitialiser?",
    it: "Vuoi ripristinare?",
    sq: "Dëshironi të rivendosni?",
    tr: "Sıfırlamak istiyor musunuz?",
    sw: "Je, unataka kurekebisha?",
    am: "ይደግማል?",
    om: "Dhiibbaa gurgurtaa feeta?",
    ig: "Ị chọrọ ịtọghata?",
    so: "Ma dooneysaa in la cusbooneysiiyo?",
    ha: "Shin kuna son sabunta?",
    mn: "Та дахин тохируулахыг хүсч байна уу?",
    ff: "Shin kuna son sake saiti?",
    yo: "Ṣe o fẹ lati tunṣe?",
  },

  "Device not found": {
    en: "Device not found",
    pt: "Dispositivo não encontrado",
    de: "Gerät nicht gefunden",
    es: "Dispositivo no encontrado",
    fr: "Appareil non trouvé",
    it: "Dispositivo non trovato",
    sq: "Pajisja nuk u gjet",
    tr: "Cihaz bulunamadı",
    sw: "Kifaa hakikupatikana",
    am: "መሣሪያ አልተገኘም",
    om: "Meesha hin argamne",
    ig: "Ngwa achọtaghị",
    so: "Qalabka lama helin",
    ha: "Na'urar ba a same ba",
    mn: "Төхөөрөмж олдсонгүй",
    ff: "Na'urar ba a same ba",
    yo: "Ẹrọ ko ri",
  },
  Disabled: {
    en: "Disabled",
    pt: "Desativado",
    de: "Deaktiviert",
    es: "Desactivado",
    fr: "Désactivé",
    it: "Disabilitato",
    sq: "E padisponueshëm",
    tr: "Devre dışı",
    sw: "Imezimwa",
    am: "ይታወሳል",
    om: "Dhiibbaa irra bu'ee",
    ig: "Gbanyụọ",
    so: "La’aanta",
    ha: "An kashe",
    mn: "Идэвхгүй",
    ff: "An kashe",
    yo: "Túbọ",
  },
  "Deactivate device": {
    en: "Deactivate device",
    pt: "Desativar dispositivo",
    de: "Gerät deaktivieren",
    es: "Desactivar dispositivo",
    fr: "Désactiver l'appareil",
    it: "Disattivare dispositivo",
    sq: "Çaktivizo pajisjen",
    tr: "Cihazı devre dışı bırak",
    sw: "Zima kifaa",
    am: "መሣሪያ ይታወሳል",
    om: "Meesha dhangala'aa",
    ig: "Gbanyụọ ngwaọrụ",
    so: "Ka joojiyo qalabka",
    ha: "Kashe na'ura",
    mn: "Төхөөрөмжийг идэвхгүй болгох",
    ff: "Kashe na'ura",
    yo: "Túbọ ẹrọ",
  },
  Deactivate: {
    en: "Deactivate",
    pt: "Desativar",
    de: "Deaktivieren",
    es: "Desactivar",
    fr: "Désactiver",
    it: "Disattiva",
    sq: "Çaktivizo",
    tr: "Devre dışı bırak",
    sw: "Zima",
    am: "ይታወሳል",
    om: "Dhiibbaa",
    ig: "Gbanyụọ",
    so: "Jooji",
    ha: "Kashe",
    mn: "Идэвхгүй болгох",
    ff: "Kashe",
    yo: "Túbọ",
  },
  Dashboard: {
    en: "Dashboard",
    pt: "Painel",
    de: "Dashboard",
    es: "Tablero",
    fr: "Tableau de bord",
    it: "Cruscotto",
    sq: "Paneli",
    tr: "Gösterge paneli",
    sw: "Dashibodi",
    am: "ዳሽቦርድ",
    om: "Daashbordii",
    ig: "Dashboard",
    so: "Daaqad",
    ha: "Dashboard",
    mn: "Хяналтын самбар",
    ff: "Dashboard",
    yo: "Ibo Dashboard",
  },
  "Device key": {
    en: "Device key",
    pt: "Chave do dispositivo",
    de: "Geräteschlüssel",
    es: "Clave del dispositivo",
    fr: "Clé de l'appareil",
    it: "Chiave del dispositivo",
    sq: "Çelësi i pajisjes",
    tr: "Cihaz anahtarı",
    sw: "Funguo la kifaa",
    am: "የምስል ቁልፍ",
    om: "Kilabni meesha",
    ig: "Igodo ngwá",
    so: "Furaha qalabka",
    ha: "Mabuɗin na'ura",
    mn: "Төхөөрөмжийн түлхүүр",
    ff: "Mabuɗin na'ura",
    yo: "Bọtini ẹrọ",
  },
  "Devices count": {
    en: "Devices count",
    pt: "Contagem de dispositivos",
    de: "Geräteanzahl",
    es: "Conteo de dispositivos",
    fr: "Nombre d'appareils",
    it: "Conteggio dispositivi",
    sq: "Numri i pajisjeve",
    tr: "Cihaz sayısı",
    sw: "Idadi ya vifaa",
    am: "የምስል ብዛት",
    om: "Baay'ina meeshaalee",
    ig: "Ọnụ ọgụgụ ngwá",
    so: "Tirinta qalabka",
    ha: "Yawan na'urorin",
    mn: "Төхөөрөмжийн тоо",
    ff: "Yawan na'urorin",
    yo: "Iye ẹrọ",
  },
  Deactivated: {
    en: "Deactivated",
    pt: "Desativado",
    de: "Deaktiviert",
    es: "Desactivado",
    fr: "Désactivé",
    it: "Disattivato",
    sq: "Deaktivizuar",
    tr: "Devre dışı bırakıldı",
    sw: "Imezimwa",
    am: "አይታወሳል",
    om: "Dhanga'aa",
    ig: "Dịrị na njedebe",
    so: "La'aanta",
    ha: "An kashe",
    mn: "Идэвхгүй",
    ff: "An kashe",
    yo: "Túbọ",
  },
  "Deactivated by": {
    en: "Deactivated by",
    pt: "Desativado por",
    de: "Deaktiviert von",
    es: "Desactivado por",
    fr: "Désactivé par",
    it: "Disattivato da",
    sq: "Deaktivizuar nga",
    tr: "Devre dışı bırakan",
    sw: "Imezimwa na",
    am: "በማታወሳት",
    om: "Dhiibbaa kan",
    ig: "Gbanyụrụ site na",
    so: "La'aanta waxaa sameeyey",
    ha: "An kashe ta",
    mn: "Идэвхгүй болгосон",
    ff: "An kashe ta",
    yo: "Túbọ nipasẹ",
  },
  Date: {
    en: "Date",
    es: "Fecha",
    fr: "Date",
    de: "Datum",
    pt: "Data",
    it: "Data",
    sq: "Data",
    tr: "Tarih",
    sw: "Tarehe",
    am: "ቀን",
    om: "Guyyaa",
    ig: "Ụbọchị",
    so: "Taariikh",
    ha: "Kwanan wata",
    mn: "Огноо",
    ff: "Rana",
    yo: "Ọjọ",
  },
  "Deactivated date": {
    en: "Deactivated date",
    pt: "Data de desativação",
    de: "Deaktivierungsdatum",
    es: "Fecha de desactivación",
    fr: "Date de désactivation",
    it: "Data di disattivazione",
    sq: "Data e deaktivizimit",
    tr: "Devre dışı bırakılma tarihi",
    sw: "Tarehe ya kuzimwa",
    am: "የተወሰነ ቀን",
    om: "Guyyaa dhangala'aa",
    ig: "Ụbọchị gbanyụrụ",
    so: "Taariikhda la'aanta",
    ha: "Kwanan ranar da aka kashe",
    mn: "Идэвхгүй болгосон огноо",
    ff: "Kwanan ranar da aka kashe",
    yo: "Ọjọ túbọ",
  },
  "Deactivated Devices History": {
    en: "Deactivated Devices History",
    pt: "Histórico de Dispositivos Desativados",
    de: "Deaktivierte Gerätehistorie",
    es: "Historial de Dispositivos Desactivados",
    fr: "Historique des Appareils Désactivés",
    it: "Cronologia Dispositivi Disattivati",
    sq: "Historia e Dispositivëve të Deaktivizuar",
    tr: "Devre Dışı Bırakılan Cihazların Geçmişi",
    sw: "Historia ya vifaa vilivyomezwa",
    am: "የወይዘራ አስታውሰ ዝርዝር",
    om: "Seenaa meeshaalee dhangala'aa",
    ig: "Akụkọ ihe mere eme nke ngwá gbanyụrụ",
    so: "Taariikhda qalabka la'aanta",
    ha: "Tarihin na'urorin da aka kashe",
    mn: "Идэвхгүй болсон төхөөрөмжүүдийн түүх",
    ff: "Tarihin na'urorin da aka kashe",
    yo: "Ihistory ẹrọ ti túbọ",
  },
  "Deactivated history": {
    en: "Deactivated history",
    pt: "Histórico desativado",
    de: "Verlauf deaktiviert",
    es: "Historial desactivado",
    fr: "Historique désactivé",
    it: "Cronologia disattivata",
    sq: "Historia e deaktivizuar",
    tr: "Devre Dışı Bırakılan Geçmiş",
    sw: "Historia ya kuzimwa",
    am: "የተወሰነ ታሪክ",
    om: "Seenaa dhangala'aa",
    ig: "Akụkọ ihe mere eme gbanyụrụ",
    so: "Taariikhda la'aanta",
    ha: "Tarihin da aka kashe",
    mn: "Идэвхгүй болсон түүх",
    ff: "Tarihin da aka kashe",
    yo: "Ihistory túbọ",
  },
  Description: {
    en: "Description",
    pt: "Descrição",
    de: "Beschreibung",
    es: "Descripción",
    fr: "Description",
    it: "Descrizione",
    sq: "Përshkrim",
    tr: "Açıklama",
    sw: "Maelezo",
    am: "መግለጫ",
    om: "Ibsa",
    ig: "Nkọwa",
    so: "Sharaxaad",
    ha: "Bayani",
    mn: "Тодорхойлолт",
    ff: "Bayani",
    yo: "Apejuwe",
  },
  "Device count": {
    en: "Device count",
    pt: "Contagem de dispositivos",
    de: "Geräteanzahl",
    es: "Cantidad de dispositivos",
    fr: "Nombre d'appareils",
    it: "Conteggio dei dispositivi",
    sq: "Numri i pajisjeve",
    tr: "Cihaz sayısı",
    sw: "Idadi ya vifaa",
    am: "የምስል ብዛት",
    om: "Baay'ina meeshaalee",
    ig: "Ọnụ ọgụgụ ngwá",
    so: "Tirinta qalabka",
    ha: "Yawan na'urorin",
    mn: "Төхөөрөмжийн тоо",
    ff: "Yawan na'urorin",
    yo: "Iye ẹrọ",
  },
  "Device UUID": {
    en: "Device UUID",
    pt: "UUID do dispositivo",
    de: "Geräte UUID",
    es: "UUID del dispositivo",
    fr: "UUID de l'appareil",
    it: "UUID del dispositivo",
    sq: "UUID e pajisjes",
    tr: "Cihaz UUID",
    sw: "UUID ya kifaa",
    am: "የምስል UUID",
    om: "UUID meesha",
    ig: "UUID ngwá",
    so: "UUID qalabka",
    ha: "UUID na'ura",
    mn: "Төхөөрөмжийн UUID",
    ff: "UUID na'ura",
    yo: "UUID ẹrọ",
  },
  "Device Config": {
    en: "Device Config",
    pt: "Configuração do dispositivo",
    de: "Gerätekonfiguration",
    es: "Configuración del dispositivo",
    fr: "Configuration de l'appareil",
    it: "Configurazione del dispositivo",
    sq: "Konfigurimi i pajisjes",
    tr: "Cihaz Yapılandırması",
    sw: "Mipangilio ya kifaa",
    am: "የምስል ዝርዝር",
    om: "Meesha Mukaayyoo",
    ig: "Ntọala ngwá",
    so: "Dejinta qalabka",
    ha: "Tsarin na'ura",
    mn: "Төхөөрөмжийн тохиргоо",
    ff: "Tsarin na'ura",
    yo: "Eto ẹrọ",
  },
  "Don't have account": {
    en: "Don't have account?",
    pt: "Não tem uma conta?",
    de: "Haben Sie kein Konto?",
    es: "¿No tienes una cuenta?",
    fr: "Vous n'avez pas de compte ?",
    it: "Non hai un account?",
    sq: "Nuk keni llogari?",
    tr: "Hesabınız yok mu?",
    sw: "Huna akaunti",
    am: "አካውንት አልተወዳድሩም",
    om: "Akkawwa hin qabdu",
    ig: "Enweghị akaụntụ",
    so: "Ma haysato akoon",
    ha: "Babu asusun",
    mn: "Байгууллагагүй",
    ff: "Babu asusun",
    yo: "Kò ní àkọọlẹ",
  },
  "Deactivated devices": {
    en: "Deactivated devices",
    pt: "Dispositivos desativados",
    de: "Deaktivierte Geräte",
    es: "Dispositivos desactivados",
    fr: "Appareils désactivés",
    it: "Dispositivi disattivati",
    sq: "Pajisjet e çaktivizuara",
    tr: "Devre dışı bırakılan cihazlar",
    sw: "Vifaa vilivyomezwa",
    am: "የተወሰኑ መሣሪያዎች",
    om: "Meeshaalee dhangala'aa",
    ig: "Ngwa gbanyụrụ",
    so: "Qalabka la'aanta",
    ha: "Na'urorin da aka kashe",
    mn: "Идэвхгүй болсон төхөөрөмжүүд",
    ff: "Na'urorin da aka kashe",
    yo: "Ẹrọ tí túbọ",
  },

  "DE:Sky Thriller HD+": {
    en: "DE:Sky Thriller HD+",
    pt: "DE: Sky Thriller HD+",
    de: "DE: Sky Thriller HD+",
    es: "DE: Sky Thriller HD+",
    fr: "DE: Sky Thriller HD+",
    it: "DE: Sky Thriller HD+",
    sq: "DE: Sky Thriller HD+",
    tr: "DE: Sky Thriller HD+",
    sw: "DE:Sky Thriller HD+",
    am: "DE:Sky Thriller HD+",
    om: "DE:Sky Thriller HD+",
    ig: "DE:Sky Thriller HD+",
    so: "DE:Sky Thriller HD+",
    ha: "DE:Sky Thriller HD+",
    mn: "DE:Sky Thriller HD+",
    ff: "DE:Sky Thriller HD+",
    yo: "DE:Sky Thriller HD+",
  },
  "Date (MM/YY)": {
    en: "Date (MM/YY)",
    pt: "Data (MM/AA)",
    de: "Datum (MM/YY)",
    es: "Fecha (MM/AA)",
    fr: "Date (MM/AA)",
    it: "Data (MM/AA)",
    sq: "Data (MM/VV)",
    tr: "Tarih (AA/YY)",
    sw: "Tarehe (MM/YY)",
    am: "ቀን (MM/YY)",
    om: "Guyyaa (MM/YY)",
    ig: "Ụbọchị (MM/YY)",
    so: "Taariikh (MM/YY)",
    ha: "Kwanan wata (MM/YY)",
    mn: "Огноо (MM/YY)",
    ff: "Kwanan wata (MM/YY)",
    yo: "Ọjọ (MM/YY)",
  },

  "Do you want to delete this": {
    en: "Do you want to delete this",
    pt: "Deseja excluir isso",
    de: "Möchten Sie dies löschen",
    es: "¿Quieres eliminar esto?",
    fr: "Voulez-vous supprimer ceci?",
    it: "Vuoi eliminare questo?",
    sq: "Dëshironi të fshini këtë?",
    tr: "Bunu silmek istiyor musunuz?",
    sw: "Je, unataka kufuta hii?",
    am: "ይፈጥሩ ይፈጥሩ?",
    om: "Kun hiikuu barbaaddaa?",
    ig: "Ị chọrọ ihichapụ nke a?",
    so: "Ma dooneysaa in la tirtirto tan?",
    ha: "Shin kuna son goge wannan?",
    mn: "Энэ зүйлийг устгахыг хүсч байна уу?",
    ff: "Shin kuna son goge wannan?",
    yo: "Ṣe o fẹ lati pa eyi?",
  },
  "DNS deleted successfully": {
    en: "DNS deleted successfully",
    pt: "DNS excluído com sucesso",
    de: "DNS erfolgreich gelöscht",
    es: "DNS eliminado con éxito",
    fr: "DNS supprimé avec succès",
    it: "DNS eliminato con successo",
    sq: "DNS u fshi me sukses",
    tr: "DNS başarıyla silindi.",
    sw: "DNS imefutwa kwa mafanikio",
    am: "DNS በታማኝነት ተወውታል",
    om: "DNS milkaa'inaadhaan dhangala'e",
    ig: "DNS kwụsịrị nke ọma",
    so: "DNS si guuleysto tirtiray",
    ha: "DNS an goge shi da nasara",
    mn: "DNS амжилттай устгалаа",
    ff: "DNS an goge shi da nasara",
    yo: "DNS ti wa ni pa pẹlu aṣeyọri",
  },
  "Do you want to logout": {
    en: "Do you want to logout",
    pt: "Deseja sair",
    de: "Möchten Sie sich abmelden",
    es: "¿Quieres cerrar sesión",
    fr: "Voulez-vous vous déconnecter",
    it: "Vuoi uscire",
    sq: "Dëshironi të dilni",
    tr: "Çıkış yapmak istiyor musunuz",
    sw: "Je, unataka kutoka?",
    am: "ይወዳድሩ?",
    om: "Keenya baqattee?",
    ig: "Ị chọrọ ịpụọ?",
    so: "Ma dooneysaa inaad ka baxdo?",
    ha: "Shin kuna son fita?",
    mn: "Та гарч буй юу?",
    ff: "Shin kuna son fita?",
    yo: "Ṣe o fẹ lati jade?",
  },

  "Do you want to reset?": {
    en: "Do you want to reset?",
    pt: "Deseja redefinir?",
    de: "Möchten Sie zurücksetzen?",
    es: "¿Quieres restablecer?",
    fr: "Voulez-vous réinitialiser?",
    it: "Vuoi ripristinare?",
    sq: "Dëshironi të rivendosni?",
    tr: "Sıfırlamak istiyor musunuz?",
    sw: "Je, unataka kurekebisha?",
    am: "ይደግማል?",
    om: "Dhiibbaa gurgurtaa feeta?",
    ig: "Ị chọrọ ịtọghata?",
    so: "Ma dooneysaa in la cusbooneysiiyo?",
    ha: "Shin kuna son sabunta?",
    mn: "Та дахин тохируулахыг хүсч байна уу?",
    ff: "Shin kuna son sake saiti?",
    yo: "Ṣe o fẹ lati tunṣe?",
  },
  "Device key must be 6 characters": {
    en: "Device key must be 6 characters",
    pt: "A chave do dispositivo deve ter 6 caracteres",
    de: "Geräteschlüssel muss 6 Zeichen lang sein",
    es: "La clave del dispositivo debe tener 6 caracteres",
    fr: "La clé de l'appareil doit contenir 6 caractères",
    it: "La chiave del dispositivo deve essere composta da 6 caratteri",
    sq: "Çelësi i pajisjes duhet të jetë 6 karaktere",
    tr: "Cihaz anahtarı 6 karakter olmalıdır.",
    sw: "Funguo la kifaa lazima liwe na herufi 6",
    am: "የምስል ቁልፍ 6 ፊደል መኖር ይኖርብዎታል",
    om: "Kilabni meeshaa qubeen 6 qabaachuu qaba",
    ig: "Igodo ngwá ga-abụ ihe 6",
    so: "Furaha qalabka waa inuu noqdaa 6 xaraf",
    ha: "Mabuɗin na'ura dole ne ya zama haruffa 6",
    mn: "Төхөөрөмжийн түлхүүр нь 6 тэмдэгттэй байх ёстой",
    ff: "Mabuɗin na'ura dole ne ya zama haruffa 6",
    yo: "Bọtini ẹrọ gbọdọ jẹ ohun 6",
  },

  "Do you want to deactivate this device": {
    en: "Do you want to deactivate this device?",
    pt: "Deseja desativar este dispositivo?",
    de: "Möchten Sie dieses Gerät deaktivieren?",
    es: "¿Desea desactivar este dispositivo?",
    fr: "Voulez-vous désactiver cet appareil ?",
    it: "Vuoi disattivare questo dispositivo?",
    sq: "A dëshironi të çaktivizoni këtë pajisje?",
    tr: "Bu cihazı devre dışı bırakmak istiyor musunuz?",
    sw: "Je, unataka kuzima kifaa hiki?",
    am: "ይወዳድሩ ይደግማል?",
    om: "Keenya kan hiika?",
    ig: "Ị chọrọ gbanyụọ ngwá a?",
    so: "Ma dooneysaa inaad ka joojiso qalabkan?",
    ha: "Shin kuna son kashe wannan na'ura?",
    mn: "Та энэ төхөөрөмжийг идэвхгүй болгохыг хүсч байна уу?",
    ff: "Shin kuna son kashe wannan na'ura?",
    yo: "Ṣe o fẹ lati túbọ ẹrọ yii?",
  },

  "Do you want to block this device": {
    en: "Do you want to block this device?",
    pt: "Você quer bloquear este dispositivo?",
    de: "Möchten Sie dieses Gerät blockieren?",
    es: "¿Quieres bloquear este dispositivo?",
    fr: "Voulez-vous bloquer ce dispositif?",
    it: "Vuoi bloccare questo dispositivo?",
    sq: "A dëshironi të bllokoni këtë pajisje?",
    tr: "Bu cihazı engellemek istiyor musunuz?",
    sw: "Je, unataka kuzuia kifaa hiki?",
    am: "ይታወሳል ይደግማል?",
    om: "Keenya kan dhangala'aa?",
    ig: "Ị chọrọ igbochi ngwá a?",
    so: "Ma dooneysaa inaad ka xannibto qalabkan?",
    ha: "Shin kuna son toshe wannan na'ura?",
    mn: "Та энэ төхөөрөмжийг хаахыг хүсч байна уу?",
    ff: "Shin kuna son toshe wannan na'ura?",
    yo: "Ṣe o fẹ lati dènà ẹrọ yii?",
  },
  "Do you want to unblock this device": {
    en: "Do you want to unblock this device?",
    pt: "Deseja desbloquear este dispositivo?",
    de: "Möchten Sie dieses Gerät freischalten?",
    es: "¿Desea desbloquear este dispositivo?",
    fr: "Voulez-vous débloquer ce périphérique?",
    it: "Vuoi sbloccare questo dispositivo?",
    sq: "A dëshironi të çaktivizoni bllokimin e kësaj pajisje?",
    tr: "Bu cihazın engelini kaldırmak istiyor musunuz?",
    sw: "Je, unataka kufungua kifaa hiki?",
    am: "ይወዳድሩ ይደግማል?",
    om: "Keenya kan dhiibbaa?",
    ig: "Ị chọrọ iwepu ngwá a?",
    so: "Ma dooneysaa inaad ka furto qalabkan?",
    ha: "Shin kuna son bude wannan na'ura?",
    mn: "Та энэ төхөөрөмжийг нээхийг хүсч байна уу?",
    ff: "Shin kuna son bude wannan na'ura?",
    yo: "Ṣe o fẹ lati yọ ẹjọ yii?",
  },
  "Device configuration updates are successful": {
    en: "Device configuration updates are successful.",
    pt: "As atualizações de configuração do dispositivo foram bem-sucedidas.",
    de: "Gerätekonfigurationsaktualisierungen waren erfolgreich.",
    es: "Las actualizaciones de configuración del dispositivo se realizaron con éxito.",
    fr: "Les mises à jour de la configuration de l'appareil ont réussi.",
    it: "Gli aggiornamenti della configurazione del dispositivo sono avvenuti con successo.",
    sq: "Përditësimet e konfigurimit të pajisjes janë të suksesshme.",
    tr: "Cihaz yapılandırma güncellemeleri başarılıdır.",
    sw: "Sasisho la mipangilio ya kifaa limefanikiwa",
    am: "የመሣሪያ ቅንብሮች ዝርዝር ደረጃ ተሳክቷል",
    om: "Guddina mijeexaa meeshaa milkaa'inaadha",
    ig: "Nchịkọta ngwá na-eme nke ọma",
    so: "Cusbooneysiinta qalabka waa guul",
    ha: "Sabuntawa na'ura sun yi nasara",
    mn: "Төхөөрөмжийн тохиргооны шинэчлэлт амжилттай боллоо",
    ff: "Sabuntawa na'ura sun yi nasara",
    yo: "Awọn imudojuiwọn iṣeto ẹrọ ti ṣaṣeyọri",
  },
  "Double check your server credentials before pressing start": {
    en: "Double check your server credentials before pressing start.",
    pt: "Verifique suas credenciais do servidor antes de pressionar Iniciar.",
    de: "Überprüfen Sie Ihre Server-Anmeldeinformationen, bevor Sie starten.",
    es: "Verifique sus credenciales del servidor antes de presionar Iniciar.",
    fr: "Vérifiez vos identifiants de serveur avant d'appuyer sur Démarrer.",
    it: "Verifica le tue credenziali del server prima di premere Avvia.",
    sq: "Verifikoni kredencialet tuaja të serverit para se të shtypni fillimi.",
    tr: "Başlatmadan önce sunucu kimlik bilgilerinizi kontrol edin.",
    sw: "Angalia mara mbili hati zako za seva kabla ya kubonyeza kuanza",
    am: "ከመጀመሪያ በፊት የሰርቨር መረጃዎች ይረጋገጡ",
    om: "Dhiibbaa sirna keessan guyyuu dura mirkaneeffadhu",
    ig: "Nyochaa akwụkwọ ikike sava gị tupu ịpịa malite",
    so: "Hubi laba jeer aqoonsigaaga server ka hor inta aad riixdo bilow",
    ha: "Duba shaida na'urar ku kafin danna farawa",
    mn: "Серверийн гэрчилгээ тань шалгах, эхлэх товчийг дарахаас өмнө",
    ff: "Duba shaida na'urar ku kafin danna farawa",
    yo: "Ṣayẹwo awọn iwe-ẹri olupin rẹ ṣaaju titẹ bọtini ibẹrẹ",
  },
  "device activation info": {
    en: "If you would like to activate this device in the future, you need to spend a credit on it again. Do you want to deactivate this device?",
    pt: "Se você deseja ativar este dispositivo no futuro, precisará gastar créditos novamente. Deseja desativar este dispositivo?",
    de: "Wenn Sie dieses Gerät in Zukunft aktivieren möchten, müssen Sie erneut Guthaben dafür ausgeben. Möchten Sie dieses Gerät deaktivieren?",
    es: "Si desea activar este dispositivo en el futuro, deberá gastar créditos nuevamente. ¿Desea desactivar este dispositivo?",
    fr: "Si vous souhaitez activer ce dispositif à l'avenir, vous devrez dépenser à nouveau des crédits. Souhaitez-vous désactiver ce dispositif ?",
    it: "Se desideri attivare nuovamente questo dispositivo in futuro, dovrai spendere nuovamente un credito. Vuoi disattivare questo dispositivo?",
    sq: "Nëse dëshironi të aktivizoni këtë pajisje në të ardhmen, duhet të shpenzoni përsëri një kredit për të. Doni të çaktivizoni këtë pajisje?",
    tr: "Bu cihazı gelecekte etkinleştirmek istiyorsanız, tekrar kredi harcamanız gerekiyor. Bu cihazı devre dışı bırakmak istiyor musunuz?",
    sw: "taarifa ya kuanzisha kifaa",
    am: "የመሣሪያ እንቅስቃሴ መረጃ",
    om: "Odeeffannoo hiikkaa meeshaa",
    ig: "Ozi nkwado ngwá",
    so: "Macluumaadka hawlgelinta qalabka",
    ha: "Bayanan kunna na'ura",
    mn: "Төхөөрөмжийн идэвхжүүлэлтийн мэдээлэл",
    ff: "Bayanan kunna na'ura",
    yo: "Alaye iṣiṣẹ ẹrọ",
  },
  "Do you want to reset this device": {
    en: "Do you want to reset this device?",
    pt: "Deseja reiniciar este dispositivo?",
    de: "Möchten Sie dieses Gerät zurücksetzen?",
    es: "¿Desea restablecer este dispositivo?",
    fr: "Voulez-vous réinitialiser cet appareil?",
    it: "Vuoi ripristinare questo dispositivo?",
    sq: "Doni të ristartoni këtë pajisje?",
    tr: "Bu cihazı sıfırlamak istiyor musunuz?",
    sw: "Je, unataka kurekebisha kifaa hiki?",
    am: "ይወዳድሩ ይደግማል?",
    om: "Keenya kan hiika?",
    ig: "Ị chọrọ ịtọghata ngwá a?",
    so: "Ma dooneysaa in la cusbooneysiiyo qalabkan?",
    ha: "Shin kuna son sabunta wannan na'ura?",
    mn: "Та энэ төхөөрөмжийг дахин тохируулахыг хүсч байна уу?",
    ff: "Shin kuna son sabunta wannan na'ura?",
    yo: "Ṣe o fẹ lati tunṣe ẹrọ yii?",
  },
  "Do you want to cancel reset this device": {
    en: "Do you want to cancel reset this device?",
    pt: "Você deseja cancelar a reinicialização deste dispositivo?",
    de: "Möchten Sie den Reset dieses Geräts abbrechen?",
    es: "¿Desea cancelar el restablecimiento de este dispositivo?",
    fr: "Voulez-vous annuler la réinitialisation de cet appareil?",
    it: "Vuoi annullare il ripristino di questo dispositivo?",
    sq: "Doni të anuloni ristartin e kësaj pajisje?",
    tr: "Bu cihazın sıfırlanmasını iptal etmek istiyor musunuz?",
    sw: "Je, unataka kufuta kurekebisha kifaa hiki?",
    am: "ይወዳድሩ ይደግማል?",
    om: "Keenya kan hiikkaa dhangala'aa?",
    ig: "Ị chọrọ ịgbanwe ịtọghata ngwá a?",
    so: "Ma dooneysaa in la kansal qalabkan cusbooneysiinta?",
    ha: "Shin kuna son soke sabunta wannan na'ura?",
    mn: "Та энэ төхөөрөмжийг дахин тохируулах үйлдлийг цуцлахыг хүсч байна уу?",
    ff: "Shin kuna son soke sabunta wannan na'ura?",
    yo: "Ṣe o fẹ lati fagile atunṣe ẹrọ yii?",
  },

  Date: {
    en: "Date",
    pt: "Data",
    de: "Datum",
    es: "Fecha",
    fr: "Date",
    it: "Data",
    sq: "Data",
    tr: "Tarih",
    sw: "Tarehe",
    am: "ቀን",
    om: "Guyyaa",
    ig: "Ụbọchị",
    so: "Taariikh",
    ha: "Kwanan wata",
    mn: "Огноо",
    ff: "Kwanan wata",
    yo: "Ọjọ",
  },
  Delete: {
    en: "Delete",
    pt: "Excluir",
    de: "Löschen",
    es: "Eliminar",
    fr: "Supprimer",
    it: "Elimina",
    sq: "Fshij",
    tr: "Sil",
    sw: "Futa",
    am: "ማጥፋት",
    om: "Dhimma",
    ig: "Hichapụ",
    so: "Tirtir",
    ha: "Goge",
    mn: "Устгах",
    ff: "Goge",
    yo: "Pa",
  },
  Day: {
    en: "Day",
    es: "Día",
    fr: "Jour",
    de: "Tag",
    pt: "Dia",
    it: "Giorno",
    sq: "Ditë",
    tr: "Gün",
    sw: "Siku",
    am: "ቀን",
    om: "Guyyaa",
    ig: "Ụbọchị",
    so: "Maalin",
    ha: "Rana",
    mn: "Өдөр",
    ff: "Rana",
    yo: "Ọjọ",
  },

  //m
  Media: {
    en: "Media",
    pt: "Mídia",
    de: "Medien",
    es: "Medios",
    fr: "Médias",
    it: "Media",
    sq: "Media",
    tr: "Medya",
    sw: "Vyombo vya habari",
    am: "ማህበረሰብ",
    om: "Mediyaa",
    ig: "Mmadụ",
    so: "Warbaahinta",
    ha: "Kafofin watsa labarai",
    mn: "Медиа",
    ff: "Kafofin watsa labarai",
    yo: "Iṣẹ́ ìròyìn",
  },
  Month: {
    en: "Month",
    es: "Mes",
    fr: "Mois",
    de: "Monat",
    pt: "Mês",
    it: "Mese",
    sq: "Mënd",
    tr: "Ay",
    sw: "Mwezi",
    am: "ወር",
    om: "Ji'a",
    ig: "Ọnwa",
    so: "Bishii",
    ha: "Wata",
    mn: "Сар",
    ff: "Wata",
    yo: "Oṣù",
  },
  Message: {
    en: "Message",
    pt: "Mensagem",
    de: "Nachricht",
    es: "Mensaje",
    fr: "Message",
    it: "Messaggio",
    sq: "Mesazh",
    tr: "Mesaj",
    sw: "Ujumbe",
    am: "መልእክት",
    om: "Ergaa",
    ig: "Ozi",
    so: "Fariinta",
    ha: "Sako",
    mn: "Мэссеж",
    ff: "Sako",
    yo: "Ifiranṣẹ",
  },
  "Movie ID": {
    en: "Movie ID",
    pt: "ID do Filme",
    de: "Film-ID",
    es: "ID de Película",
    fr: "ID du Film",
    it: "ID Film",
    sq: "ID e Filmit",
    tr: "Film ID",
    sw: "Kitambulisho cha Filamu",
    am: "የፊልም መለያ",
    om: "ID faaya",
    ig: "Nkọwa Filim",
    so: "Aqoonsiga Filimka",
    ha: "ID na'ura",
    mn: "Киноны ID",
    ff: "ID na'ura",
    yo: "ID Fíìmù",
  },
  Menu: {
    en: "Menu",
    pt: "Menu",
    de: "Menü",
    es: "Menú",
    fr: "Menu",
    it: "Menu",
    sq: "Meny",
    tr: "Menü",
    sw: "Menyu",
    am: "መዝገብ",
    om: "Meni",
    ig: "Menu",
    so: "Liiska",
    ha: "Jadawali",
    mn: "Цэс",
    ff: "Jadawali",
    yo: "Mẹ́ńù",
  },

  "Menu Page": {
    en: "Menu Page",
    es: "Página de menú",
    fr: "Page de menu",
    de: "Menüseite",
    pt: "Página de menu",
    it: "Pagina del menu",
    sq: "Faqja e menysë",
    tr: "Sayfa Menüsü",
    sw: "Ukurasa wa Menyu",
    am: "የመዝገብ ገጽ",
    om: "Fuula Meni",
    ig: "Okwu Menu",
    so: "Bogga Liiska",
    ha: "Shafin Menu",
    mn: "Цэсийн хуудас",
    ff: "Shafin Menu",
    yo: "Ile-iwe Mẹ́ńù",
  },
  month: {
    en: "month",
    pt: "mês",
    de: "Monat",
    es: "mes",
    fr: "mois",
    it: "mese",
    sq: "muaj",
    tr: "ay",
    sw: "mwezi",
    am: "ወር",
    om: "ji'a",
    ig: "ọnwa",
    so: "bil",
    ha: "wata",
    mn: "сар",
    ff: "wata",
    yo: "oṣù",
  },
  Movies: {
    en: "Movies",
    pt: "Filmes",
    de: "Filme",
    es: "Películas",
    fr: "Films",
    it: "Film",
    sq: "Filma",
    tr: "Filmler",
    sw: "Filamu",
    am: "ፊልም",
    om: "Filma",
    ig: "Ụlọ nkiri",
    so: "Filimada",
    ha: "Fina-finai",
    mn: "Кино",
    ff: "Fina-finai",
    yo: "Fíìmù",
  },
  Migration: {
    en: "Migration",
    pt: "Migração",
    de: "Migration",
    es: "Migración",
    fr: "Migration",
    it: "Migrazione",
    sq: "Migracion",
    tr: "Göç",
    sw: "Uhamiaji",
    am: "ምርጥ መድረሻ",
    om: "Guddina",
    ig: "Mgbasa",
    so: "Guuritaanka",
    ha: "Hijira",
    mn: "Нүүх",
    ff: "Hijira",
    yo: "Àtúnlò",
  },
  Migrate: {
    en: "Migrate",
    pt: "Migrar",
    de: "Migrieren",
    es: "Migrar",
    fr: "Migrer",
    it: "Migrare",
    sq: "Migrimi",
    tr: "Göç",
    sw: "Hamia",
    am: "ይምርጡ",
    om: "Hammachuu",
    ig: "Kwuru",
    so: "Guuri",
    ha: "Hawan",
    mn: "Нүүх",
    ff: "Hawan",
    yo: "Gbe",
  },
  Model: {
    en: "Model",
    pt: "Modelo",
    de: "Modell",
    es: "Modelo",
    fr: "Modèle",
    it: "Modello",
    sq: "Modeli",
    tr: "Model",
    sw: "Mfano",
    am: "ሞዴል",
    om: "Mootii",
    ig: "Ụdị",
    so: "Muuqaal",
    ha: "Mọdel",
    mn: "Загвар",
    ff: "Mọdel",
    yo: "Àpẹẹrẹ",
  },
  "Movies Categories": {
    en: "Movies Categories",
    pt: "Categorias de filmes",
    de: "Filmkategorien",
    es: "Categorías de películas",
    fr: "Catégories de films",
    it: "Categorie di film",
    sq: "Kategoritë e filmit",
    tr: "Film Kategorileri",
    sw: "Kategoria za Filamu",
    am: "የፊልም ምድቦች",
    om: "Qabiyyee Filmoota",
    ig: "Ọrụ nke Filim",
    so: "Qaybaha Filimada",
    ha: "Rukuni na fina-finai",
    mn: "Киноны ангилал",
    ff: "Rukuni na fina-finai",
    yo: "Ibi fíìmù",
  },

  "Monday/September/2023": {
    en: "Monday/September/2023",
    pt: "Segunda-feira/Setembro/2023",
    de: "Montag/September/2023",
    es: "Lunes/Septiembre/2023",
    fr: "Lundi/Septembre/2023",
    it: "Lunedì/Settembre/2023",
    sq: "E Hënë/Shtator/2023",
    tr: "Pazartesi/ Eylül/2023",
    sw: "Jumatatu/Septemba/2023",
    am: "ሰኞ/ሴፕቴምበር/2023",
    om: "Wiixata/Siqembre/2023",
    ig: "Mọnde/Septemba/2023",
    so: "Isniin/Seteembar/2023",
    ha: "Litinin/Satumba/2023",
    mn: "Даваа/Септембер/2023",
    ff: "Litinin/Seteembar/2023",
    yo: "Ọjọ́ Àìkú/Ṣẹ́pẹ́m̀bà/2023",
  },
  "Migration system": {
    en: "Migration system",
    pt: "Sistema de migração",
    de: "Migrationsystem",
    es: "Sistema de migración",
    fr: "Système de migration",
    it: "Sistema di migrazione",
    sq: "Sistemi i migracionit",
    tr: "Göç sistemi",
    sw: "Mfumo wa Uhamiaji",
    am: "የምርጥ መድረሻ",
    om: "Sistama Guddinaa",
    ig: "Sistem Mgbasa",
    so: "Nidaamka Guuritaanka",
    ha: "Tsarin Hijira",
    mn: "Нүүхийн систем",
    ff: "Tsarin Hijira",
    yo: "Ẹ̀rọ ìlò",
  },
  "Migrate your users from one server to another": {
    en: "Migrate your users from one server to another",
    pt: "Migre seus usuários de um servidor para outro",
    de: "Migrieren Sie Ihre Benutzer von einem Server auf einen anderen",
    es: "Migra a tus usuarios de un servidor a otro",
    fr: "Migrez vos utilisateurs d'un serveur à un autre",
    it: "Migra i tuoi utenti da un server all'altro",
    sq: "Migrojini përdoruesit tuaj nga një server në një tjetër",
    tr: "Kullanıcılarınızı bir sunucudan diğerine taşımak için",
    sw: "Hamisha watumiaji wako kutoka seva moja hadi nyingine",
    am: "አንደኛ ወር ወደ ሌላ ይምርጡ",
    om: "User keesaa bakka tokko irraa gara biraatti hammachuu",
    ig: "Kwuru ndị ọrụ gị site na sava otu gaa na nke ọzọ",
    so: "Guuri isticmaalayaashaada hal-abuurka ka mid ah hal kale",
    ha: "Migrat da'a daga na'ura guda zuwa wani",
    mn: "Хэрэглэгчдийг нэг серверээс нөгөөг рүү нүүж оруулна",
    ff: "Migrat da'a daga na'ura guda zuwa wani",
    yo: "Gbe awọn olumulo rẹ lati olupin kan si omiiran",
  },
  "Migrate your users from": {
    en: "Migrate your users from",
    pt: "Migre seus usuários de",
    de: "Migrieren Sie Ihre Benutzer von",
    es: "Migra tus usuarios desde",
    fr: "Migrez vos utilisateurs depuis",
    it: "Migra i tuoi utenti da",
    sq: "Migrojini përdoruesit tuaj nga",
    tr: "Kullanıcılarınızı buradan taşıyın",
    sw: "Hamisha watumiaji wako kutoka",
    am: "ተጠቃሚዎችዎን ከምንጭ",
    om: "User keesaa bakka irraa",
    ig: "Kwuru ndị ọrụ gị site na",
    so: "Guuri isticmaalayaashaada ka",
    ha: "Migrat da'a daga",
    mn: "Хэрэглэгчдийг нүүлгэх",
    ff: "Migrat da'a daga",
    yo: "Gbe awọn olumulo rẹ lati",
  },
  "Migration is not possible": {
    en: "Migration is not possible. The new migration server matches the server you want to migrate from.",
    pt: "A migração não é possível. O novo servidor de migração corresponde ao servidor de origem que deseja migrar.",
    de: "Die Migration ist nicht möglich. Der neue Migrationsserver entspricht dem Server, von dem Sie migrieren möchten.",
    es: "La migración no es posible. El nuevo servidor de migración coincide con el servidor del que desea migrar.",
    fr: "La migration n'est pas possible. Le nouveau serveur de migration correspond au serveur que vous souhaitez migrer.",
    it: "La migrazione non è possibile. Il nuovo server di migrazione corrisponde al server da cui desideri migrare.",
    sq: "Migrimi nuk është i mundur. Serveri i ri i migrimit përputhet me serverin që dëshironi të migroni.",
    tr: "Göç mümkün değildir. Yeni göç sunucusu, taşımak istediğiniz sunucu ile eşleşiyor.",
    sw: "Uhamiaji hauwezekani",
    am: "ምርጥ አይቻልም",
    om: "Guddina hin danda'amu",
    ig: "Mgbasa adịghị",
    so: "Guuritaanku ma dhici karo",
    ha: "Hijira ba zai yiwu ba",
    mn: "Нүүх боломжгүй",
    ff: "Hijira ba zai yiwu ba",
    yo: "Ìkànsí rẹ kò le ṣeé ṣe",
  },
  "Migration system allows you to move all": {
    en: "Migration system allows you to move all your users from one server to another server within a few minutes in case of a problem.",
    pt: "O sistema de migração permite mover todos os seus usuários de um servidor para outro em poucos minutos, em caso de problema.",
    de: "Das Migrationsystem ermöglicht es Ihnen, alle Ihre Benutzer innerhalb weniger Minuten von einem Server auf einen anderen zu verschieben, falls ein Problem auftritt.",
    es: "El sistema de migración le permite mover a todos sus usuarios de un servidor a otro en cuestión de minutos en caso de un problema.",
    fr: "Le système de migration vous permet de déplacer tous vos utilisateurs d'un serveur à un autre en quelques minutes en cas de problème.",
    it: "Il sistema di migrazione consente di spostare tutti i tuoi utenti da un server all'altro in pochi minuti in caso di problemi.",
    sq: "Sistemi i migrimit ju lejon të lëvizni të gjithë përdoruesit tuaj nga një server në një tjetër brenda disa minutave në rast se ka një problem.",
    tr: "Göç sistemi, bir sorun olması durumunda, tüm kullanıcılarınızı birkaç dakika içinde bir sunucudan diğerine taşımanıza olanak tanır.",
    sw: "Mfumo wa uhamiaji unakuwezesha kuhamasisha yote",
    am: "የምርጥ መድረሻ ወደ ሌላ ይምርጡ",
    om: "Sistama Guddina si'aa hunda guutuu",
    ig: "Sistem mgbasa na-enye gị ohere ịkwaga niile",
    so: "Nidaamka guuritaanka wuxuu kuu oggolaanayaa inaad guurto dhammaan",
    ha: "Tsarin hijira yana ba ku damar motsawa duk",
    mn: "Нүүхийн систем бүх зүйлсийг нүүж оруулах боломжийг олгодог",
    ff: "Tsarin hijira yana ba ku damar motsawa duk",
    yo: "Ẹ̀rọ ìlò ń jẹ́ kí o gbe gbogbo",
  },

  //l
  Lock: {
    en: "Lock",
    pt: "Trancar",
    de: "Sperren",
    es: "Bloquear",
    fr: "Verrouiller",
    it: "Blocca",
    sq: "Blloko",
    tr: "Kilitle",
    sw: "Funga",
    am: "ቁልፍ",
    om: "Cufaa",
    ig: "Lock",
    so: "Xidh",
    ha: "Kulle",
    mn: "Түлхүүр",
    ff: "Kulle",
    yo: "Lock",
  },
  Login: {
    en: "Login",
    pt: "Entrar",
    de: "Anmeldung",
    es: "Iniciar sesión",
    fr: "Connexion",
    it: "Accesso",
    sq: "Identifikohu",
    tr: "Giriş",
    sw: "Ingiza",
    am: "ወደ ይዘው",
    om: "Seensaa",
    ig: "Banye",
    so: "Gali",
    ha: "Shiga",
    mn: "Нэвтрэх",
    ff: "Shiga",
    yo: "Wọlé",
  },
  Logout: {
    en: "Logout",
    pt: "Sair",
    de: "Abmelden",
    es: "Cerrar sesión",
    fr: "Déconnexion",
    it: "Esci",
    sq: "Çkyçu",
    tr: "Çıkış",
    sw: "Toka",
    am: "ወደ ይዘው",
    om: "Baafannaa",
    ig: "Pụọ",
    so: "Ka bax",
    ha: "Fita",
    mn: "Гарах",
    ff: "Fita",
    yo: "Jade",
  },
  "Logo size": {
    en: "Logo size",
    pt: "Tamanho do logotipo",
    de: "Logo-Größe",
    es: "Tamaño del logotipo",
    fr: "Taille du logo",
    it: "Dimensione del logo",
    sq: "Madhësia e logotipit",
    tr: "Logo boyutu",
    sw: "Ukubwa wa Nembo",
    am: "የሎጎ መጠን",
    om: "Cinaa Loogoo",
    ig: "Nkwupụta Logo",
    so: "Cabbirka Summada",
    ha: "Girman Logo",
    mn: "Логогийн хэмжээ",
    ff: "Girman Logo",
    yo: "Iwọn Àmi",
  },
  "Live TV": {
    en: "Live TV",
    pt: "TV ao Vivo",
    de: "Live-TV",
    es: "TV en vivo",
    fr: "Télévision en direct",
    it: "TV in diretta",
    sq: "TV Live",
    tr: "Canlı TV",
    sw: "Televisheni ya Moja kwa Moja",
    am: "ቀጣይ ቴሌቪዥን",
    om: "Televizhinii Dhiha",
    ig: "Television na-adị ndụ",
    so: "Telefishinka Tooska ah",
    ha: "Talabijin Kai tsaye",
    mn: "Амьд телевиз",
    ff: "Talabijin Kai tsaye",
    yo: "Televison Laye",
  },
  lifetime: {
    en: "Lifetime",
    pt: "Vitalício",
    de: "Lebensdauer",
    es: "Vitalicio",
    fr: "Durée de vie",
    it: "Vita",
    sq: "Jeta",
    tr: "Ömür boyu",
    sw: "Muda wa Maisha",
    am: "ዕድሜ",
    om: "Guyyaa jireenyaa",
    ig: "Ndụmọdụ",
    so: "Nolosha",
    ha: "Rayuwa",
    mn: "Амьдралын хугацаа",
    ff: "Rayuwa",
    yo: "Igbagbọ",
  },
  "Last online": {
    en: "Last online",
    pt: "Último online",
    de: "Zuletzt online",
    es: "Último en línea",
    fr: "Dernière connexion",
    it: "Ultimo accesso",
    sq: "Online i fundit",
    tr: "Son çevrimiçi",
    sw: "Mtandaoni wa Mwisho",
    am: "የተገናኝ መጨረሻ",
    om: "Online dhumaa",
    ig: "N'ịntanetị ikpeazụ",
    so: "Ugu danbeeyay onleen",
    ha: "Bayanin ƙarshe kan layi",
    mn: "Сүүлийн онлайн",
    ff: "Bayanin ƙarshe kan layi",
    yo: "Àkókò tó kẹ̀yìn tó wà lórí ayélujára",
  },
  "Live TV Categories": {
    en: "Live TV Categories",
    pt: "Categorias de TV ao Vivo",
    de: "Live-TV-Kategorien",
    es: "Categorías de TV en vivo",
    fr: "Catégories de télévision en direct",
    it: "Categorie TV in diretta",
    sq: "Kategoritë e TV Live",
    tr: "Canlı TV Kategorileri",
    sw: "Kategoria za Televisheni ya Moja kwa Moja",
    am: "የቀጣይ ቴሌቪዥን ምድቦች",
    om: "Qabiyyee Televizhinii Dhiha",
    ig: "Ụdị Televishọn Na-adị Ndụ",
    so: "Qaybaha Telefishinka Tooska ah",
    ha: "Rukuni na talabijin kai tsaye",
    mn: "Амьд телевизийн ангилал",
    ff: "Rukuni na talabijin kai tsaye",
    yo: "Ibi Àwọn Tẹlifíṣàn Laye",
  },
  //n
  "Not available platform": {
    en: "Not available platform",
    pt: "Plataforma não disponível",
    de: "Plattform nicht verfügbar",
    es: "Plataforma no disponible",
    fr: "Plateforme non disponible",
    it: "Piattaforma non disponibile",
    sq: "Platformë e padisponueshme",
    tr: "Mevcut olmayan platform",
    sw: "Jukwaa halipo",
    am: "የሚገኝ መድረክ",
    om: "Piraatofii hin argamne",
    ig: "Platform adịghị",
    so: "Madasha ma jiraan",
    ha: "Babu dandali",
    mn: "Байхгүй платформ",
    ff: "Babu dandali",
    yo: "Pẹpẹ tí kò sí",
  },
  "No devices found on server": {
    en: "No devices found on server",
    pt: "Nenhum dispositivo encontrado no servidor",
    de: "Keine Geräte auf dem Server gefunden",
    es: "No se encontraron dispositivos en el servidor",
    fr: "Aucun appareil trouvé sur le serveur",
    it: "Nessun dispositivo trovato sul server",
    sq: "Nuk u gjetën pajisje në server",
    tr: "Sunucuda hiç cihaz bulunamadı",
    sw: "Hakuna vifaa vilivyopatikana kwenye seva",
    am: "በአገልግሎት ውስጥ ምንም መነሻ አልተገኝም",
    om: "Seerivara irratti meeshaalee hin argamne",
    ig: "Enweghị ngwaọrụ ndị a chọpụtara na sava",
    so: "Ma jiro qalab laga helay serverka",
    ha: "Babu na'urori da aka samu a kan sabar",
    mn: "Серверт олдсон төхөөрөмж байхгүй",
    ff: "Babu na'urori da aka samu a kan sabar",
    yo: "Ko awọn ẹrọ kankan ti a rii lori olupin",
  },

  No: {
    en: "No",
    pt: "Não",
    de: "Nein",
    es: "No",
    fr: "Non",
    it: "No",
    sq: "Jo",
    tr: "Hayır",
    sw: "Hapana",
    am: "አይ",
    om: "Lakki",
    ig: "Mba",
    so: "Maya",
    ha: "A'a",
    mn: "Үгүй",
    ff: "A'a",
    yo: "Rara",
  },
  "N/A": {
    en: "N/A",
    pt: "N/D",
    de: "N/A",
    es: "N/D",
    fr: "N/D",
    it: "N/D",
    sq: "N/A",
    tr: "Yok",
    sw: "S/H",
    am: "የለውም",
    om: "Hin jiru",
    ig: "N/A",
    so: "Ma jirto",
    ha: "N/A",
    mn: "N/A",
    ff: "N/A",
    yo: "N/A",
  },
  Name: {
    en: "Name",
    pt: "Nome",
    de: "Name",
    es: "Nombre",
    fr: "Nom",
    it: "Nome",
    sq: "Emër",
    tr: "İsim",
    sw: "Jina",
    am: "ስም",
    om: "Maqaa",
    ig: "Aha",
    so: "Magac",
    ha: "Suna",
    mn: "Нэр",
    ff: "Suna",
    yo: "Orukọ",
  },
  Next: {
    en: "Next",
    pt: "Próximo",
    de: "Nächster",
    es: "Siguiente",
    fr: "Suivant",
    it: "Successivo",
    sq: "Tjetra",
    tr: "Sonraki",
    sw: "Ifuatayo",
    am: "ቀጣይ",
    om: "Itti aanuu",
    ig: "Nnext",
    so: "Xiga",
    ha: "Na gaba",
    mn: "Дараа",
    ff: "Na gaba",
    yo: "Tẹ̀síwájú",
  },
  "New password": {
    en: "New password",
    pt: "Nova senha",
    de: "Neues Passwort",
    es: "Nueva contraseña",
    fr: "Nouveau mot de passe",
    it: "Nuova password",
    sq: "Fjalëkalim i ri",
    tr: "Yeni şifre",
    sw: "Neno la siri jipya",
    am: "አዲስ የይለፍ ቃል",
    om: "Kallacha haaraa",
    ig: "Okwuntughe ọhụrụ",
    so: "Furaha cusub",
    ha: "Sabon kalmar wucewa",
    mn: "Шинэ нууц үг",
    ff: "Sabon kalmar wucewa",
    yo: "Ọrọigbani tuntun",
  },
  "Name on card": {
    en: "Name on card",
    pt: "Nome no cartão",
    de: "Name auf der Karte",
    es: "Nombre en la tarjeta",
    fr: "Nom sur la carte",
    it: "Nome sulla carta",
    sq: "Emri në kartë",
    tr: "Karttaki isim",
    sw: "Jina kwenye kadi",
    am: "በካርድ ላይ ስም",
    om: "Kaarra irratti maqaa",
    ig: "Aha n'ekarịrị",
    so: "Magaca kaarka",
    ha: "Sunan akan kati",
    mn: "Карт дээрх нэр",
    ff: "Sunan akan kati",
    yo: "Orukọ lori kaadi",
  },
  Notes: {
    en: "Notes",
    pt: "Notas",
    de: "Notizen",
    es: "Notas",
    fr: "Notes",
    it: "Note",
    sq: "Shënime",
    tr: "Notlar",
    sw: "Maelezo",
    am: "ዝርዝር",
    om: "Yaada",
    ig: "Nkwupụta",
    so: "Qoraallada",
    ha: "Lura",
    mn: "Тэмдэглэл",
    ff: "Lura",
    yo: "Àkótán",
  },
  Notifications: {
    en: "Notifications",
    pt: "Notificações",
    de: "Benachrichtigungen",
    es: "Notificaciones",
    fr: "Notifications",
    it: "Notifiche",
    sq: "Njoftimet",
    tr: "Bildirimler",
    sw: "Taarifa",
    am: "ማስታወቂያ",
    om: "Beeksisa",
    ig: "Nkwupụta",
    so: "Oge warbixin",
    ha: "Sako",
    mn: "Мэдэгдэл",
    ff: "Sako",
    yo: "Ìkìlọ́",
  },
  "New device configurations": {
    en: "New device configurations",
    pt: "Novas configurações de dispositivo",
    de: "Neue Gerätekonfigurationen",
    es: "Nuevas configuraciones de dispositivo",
    fr: "Nouvelles configurations de l'appareil",
    it: "Nuove configurazioni del dispositivo",
    sq: "Konfigurimet e reja të pajisjes",
    tr: "Yeni cihaz yapılandırmaları",
    sw: "Mikakati mipya ya kifaa",
    am: "አዲስ የመሣሪያ ምድቦች",
    om: "Haarawa qindaa'ina meeshaa",
    ig: "Nhazi ngwaọrụ ọhụrụ",
    so: "Qorsheyaal cusub oo qalab",
    ha: "Sabbin saitin na'ura",
    mn: "Шинэ төхөөрөмжийн тохиргоо",
    ff: "Sabbin saitin na'ura",
    yo: "Àtúnṣe tuntun ti ẹrọ",
  },
  "New device configuration guide": {
    en: "New device configuration guide",
    pt: "Novo guia de configuração de dispositivo",
    de: "Neue Gerätekonfigurationsanleitung",
    es: "Nueva guía de configuración de dispositivo",
    fr: "Nouveau guide de configuration de l'appareil",
    it: "Nuova guida alla configurazione del dispositivo",
    sq: "Udhëzuesi i konfigurimit të pajisjes së re",
    tr: "Yeni cihaz yapılandırma kılavuzu",
    sw: "Mwongozo wa mipangilio mipya ya kifaa",
    am: "አዲስ የመሣሪያ ምድብ መመሪያ",
    om: "Haarawa qindaa'ina meeshaa gidduu",
    ig: "Ntuziaka nhazi ngwaọrụ ọhụrụ",
    so: "Hagaha qorsheyaasha cusub ee qalabka",
    ha: "Jagoran saitin na'ura sabbi",
    mn: "Шинэ төхөөрөмжийн тохиргооны гарын авлага",
    ff: "Jagoran saitin na'ura sabbi",
    yo: "Ìtòlẹ́sẹẹsẹ tuntun ti ẹrọ",
  },
  //g
  "General Info": {
    en: "General Info",
    pt: "Informações Gerais",
    de: "Allgemeine Informationen",
    es: "Información General",
    fr: "Informations Générales",
    it: "Informazioni Generali",
    sq: "Informacione të përgjithshme",
    tr: "Genel Bilgi",
    sw: "Taarifa Kuu",
    am: "አጠቃላይ መረጃ",
    om: "Odeeffannoo Waliigalaa",
    ig: "Ozi Nkọwa",
    so: "Macluumaad Guud",
    ha: "Bayanin Gabaɗaya",
    mn: "Ерөнхий мэдээлэл",
    ff: "Bayanin Gabaɗaya",
    yo: "Alaye Gbogbogbo",
  },
  "Generic Config": {
    en: "Generic Config",
    pt: "Configuração Genérica",
    de: "Generische Konfiguration",
    es: "Configuración Genérica",
    fr: "Configuration Générique",
    it: "Configurazione Generica",
    sq: "Konfigurim Gjenrik",
    tr: "Genel Yapılandırma",
    sw: "Mikakati ya Kawaida",
    am: "አጠቃላይ ቅንብር",
    om: "Qindaa'ina Gabaabaa",
    ig: "Nhazi Nkọwa",
    so: "Qorshe Guud",
    ha: "Tsarin Gabaɗaya",
    mn: "Ерөнхий тохиргоо",
    ff: "Tsarin Gabaɗaya",
    yo: "Ìtòlẹ́sẹẹsẹ Gbogbogbo",
  },
  "Get code": {
    en: "Get code",
    pt: "Obter código",
    de: "Code erhalten",
    es: "Obtener código",
    fr: "Obtenir le code",
    it: "Ottieni codice",
    sq: "Merr kodin",
    tr: "Kodu Al",
    sw: "Pata msimbo",
    am: "ኮድ ይውሰዱ",
    om: "Koodii argachuuf",
    ig: "Nweta koodu",
    so: "Hel koodh",
    ha: "Samun lamba",
    mn: "Код авах",
    ff: "Samun lamba",
    yo: "Gba koodu",
  },
  "Get credits": {
    en: "Get credits",
    pt: "Obter créditos",
    de: "Credits erhalten",
    es: "Obtener créditos",
    fr: "Obtenir des crédits",
    it: "Ottieni crediti",
    sq: "Merr kredite",
    tr: "Kredi Al",
    sw: "Pata mikopo",
    am: "ክፍያ ይውሰዱ",
    om: "Kireedii argachuu",
    ig: "Nweta krediti",
    so: "Hel credit",
    ha: "Samun ƙididdiga",
    mn: "Кредит авах",
    ff: "Samun ƙididdiga",
    yo: "Gba àfikún",
  },
  Genre: {
    en: "Genre",
    pt: "Gênero",
    de: "Genre",
    es: "Género",
    fr: "Genre",
    it: "Genere",
    sq: "Janër",
    tr: "Tür",
    sw: "Aina",
    am: "ዓይነት",
    om: "Aadaa",
    ig: "Ụdị",
    so: "Nooca",
    ha: "Nau'i",
    mn: "Жанр",
    ff: "Nau'i",
    yo: "Iru",
  },
  "Generate playlist": {
    en: "Generate playlist",
    pt: "Gerar lista de reprodução",
    de: "Playlist generieren",
    es: "Generar lista de reproducción",
    fr: "Générer une playlist",
    it: "Genera playlist",
    sq: "Krijo listën e për të dëgjuar",
    tr: "Çalma listesi oluştur",
    sw: "Unda orodha ya nyimbo",
    am: "የዝማሬ ዝርዝር ይፈጥሩ",
    om: "Playlists uumuu",
    ig: "Mepụta ndepụta egwu",
    so: "Abuur liiska heesaha",
    ha: "Haƙƙin zane playlist",
    mn: "Плейлист үүсгэх",
    ff: "Haƙƙin zane playlist",
    yo: "Ṣẹda akojọ orin",
  },

  //s
  "Server ID": {
    en: "Server ID",
    pt: "ID do Servidor",
    de: "Server-ID",
    es: "ID del Servidor",
    fr: "ID du Serveur",
    it: "ID del Server",
    sq: "ID e Serverit",
    tr: "Sunucu Kimliği",
    sw: "Kitambulisho cha Seva",
    am: "የአገልግሎት መለያ",
    om: "ID Seerivara",
    ig: "ID sava",
    so: "Aqoonsiga server",
    ha: "ID na'ura",
    mn: "Серверийн ID",
    ff: "ID na'ura",
    yo: "ID olupin",
  },
  "Start date": {
    en: "Start date",
    es: "Fecha de inicio",
    fr: "Date de début",
    de: "Startdatum",
    pt: "Data de início",
    it: "Data di inizio",
    sq: "Data e fillimit",
    tr: "Başlangıç tarihi",
    sw: "Tarehe ya Mwanzo",
    am: "የመጀመሪያ ቀን",
    om: "Guyyaa jalqaba",
    ig: "Ụbọchị mmalite",
    so: "Taariikhda Bilowga",
    ha: "Ranar Fara",
    mn: "Эхлэх огноо",
    ff: "Ranar Fara",
    yo: "Ọjọ́ ibẹrẹ",
  },
  success: {
    en: "Success",
    pt: "Sucesso",
    de: "Erfolg",
    es: "Éxito",
    fr: "Succès",
    it: "Successo",
    sq: "Sukses",
    tr: "Başarı",
    sw: "Ufanisi",
    am: "አስተዳደር",
    om: "Milkaa'ina",
    ig: "Success",
    so: "Guul",
    ha: "Nasara",
    mn: "Амжилт",
    ff: "Nasara",
    yo: "Aseyori",
  },
  "Search by device key": {
    en: "Search by device key",
    pt: "Pesquisar por chave do dispositivo",
    de: "Suche nach Geräteschlüssel",
    es: "Buscar por clave de dispositivo",
    fr: "Recherche par clé de dispositif",
    it: "Ricerca per chiave del dispositivo",
    sq: "Kërkoni sipas çelësit të pajisjes",
    tr: "Cihaz anahtarı ile ara",
    sw: "Tafuta kwa funguo la kifaa",
    am: "በመሣሪያ ቁልፍ ይፈልጉ",
    om: "Meeshaan maqaalee qabu",
    ig: "Chọọ site na igodo ngwaọrụ",
    so: "Raadi adigga qalabka",
    ha: "Bincika ta hanyar maɓallin na'ura",
    mn: "Төхөөрөмжийн түлхүүрээр хайх",
    ff: "Bincika ta hanyar maɓallin na'ura",
    yo: "Wàáyé pẹ̀lú bọtìn ẹrọ",
  },
  State: {
    en: "State",
    pt: "Estado",
    de: "Zustand",
    es: "Estado",
    fr: "État",
    it: "Stato",
    sq: "Gjendja",
    tr: "Durum",
    sw: "Hali",
    am: "ሁኔታ",
    om: "Haala",
    ig: "Steeti",
    so: "Xaalad",
    ha: "Jihar",
    mn: "Төрөл",
    ff: "Jihar",
    yo: "Ipin",
  },
  "Stream ID": {
    en: "Stream ID",
    pt: "ID do Fluxo",
    de: "Stream-ID",
    es: "ID de Transmisión",
    fr: "Identifiant de Flux",
    it: "ID del Flusso",
    sq: "ID e Strujës",
    tr: "Akış Kimliği",
    sw: "Kitambulisho cha Mwamvuli",
    am: "የዕለት መለያ",
    om: "ID Mawaala",
    ig: "ID nkwụsịrị",
    so: "Aqoonsiga qulqulka",
    ha: "ID ruwan teku",
    mn: "Стримийн ID",
    ff: "ID ruwan teku",
    yo: "ID ṣiṣan",
  },
  "Series ID": {
    en: "Series ID",
    pt: "ID da Série",
    de: "Seriennummer",
    es: "ID de la Serie",
    fr: "Identifiant de Série",
    it: "ID Serie",
    sq: "ID e Serisë",
    tr: "Dizi Kimliği",
    sw: "Kitambulisho cha Mfululizo",
    am: "የተረቂቅ መለያ",
    om: "ID Seerii",
    ig: "ID usoro",
    so: "Aqoonsiga taxanaha",
    ha: "ID jerin",
    mn: "Серийн ID",
    ff: "ID jerin",
    yo: "ID jara",
  },
  Season: {
    en: "Season",
    pt: "Temporada",
    de: "Saison",
    es: "Temporada",
    fr: "Saison",
    it: "Stagione",
    sq: "Stinë",
    tr: "Mevsim",
    sw: "Msimu",
    am: "ወቅት",
    om: "Saqa",
    ig: "Oge",
    so: "Xilli",
    ha: "Lokaci",
    mn: "Улирал",
    ff: "Lokaci",
    yo: "Igbé",
  },
  "server to another": {
    en: "server to another",
    pt: "servidor para outro",
    de: "Server zu einem anderen",
    es: "servidor a otro",
    fr: "serveur vers un autre",
    it: "server verso un altro",
    sq: "server për në tjetër",
    tr: "sunucu bir diğerine",
    sw: "seva hadi nyingine",
    am: "ከአገልግሎት ወደ ሌላ",
    om: "Seerivara irraa seerivara biraa",
    ig: "sava na sava ọzọ",
    so: "server ilaa mid kale",
    ha: "sabar zuwa wata",
    mn: "Серверээс өөр серверт",
    ff: "sabar zuwa wata",
    yo: "olupin si omiran",
  },
  Send: {
    en: "Send",
    pt: "Enviar",
    de: "Senden",
    es: "Enviar",
    fr: "Envoyer",
    it: "Invia",
    sq: "Dërgo",
    tr: "Gönder",
    sw: "Tuma",
    am: "ላክ",
    om: "Ergaa",
    ig: "Zipu",
    so: "Dir",
    ha: "Aika",
    mn: "Илгээх",
    ff: "Aika",
    yo: "Rán",
  },
  Seen: {
    en: "Seen",
    pt: "Visto",
    de: "Gesehen",
    es: "Visto",
    fr: "Vu",
    it: "Visto",
    sq: "Shikuar",
    tr: "Görüldü",
    sw: "Tazama",
    am: "ተመለከተ",
    om: "Argame",
    ig: "Hụrụ",
    so: "Arag",
    ha: "Gani",
    mn: "Үзсэн",
    ff: "Gani",
    yo: "Rí",
  },
  Sync: {
    en: "Sync",
    pt: "Sincronizar",
    de: "Synchronisieren",
    es: "Sincronizar",
    fr: "Synchroniser",
    it: "Sincronizza",
    sq: "Sinkronizo",
    tr: "Senkranize Et",
    sw: "Sanidha",
    am: "ማዋቀር",
    om: "Walitti fidu",
    ig: "Nkwughari",
    so: "Isku-xidh",
    ha: "Saka",
    mn: "Нэгдсэн",
    ff: "Saka",
    yo: "Dá",
  },
  "Sync playlist": {
    en: "Sync playlist",
    pt: "Sincronizar lista de reprodução",
    de: "Wiedergabeliste synchronisieren",
    es: "Sincronizar lista de reproducción",
    fr: "Synchroniser la playlist",
    it: "Sincronizza playlist",
    sq: "Sinkronizo listën e përhapjes",
    tr: "Senkranize Et liste",
    sw: "Sanidha orodha ya nyimbo",
    am: "የዝማሬ ዝርዝርን ወይም አዋቀር",
    om: "Playlists walitti fidu",
    ig: "Nkwughari ndepụta egwu",
    so: "Isku-xidh liiska heesaha",
    ha: "Saka jerin kiɗa",
    mn: "Плейлистийг синк хийх",
    ff: "Saka jerin kiɗa",
    yo: "Dá akojọ orin",
  },
  Save: {
    en: "Save",
    pt: "Salvar",
    de: "Speichern",
    es: "Guardar",
    fr: "Enregistrer",
    it: "Salva",
    sq: "Ruaj",
    tr: "Kaydet",
    sw: "Hifadhi",
    am: "አስቀምጥ",
    om: "Eeggachu",
    ig: "Zọpụta",
    so: "Kaydso",
    ha: "Ajiye",
    mn: "Хадгалах",
    ff: "Ajiye",
    yo: "Fipamọ",
  },
  Start: {
    en: "Start",
    pt: "Iniciar",
    de: "Starten",
    es: "Iniciar",
    fr: "Démarrer",
    it: "Avvia",
    sq: "Fillo",
    tr: "Başla",
    sw: "Anza",
    am: "መጀመሪያ",
    om: "Jalqaba",
    ig: "Malite",
    so: "Bilow",
    ha: "Fara",
    mn: "Эхлэх",
    ff: "Fara",
    yo: "Bẹrẹ",
  },
  Search: {
    en: "Search",
    pt: "Pesquisar",
    de: "Suchen",
    es: "Buscar",
    fr: "Rechercher",
    it: "Cerca",
    sq: "Kërko",
    tr: "Ara",
    sw: "Tafuta",
    am: "ፈልግ",
    om: "Barbaadaa",
    ig: "Chọọ",
    so: "Raadi",
    ha: "Bincika",
    mn: "Хайх",
    ff: "Bincika",
    yo: "Wá",
  },
  Status: {
    en: "Status",
    pt: "Status",
    de: "Status",
    es: "Estado",
    fr: "Statut",
    it: "Stato",
    sq: "Statusi",
    tr: "Durum",
    sw: "Hali",
    am: "ሁኔታ",
    om: "Haala",
    ig: "Ọnọdụ",
    so: "Xaalad",
    ha: "Matsayi",
    mn: "Төрөл",
    ff: "Matsayi",
    yo: "Ipo",
  },
  Schedule: {
    en: "Schedule",
    es: "Horario",
    fr: "Horaire",
    de: "Zeitplan",
    pt: "Agenda",
    it: "Programma",
    sq: "Orari",
    tr: "Program",
    sw: "Ratiba",
    am: "እቅድ",
    om: "Tajaajila",
    ig: "Ntọala",
    so: "Jadwal",
    ha: "Tsari",
    mn: "Хуваарь",
    ff: "Tsari",
    yo: "Tẹ́lẹ̀",
  },
  Select: {
    en: "Select",
    pt: "Selecionar",
    de: "Auswählen",
    es: "Seleccionar",
    fr: "Sélectionner",
    it: "Seleziona",
    sq: "Zgjidh",
    tr: "Seç",
    sw: "Chagua",
    am: "ይምረጡ",
    om: "Filadhu",
    ig: "Họrọ",
    so: "Xulo",
    ha: "Zabi",
    mn: "Сонгох",
    ff: "Zabi",
    yo: "Yan",
  },
  Series: {
    en: "Series",
    pt: "Séries",
    de: "Serien",
    es: "Series",
    fr: "Séries",
    it: "Serie",
    sq: "Seria",
    tr: "Seriler",
    sw: "Mfululizo",
    am: "ተረቂቅ",
    om: "Seerii",
    ig: "Usoro",
    so: "Taxane",
    ha: "Jerin",
    mn: "Серий",
    ff: "Jerin",
    yo: "Jara",
  },
  Surname: {
    en: "Surname",
    pt: "Sobrenome",
    de: "Nachname",
    es: "Apellido",
    fr: "Nom de famille",
    it: "Cognome",
    sq: "Mbiemri",
    tr: "Soyadı",
    sw: "Jina la Nyuma",
    am: "የአይነት ስም",
    om: "Maqaa Duraa",
    ig: "Aha",
    so: "Magaca Qoyska",
    ha: "Sunan",
    mn: "Овог",
    ff: "Sunan",
    yo: "Orukọ ìdílé",
  },
  Settings: {
    en: "Settings",
    pt: "Configurações",
    de: "Einstellungen",
    es: "Ajustes",
    fr: "Paramètres",
    it: "Impostazioni",
    sq: "Parametrat",
    tr: "Ayarlar",
    sw: "Mipangilio",
    am: "ቅንብሮች",
    om: "Qindaa'inoota",
    ig: "Ntọala",
    so: "Dejinta",
    ha: "Saitin",
    mn: "Тохиргоо",
    ff: "Saitin",
    yo: "Àtúnṣe",
  },
  Seller: {
    en: "Seller",
    pt: "Vendedor",
    de: "Verkäufer",
    es: "Vendedor",
    fr: "Vendeur",
    it: "Venditore",
    sq: "Shitësi",
    tr: "Satıcı",
    sw: "Muuzaji",
    am: "ሻጭ",
    om: "Bittaa",
    ig: "Ọkụ",
    so: "Iibiyaha",
    ha: "Mai siyar",
    mn: "Зарагч",
    ff: "Mai siyar",
    yo: "Olupese",
  },
  Success: {
    en: "Success",
    pt: "Sucesso",
    de: "Erfolg",
    es: "Éxito",
    fr: "Succès",
    it: "Successo",
    sq: "Sukses",
    tr: "Başarı",
    sw: "Ufanisi",
    am: "አስተዳደር",
    om: "Milkaa'ina",
    ig: "Success",
    so: "Guul",
    ha: "Nasara",
    mn: "Амжилт",
    ff: "Nasara",
    yo: "Aseyori",
  },
  Signup: {
    en: "Signup",
    pt: "Cadastro",
    de: "Anmeldung",
    es: "Registro",
    fr: "Inscription",
    it: "Registrazione",
    sq: "Regjistrohu",
    tr: "Kayıt Ol",
    sw: "Jisajili",
    am: "ይመዝገቡ",
    om: "Galma'i",
    ig: "Debanye aha",
    so: "Isqor",
    ha: "Rajista",
    mn: "Бүртгүүлэх",
    ff: "Rajista",
    yo: "Forukọsilẹ",
  },
  "Send date": {
    en: "Send date",
    pt: "Data de envio",
    de: "Sendedatum",
    es: "Fecha de envío",
    fr: "Date d'envoi",
    it: "Data di invio",
    sq: "Data e dërgimit",
    tr: "Gönderim tarihi",
    sw: "Tuma tarehe",
    am: "ቀን ይላኩ",
    om: "Guyyaa ergaa",
    ig: "Zipu ụbọchị",
    so: "Dir taariikhda",
    ha: "Aika ranar",
    mn: "Огноо илгээх",
    ff: "Aika ranar",
    yo: "Rán ọjọ́",
  },
  Statistics: {
    en: "Statistics",
    pt: "Estatísticas",
    de: "Statistiken",
    es: "Estadísticas",
    fr: "Statistiques",
    it: "Statistiche",
    sq: "Statistikat",
    tr: "İstatistikler",
    sw: "Takwimu",
    am: "ስታትስቲክስ",
    om: "Statistiksii",
    ig: "Statistiks",
    so: "Tirako",
    ha: "Kididdiga",
    mn: "Статистик",
    ff: "Kididdiga",
    yo: "Ìtòlẹ́sẹẹsẹ",
  },
  "Select DNS": {
    en: "Select DNS",
    pt: "Selecionar DNS",
    de: "DNS auswählen",
    es: "Seleccionar DNS",
    fr: "Sélectionner DNS",
    it: "Seleziona DNS",
    sq: "Zgjidh DNS",
    tr: "DNS Seç",
    sw: "Chagua DNS",
    am: "ዲኤንኤስ ይምረጡ",
    om: "DNS filadhu",
    ig: "Họrọ DNS",
    so: "Xulo DNS",
    ha: "Zabi DNS",
    mn: "DNS сонгох",
    ff: "Zabi DNS",
    yo: "Yan DNS",
  },
  Support: {
    en: "Support",
    pt: "Suporte",
    de: "Unterstützung",
    es: "Soporte",
    fr: "Support",
    it: "Supporto",
    sq: "Mbështetje",
    tr: "Destek",
    sw: "Msaada",
    am: "ድጋፍ",
    om: "Deeggarsa",
    ig: "Nkwado",
    so: "Taageero",
    ha: "Taimako",
    mn: "Дэмжлэг",
    ff: "Taimako",
    yo: "Atilẹyin",
  },
  Subresellers: {
    en: "Subresellers",
    pt: "Subrevendedores",
    de: "Unterhändler",
    es: "Subrevendedores",
    fr: "Sous-revendeurs",
    it: "Subrivenditori",
    sq: "Nën-rihumbësuesit",
    tr: "Altbayiler",
    sw: "Wauzaji Wadogo",
    am: "አንድ መሸጫ",
    om: "Bittaa xiqqaa",
    ig: "Ndị na-ere ahịa",
    so: "Iibiyeyaasha Hoose",
    ha: "Kanakuna",
    mn: "Дэд зарагчид",
    ff: "Kanakuna",
    yo: "Olupese kekere",
  },
  "Select platform": {
    en: "Select platform",
    pt: "Selecionar plataforma",
    de: "Plattform auswählen",
    es: "Seleccionar plataforma",
    fr: "Sélectionner la plateforme",
    it: "Seleziona piattaforma",
    sq: "Zgjidh platformën",
    tr: "Platform Seç",
    sw: "Chagua jukwaa",
    am: "መድረክ ይምረጡ",
    om: "Platfoma filadhu",
    ig: "Họrọ nyiwe",
    so: "Xulo madal",
    ha: "Zabi dandamali",
    mn: "Платформыг сонгох",
    ff: "Zabi dandamali",
    yo: "Yan pẹpẹ",
  },
  "Select device by": {
    en: "Select device by:",
    pt: "Selecionar dispositivo por:",
    de: "Gerät auswählen nach:",
    es: "Seleccionar dispositivo por:",
    fr: "Sélectionner l'appareil par :",
    it: "Seleziona dispositivo per:",
    sq: "Zgjidh pajisjen me:",
    tr: "Cihazı seç:",
    sw: "Chagua kifaa kwa",
    am: "ይምረጡ መሳሪያ በ",
    om: "Qabeenya kan filadhu",
    ig: "Họrọ ngwaọrụ site na",
    so: "Xulo qalab marka",
    ha: "Zabi na'ura ta",
    mn: "Төхөөрөмжийг сонгох",
    ff: "Zabi na'ura ta",
    yo: "Yan ẹrọ nipasẹ",
  },
  "Secondary color": {
    en: "Secondary color",
    pt: "Cor secundária",
    de: "Sekundärfarbe",
    es: "Color secundario",
    fr: "Couleur secondaire",
    it: "Colore secondario",
    sq: "Ngjyra e dytësore",
    tr: "İkincil renk",
    sw: "Rangi ya Pili",
    am: "የሁለተኛ ቀለም",
    om: "Bara haaraa",
    ig: "Agbụrụ nke abụọ",
    so: "Midabka Labaad",
    ha: "Launin biyu",
    mn: "Хоёрдогч өнгө",
    ff: "Launin biyu",
    yo: "Àwọ̀ kejì",
  },
  "Sub-Reseller Info": {
    en: "Sub-Reseller Info",
    pt: "Informações de Sub-Revendedor",
    de: "Sub-Händler Informationen",
    es: "Información de Sub-Revendedor",
    fr: "Informations du sous-revendeur",
    it: "Informazioni del Sub-Rivenditore",
    sq: "Informacione për Nën-rihumbësuesin",
    tr: "Alt-Bayi Bilgisi",
    sw: "Taarifa za Muuzaji Mdogo",
    am: "የበለጠ መሸጫ መረጃ",
    om: "Odeeffannoo Bittaa xiqqaa",
    ig: "Ozi ndị na-ere ahịa",
    so: "Macluumaadka Iibiyaha Hoose",
    ha: "Bayanan Kanakuna",
    mn: "Дэд зарагчийн мэдээлэл",
    ff: "Bayanan Kanakuna",
    yo: "Alaye Olupese Kekere",
  },
  "Send notifications": {
    en: "Send notifications",
    pt: "Enviar notificações",
    de: "Benachrichtigungen senden",
    es: "Enviar notificaciones",
    fr: "Envoyer des notifications",
    it: "Invia notifiche",
    sq: "Dërgo njoftime",
    tr: "Bildirim gönder",
    sw: "Tuma arifa",
    am: "ማስታወቂያዎችን ይላኩ",
    om: "Ergaa beeksisota",
    ig: "Zipu nkwupụta",
    so: "Dir ogeeyay",
    ha: "Aika sanarwa",
    mn: "Мэдэгдэл илгээх",
    ff: "Aika sanarwa",
    yo: "Rán ìkìlọ̀",
  },
  "Select credit count": {
    en: "Select credit count",
    pt: "Selecionar contagem de crédito",
    de: "Kreditanzahl auswählen",
    es: "Seleccionar cantidad de crédito",
    fr: "Sélectionner le nombre de crédits",
    it: "Seleziona il conteggio dei crediti",
    sq: "Zgjidh numrin e kredive",
    tr: "Kredi sayısını seç",
    sw: "Chagua idadi ya mkopo",
    am: "የክሬዲት ብዛት ይምረጡ",
    om: "Lakkoofsa karra filadhu",
    ig: "Họrọ ọnụ ọgụgụ ụgwọ",
    so: "Xulo tirada deynta",
    ha: "Zabi adadin kirediti",
    mn: "Кредитийн тоог сонгох",
    ff: "Zabi adadin kirediti",
    yo: "Yan iye kirẹditi",
  },
  "Something went wrong": {
    en: "Something went wrong.",
    pt: "Algo deu errado.",
    de: "Etwas ist schiefgelaufen.",
    es: "Algo salió mal.",
    fr: "Quelque chose s'est mal passé.",
    it: "Qualcosa è andato storto.",
    sq: "Diçka shkoi gabim.",
    tr: "Bir şeyler yanlış gitti.",
    sw: "Kuna kitu kibaya kilichotokea",
    am: "አንድ ነገር ተሳክቷል",
    om: "Wanti dogongorameera",
    ig: "Ihe na-adịghị mma",
    so: "Wax walba ayaa qaldamay",
    ha: "Wani abu ya yi kuskure",
    mn: "Алдаа гарлаа",
    ff: "Wani abu ya yi kuskure",
    yo: "Ohun kan ṣẹlẹ̀",
  },
  "Start migration process": {
    en: "Start migration process",
    pt: "Iniciar processo de migração",
    de: "Starte den Migrationsprozess",
    es: "Iniciar proceso de migración",
    fr: "Démarrer le processus de migration",
    it: "Avvia il processo di migrazione",
    sq: "Fillo procesin e migrimit",
    tr: "Geçiş sürecini başlat",
    sw: "Anza mchakato wa uhamishaji",
    am: "የማሻን ሂደት ይጀምሩ",
    om: "Dhaabbata uggummaa jalqabu",
    ig: "Malite usoro nnyefe",
    so: "Bilow habka guuritaanka",
    ha: "Fara tsarin hijira",
    mn: "Шилжүүлэлтийн процессыг эхлүүлэх",
    ff: "Fara tsarin hijira",
    yo: "Bẹrẹ ilana gbigbe",
  },
  "Select activation type": {
    en: "Select activation type",
    pt: "Selecionar tipo de ativação",
    de: "Aktivierungstyp auswählen",
    es: "Seleccionar tipo de activación",
    fr: "Sélectionner le type d'activation",
    it: "Seleziona il tipo di attivazione",
    sq: "Zgjidh llojin e aktivizimit",
    tr: "Aktivasyon türünü seç",
    sw: "Chagua aina ya uanzishaji",
    am: "የእንቅስቃሴ ዓይነት ይምረጡ",
    om: "Akkasumaa filadhu",
    ig: "Họrọ ụdị mm activation",
    so: "Xulo nooca firfircoonida",
    ha: "Zabi nau'in kunna",
    mn: "Идэвхжүүлэх төрөл сонгох",
    ff: "Zabi nau'in kunna",
    yo: "Yan iru ṣiṣẹ́",
  },
  "Select the type of your server": {
    en: "Select the type of your server:",
    pt: "Selecione o tipo do seu servidor:",
    de: "Wählen Sie den Servertyp aus:",
    es: "Seleccione el tipo de su servidor:",
    fr: "Sélectionnez le type de votre serveur :",
    it: "Seleziona il tipo del tuo server:",
    sq: "Zgjidhni llojin e serverit tuaj:",
    tr: "Sunucunuzun türünü seçin:",
    sw: "Chagua aina ya seva yako",
    am: "የአገልግሎት ዓይነት ይምረጡ",
    om: "Waa'ee seerfarii keetii filadhu",
    ig: "Họrọ ụdị sava gị",
    so: "Xulo nooca server-kaaga",
    ha: "Zabi nau'in sabar ku",
    mn: "Серверийн төрлийг сонгох",
    ff: "Zabi nau'in sabar ku",
    yo: "Yan iru olupin rẹ",
  },
  "Sorry, the page you visited does not exist": {
    en: "Sorry, the page you visited does not exist.",
    pt: "Desculpe, a página que você visitou não existe.",
    de: "Entschuldigung, die von Ihnen besuchte Seite existiert nicht.",
    es: "Lo siento, la página que visitó no existe.",
    fr: "Désolé, la page que vous avez visitée n'existe pas.",
    it: "Spiacente, la pagina che hai visitato non esiste.",
    sq: "Ndjesi, faqja që vizituat nuk ekziston.",
    tr: "Üzgünüm, ziyaret ettiğiniz sayfa mevcut değil.",
    sw: "Samahani, ukurasa ulioitembelea haupo",
    am: "እቅፍ የሚገኝ ገፅ አልተገኘም",
    om: "Dhiiga kadhimni mitii hinjiru",
    ig: "Ndo, ibe ị gara adịghị",
    so: "Waan ka xumadey ma jiro bogga aad booqatay",
    ha: "Yi hakuri, shafin da kuka ziyarci ba ya wanzu",
    mn: "Уучлаарай, та зочилсон хуудас байхгүй",
    ff: "Yi hakuri, shafin da kuka ziyarci ba ya wanzu",
    yo: "E ma binu, oju-iwe ti o ṣabẹwo ko si",
  },
  "Select and attach one from your server": {
    en: "Select and attach one from your DNS server hosts then write the username and password of the chosen host. Or select one of your Xtream Servers and generate a playlist which will be attached to your device. You can see the generated URL in Playlist URL input.",
    pt: "Selecione e anexe um dos hosts do seu servidor DNS e, em seguida, escreva o nome de usuário e a senha do host escolhido. Ou selecione um dos seus servidores Xtream e gere uma lista de reprodução que será anexada ao seu dispositivo. Você pode ver a URL gerada na entrada de URL da lista de reprodução.",
    de: "Wählen Sie einen Ihrer DNS-Serverhosts aus und schreiben Sie dann den Benutzernamen und das Passwort des ausgewählten Hosts. Oder wählen Sie einen Ihrer Xtream Server aus und generieren Sie eine Playlist, die an Ihr Gerät angehängt wird. Sie können die generierte URL im Eingabefeld für die Playlist-URL sehen.",
    es: "Seleccione y adjunte uno de los hosts de su servidor DNS, luego escriba el nombre de usuario y la contraseña del host elegido. O seleccione uno de sus servidores Xtream y genere una lista de reproducción que se adjuntará a su dispositivo. Puede ver la URL generada en el campo de entrada de la URL de la lista de reproducción.",
    fr: "Sélectionnez et attachez l'un de vos hôtes de serveur DNS, puis saisissez le nom d'utilisateur et le mot de passe de l'hôte choisi. Ou sélectionnez l'un de vos serveurs Xtream et générez une liste de lecture qui sera attachée à votre appareil. Vous pouvez voir l'URL générée dans le champ d'entrée de l'URL de la liste de lecture.",
    it: "Seleziona e allega uno dei server DNS del tuo server, quindi scrivi il nome utente e la password dell'host scelto. Oppure seleziona uno dei tuoi server Xtream e genera una playlist che verrà allegata al tuo dispositivo. Puoi vedere l'URL generato nell'input dell'URL della playlist.",
    sq: "Zgjidhni dhe bashkangjitni një nga hostet e serverit tuaj DNS pastaj shkruani emrin e përdoruesit dhe fjalëkalimin e hostit të zgjedhur. Ose zgjidhni një nga Serverët tuaj Xtream dhe gjeneroni një listë riprodhimi që do të bashkangjitet me pajisjen tuaj. Mund të shihni URL-në e gjeneruar në hyrjen e URL-së së listës së riprodhimit.",
    tr: "DNS sunucunuzun hostlarından birini seçin ve ekleyin, ardından seçilen hostun kullanıcı adını ve şifresini yazın. Ya da Xtream sunucularınızdan birini seçin ve cihazınıza eklenecek bir çalma listesi oluşturun. Oluşturulan URL'yi Çalma Listesi URL girişi kısmında görebilirsiniz.",
    sw: "Chagua na ung'anishe mmoja kutoka kwa wenyeji wa seva ya DNS yako kisha andika jina la mtumiaji na nenosiri la mwenyeji uliochaguliwa. Au chagua mmoja wa Seva zako za Xtream na tengeneza orodha ya nyimbo ambayo itaundwa kwenye kifaa chako. Unaweza kuona URL iliyoundwa katika kiingilio cha URL ya Orodha ya Nyimbo.",
    am: "ከዲኤንኤስ አገልግሎት የአማራጭዎ የተመረጡ አማራጮች አስቀምጡ እና የተመረጡትን የመስረት የተጠቃሚ እና የየየይታይ አለም ይወስዱ፡፡ ወይም ከእንቅስቃሴ ምርቶችዎ አንዱን ይምረጡ እና ወይም ወይም ወይም የተቀመጡ ሚንስትር አንድ ይቀባ፡፡ ይወስዱ እና አንዳንድ የሚናገር ሞባይል ይላሉ በአማርኛ፡፡",
    om: "Maqaa filadhu fiiluu galu, akkasumas maqaa fiiluu galu si’achu fi’ee barreeffamu dhaa yookaan, fe’i dhimmichaa fi’ee waraqaa makala jala muudhuu jirtu, fiiluu galu, barreeffamu dhaa wal daawwachaa buufadhaa.",
    ig: "Họrọ ma jikọta otu site na ndị hosts sava DNS gị, mgbe ahụ, dee aha njirimara na okwuntughe nke onye host a họọrọ. Ma họrọ otu n’ime sava Xtream gị ma mepụta ndepụta nke ga-ejikọta na ngwaọrụ gị. Ị nwere ike ịhụ URL nke e mepụtara na ntinye URL Ndepụta.",
    so: "Xulo oo ka xidhidhsan marti DNS server-kaaga ka dibna qor username iyo password ka heshin shabakada la doorato. Ama dooro mid kamid ah Xtream Servers-kaaga ka soo kordhiso playlist oo ku xidhan dookhaaga. Waxaad ku arki kartaa URL-ka la soo saaray meesha URL-ka playlist-ka.",
    ha: "Zabi kuma haɗa daga masaukin sabar DNS ɗinku sannan ku rubuta sunan mai amfani da kalmar wucewa ta masaukin da aka zaɓa. Ko kuma zabi ɗaya daga cikin sabobin Xtream ɗinku ka ƙirƙiri jerin waƙoƙi wanda za'a haɗa shi da na'urar ku. Zaku iya ganin URL ɗin da aka ƙirƙira a cikin shigarwar URL ɗin Playlist.",
    mn: "DNS серверийн хостуудаас нэгийг сонгоод хавсрах, дараа нь сонгосон хостын хэрэглэгчийн нэр, нууц үгээ бичнэ үү. Эсвэл Xtream серверүүдийн нэгийг сонгоод таны төхөөрөмжид хавсаргасан плейлист үүсгэнэ. Үүсгэсэн URL-г Плейлист URL оруулах хэсэгт харж болно.",
    ff: "Zabi kuma haɗa daga masaukin sabar DNS ɗinku sannan ku rubuta sunan mai amfani da kalmar wucewa ta masaukin da aka zaɓa. Ko kuma zabi ɗaya daga cikin sabobin Xtream ɗinku ka ƙirƙiri jerin waƙoƙi wanda za'a haɗa shi da na'urar ku. Zaku iya ganin URL ɗin da aka ƙirƙira a cikin shigarwar URL ɗin Playlist.",
    yo: "Yan ki o si so ọkan lati ọdọ awọn ayẹwo DNS rẹ, lẹhinna kọ orukọ olumulo ati ọrọ igbaniwọle ti ayẹwo ti a yan. Tabi yan ọkan ninu awọn olupin Xtream rẹ ki o ṣe agbekalẹ akojọ orin ti yoo so pọ pẹlu ẹrọ rẹ. O le wo URL ti a ṣẹda ninu ikanni URL Akojọ orin.",
  },

  "server migration info": {
    en: "In first you need to choose the server from where you want to transfer your users, then choose the server to where you want to migrate your users.",
    pt: "Primeiro, você precisa escolher o servidor de onde deseja transferir seus usuários e, em seguida, escolher o servidor para onde deseja migrar seus usuários.",
    de: "Zuerst müssen Sie den Server auswählen, von dem aus Sie Ihre Benutzer übertragen möchten, und dann den Server auswählen, auf den Sie Ihre Benutzer migrieren möchten.",
    es: "En primer lugar, debe elegir el servidor desde el que desea transferir a sus usuarios y luego elegir el servidor al que desea migrar a sus usuarios.",
    fr: "Tout d'abord, vous devez choisir le serveur à partir duquel vous souhaitez transférer vos utilisateurs, puis choisir le serveur vers lequel vous souhaitez migrer vos utilisateurs.",
    it: "Innanzitutto, devi scegliere il server da cui desideri trasferire i tuoi utenti e poi scegliere il server in cui desideri migrare i tuoi utenti.",
    sq: "Fillimisht duhet të zgjidhni serverin nga ku dëshironi të transferoni përdoruesit tuaj, pastaj zgjidhni serverin në të cilin dëshironi të migratoni përdoruesit tuaj.",
    tr: "Öncelikle, kullanıcılarınızı transfer etmek istediğiniz sunucuyu seçmeniz ve ardından kullanıcılarınızı taşımak istediğiniz sunucuyu seçmeniz gerekir.",
    sw: "Kwanza unahitaji kuchagua seva ambayo unataka kuhamasisha watumiaji wako, kisha chagua seva ambayo unataka kuhamasisha watumiaji wako.",
    am: "በመጀመሪያ እባኮትን ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ወይም ከዚህ ይምረጡ",
    om: "Jalqaba, meesha intuu raacuu akka hinargamneef filadhu, ergasii ammoo, meesha akka hinargamneef filadhu",
    ig: "Na mbụ, ịhọrọ sava ị na-achọ ịkwaga ndị ọrụ gị, mgbe ahụ họrọ sava ị chọrọ ịkwaga ndị ọrụ gị.",
    so: "Marka hore, waxaad u baahan tahay inaad doorato server-ka aad rabto inaad ka guurto isticmaaleyaashaaga, ka dibna dooro server-ka aad rabto inaad u guurto isticmaaleyaashaaga.",
    ha: "Da farko, kuna buƙatar zaɓar sabar daga inda kuke son canza masu amfani, sannan zaɓi sabar inda kuke son motsa masu amfani.",
    mn: "Эхний ээлжинд та хэрэглэгчдийг шилжүүлэх серверээ сонгох хэрэгтэй, дараа нь хэрэглэгчдийг шилжүүлэх серверээ сонгоно.",
    ff: "Da farko, kuna buƙatar zaɓar sabar daga inda kuke son canza masu amfani, sannan zaɓi sabar inda kuke son motsa masu amfani.",
    yo: "Ni akọkọ, o nilo lati yan olupin lati ibi ti o fẹ lati gbe awọn olumulo rẹ, lẹhinna yan olupin si ibi ti o fẹ lati gbe awọn olumulo rẹ.",
  },
  "Something went wrong, try again": {
    en: "Something went wrong, try again",
    pt: "Algo deu errado, tente novamente",
    de: "Etwas ist schiefgegangen, versuche es erneut",
    es: "Algo salió mal, inténtalo de nuevo",
    fr: "Quelque chose s'est mal passé, réessayez",
    it: "Qualcosa è andato storto, riprova",
    sq: "Diçka shkoi keq, provoni përsëri",
    tr: "Bir şeyler ters gitti, lütfen tekrar deneyin.",
    sw: "Kuna shida, jaribu tena",
    am: "አንድ ነገር ተደርጎ ነው፣ ድጋፍ እንደገና ይሞክሩ",
    om: "Wanti dogoggora ta'e jira, deebi'aa yaali",
    ig: "Ihe na-ezighị ezi, biko gbalịa ọzọ",
    so: "Wax walwalay, fadlan mar kale isku day",
    ha: "Wani abu ya faru, don Allah ku sake gwadawa",
    mn: "Алдаа гарлаа, дахин оролдоно уу",
    ff: "Wanti dogoggora ta'e jira, deebi'aa yaali",
    yo: "Nkan kan ko tọ, jọwọ tun gbiyanju",
  },
  "Select file to upload": {
    en: "Select file to upload",
    es: "Seleccionar archivo para subir",
    fr: "Sélectionnez le fichier à télécharger",
    de: "Datei zum Hochladen auswählen",
    pt: "Selecione o arquivo para carregar",
    it: "Seleziona il file da caricare",
    sq: "Zgjidhni skedarin për ta ngarkuar",
    tr: "Yüklemek için dosyayı seçin",
    sw: "Chagua faili ya kupakia",
    am: "ፋይል ለመጫን ይምረጡ",
    om: "Faayilii olkaafamuu filadhu",
    ig: "Họrọ faịlụ ịbulite",
    so: "Dooro faylka la soo gelinayo",
    ha: "Zaɓi fayil ɗin da za a ɗora",
    mn: "Татаж авах файлыг сонгоно уу",
    ff: "Zaɓi fayil ɗin da za a ɗora",
    yo: "Yan faili lati gbe kalẹ",
  },

  //k
  kids: {
    en: "kids",
    pt: "crianças",
    de: "Kinder",
    es: "niños",
    fr: "enfants",
    it: "bambini",
    sq: "fëmijët",
    tr: "çocuklar",
    sw: "watoto",
    am: "ልጆች",
    om: "ilmaanota",
    ig: "nwa",
    so: "caruur",
    ha: "yara",
    mn: "хүүхэд",
    ff: "yara",
    yo: "àwọn ọmọ",
  },
  //h
  "How to activate": {
    en: "How to activate",
    pt: "Como ativar",
    de: "Wie aktivieren",
    es: "Cómo activar",
    fr: "Comment activer",
    it: "Come attivare",
    sq: "Si të aktivizoj",
    tr: "Nasıl aktive edilir",
    sw: "Jinsi ya kuhamasisha",
    am: "እንዴት አነሳሳ",
    om: "Akka armaan olkaafamu",
    ig: "Olee otú e si mee ka ọ rụọ ọrụ",
    so: "Sidee loo firfircooni",
    ha: "Yadda ake kunna",
    mn: "Хэрхэн идэвхжүүлэх вэ",
    ff: "Yadda ake kunna",
    yo: "Bawo ni a ṣe le mu ṣiṣẹ",
  },
  History: {
    en: "History",
    pt: "História",
    de: "Geschichte",
    es: "Historia",
    fr: "Histoire",
    it: "Storia",
    sq: "Historiku",
    tr: "Tarih",
    sw: "Historia",
    am: "ታሪክ",
    om: "Seenaa",
    ig: "Akụkọ",
    so: "Taariikh",
    ha: "Tarihi",
    mn: "Түүх",
    ff: "Tarihi",
    yo: "Itan",
  },
  hide: {
    en: "hide",
    pt: "ocultar",
    de: "verbergen",
    es: "ocultar",
    fr: "cacher",
    it: "nascondi",
    sq: "fsheh",
    tr: "gizle",
    sw: "ficha",
    am: "አይታወቅም",
    om: "dhoksa",
    ig: "zoro",
    so: "qari",
    ha: "ƙyale",
    mn: "нуух",
    ff: "ƙyale",
    yo: "tẹ́",
  },
  Host: {
    en: "Host",
    pt: "Host",
    de: "Host",
    es: "Anfitrión",
    fr: "Hôte",
    it: "Host",
    sq: "Pritësi",
    tr: "Sunucu",
    sw: " mwenyeji",
    am: "አስተናጋጅ",
    om: "Furaa",
    ig: "nche",
    so: "Martiqaad",
    ha: "Mai masauki",
    mn: "хост",
    ff: "Mai masauki",
    yo: "alejo",
  },
  "Hidden content": {
    en: "Hidden content",
    pt: "Conteúdo oculto",
    de: "Versteckter Inhalt",
    es: "Contenido oculto",
    fr: "Contenu caché",
    it: "Contenuto nascosto",
    sq: "Përmbajtje e fshehur",
    tr: "Gizli içerik",
    sw: "maudhui yaliyofichwa",
    am: "የተደበቀ ይዘት",
    om: "Dubbii dhokataa",
    ig: "n содержимого скрытого",
    so: "Maqaalad qarin",
    ha: "Abubuwan da aka ɓoye",
    mn: "нуусан контент",
    ff: "Abubuwan da aka ɓoye",
    yo: "akoonu ti a pa",
  },
  "Available now": {
    en: "Available now",
    pt: "Disponível agora",
    de: "Jetzt verfügbar",
    es: "Disponible ahora",
    fr: "Disponible maintenant",
    it: "Disponibile ora",
    sq: "E disponueshme tani",
    tr: "Şu anda mevcut",
    sw: "inapatikana sasa",
    am: "ይቀበሉ",
    om: "Amma jira",
    ig: "nkwuputara ugbu a",
    so: "Hadda la heli karo",
    ha: "Anan yanzu",
    mn: "одоо хуудаснаас",
    ff: "Anan yanzu",
    yo: "wa ni bayi",
  },

  //p
  Player: {
    en: "Player",
    pt: "Jogador",
    de: "Spieler",
    es: "Reproductor",
    fr: "Lecteur",
    it: "Giocatore",
    sq: "Lojtar",
    tr: "Oynatıcı",
    sw: "Mchezaji",
    am: "ጨዋኛ",
    om: "Taphataa",
    ig: "Nchịkọta",
    so: "Ciyaaryahan",
    ha: "Dan wasan kwaikwayo",
    mn: "Тоглогч",
    ff: "Dan wasan kwaikwayo",
    yo: "Ẹlẹsẹ́",
  },
  "Please note that only previously registered accounts within our system can be linked or attached":
    {
      en: "Please note that only previously registered accounts within our system can be linked or attached.",
      pt: "Por favor, note que apenas contas previamente registradas em nosso sistema podem ser vinculadas ou anexadas.",
      de: "Bitte beachten Sie, dass nur zuvor registrierte Konten in unserem System verknüpft oder angehängt werden können.",
      es: "Tenga en cuenta que solo las cuentas previamente registradas en nuestro sistema pueden ser vinculadas o adjuntadas.",
      fr: "Veuillez noter que seules les comptes préalablement enregistrées dans notre système peuvent être liées ou attachées.",
      it: "Si prega di notare che solo gli account precedentemente registrati nel nostro sistema possono essere collegati o associati.",
      sq: "Ju lutemi vini re se vetëm llogaritë e regjistruara më parë në sistemin tonë mund të lidhen ose bashkëngjiten.",
      tr: "Lütfen yalnızca sistemimizde daha önce kayıtlı olan hesapların bağlanabileceğini veya iliştirilebileceğini unutmayın.",
      sw: "Tafadhali kumbuka kwamba akaunti zilizowahi kuandikishwa ndani ya mfumo wetu zinaweza tu kuunganishwa au kuambatanishwa.",
      am: "እባኮት ያስታውሱ በስርዓታችን ውስጥ በቀደመ መዝግቦች ብቻ ይቀርባሉ ወይም ይቀርባሉ።",
      om: "Galatoomi yaadachi; akki dhunfaa aluu fi sirna keenya keessatti galmaawwan duraan galmeessaman qofa walitti hidhamuu ykn dabalamuu danda'u.",
      ig: "Biko mara na naanị akaụntụ ndị e debanyere n'ụlọ ọrụ anyị nwere ike ijikọ ma ọ bụ tinye.",
      so: "Fadlan ogow in kaliya akoonada horey loogu diiwaan gashado nidaamkeena ayaa la xiriiri karaa ama la xidhidhin karaa.",
      ha: "Don Allah ku lura cewa kawai asusun da aka riga aka yi rajista a cikin tsarinmu ne za a iya haɗawa ko haɗawa.",
      mn: "Манай системд өмнө нь бүртгэгдсэн данс л холбоно, эсвэл хавсаргана уу.",
      ff: "Don Allah ku lura cewa kawai asusun da aka riga aka yi rajista a cikin tsarinmu ne za a iya haɗawa ko haɗawa.",
      yo: "Jọwọ ṣe akiyesi pe awọn iroyin ti a ti forukọsilẹ tẹlẹ nikan ni a le so pọ tabi so pọ.",
    },
  pixels: {
    en: "pixels",
    es: "píxeles",
    fr: "pixels",
    de: "Pixel",
    pt: "pixels",
    it: "pixel",
    sq: "piksela",
    tr: "piksel",
    sw: "pixels",
    am: "ፒክሴል",
    om: "pixels",
    ig: "pixels",
    so: "pixels",
    ha: "pixels",
    mn: "пиксел",
    ff: "pixels",
    yo: "pixels",
  },
  "Please enter date": {
    en: "Please enter date",
    es: "Por favor, ingrese la fecha",
    fr: "Veuillez entrer la date",
    de: "Bitte Datum eingeben",
    pt: "Por favor, insira a data",
    it: "Per favore, inserisci la data",
    sq: "Ju lutem, futni datën",
    tr: "Lütfen tarihi girin",
    sw: "Tafadhali ingiza tarehe",
    am: "እባክዎ ቀን ይግቡ",
    om: "Maaloo guyyaa galchaa",
    ig: "Biko tinye ụbọchị",
    so: "Fadlan gali taariikhda",
    ha: "Don Allah shigar da ranar",
    mn: "Өглөж оруулна уу",
    ff: "Don Allah shigar da ranar",
    yo: "Jọwọ tẹ ọjọ sii",
  },
  "Payment status": {
    en: "Payment status",
    es: "Estado del pago",
    fr: "Statut de paiement",
    de: "Zahlungsstatus",
    pt: "Status de pagamento",
    it: "Stato del pagamento",
    sq: "Statusi i pagesës",
    tr: "Ödeme durumu",
    sw: "Hali ya malipo",
    am: "የክፍያ ሁኔታ",
    om: "Haala kaffaltii",
    ig: "Ọnọdụ akwụmụgwọ",
    so: "Xaaladda lacag bixinta",
    ha: "Matsayin biyan kuɗi",
    mn: "Төлбөрийн байдал",
    ff: "Matsayin biyan kuɗi",
    yo: "Ipo sisanwo",
  },
  panel: {
    en: "Panel",
    pt: "Painel",
    de: "Panel",
    es: "Panel",
    fr: "Panneau",
    it: "Pannello",
    sq: "Paneli",
    tr: "Panel",
    sw: "Paneli",
    am: "ፓነል",
    om: "Panela",
    ig: "Panụl",
    so: "Daa'imo",
    ha: "Pani",
    mn: "Панель",
    ff: "Pani",
    yo: "Panẹli",
  },
  "Pay with": {
    en: "Pay with",
    pt: "Pagar com",
    de: "Bezahlen mit",
    es: "Pagar con",
    fr: "Payer avec",
    it: "Paga con",
    sq: "Paguaj me",
    tr: "İle Öde",
    sw: "Lipa kwa",
    am: "በዚህ ይክፈሉ",
    om: "Kaffaltii",
    ig: "Kwụrụ ụgwọ na",
    so: "Bixi iyadoo",
    ha: "Biya tare da",
    mn: "Төлбөрийг",
    ff: "Biya tare da",
    yo: "Sanwo pẹlu",
  },
  "Pay with Card": {
    en: "Pay with Card",
    pt: "Pagar com Cartão",
    de: "Mit Karte bezahlen",
    es: "Pagar con Tarjeta",
    fr: "Payer avec Carte",
    it: "Pagare con Carta",
    sq: "Pagesë me Kartë",
    tr: "Kartla Öde",
    sw: "Lipa kwa Kadi",
    am: "በካርድ ይክፈሉ",
    om: "Kaarraatiin kaffalu",
    ig: "Kwụrụ ụgwọ na kaadị",
    so: "Bixi iyadoo kaarka",
    ha: "Biya tare da kati",
    mn: "Карттай төлбөр хийх",
    ff: "Biya tare da kati",
    yo: "Sanwo pẹlu kaadi",
  },
  "PIX card": {
    en: "PIX card",
    pt: "Cartão PIX",
    de: "PIX-Karte",
    es: "Tarjeta PIX",
    fr: "Carte PIX",
    it: "Carta PIX",
    sq: "Kartë PIX",
    tr: "PIX kartı",
    sw: "Kadi ya PIX",
    am: "ፒክስ ካርድ",
    om: "Kaarra PIX",
    ig: "Kaarị PIX",
    so: "Kaarka PIX",
    ha: "Katin PIX",
    mn: "PIX карт",
    ff: "Katin PIX",
    yo: "Kaadi PIX",
  },

  Paid: {
    en: "Paid",
    pt: "Pago",
    de: "Bezahlt",
    es: "Pagado",
    fr: "Payé",
    it: "Pagato",
    sq: "Paguar",
    tr: "Ödenmiş",
    sw: "Imelipwa",
    am: "ተከፍሏል",
    om: "Kaffalamee",
    ig: "Kwụrụ ụgwọ",
    so: "Bixiyay",
    ha: "An biya",
    mn: "Төлсөн",
    ff: "An biya",
    yo: "Ti sanwo",
  },
  Price: {
    en: "Price",
    pt: "Preço",
    de: "Preis",
    es: "Precio",
    fr: "Prix",
    it: "Prezzo",
    sq: "Çmimi",
    tr: "Fiyat",
    sw: "Bei",
    am: "ዋጋ",
    om: "Gatii",
    ig: "Ọnụahịa",
    so: "Qiimo",
    ha: "Farashin",
    mn: "Үнэ",
    ff: "Farashin",
    yo: "Owo",
  },
  Provider: {
    en: "Provider",
    pt: "Fornecedor",
    de: "Anbieter",
    es: "Proveedor",
    fr: "Fournisseur",
    it: "Provider",
    sq: "Furnizues",
    tr: "Sağlayıcı",
    sw: "Mtoa huduma",
    am: "አቅራቢ",
    om: "Kennataa",
    ig: "Onye nyere",
    so: "Bixiye",
    ha: "Mai bayarwa",
    mn: "Нийлүүлэгч",
    ff: "Mai bayarwa",
    yo: "Olupese",
  },
  "Provider not found": {
    en: "Provider not found",
    pt: "Fornecedor não encontrado",
    de: "Anbieter nicht gefunden",
    es: "Proveedor no encontrado",
    fr: "Fournisseur non trouvé",
    it: "Provider non trovato",
    sq: "Furnizuesi nuk u gjet",
    tr: "Sağlayıcı bulunamadı",
    sw: "Mtoa huduma hajapatikana",
    am: "አቅራቢ አልተገኘም",
    om: "Kennataan hin argamne",
    ig: "Echefuru onye nyere",
    so: "Ma jiro bixiye",
    ha: "Ba a samu mai bayarwa ba",
    mn: "Нийлүүлэгч олдсонгүй",
    ff: "Ba a samu mai bayarwa ba",
    yo: "Ko ri olupese",
  },
  Partner: {
    en: "Partner",
    pt: "Parceiro",
    de: "Partner",
    es: "Socio",
    fr: "Partenaire",
    it: "Partner",
    sq: "Partner",
    tr: "Ortak",
    sw: "Mshirika",
    am: "ባለስልጣን",
    om: "Waliin hojjetaa",
    ig: "Egwuregwu",
    so: "Lamaan",
    ha: "Abokin tarayya",
    mn: "Түнш",
    ff: "Abokin tarayya",
    yo: "Ọrẹ́pọ̀",
  },
  Platform: {
    en: "Platform",
    pt: "Plataforma",
    de: "Plattform",
    es: "Plataforma",
    fr: "Plateforme",
    it: "Piattaforma",
    sq: "Platformë",
    tr: "Platform",
    sw: "Jukwaa",
    am: "መድረክ",
    om: "Wiirtuu",
    ig: "Nweta",
    so: "Madal",
    ha: "Dandalin",
    mn: "Платформ",
    ff: "Dandalin",
    yo: "Syeed",
  },
  Platforms: {
    en: "Platforms",
    pt: "Plataformas",
    de: "Plattformen",
    es: "Plataformas",
    fr: "Plateformes",
    it: "Piattaforme",
    sq: "Platforma",
    tr: "Platformlar",
    sw: "Majukwaa",
    am: "መድረኮች",
    om: "Wiirtuuwwan",
    ig: "Mbadamba",
    so: "Madalada",
    ha: "Dandali",
    mn: "Платформууд",
    ff: "Dandali",
    yo: "Àwọn syeed",
  },
  Playlist: {
    en: "Playlist",
    pt: "Lista de reprodução",
    de: "Wiedergabeliste",
    es: "Lista de reproducción",
    fr: "Liste de lecture",
    it: "Playlist",
    sq: "Lista e këngëve",
    tr: "Çalma Listesi",
    sw: "Orodha ya nyimbo",
    am: "የትርኢት ዝርዝር",
    om: "Galmee taphannaa",
    ig: "Nchịkọta egwu",
    so: "Liiska ciyaaraha",
    ha: "Jerin waƙoƙi",
    mn: "Тоглуулах жагсаалт",
    ff: "Jerin waƙoƙi",
    yo: "Àtòkọ orin",
  },
  Profile: {
    en: "Profile",
    pt: "Perfil",
    de: "Profil",
    es: "Perfil",
    fr: "Profil",
    it: "Profilo",
    sq: "Profili",
    tr: "Profil",
    sw: "Profaili",
    am: "መገለጫ",
    om: "Bifa",
    ig: "Profaịlụ",
    so: "Astaan",
    ha: "Bayanan martaba",
    mn: "Хувийн мэдээлэл",
    ff: "Bayanan martaba",
    yo: "Àkọọlẹ",
  },
  "Previous step": {
    en: "Previous step",
    pt: "Passo anterior",
    de: "Vorheriger Schritt",
    es: "Paso anterior",
    fr: "Étape précédente",
    it: "Passaggio precedente",
    sq: "Hapi i mëparshëm",
    tr: "Önceki adım",
    sw: "Hatua iliyopita",
    am: "ቀድሞ እርምጃ",
    om: "Gara boodaa",
    ig: "Nzọụkwụ gara aga",
    so: "Talaabadii hore",
    ha: "Mataki na baya",
    mn: "Өмнөх алхам",
    ff: "Mataki na baya",
    yo: "Igbese sẹ́yìn",
  },
  Password: {
    en: "Password",
    pt: "Senha",
    de: "Passwort",
    es: "Contraseña",
    fr: "Mot de passe",
    it: "Password",
    sq: "Fjalëkalim",
    tr: "Şifre",
    sw: "Nenosiri",
    am: "የይለፍ ቃል",
    om: "Jecha ce'umsaa",
    ig: "Okwuntughe",
    so: "Eray sirta",
    ha: "Kalmar shiga",
    mn: "Нууц үг",
    ff: "Kalmar shiga",
    yo: "Ọrọigbaniwọle",
  },
  Phone: {
    en: "Phone",
    pt: "Telefone",
    de: "Telefon",
    es: "Teléfono",
    fr: "Téléphone",
    it: "Telefono",
    sq: "Telefoni",
    tr: "Telefon",
    sw: "Simu",
    am: "ስልክ",
    om: "Bilbila",
    ig: "Ekwe nti",
    so: "Taleefan",
    ha: "Wayar hannu",
    mn: "Утас",
    ff: "Wayar hannu",
    yo: "Fóònù",
  },
  "Playlist URL": {
    en: "Playlist URL",
    pt: "URL da lista de reprodução",
    de: "Playlist-URL",
    es: "URL de la lista de reproducción",
    fr: "URL de la liste de lecture",
    it: "URL della playlist",
    sq: "URL e listës së përgjimeve",
    tr: "Oynatma Listesi URL'si",
    sw: "URL ya orodha ya nyimbo",
    am: "የትርኢት አድራሻ",
    om: "URL galmee taphannaa",
    ig: "URL nchịkọta egwu",
    so: "URL liiska ciyaaraha",
    ha: "URL na jerin waƙoƙi",
    mn: "Тоглуулах жагсаалтын URL",
    ff: "URL na jerin waƙoƙi",
    yo: "URL àtòkọ orin",
  },
  "Partner key": {
    en: "Partner key",
    pt: "Chave do parceiro",
    de: "Partner-Schlüssel",
    es: "Clave del socio",
    fr: "Clé du partenaire",
    it: "Chiave del partner",
    sq: "Çelësi i partnerit",
    tr: "Ortak anahtarı",
    sw: "Kitufe cha mshirika",
    am: "ባለስልጣን ቁልፍ",
    om: "Furmaa waliinii",
    ig: "Igodo onye mmekọ",
    so: "Furaha lamaan",
    ha: "Maɓallin haɗin gwiwa",
    mn: "Түншийн түлхүүр",
    ff: "Maɓallin haɗin gwiwa",
    yo: "Bọtini ọrẹ́pọ̀",
  },
  "Primary color": {
    en: "Primary color",
    pt: "Cor primária",
    de: "Primärfarbe",
    es: "Color primario",
    fr: "Couleur principale",
    it: "Colore primario",
    sq: "Ngjyra kryesore",
    tr: "Ana renk",
    sw: "Rangi ya msingi",
    am: "ዋና ቀለም",
    om: "Halluu dursaa",
    ig: "Agba bụ isi",
    so: "Midabka muhiimka ah",
    ha: "Babban launi",
    mn: "Үндсэн өнгө",
    ff: "Babban launi",
    yo: "Àwọ̀ àkọ́kọ́",
  },
  "Payment method": {
    en: "Payment method",
    pt: "Método de pagamento",
    de: "Zahlungsmethode",
    es: "Método de pago",
    fr: "Moyen de paiement",
    it: "Metodo di pagamento",
    sq: "Metoda e pagesës",
    tr: "Ödeme yöntemi",
    sw: "Njia ya malipo",
    am: "የክፍያ ዘዴ",
    om: "Maloota kaffaltii",
    ig: "Usoro ịkwụ ụgwọ",
    so: "Habka lacag bixinta",
    ha: "Hanyar biyan kuɗi",
    mn: "Төлбөрийн арга",
    ff: "Hanyar biyan kuɗi",
    yo: "Ọna ìsanwó",
  },
  "Password changed": {
    en: "Password changed!",
    pt: "Senha alterada!",
    de: "Passwort geändert!",
    es: "¡Contraseña cambiada!",
    fr: "Mot de passe changé !",
    it: "Password cambiata!",
    sq: "Fjalëkalimi u ndryshua!",
    tr: "Şifre değiştirildi!",
    sw: "Nenosiri limebadilishwa!",
    am: "የይለፍ ቃል ተቀይሯል!",
    om: "Jecha ce'umsaa jijjiirame!",
    ig: "Agbanwe okwuntughe!",
    so: "Eray sirta waa la beddelay!",
    ha: "An canza kalmar shiga!",
    mn: "Нууц үг өөрчлөгдлөө!",
    ff: "An canza kalmar shiga!",
    yo: "Ọrọigbaniwọle ti yipada!",
  },
  "Please enter the password of the account you want to attach.": {
    en: "Please enter the password of the account you want to attach.",
    pt: "Por favor, insira a senha da conta que deseja anexar.",
    de: "Bitte geben Sie das Passwort des Kontos ein, das Sie anhängen möchten.",
    es: "Por favor, ingrese la contraseña de la cuenta que desea adjuntar.",
    fr: "Veuillez entrer le mot de passe du compte que vous souhaitez attacher.",
    it: "Si prega di inserire la password dell'account che si desidera allegare.",
    sq: "Ju lutemi vendosni fjalëkalimin e llogarisë që dëshironi të bashkangjitni.",
    tr: "Lütfen eklemek istediğiniz hesabın şifresini girin.",
    sw: "Tafadhali ingiza nenosiri la akaunti unayotaka kuambatisha.",
    am: "እባክዎን ለመያዣ የሚፈልጉትን አካውንት የይለፍ ቃል ያስገቡ።",
    om: "Maaloo jecha ce’umsaa akkaawuntii walitti fiduu barbaadduu galchaa.",
    ig: "Biko tinye okwuntughe nke akaụntụ ị chọrọ ijikọ.",
    so: "Fadlan geli eray sirta akoonka aad rabto inaad lifaaqdo.",
    ha: "Don Allah shigar da kalmar shiga na asusun da kake son haɗawa.",
    mn: "Хавсаргахыг хүссэн аккаунтын нууц үгийг оруулна уу.",
    ff: "Don Allah shigar da kalmar shiga na asusun da kake son haɗawa.",
    yo: "Jọwọ tẹ ọrọigbaniwọle àkọọlẹ ti o fẹ̀ so mọ́.",
  },
  "Please check the playlist as it is incorrectly filled in.": {
    en: "Please check the playlist as it is incorrectly filled in.",
    pt: "Por favor, verifique a lista de reprodução, pois está preenchida incorretamente.",
    de: "Bitte überprüfen Sie die Playlist, da sie falsch ausgefüllt ist.",
    es: "Por favor, compruebe la lista de reproducción, ya que está incorrectamente rellenada.",
    fr: "Veuillez vérifier la playlist car elle est incorrectement remplie.",
    it: "Si prega di controllare la playlist in quanto è compilata in modo errato.",
    sq: "Ju lutemi kontrolloni listën e këngëve, pasi është mbushur gabimisht.",
    tr: "Lütfen listeyi kontrol edin, çünkü yanlış doldurulmuş.",
    sw: "Tafadhali angalia orodha ya nyimbo kwani imejazwa vibaya.",
    am: "እባክዎን የትርኢት ዝርዝርን ያረጋግጡ በትክክል አልተሞላም።",
    om: "Maaloo galmee taphannaa ilaali, akka sirriitti hin guutamne.",
    ig: "Biko lelee nchịkọta egwu maka na ejiri ya emeghị nke ọma.",
    so: "Fadlan hubi liiska ciyaaraha maxaa yeelay si khaldan ayaa loo buuxiyay.",
    ha: "Don Allah duba jerin waƙoƙin saboda an cika shi ba daidai ba.",
    mn: "Тоглуулах жагсаалтыг зөв бөглөөгүй тул шалгана уу.",
    ff: "Don Allah duba jerin waƙoƙin saboda an cika shi ba daidai ba.",
    yo: "Jọwọ ṣayẹwo àtòkọ orin nitori a ti fi ọ̀nà tàbí gígòòrò kọ́.",
  },
  "Please check this playlist as its expiration date has expired.": {
    en: "Please check this playlist as its expiration date has expired.",
    pt: "Por favor, verifique esta lista de reprodução, pois a data de validade expirou.",
    de: "Bitte überprüfen Sie diese Playlist, da das Ablaufdatum abgelaufen ist.",
    es: "Por favor, compruebe esta lista de reproducción, ya que su fecha de vencimiento ha caducado.",
    fr: "Veuillez vérifier cette playlist car sa date d'expiration est dépassée.",
    it: "Si prega di controllare questa playlist poiché la data di scadenza è scaduta.",
    sq: "Ju lutemi kontrolloni këtë listë këngësh, pasi data e skadimit ka përfunduar.",
    tr: "Lütfen bu listeyi kontrol edin, çünkü son kullanma tarihi dolmuştur.",
    sw: "Tafadhali angalia orodha hii ya nyimbo kwani tarehe ya kumalizika imepita.",
    am: "እባክዎን ይህን የትርኢት ዝርዝር ያረጋግጡ የመጠናቀቂያ ቀን አልፏል።",
    om: "Maaloo galmee taphannaa ilaali, guyyaan xumuramaa isaa darbee jira.",
    ig: "Biko lelee nchịkọta egwu a ka ụbọchị ngwụcha ya agafela.",
    so: "Fadlan hubi liiskan ciyaaraha tan iyo markii taariikhda dhammaadka uu dhacay.",
    ha: "Don Allah duba wannan jerin waƙoƙi saboda ranar karewarsa ta wuce.",
    mn: "Энэ тоглуулах жагсаалтын дуусах хугацаа дууссан тул шалгана уу.",
    ff: "Don Allah duba wannan jerin waƙoƙi saboda ranar karewarsa ta wuce.",
    yo: "Jọwọ ṣayẹwo àtòkọ orin yìí torí ọjọ́ ipari rẹ ti kọjá.",
  },

  "Please input playlist URL": {
    en: "Please input playlist URL",
    pt: "Por favor, insira o URL da lista de reprodução",
    de: "Bitte geben Sie die URL der Wiedergabeliste ein",
    es: "Por favor, introduzca la URL de la lista de reproducción",
    fr: "Veuillez entrer l'URL de la playlist",
    it: "Si prega di inserire l'URL della playlist",
    sq: "Ju lutemi vendosni URL-në e listës së përgjimeve",
    tr: "Lütfen oynatma listesi URL'sini girin",
    sw: "Tafadhali ingiza URL ya orodha ya nyimbo",
    am: "እባክዎን የትርኢት ዝርዝር አድራሻ ያስገቡ",
    om: "Maaloo URL galmee taphannaa galchaa",
    ig: "Biko tinye URL nchịkọta egwu",
    so: "Fadlan geli URL liiska ciyaaraha",
    ha: "Don Allah shigar da URL na jerin waƙoƙi",
    mn: "Тоглуулах жагсаалтын URL-ийг оруулна уу",
    ff: "Don Allah shigar da URL na jerin waƙoƙi",
    yo: "Jọwọ tẹ URL àtòkọ orin",
  },
  "Please enter device key": {
    en: "Please enter device key",
    pt: "Por favor, insira a chave do dispositivo",
    de: "Bitte geben Sie den Geräteschlüssel ein",
    es: "Por favor, introduzca la clave del dispositivo",
    fr: "Veuillez entrer la clé de l'appareil",
    it: "Per favore, inserisci la chiave del dispositivo",
    sq: "Ju lutemi vendosni çelësin e pajisjes",
    tr: "Lütfen cihaz anahtarını girin",
    sw: "Tafadhali ingiza kitufe cha kifaa",
    am: "እባክዎን የመሣሪያውን ቁልፍ ያስገቡ",
    om: "Maaloo furtuu meeshaa galchaa",
    ig: "Biko tinye igodo ngwaọrụ",
    so: "Fadlan geli furaha aaladda",
    ha: "Don Allah shigar da maɓallin na'ura",
    mn: "Төхөөрөмжийн түлхүүрийг оруулна уу",
    ff: "Don Allah shigar da maɓallin na'ura",
    yo: "Jọwọ tẹ bọtini ẹrọ",
  },
  "Please check the entered data": {
    en: "Please check the entered data",
    pt: "Por favor, verifique os dados inseridos",
    de: "Bitte überprüfen Sie die eingegebenen Daten",
    es: "Por favor, verifique los datos ingresados",
    fr: "Veuillez vérifier les données saisies",
    it: "Si prega di verificare i dati inseriti",
    sq: "Ju lutemi kontrolloni të dhënat e vendosura",
    tr: "Lütfen girilen verileri kontrol edin",
    sw: "Tafadhali angalia data iliyowekwa",
    am: "እባክዎን ያስገቡትን መረጃ ያረጋግጡ",
    om: "Maaloo data galchite ilaali",
    ig: "Biko lelee data e tinyere",
    so: "Fadlan hubi xogta la geliyey",
    ha: "Don Allah duba bayanan da aka shigar",
    mn: "Оруулсан өгөгдлийг шалгана уу",
    ff: "Don Allah duba bayanan da aka shigar",
    yo: "Jọwọ ṣayẹwo àwọn data tó tẹ",
  },

  "Please input name": {
    en: "Please input name",
    pt: "Por favor, insira o nome",
    de: "Bitte geben Sie den Namen ein",
    es: "Por favor, ingrese el nombre",
    fr: "Veuillez entrer le nom",
    it: "Per favore, inserisci il nome",
    sq: "Ju lutemi vendosni emrin",
    tr: "Lütfen ismi girin",
    sw: "Tafadhali ingiza jina",
    am: "እባክዎን ስም ያስገቡ",
    om: "Maaloo maqaa galchaa",
    ig: "Biko tinye aha",
    so: "Fadlan geli magaca",
    ha: "Don Allah shigar da suna",
    mn: "Нэрээ оруулна уу",
    ff: "Don Allah shigar da suna",
    yo: "Jọwọ tẹ orukọ",
  },
  "Please input Host": {
    en: "Please input Host",
    pt: "Por favor, insira o Host",
    de: "Bitte geben Sie den Host ein",
    es: "Por favor, ingrese el Host",
    fr: "Veuillez entrer l'hôte",
    it: "Per favore, inserisci l'Host",
    sq: "Ju lutemi vendosni Hostin",
    tr: "Lütfen Host'u girin",
    sw: "Tafadhali ingiza Mwenyeji",
    am: "እባክዎን ማሳደጊውን ያስገቡ",
    om: "Maaloo Hoostii galchaa",
    ig: "Biko tinye onye na-ebu",
    so: "Fadlan geli martigeliyaha",
    ha: "Don Allah shigar da Mai masauki",
    mn: "Хостыг оруулна уу",
    ff: "Don Allah shigar da Mai masauki",
    yo: "Jọwọ tẹ Olùgbélé",
  },

  "Please input api key": {
    en: "Please input api key",
    pt: "Por favor, insira a chave da API",
    de: "Bitte geben Sie den API-Schlüssel ein",
    es: "Por favor, ingrese la clave de la API",
    fr: "Veuillez entrer la clé API",
    it: "Per favore, inserisci la chiave API",
    sq: "Ju lutemi vendosni çelësin API",
    tr: "Lütfen API anahtarını girin",
    sw: "Tafadhali ingiza kitufe cha api",
    am: "እባክዎን የኤፒአይ ቁልፍ ያስገቡ",
    om: "Maaloo furtuu API galchaa",
    ig: "Biko tinye igodo api",
    so: "Fadlan geli furaha api",
    ha: "Don Allah shigar da maɓallin API",
    mn: "API түлхүүрийг оруулна уу",
    ff: "Don Allah shigar da maɓallin API",
    yo: "Jọwọ tẹ bọtini API",
  },
  "Please input your name": {
    en: "Please input your name!",
    pt: "Por favor, insira o seu nome!",
    de: "Bitte geben Sie Ihren Namen ein!",
    es: "Por favor, ingrese su nombre!",
    fr: "Veuillez entrer votre nom !",
    it: "Per favore, inserisci il tuo nome!",
    sq: "Ju lutemi vendosni emrin tuaj!",
    tr: "Lütfen adınızı girin!",
    sw: "Tafadhali ingiza jina lako!",
    am: "እባክዎን ስምዎን ያስገቡ!",
    om: "Maaloo maqaa keetii galchaa!",
    ig: "Biko tinye aha gị!",
    so: "Fadlan geli magacaaga!",
    ha: "Don Allah shigar da sunanka!",
    mn: "Өөрийнхөө нэрийг оруулна уу!",
    ff: "Don Allah shigar da sunanka!",
    yo: "Jọwọ tẹ orukọ rẹ!",
  },
  "Please input cvv": {
    en: "Please input cvv",
    pt: "Por favor, insira o CVV",
    de: "Bitte geben Sie den CVV ein",
    es: "Por favor, ingrese el CVV",
    fr: "Veuillez saisir le cryptogramme visuel",
    it: "Inserisci il CVV per favore",
    sq: "Ju lutemi vendosni CVV-në",
    tr: "Lütfen CVV'yi girin",
    sw: "Tafadhali ingiza cvv",
    am: "እባክዎን cvv ያስገቡ",
    om: "Maaloo CVV galchaa",
    ig: "Biko tinye cvv",
    so: "Fadlan geli cvv",
    ha: "Don Allah shigar da cvv",
    mn: "CVV-ийг оруулна уу",
    ff: "Don Allah shigar da cvv",
    yo: "Jọwọ tẹ cvv",
  },
  "Please input number": {
    en: "Please input number",
    pt: "Por favor, insira um número",
    de: "Bitte geben Sie eine Zahl ein",
    es: "Por favor, ingrese un número",
    fr: "Veuillez saisir un numéro",
    it: "Per favore, inserire un numero",
    sq: "Ju lutemi vendosni numrin",
    tr: "Lütfen bir numara girin",
    sw: "Tafadhali ingiza nambari",
    am: "እባክዎን ቁጥር ያስገቡ",
    om: "Maaloo lakkoofsa galchaa",
    ig: "Biko tinye nọmba",
    so: "Fadlan geli lambarka",
    ha: "Don Allah shigar da lamba",
    mn: "Дугаарыг оруулна уу",
    ff: "Don Allah shigar da lamba",
    yo: "Jọwọ tẹ nọmba",
  },
  "Please input expiration month": {
    en: "Please input expiration month",
    pt: "Por favor, insira o mês de validade",
    de: "Bitte geben Sie den Ablaufmonat ein",
    es: "Por favor, ingrese el mes de vencimiento",
    fr: "Veuillez entrer le mois d'expiration",
    it: "Si prega di inserire il mese di scadenza",
    sq: "Ju lutemi vendosni muajin e skadimit",
    tr: "Lütfen son kullanma ayını girin",
    sw: "Tafadhali ingiza mwezi wa kumalizika",
    am: "እባክዎን የመጨረሻ ወር ያስገቡ",
    om: "Maaloo ji'a xumuramaa galchaa",
    ig: "Biko tinye ọnwa ngwụcha",
    so: "Fadlan geli bisha dhammaadka",
    ha: "Don Allah shigar da watan karewa",
    mn: "Хугацаа дуусах сарын нэрийг оруулна уу",
    ff: "Don Allah shigar da watan karewa",
    yo: "Jọwọ tẹ oṣù ipari",
  },
  "Please input expiration year": {
    en: "Please input expiration year",
    pt: "Por favor, insira o ano de expiração",
    de: "Bitte geben Sie das Ablaufjahr ein",
    es: "Por favor, introduzca el año de vencimiento",
    fr: "Veuillez entrer l'année d'expiration",
    it: "Si prega di inserire l'anno di scadenza",
    sq: "Ju lutemi vendosni vitin e skadimit",
    tr: "Lütfen son kullanma yılını girin",
    sw: "Tafadhali ingiza mwaka wa kumalizika",
    am: "እባክዎን የመጨረሻ ዓመት ያስገቡ",
    om: "Maaloo bara xumuramaa galchaa",
    ig: "Biko tinye afọ ngwụcha",
    so: "Fadlan geli sanadka dhammaadka",
    ha: "Don Allah shigar da shekarar karewa",
    mn: "Хугацаа дуусах жилийн нэрийг оруулна уу",
    ff: "Don Allah shigar da shekarar karewa",
    yo: "Jọwọ tẹ ọdun ipari",
  },

  "Please check auto renew": {
    en: "Please check auto renew",
    pt: "Por favor, verifique a renovação automática",
    de: "Bitte überprüfen Sie die automatische Verlängerung",
    es: "Por favor, compruebe la renovación automática",
    fr: "Veuillez vérifier le renouvellement automatique",
    it: "Per favore, controlla il rinnovo automatico",
    sq: "Ju lutemi kontrolloni rinovimin automatik",
    tr: "Lütfen otomatik yenilemeyi kontrol edin",
    sw: "Tafadhali angalia upya kiotomatiki",
    am: "እባክዎን የአውቶ አደረጃጀት ያረጋግጡ",
    om: "Maaloo haaromsa ofumaa ilaali",
    ig: "Biko lelee ntinye nke na-emepụta onwe ya",
    so: "Fadlan hubi cusbooneysiinta otomaatiga ah",
    ha: "Don Allah duba sabuntawa ta atomatik",
    mn: "Автоматаар шинэчлэхийг шалгана уу",
    ff: "Don Allah duba sabuntawa ta atomatik",
    yo: "Jọwọ ṣayẹwo atunṣe laifọwọyi",
  },

  "Please input your password": {
    en: "Please input your password!",
    pt: "Por favor, insira sua senha!",
    de: "Bitte geben Sie Ihr Passwort ein!",
    es: "Por favor, ingrese su contraseña!",
    fr: "Veuillez entrer votre mot de passe !",
    it: "Per favore, inserisci la tua password!",
    sq: "Ju lutemi vendosni fjalëkalimin tuaj!",
    tr: "Lütfen şifrenizi girin!",
    sw: "Tafadhali ingiza nywila yako",
    am: "እባክዎን የይለፍ ቃልዎን ያስገቡ",
    om: "Maaloo jecha iccitii keeti galchaa",
    ig: "Biko tinye okwuntughe gị",
    so: "Fadlan geli eraygaaga",
    ha: "Don Allah shigar da kalmar wucewa",
    mn: "Нууц үгээ оруулна уу",
    ff: "Don Allah shigar da kalmar wucewa",
    yo: "Jọwọ tẹ ọrọ igbaniwọle rẹ",
  },

  "Please confirm your password": {
    en: "Please confirm your password!",
    pt: "Por favor, confirme sua senha!",
    de: "Bitte bestätigen Sie Ihr Passwort!",
    es: "Por favor, confirme su contraseña!",
    fr: "Veuillez confirmer votre mot de passe !",
    it: "Per favore, conferma la tua password!",
    sq: "Ju lutemi konfirmoni fjalëkalimin tuaj!",
    tr: "Lütfen şifrenizi onaylayın!",
    sw: "Tafadhali thibitisha nywila yako!",
    am: "እባክዎን የይለፍ ቃልዎን ይረግግሙ!",
    om: "Maaloo jecha iccitii keeti mirkaneessi!",
    ig: "Biko kwadoro okwuntughe gị!",
    so: "Fadlan xaqiiji eraygaaga!",
    ha: "Don Allah tabbatar da kalmar wucewa!",
    mn: "Нууц үгээ баталгаажуулна уу!",
    ff: "Don Allah tabbatar da kalmar wucewa!",
    yo: "Jọwọ jẹrisi ọrọ igbaniwọle rẹ!",
  },
  "Please input your username": {
    en: "Please input your username!",
    pt: "Por favor, insira seu nome de usuário!",
    de: "Bitte geben Sie Ihren Benutzernamen ein!",
    es: "Por favor, ingrese su nombre de usuario!",
    fr: "Veuillez entrer votre nom d'utilisateur !",
    it: "Per favore, inserisci il tuo nome utente!",
    sq: "Ju lutemi vendosni emrin tuaj të përdoruesit!",
    tr: "Lütfen kullanıcı adınızı girin!",
    sw: "Tafadhali ingiza jina lako la mtumiaji!",
    am: "እባክዎን የተጠቃሚ ስምዎን ያስገቡ!",
    om: "Maaloo maqaa fayyadamtoota keeti galchaa!",
    ig: "Biko tinye aha onye ọrụ gị!",
    so: "Fadlan geli magacaaga isticmaale!",
    ha: "Don Allah shigar da sunan mai amfani!",
    mn: "Хэрэглэгчийн нэрийг оруулна уу!",
    ff: "Don Allah shigar da sunan mai amfani!",
    yo: "Jọwọ tẹ orukọ olumulo rẹ!",
  },
  "Please input access code": {
    en: "Please input access code",
    pt: "Por favor, insira o código de acesso",
    de: "Bitte geben Sie den Zugangscode ein",
    es: "Por favor, ingrese el código de acceso",
    fr: "Veuillez entrer le code d'accès",
    it: "Per favore, inserisci il codice di accesso",
    sq: "Ju lutemi vendosni kodin e qasjes",
    tr: "Lütfen erişim kodunu girin",
    sw: "Tafadhali ingiza msimbo wa ufikiaji",
    am: "እባክዎን የግባት ኮድ ያስገቡ",
    om: "Maaloo koodii seenuu galchaa",
    ig: "Biko tinye koodu n'ịbanye",
    so: "Fadlan geli koodhka gelitaanka",
    ha: "Don Allah shigar da lambar shiga",
    mn: "Нэвтрэх кодыг оруулна уу",
    ff: "Don Allah shigar da lambar shiga",
    yo: "Jọwọ tẹ koodu iraye",
  },
  "Please input your E-mail": {
    en: "Please input your E-mail!",
    pt: "Por favor, insira o seu e-mail!",
    de: "Bitte geben Sie Ihre E-Mail-Adresse ein!",
    es: "Por favor, ingrese su correo electrónico!",
    fr: "Veuillez entrer votre adresse e-mail !",
    it: "Per favore, inserisci la tua email!",
    sq: "Ju lutemi vendosni emailin tuaj!",
    tr: "Lütfen e-posta adresinizi girin!",
    sw: "Tafadhali ingiza barua pepe yako!",
    am: "እባክዎን ኢ-ሜይልዎን ያስገቡ!",
    om: "Maaloo E-mail keeti galchaa!",
    ig: "Biko tinye email gị!",
    so: "Fadlan geli emaylkaaga!",
    ha: "Don Allah shigar da adireshin imel ɗinku!",
    mn: "И-мэйл хаягаа оруулна уу!",
    ff: "Don Allah shigar da adireshin imel ɗinku!",
    yo: "Jọwọ tẹ imeeli rẹ!",
  },
  "Please input title": {
    en: "Please input title!",
    pt: "Por favor, insira o título!",
    de: "Bitte geben Sie den Titel ein!",
    es: "Por favor, ingrese el título!",
    fr: "Veuillez entrer le titre !",
    it: "Per favore, inserisci il titolo!",
    sq: "Ju lutemi vendosni titullin!",
    tr: "Lütfen başlığı girin!",
    sw: "Tafadhali ingiza kichwa!",
    am: "እባክዎን አንቀፅ ያስገቡ!",
    om: "Maaloo mata-duree galchaa!",
    ig: "Biko tinye aha!",
    so: "Fadlan geli cinwaanka!",
    ha: "Don Allah shigar da taken!",
    mn: "Гарчигийг оруулна уу!",
    ff: "Don Allah shigar da taken!",
    yo: "Jọwọ tẹ akọle!",
  },
  "Please input your 2FA email address": {
    en: "Please input your 2FA email address",
    pt: "Por favor, insira o seu endereço de e-mail de 2FA",
    de: "Bitte geben Sie Ihre 2FA-E-Mail-Adresse ein",
    es: "Por favor, ingrese su dirección de correo electrónico de 2FA",
    fr: "Veuillez entrer votre adresse e-mail 2FA",
    it: "Inserisci il tuo indirizzo email 2FA, per favore",
    sq: "Ju lutemi vendosni adresën tuaj të emailit 2FA",
    tr: "Lütfen 2FA e-posta adresinizi girin",
    sw: "Tafadhali ingiza anwani yako ya barua pepe ya 2FA",
    am: "እባክዎን የ2FA ኢ-ሜይል አድራሻዎን ያስገቡ",
    om: "Maaloo E-mail 2FA keeti galchaa",
    ig: "Biko tinye adreesị email 2FA gị",
    so: "Fadlan geli cinwaanka emaylkaaga 2FA",
    ha: "Don Allah shigar da adireshin imel 2FA ɗinku",
    mn: "2FA и-мэйл хаягаа оруулна уу",
    ff: "Don Allah shigar da adireshin imel 2FA ɗinku",
    yo: "Jọwọ tẹ adirẹsi imeeli 2FA rẹ",
  },
  "Please input your brand name": {
    en: "Please input your brand name!",
    pt: "Por favor, insira o nome da sua marca!",
    de: "Bitte geben Sie den Markennamen ein!",
    es: "Por favor, ingrese el nombre de su marca!",
    fr: "Veuillez entrer le nom de votre marque !",
    it: "Per favore, inserisci il nome del tuo brand!",
    sq: "Ju lutemi vendosni emrin e markës suaj!",
    tr: "Lütfen marka adınızı girin!",
    sw: "Tafadhali ingiza jina lako la chapa!",
    am: "እባክዎን የእቃዎት ስም ያስገቡ!",
    om: "Maaloo maqaa maarka keeti galchaa!",
    ig: "Biko tinye aha brand gị!",
    so: "Fadlan geli magacaaga sumadda!",
    ha: "Don Allah shigar da sunan alamar ku!",
    mn: "Брэнд нэрийг оруулна уу!",
    ff: "Don Allah shigar da sunan alamar ku!",
    yo: "Jọwọ tẹ orukọ ami rẹ!",
  },
  "Please input description": {
    en: "Please input description!",
    pt: "Por favor, insira a descrição!",
    de: "Bitte geben Sie eine Beschreibung ein!",
    es: "Por favor, ingrese una descripción!",
    fr: "Veuillez entrer la description !",
    it: "Per favore, inserisci la descrizione!",
    sq: "Ju lutemi vendosni përshkrimin!",
    tr: "Lütfen açıklamayı girin!",
    sw: "Tafadhali ingiza maelezo!",
    am: "እባክዎን  description ያስገቡ!",
    om: "Maaloo ibsa galchaa!",
    ig: "Biko tinye nkọwa!",
    so: "Fadlan geli sharraxaad!",
    ha: "Don Allah shigar da bayanin!",
    mn: "Тодорхойлолтыг оруулна уу!",
    ff: "Don Allah shigar da bayanin!",
    yo: "Jọwọ tẹ apejuwe!",
  },
  "Please input your credit count": {
    en: "Please input your credit count!",
    pt: "Por favor, insira a contagem de crédito!",
    de: "Bitte geben Sie die Kreditanzahl ein!",
    es: "Por favor, ingrese la cantidad de créditos!",
    fr: "Veuillez entrer le nombre de crédits !",
    it: "Per favore, inserisci il conteggio dei crediti!",
    sq: "Ju lutemi vendosni numrin tuaj të kreditit!",
    tr: "Lütfen kredi sayınızı girin!",
    sw: "Tafadhali ingiza idadi ya mikopo yako!",
    am: "እባክዎን የክሬዲት ብዛትዎን ያስገቡ!",
    om: "Maaloo lakkoofsa kireediti keeti galchaa!",
    ig: "Biko tinye ọnụ ọgụgụ kredit gị!",
    so: "Fadlan geli tirada deyntaada!",
    ha: "Don Allah shigar da adadin kuɗi!",
    mn: "Кредитийн тоог оруулна уу!",
    ff: "Don Allah shigar da adadin kuɗi!",
    yo: "Jọwọ tẹ nọmba kirẹditi rẹ!",
  },
  "Pick new server to migrate your users": {
    en: "Pick new server to migrate your users",
    pt: "Escolha um novo servidor para migrar seus usuários",
    de: "Wählen Sie einen neuen Server, um Ihre Benutzer zu migrieren",
    es: "Seleccione un nuevo servidor para migrar a sus usuarios",
    fr: "Choisissez un nouveau serveur pour migrer vos utilisateurs",
    it: "Scegli un nuovo server per migrare i tuoi utenti",
    sq: "Zgjidhni serverin e ri për të migrur përdoruesit tuaj",
    tr: "Kullanıcılarınızı taşımak için yeni bir sunucu seçin",
    sw: "Chagua seva mpya kuhamasisha watumiaji wako",
    am: "እባክዎን ወደ ወደብ አዲስ የሚያዳምጡ አይስ ይምረጡ",
    om: "Maaloo tajaajila haaraa filadhuu si fayyadamtoota keeti geessu",
    ig: "Biko họrọ sava ọhụrụ iji nyefee ndị ọrụ gị",
    so: "Fadlan dooro server cusub si aad u guuriso isticmaaleyaashaaga",
    ha: "Don Allah zaɓi sabon sabar don canza masu amfani da ku",
    mn: "Хэрэглэгчдийг шилжүүлэхийн тулд шинэ серверийг сонгоно уу",
    ff: "Don Allah zaɓi sabon sabar don canza masu amfani da ku",
    yo: "Yan ki o yan server tuntun lati gbe awọn olumulo rẹ",
  },
  "Please input your new password": {
    en: "Please input your new password!",
    pt: "Por favor, insira sua nova senha!",
    de: "Bitte geben Sie Ihr neues Passwort ein!",
    es: "Por favor, ingrese su nueva contraseña!",
    fr: "Veuillez entrer votre nouveau mot de passe !",
    it: "Per favore, inserisci la tua nuova password!",
    sq: "Ju lutemi vendosni fjalëkalimin tuaj të ri!",
    tr: "Lütfen yeni şifrenizi girin!",
    sw: "Tafadhali ingiza nywila yako mpya!",
    am: "እባክዎን አዲስ የይለፍ ቃል ያስገቡ!",
    om: "Maaloo jecha haaraa galchaa!",
    ig: "Biko tinye okwuntughe ọhụrụ gị!",
    so: "Fadlan geli eraygaaga cusub!",
    ha: "Don Allah shigar da sabuwar kalmar wucewa!",
    mn: "Шинэ нууц үгээ оруулна уу!",
    ff: "Don Allah shigar da sabuwar kalmar wucewa!",
    yo: "Jọwọ tẹ ọrọ igbaniwọle tuntun rẹ!",
  },
  "Please input your verify code": {
    en: "Please input your verify code",
    pt: "Por favor, insira seu código de verificação",
    de: "Bitte geben Sie Ihren Bestätigungscode ein",
    es: "Por favor, ingrese su código de verificación",
    fr: "Veuillez saisir votre code de vérification",
    it: "Inserisci il tuo codice di verifica",
    sq: "Ju lutemi vendosni kodin tuaj të verifikimit",
    tr: "Lütfen doğrulama kodunuzu girin",
    sw: "Tafadhali ingiza msimbo wako wa uthibitisho",
    am: "እባክዎን የማረጋገጫ ኮድዎን ያስገቡ",
    om: "Maaloo koodii mirkaneessaa keeti galchaa",
    ig: "Biko tinye koodu gị nke ịkọwapụtara",
    so: "Fadlan geli koodhkaaga xaqiijinta",
    ha: "Don Allah shigar da lambar tabbatarwa",
    mn: "Баталгаажуулах кодыг оруулна уу",
    ff: "Don Allah shigar da lambar tabbatarwa",
    yo: "Jọwọ tẹ koodu rẹ ti iṣeduro",
  },
  "Password must be minimum 8 characters": {
    en: "Password must be minimum 8 characters.",
    pt: "A senha deve ter no mínimo 8 caracteres.",
    de: "Das Passwort muss mindestens 8 Zeichen lang sein.",
    es: "La contraseña debe tener como mínimo 8 caracteres.",
    fr: "Le mot de passe doit comporter au moins 8 caractères.",
    it: "La password deve essere lunga almeno 8 caratteri.",
    sq: "Fjalëkalimi duhet të jetë minimum 8 karaktere",
    tr: "Şifre en az 8 karakter olmalıdır.",
    sw: "Nywila lazima iwe na herufi 8 angalau.",
    am: "የይለፍ ቃል ከ8 ቁምፊዎች የታቀደ መሆን ይገባል.",
    om: "Jecha icciitiin 8 walakkaa ta'uu qaba.",
    ig: "Okwuntughe ga-enwerịrị obere 8 akara.",
    so: "Ereyga sirta ah waa in uu yahay ugu yaraan 8 xaraf.",
    ha: "Kalmar wucewa ya kamata ta kasance aƙalla haruffa 8.",
    mn: "Нууц үг 8 тэмдэгтээс багагүй байх ёстой.",
    ff: "Kalmar wucewa ya kamata ta kasance aƙalla haruffa 8.",
    yo: "Ọrọ igbaniwọle gbọdọ jẹ o kere ju awọn ohun kikọ 8.",
  },
  "Provider hasn't approved": {
    en: "Provider hasn't approved",
    pt: "O provedor não aprovou",
    de: "Der Anbieter hat nicht zugestimmt",
    es: "El proveedor no ha aprobado",
    fr: "Le fournisseur n'a pas approuvé",
    it: "Il fornitore non ha approvato",
    sq: "Furnizuesi nuk ka miratuar",
    tr: "Sağlayıcı onaylamadı",
    sw: "Mtoa huduma hajakubali",
    am: "አቅራቢው አልተወሰነም",
    om: "Tajaajilaan hin mirkaneeffamne",
    ig: "Ndị na-eweta achọghị nkwado",
    so: "Bixiyaha weli ma ansixin",
    ha: "Mai bayar da sabis ya tabbatar da shi",
    mn: "Ний Provider зөвшөөрөөгүй",
    ff: "Mai bayar da sabis ya tabbatar da shi",
    yo: "Olupese ko ti fọwọsi",
  },
  "Pick expiration date of the playlist": {
    en: "Pick expiration date of the playlist",
    pt: "Escolha a data de expiração da lista de reprodução",
    de: "Wählen Sie das Ablaufdatum der Wiedergabeliste",
    es: "Seleccione la fecha de vencimiento de la lista de reproducción",
    fr: "Choisissez la date d'expiration de la liste de lecture",
    it: "Scegli la data di scadenza della playlist",
    sq: "Zgjidh datën e skadimit të listës së riprodhimit",
    tr: "Oynatma listesinin sonlandırma tarihini seçin",
    sw: "Chagua tarehe ya kumalizika ya orodha ya nyimbo",
    am: "የታይታ ቀን ይምረጡ",
    om: "Fakkii yaada guutuu guyyaa filadhuu",
    ig: "Họrọ ụbọchị mmalite nke usoro egwu",
    so: "Dooro taariikhda dhicitaanka liiska",
    ha: "Zabi ranar karewar jerin",
    mn: "Плэйлистын дуусах өдрийг сонгоно уу",
    ff: "Zabi ranar karewar jerin",
    yo: "Yan ki o yan ọjọ ipari ti atokọ",
  },
  "Please select the type of your server": {
    en: "Please select the type of your server",
    pt: "Por favor, selecione o tipo do seu servidor",
    de: "Bitte wählen Sie den Servertyp aus",
    es: "Por favor, seleccione el tipo de su servidor",
    fr: "Veuillez sélectionner le type de votre serveur",
    it: "Per favore, seleziona il tipo del tuo server",
    sq: "Ju lutemi zgjidhni llojin e serverit tuaj",
    tr: "Lütfen sunucunuzun türünü seçin",
    sw: "Tafadhali chagua aina ya seva yako",
    am: "እባክዎን የአገልግሎትዎ አይነት ይምረጡ",
    om: "Maaloo tajaajila keeti filadhuu",
    ig: "Biko họrọ ụdị sava gị",
    so: "Fadlan dooro nooca server-kaaga",
    ha: "Don Allah zaɓi nau'in sabar ku",
    mn: "Таны серверийн төрөл сонгоно уу",
    ff: "Don Allah zaɓi nau'in sabar ku",
    yo: "Jọwọ yan iru olupin rẹ",
  },
  "Please, contact us in case of any changes": {
    en: "Please, contact us in case of any changes.",
    pt: "Por favor, entre em contato conosco em caso de qualquer alteração.",
    de: "Bitte kontaktieren Sie uns im Falle von Änderungen.",
    es: "Por favor, contáctenos en caso de cualquier cambio.",
    fr: "Veuillez nous contacter en cas de modifications.",
    it: "Per favore, contattaci in caso di modifiche.",
    sq: "Ju lutemi, na kontaktoni në rast ndonjë ndryshimi.",
    tr: "Lütfen herhangi bir değişiklik durumunda bizimle iletişime geçin.",
    sw: "Tafadhali, wasiliana nasi ikiwa kuna mabadiliko yoyote.",
    am: "እባክዎን ማስተካከል ካለ እባክዎን ይደውሉን",
    om: "Maaloo, jijjiirama ta'ee nu qunnamu.",
    ig: "Biko, kọntakt anyị ma ọ bụrụ na e nwere mgbanwe.",
    so: "Fadlan, nala soo xiriir haddii isbedel jiro.",
    ha: "Don Allah, tuntube mu idan akwai canje-canje.",
    mn: "Хэрэв өөрчлөлт гарах юм бол бидэнтэй холбогдоорой.",
    ff: "Don Allah, tuntube mu idan akwai canje-canje.",
    yo: "Jọwọ kan si wa ti awọn ayipada ba wa.",
  },
  "Please select the server you want to migrate": {
    en: "Please select the server you want to migrate from and the new server you want to migrate to!",
    pt: "Por favor, selecione o servidor de origem e o novo servidor para migrar!",
    de: "Bitte wählen Sie den Server aus, von dem Sie migrieren möchten, und den neuen Server, zu dem Sie migrieren möchten!",
    es: "Por favor, seleccione el servidor del que desea migrar y el nuevo servidor al que desea migrar.",
    fr: "Veuillez sélectionner le serveur à partir duquel vous souhaitez migrer et le nouveau serveur vers lequel vous souhaitez migrer !",
    it: "Per favore, seleziona il server da cui vuoi migrare e il nuovo server in cui vuoi migrare!",
    sq: "Ju lutemi zgjidhni serverin nga të cilit dëshironi të migratoni dhe serverin e ri ku dëshironi të migroni!",
    tr: "Lütfen taşımak istediğiniz sunucuyu seçin ve taşımak istediğiniz yeni sunucuyu seçin!",
    sw: "Tafadhali chagua seva unayotaka kuhamasisha kutoka na seva mpya unayotaka kuhamasisha!",
    am: "እባክዎን ከሚለው ወደ አዲስ ምንጭ መዝገብ ይምረጡ!",
    om: "Maaloo, eessa akka geessu fi tajaajila haaraa akka filattan filadhu!",
    ig: "Biko họrọ sava ị chọrọ ịkwaga site na ya na sava ọhụrụ ị chọrọ ịkwaga na ya!",
    so: "Fadlan dooro server-ka aad ka guureyso iyo server cusub oo aad ka guurayso!",
    ha: "Don Allah zaɓi sabar da kake son ƙaura daga gare ta da sabon sabar da kake son ƙaura zuwa!",
    mn: "Ашиглах серверийг сонгож, шинэ сервер рүү шилжүүлэхийг хүсч буйгаа сонгоно уу!",
    ff: "Don Allah zaɓi sabar da kake son ƙaura daga gare ta da sabon sabar da kake son ƙaura zuwa!",
    yo: "Jọwọ yan olupin ti o fẹ lati gbe lati ati olupin tuntun ti o fẹ lati gbe si!",
  },
  "Please enter the Device key": {
    en: "Please enter the Device key. It is shown in the bottom left corner of the device.",
    pt: "Por favor, insira a chave do dispositivo. Ela é mostrada no canto inferior esquerdo do dispositivo.",
    de: "Bitte geben Sie den Geräteschlüssel ein. Er wird unten links auf dem Gerät angezeigt.",
    es: "Por favor, ingrese la clave del dispositivo. Se muestra en la esquina inferior izquierda del dispositivo.",
    fr: "Veuillez entrer la clé de l'appareil. Elle est affichée dans le coin inférieur gauche de l'appareil.",
    it: "Per favore, inserisci la chiave del dispositivo. È mostrata nell'angolo in basso a sinistra del dispositivo.",
    sq: "Ju lutemi vendosni çelësin e pajisjes. Ai shfaqet në cepin e majtë të fundit të pajisjes.",
    tr: "Lütfen Aygıt anahtarını girin. Aygıtın sol alt köşesinde gösterilmektedir.",
    sw: "Tafadhali ingiza funguo la Kifaa. Inaonyeshwa kwenye kona ya chini kushoto ya kifaa.",
    am: "እባክዎን የመሳሪያ ቁልፍ ይግቡ። በመሳሪያው በማታ ቀኝ ማእከል ላይ ይታያል።",
    om: "Maaloo furmaata meeshaa galchaa. Tajaajila alaabaa yeroo xiqqoo gara mirgaa gaditti mul’ata.",
    ig: "Biko tinye igodo Ngwa. A na-egosi ya na nkuku aka ekpe nke ngwa.",
    so: "Fadlan geli furaha qalabka. Waxa lagu muujiyay geeska hoose ee bidix ee qalabka.",
    ha: "Don Allah shigar da maɓallin Na'ura. Ana nuna shi a ƙasan hagu na na'urar.",
    mn: "Төхөөрөмжийн түлхүүрийг оруулна уу. Төхөөрөмжийн зүүн доод буланд харуулдаг.",
    ff: "Don Allah shigar da maɓallin Na'ura. Ana nuna shi a ƙasan hagu na na'urar.",
    yo: "Jọwọ tẹ bọtini ẹrọ. O han ni igun isalẹ osi ti ẹrọ naa.",
  },

  "Please input": {
    en: "Please input",
    pt: "Por favor, insira",
    de: "Bitte eingeben",
    es: "Por favor, ingrese",
    fr: "Veuillez entrer",
    it: "Per favore, inserire",
    sq: "Ju lutemi vendosni",
    tr: "Lütfen girin",
    sw: "Tafadhali ingiza",
    am: "እባክዎን ይምረጡ",
    om: "Maaloo galchaa",
    ig: "Biko tinye",
    so: "Fadlan geli",
    ha: "Don Allah shigar",
    mn: "Бидэнд оруулна уу",
    ff: "Don Allah shigar",
    yo: "Jọwọ tẹ",
  },
  Payments: {
    en: "Payments",
    pt: "Pagamentos",
    de: "Zahlungen",
    es: "Pagos",
    fr: "Paiements",
    it: "Pagamenti",
    sq: "Pagesat",
    tr: "Ödemeler",
    sw: "Malipo",
    am: "ክፍያዎች",
    om: "Bittaa",
    ig: "Ụgwọ",
    so: "Lacag bixinta",
    ha: "Biyan kuɗi",
    mn: "Төлбөрүүд",
    ff: "Biyan kuɗi",
    yo: "Sanwo",
  },
  "Pay with BTC": {
    en: "Pay with BTC",
    pt: "Pagar com BTC",
    de: "Mit BTC bezahlen",
    es: "Pagar con BTC",
    fr: "Payer avec BTC",
    it: "Paga con BTC",
    sq: "Paguaj me BTC",
    tr: "BTC ile öde",
    sw: "Lipa kwa BTC",
    am: "በBTC ይከፍሉ",
    om: "BTC waliin kaffaluu",
    ig: "Kwụrụ na BTC",
    so: "Bixiya BTC",
    ha: "Biya tare da BTC",
    mn: "BTC-ээр төл",
    ff: "Biya tare da BTC",
    yo: "Sanwo pẹlu BTC",
  },
  "Pay with Coinbase": {
    en: "Pay with Coinbase",
    pt: "Pagar com Coinbase",
    de: "Mit Coinbase bezahlen",
    es: "Pagar con Coinbase",
    fr: "Payer avec Coinbase",
    it: "Paga con Coinbase",
    sq: "Paguaj me Coinbase",
    tr: "Coinbase ile öde",
    sw: "Lipa kwa Coinbase",
    am: "በCoinbase ይከፍሉ",
    om: "Coinbase waliin kaffaluu",
    ig: "Kwụrụ na Coinbase",
    so: "Bixiya Coinbase",
    ha: "Biya tare da Coinbase",
    mn: "Coinbase-ээр төл",
    ff: "Biya tare da Coinbase",
    yo: "Sanwo pẹlu Coinbase",
  },
  "Pay with PIX": {
    en: "Pay with PIX",
    pt: "Pagar com PIX",
    de: "Zahlen Sie mit PIX",
    es: "Pagar con PIX",
    fr: "Payer avec PIX",
    it: "Paga con PIX",
    sq: "Paguaj me PIX",
    tr: "PIX ile öde",
    sw: "Lipa kwa PIX",
    am: "በPIX ይከፍሉ",
    om: "PIX waliin kaffaluu",
    ig: "Kwụrụ na PIX",
    so: "Bixiya PIX",
    ha: "Biya tare da PIX",
    mn: "PIX-ээр төл",
    ff: "Biya tare da PIX",
    yo: "Sanwo pẹlu PIX",
  },
  "Pay with PIX card": {
    en: "Pay with PIX card",
    pt: "Pagar com cartão PIX",
    de: "Mit PIX-Karte bezahlen",
    es: "Pagar con tarjeta PIX",
    fr: "Payer avec la carte PIX",
    it: "Paga con la carta PIX",
    sq: "Paguaj me kartën PIX",
    tr: "PIX kart ile öde",
    sw: "Lipa kwa kadi ya PIX",
    am: "በPIX ካርድ ይከፍሉ",
    om: "Kaarra PIX waliin kaffaluu",
    ig: "Kwụrụ na kaadị PIX",
    so: "Bixiya kaarka PIX",
    ha: "Biya tare da kati PIX",
    mn: "PIX карттай төл",
    ff: "Biya tare da kati PIX",
    yo: "Sanwo pẹlu kaadi PIX",
  },
  "Paymant method not avalible now": {
    en: "Paymant method not avalible now",
    pt: "Método de pagamento não disponível agora",
    de: "Zahlungsmethode jetzt nicht verfügbar",
    es: "Método de pago no disponible ahora",
    fr: "Méthode de paiement non disponible maintenant",
    it: "Metodo di pagamento non disponibile ora",
    sq: "Metoda e pagesës nuk është e disponueshme tani",
    tr: "Ödeme yöntemi şu anda mevcut değil",
    sw: "Njia ya malipo haipatikani sasa",
    am: "የክፍያ ዘዴ አሁን አይገኝም",
    om: "Hojii kaffaltii amma hin argamne",
    ig: "Usoro ịkwụ ụgwọ adịghị na ugbu a",
    so: "Habka lacag bixinta ma jiro hadda",
    ha: "Hanyar biyan kuɗi ba ta samuwa yanzu",
    mn: "Төлбөрийн арга одоогоор байхгүй байна",
    ff: "Hanyar biyan kuɗi ba ta samuwa yanzu",
    yo: "Ọna isanwo ko si lọwọlọwọ",
  },
  Pay: {
    en: "Pay",
    pt: "Pagar",
    de: "Bezahlen",
    es: "Pagar",
    fr: "Payer",
    it: "Pagare",
    sq: "Paguaj",
    tr: "Öde",
    sw: "Lipa",
    am: "ይከፍሉ",
    om: "Kaffalu",
    ig: "Kwụọ",
    so: "Bixi",
    ha: "Biaya",
    mn: "Төл",
    ff: "Biaya",
    yo: "Sanwo",
  },
  Poster: {
    en: "Poster",
    pt: "Pôster",
    de: "Plakat",
    es: "Póster",
    fr: "Affiche",
    it: "Poster",
    sq: "Poster",
    tr: "Afiş",
    sw: "Bango",
    am: "የመጣለቂያ",
    om: "Bitaa",
    ig: "Pọst",
    so: "Bangal",
    ha: "Pota",
    mn: "Постер",
    ff: "Pota",
    yo: "Afis",
  },
  "Privacy policy": {
    en: "Privacy policy",
    pt: "Política de privacidade",
    de: "Datenschutzbestimmungen",
    es: "Política de privacidad",
    fr: "Politique de confidentialité",
    it: "Informativa sulla privacy",
    sq: "Politika e privatësisë",
    tr: "Gizlilik politikası",
    sw: "Sera ya faragha",
    am: "የዕለታዊ የአስተያየት ፖሊሲ",
    om: "Seera dhoksaa",
    ig: "Iwu nzuzo",
    so: "Sharciyadda khaaska ah",
    ha: "Ka'idojin sirrin",
    mn: "Нууцлалын бодлого",
    ff: "Ka'idojin sirrin",
    yo: "Ilana ìpamọ́",
  },

  //t
  "Thank you for trying our service! If you enjoyed your experience and want to continue accessing all premium features, please contact us for more information on our subscription plans. We're here to help you make the most of your IPTV experience":
    {
      en: "Thank you for trying our service! If you enjoyed your experience and want to continue accessing all premium features, please contact us for more information on our subscription plans. We're here to help you make the most of your IPTV experience.",
      pt: "Obrigado por experimentar o nosso serviço! Se você gostou da sua experiência e deseja continuar acessando todos os recursos premium, entre em contato conosco para mais informações sobre nossos planos de assinatura. Estamos aqui para ajudá-lo a aproveitar ao máximo sua experiência com IPTV.",
      de: "Vielen Dank, dass Sie unseren Service ausprobiert haben! Wenn Sie Ihre Erfahrung genossen haben und weiterhin auf alle Premium-Funktionen zugreifen möchten, kontaktieren Sie uns bitte für weitere Informationen zu unseren Abonnementplänen. Wir sind hier, um Ihnen zu helfen, das Beste aus Ihrer IPTV-Erfahrung herauszuholen.",
      es: "¡Gracias por probar nuestro servicio! Si disfrutó su experiencia y desea continuar accediendo a todas las funciones premium, contáctenos para obtener más información sobre nuestros planes de suscripción. Estamos aquí para ayudarle a aprovechar al máximo su experiencia de IPTV.",
      fr: "Merci d'avoir essayé notre service ! Si vous avez apprécié votre expérience et souhaitez continuer à accéder à toutes les fonctionnalités premium, veuillez nous contacter pour plus d'informations sur nos plans d'abonnement. Nous sommes là pour vous aider à tirer le meilleur parti de votre expérience IPTV.",
      it: "Grazie per aver provato il nostro servizio! Se ti è piaciuta la tua esperienza e vuoi continuare ad accedere a tutte le funzionalità premium, contattaci per ulteriori informazioni sui nostri piani di abbonamento. Siamo qui per aiutarti a sfruttare al massimo la tua esperienza IPTV.",
      sq: "Faleminderit që provuat shërbimin tonë! Nëse keni shijuar përvojën tuaj dhe dëshironi të vazhdoni të keni qasje në të gjitha veçoritë premium, ju lutemi na kontaktoni për më shumë informacion mbi planet tona të abonimit. Ne jemi këtu për t'ju ndihmuar të përfitoni sa më shumë nga përvoja juaj IPTV.",
      tr: "Hizmetimizi denediğiniz için teşekkür ederiz! Deneyiminizi beğendiyseniz ve tüm premium özelliklere erişmeye devam etmek istiyorsanız, lütfen abonelik planlarımız hakkında daha fazla bilgi için bizimle iletişime geçin. IPTV deneyiminizden en iyi şekilde yararlanmanıza yardımcı olmak için buradayız.",
      sw: "Asante kwa kujaribu huduma zetu! Ikiwa umefurahia uzoefu wako na unataka kuendelea kupata vipengele vyote vya premium, tafadhali wasiliana nasi kwa maelezo zaidi kuhusu mipango yetu ya usajili. Tuko hapa kusaidia kuhakikisha unapata faida zaidi ya uzoefu wako wa IPTV.",
      am: "አገልግሎታችንን ለማሞከር እናመሰግናለን! የእርስዎን ምርጥ ሙከራ የተደረገ ከሆነ እና ሁሉንም የምርጥ አስተያየቶች ለመቀጠል የተወዳጅ ምንጮችን ለመረጃ እባኮትን እንክትና ይደውሉን። እኛም እንደነው ለIPTV ሙከራዎ የተለየ ዕድል ለማስፈን እንደነን እንወዳድር ይሆናል።",
      om: "Takkuma tajaajila keenyaa yaada! Yoo muuxannoo keetii gammachuu argatte, akkasumas amaloota premium hunda argachuuf itti fufuuf, gaaffiilee dabalataa ta'aniif nu quunnamaa. Muuxannoo IPTV keessan keessa faayidaa guddaa argachuuf isin gargaaruuf as jirra.",
      ig: "Daalụ maka inwe ọrụ anyị! Ọ bụrụ na ị nweghị ike ịhụ ahụmịhe gị ma chọọ ka ịga n'ihu na-enweta atụmatụ niile a na-eme, biko kpọtụrụ anyị maka ozi ndị ọzọ gbasara atụmatụ onyinye anyị. Anyị nọ ebe a iji nyere gị aka na ịme ka ị nwee ike ịme ka IPTV gị bụrụ ihe kachasị mma.",
      so: "Waad ku mahadsan tahay inaad tijaabiso adeeggeenna! Haddii aad ka heshay waayo-aragnimadaada oo aad rabto inaad sii waddo inaad gasho dhammaan sifooyinka lacagta ah, fadlan nala soo xiriir si aad u hesho macluumaad dheeraad ah oo ku saabsan qorsheyaashayaga rukumka. Waxaan halkan u joognaa inaan ku caawino inaad ka faa'iidaysato waayo-aragnimadaada IPTV.",
      ha: "Mun gode da gwada sabis ɗinmu! Idan kun ji dadin ƙwarewarka ku kuma kuna son ci gaba da samun duk ƙarin fasaloli, don Allah ku tuntube mu don samun ƙarin bayani akan shirye-shiryen mu na rajista. Muna nan don taimaka muku ku more ƙwarewar IPTV ɗinku.",
      mn: "Манай үйлчилгээг туршсанд баярлалаа! Хэрэв та туршлагаасаа таашаал авсан бол бүх Premium шинж чанарт нэвтрэхийг хүсвэл манай захиалгын төлөвлөгөөнүүдийн талаар илүү их мэдээлэл авахын тулд бидэнтэй холбоо барина уу. IPTV туршлагаа аль болох үр дүнтэй болгоход туслахын тулд бид энд байна.",
      ff: "Mun gode da gwada sabis ɗinmu! Idan kun ji dadin kwarewarku kuma kuna son ci gaba da samun dukkan fasaloli na inganci, don Allah ku tuntube mu don ƙarin bayani akan shirye-shiryenmu na rajista. Muna nan don taimaka muku don samun fa'ida daga ƙwarewar IPTV ɗin ku.",
      yo: "O ṣeun fun igbiyanju iṣẹ wa! Ti o ba ni igbadun iriri rẹ ati pe o fẹ lati tẹsiwaju lati wọle si gbogbo awọn ẹya ere, jọwọ kan si wa fun alaye diẹ sii lori awọn eto gbigba. A wa nibi lati ran ọ lọwọ lati ṣe ohun ti o dara julọ lati iriri IPTV rẹ.",
    },
  "Thank you for your prompt attention": {
    en: "Thank you for your prompt attention",
    pt: "Obrigado pela sua pronta atenção",
    de: "Danke für Ihre umgehende Aufmerksamkeit",
    es: "Gracias por su pronta atención",
    fr: "Merci pour votre attention rapide",
    it: "Grazie per la tua pronta attenzione",
    sq: "Faleminderit për vëmendjen tuaj të shpejtë",
    tr: "İlginiz için teşekkür ederim",
    sw: "Asante kwa umakini wako wa haraka",
    am: "ለፈጣን ግንዛቤዎ እናመሰግናለን",
    om: "Keenya xiyyeeffannoo siif galatoomaa",
    ig: "Daalụ maka ịtụle ngwa ngwa",
    so: "Waad ku mahadsan tahay fiiro degdeg ah",
    ha: "Na gode da kulawarku mai sauri",
    mn: "Таны хурдан анхааралд баярлалаа",
    ff: "Na gode da sauri",
    yo: "O ṣeun fun akiyesi rẹ lẹsẹkẹsẹ",
  },
  "to remove seller status": {
    en: "to remove seller status",
    pt: "remover o status de vendedor",
    de: "den Verkäuferstatus entfernen",
    es: "eliminar el estado de vendedor",
    fr: "retirer le statut de vendeur",
    it: "rimuovere lo status di venditore",
    sq: "për të hequr statusin e shitësit",
    tr: "satıcı statüsünü kaldırmak",
    sw: "kuondoa hadhi ya muuzaji",
    am: "የንግድ ስሌት ለማስወግድ",
    om: "gammachuu dhiibaa",
    ig: "iji nkwupụta onye na-ere ahịa",
    so: "si aad u saarto xaaladda iibiyaha",
    ha: "don cire matsayin mai sayarwa",
    mn: "Зарлагчийн статусыг арилгах",
    ff: "Don cire matsayin mai sayarwa",
    yo: "lati yọ ipo olutaja",
  },
  "The dimensions of the file are": {
    en: "The dimensions of the file are",
    es: "Las dimensiones del archivo son",
    fr: "Les dimensions du fichier sont",
    de: "Die Abmessungen der Datei sind",
    pt: "As dimensões do arquivo são",
    it: "Le dimensioni del file sono",
    sq: "Përmasat e skedarit janë",
    tr: "Dosyanın boyutları şunlardır",
    sw: "Vipimo vya faili ni",
    am: "የፋይሉ መጠን እነዚህ ናቸው",
    om: "Faayilii gadii",
    ig: "Nnukwu faịlụ bụ",
    so: "Cabirka faylku waa",
    ha: "Girman fayil ɗin shine",
    mn: "Файлын хэмжээс нь",
    ff: "Girman fayil ɗin shine",
    yo: "Iwọn faili naa ni",
  },

  "Terms of usage": {
    en: "Terms of usage",
    pt: "Termos de uso",
    de: "Nutzungsbedingungen",
    es: "Términos de uso",
    fr: "Conditions d'utilisation",
    it: "Termini di utilizzo",
    sq: "Kushtet e përdorimit",
    tr: "Kullanım şartları",
    sw: "Masharti ya matumizi",
    am: "የእንቅስቃሴ የመረጃ ሂደት",
    om: "Shirkoo waliin",
    ig: "Okwu nke ojiji",
    so: "Shuruudaha isticmaalka",
    ha: "Sharuɗɗan amfani",
    mn: "Хэрэглээний нөхцөл",
    ff: "Sharuɗɗan amfani",
    yo: "Awọn ipo lilo",
  },

  "Total free trial and active": {
    en: "Total free trial and active",
    pt: "Total de teste grátis e ativo",
    de: "Gesamte kostenlose Testversion und aktiv",
    es: "Total de prueba gratuita y activo",
    fr: "Essai gratuit total et actif",
    it: "Totale prova gratuita e attiva",
    sq: "Totali i provave të lira dhe aktiv",
    tr: "Toplam ücretsiz deneme ve aktif",
    sw: "Jumla ya jaribio la bure na kazi",
    am: "ጠቅላላ ነፃ ሙከራ እና እንቅስቃሴ",
    om: "Gabaabaa irraa fageessuun",
    ig: "Nnyocha efu na-arụ ọrụ",
    so: "Tijaabo bilaash ah oo firfircoon",
    ha: "Jimlar gwaji kyauta da aiki",
    mn: "Нийт үнэгүй туршилт ба идэвхтэй",
    ff: "Jimlar gwaji kyauta da aiki",
    yo: "Igbeyewo ọfẹ lapapọ ati lọwọlọwọ",
  },

  "This platform is currently unavailable to you because your partner has not subscribed yet. If you want to access this platform, please reach out to your partner":
    {
      en: "This platform is currently unavailable to you because your partner has not subscribed yet. If you want to access this platform, please reach out to your partner.",
      pt: "Esta plataforma está atualmente indisponível para você porque seu parceiro ainda não se inscreveu. Se você deseja acessar esta plataforma, por favor, entre em contato com seu parceiro.",
      de: "Diese Plattform steht Ihnen derzeit nicht zur Verfügung, da Ihr Partner sich noch nicht angemeldet hat. Wenn Sie auf diese Plattform zugreifen möchten, setzen Sie sich bitte mit Ihrem Partner in Verbindung.",
      es: "Esta plataforma no está disponible actualmente para ti porque tu compañero aún no se ha suscrito. Si deseas acceder a esta plataforma, por favor, ponte en contacto con tu compañero.",
      fr: "Cette plateforme n'est actuellement pas disponible pour vous car votre partenaire ne s'est pas encore abonné. Si vous souhaitez accéder à cette plateforme, veuillez contacter votre partenaire.",
      it: "Questa piattaforma non è attualmente disponibile per te perché il tuo partner non si è ancora iscritto. Se desideri accedere a questa piattaforma, per favore, contatta il tuo partner.",
      sq: "Ky platformë është momentalisht e padisponueshme për ju sepse partneri juaj ende nuk është abonuar. Nëse dëshironi të hyni në këtë platformë, ju lutemi kontaktoni partnerin tuaj.",
      tr: "Bu platform şu anda size uygun değil çünkü ortağınız henüz abone olmadı. Bu platforma erişmek istiyorsanız, lütfen ortağınıza ulaşın.",
      sw: "Jukwaa hili kwa sasa halipatikani kwako kwa sababu mshirika wako bado haja jiandikisha. Ikiwa unataka kufikia jukwaa hili, tafadhali wasiliana na mshirika wako.",
      am: "ይህ የመድረክ በአሁኑ ጊዜ ለእንቅስቃሴ ይገኛል እና ወንድምዎ አሁን አይወዳደር ምክንያት ይህ ይታያል። ይህ የመድረክ እንዲደርስ እባክህ ወንድምዎን ይገናኙ።",
      om: "Biyyi kunoo kan dandeetti hin qabneef sababa hidhata baafataa hin barsiisneef. Yoo biyyi kana argachuuf barbaadde, maatii si waliin qabaadhu.",
      ig: "Platform a na-emekarị ugbu a n'ihi na onye gị na ya kwụsịrị itinye. Ọ bụrụ na ịchọrọ ịnweta ikpo okwu a, biko soro onye gị na ya kpọtụrụ.",
      so: "Madaama lamaanadaaduu weliba loo qiimo ah ayaa akuraan ahin la leh. Haddii aad rabto inaad gasho madalkan, fadlan la xidhiidh lamaanadaada.",
      ha: "Wannan dandalin yana samuwa a halin yanzu saboda abokin harka ba ya rajista tukuna. Idan kuna son samun damar wannan dandalin, don Allah ku tuntubi abokin harka.",
      mn: "Энэ платформ одоогоор танд хүрэхгүй байна, учир нь таны түнш бүртгүүлээгүй байна. Хэрэв та энэ платформд нэвтрэхийг хүсвэл түнштэйгээ холбоо барина уу.",
      ff: "Wannan dandalin ba ya samuwa a yanzu saboda abokin ciniki ba ya rajistar. Idan kuna so ku shiga wannan dandalin, don Allah ku tuntubi abokin harka.",
      yo: "Ile-iṣẹ yii ko si lọwọlọwọ nitori alabaṣiṣẹpọ rẹ ko ti forukọsilẹ. Ti o ba fẹ wọle si ile-iṣẹ yii, jọwọ kan si alabaṣiṣẹpọ rẹ.",
    },

  Type: {
    en: "Type",
    pt: "Tipo",
    de: "Typ",
    es: "Tipo",
    fr: "Type",
    it: "Tipo",
    sq: "Lloji",
    tr: "Tür",
    sw: "Aina",
    am: "አይነት",
    om: "Gosa",
    ig: "Ụdị",
    so: "Nooca",
    ha: "Nau'in",
    mn: "Төрөл",
    ff: "Nau'in",
    yo: "Iru",
  },

  Title: {
    en: "Title",
    pt: "Título",
    de: "Titel",
    es: "Título",
    fr: "Titre",
    it: "Titolo",
    sq: "Titulli",
    tr: "Başlık",
    sw: "Kichwa",
    am: "ርዕስ",
    om: "Mata duree",
    ig: "Aha",
    so: "Ciwaanka",
    ha: "Suna",
    mn: "Гарчиг",
    ff: "Suna",
    yo: "Akole",
  },

  Total: {
    en: "Total",
    pt: "Total",
    de: "Gesamt",
    es: "Total",
    fr: "Total",
    it: "Totale",
    sq: "Totali",
    tr: "Toplam",
    sw: "Jumla",
    am: "ጠቅላላ",
    om: "Guutuu",
    ig: "Nọmba niile",
    so: "Wadarta",
    ha: "Jimla",
    mn: "Нийт",
    ff: "Jimla",
    yo: "Lapapọ",
  },

  Trial: {
    en: "Trial",
    pt: "Teste",
    de: "Test",
    es: "Prueba",
    fr: "Essai",
    it: "Prova",
    sq: "Provë",
    tr: "Deneme",
    sw: "Jaribio",
    am: "ሙከራ",
    om: "Qorannoo",
    ig: "Nyocha",
    so: "Tijaabo",
    ha: "Gwaji",
    mn: "Туршилт",
    ff: "Gwaji",
    yo: "Igbeyewo",
  },
  "Top Movies": {
    en: "Top Movies",
    pt: "Melhores Filmes",
    de: "Top-Filme",
    es: "Películas Populares",
    fr: "Meilleurs Films",
    it: "I Migliori Film",
    sq: "Filmat më të Mirë",
    tr: "En İyi Filmler",
    sw: "Filamu Bora",
    am: "ከፍተኛ ፊልም",
    om: "Fiilmii Ol'aanaa",
    ig: "Filmmụ kachasị elu",
    so: "Filimada Ugu Sarreeya",
    ha: "Fim ɗin sama",
    mn: "Топ кинонууд",
    ff: "Fim ɗin sama",
    yo: "Awon Fim Pẹlu Top",
  },

  "Top Lives": {
    en: "Top Lives",
    pt: "Melhores Canais",
    de: "Top-Lives",
    es: "Vidas Destacadas",
    fr: "Meilleures Lives",
    it: "Live Top",
    sq: "Lives më të Mirë",
    tr: "En İyi Yayınlar",
    sw: "Maisha Bora",
    am: "ከፍተኛ የእውነት",
    om: "Jiraatoo Ol'aanaa",
    ig: "Ndụ kachasị elu",
    so: "Nolosha Ugu Sarreeya",
    ha: "Rayuwa sama",
    mn: "Топ амьдрал",
    ff: "Rayuwa sama",
    yo: "Awọn Igbesi aye Ti o ga julọ",
  },

  "Top TV Shows": {
    en: "Top TV Shows",
    pt: "Melhores Séries",
    de: "Top-Fernsehsendungen",
    es: "Mejores Programas de TV",
    fr: "Meilleures Émissions de Télévision",
    it: "Migliori Programmi TV",
    sq: "Shfaqje televizive më të Mirë",
    tr: "En İyi TV Şovları",
    sw: "Vipindi Bora vya Televisheni",
    am: "ከፍተኛ የቴሌቪዚየን ስርጭቶች",
    om: "Tashooma TV Ol'aanaa",
    ig: "Nkọwa TV kachasị elu",
    so: "Barnaamijyada TV Ugu Sarreeya",
    ha: "Shirin TV sama",
    mn: "Топ телевизийн шоу",
    ff: "Shirin TV sama",
    yo: "Awọn Show TV Ti o ga julọ",
  },

  "Take Credits": {
    en: "Take Credits",
    pt: "Adquirir Créditos",
    de: "Credits Erwerben",
    es: "Obtener Créditos",
    fr: "Obtenir des Crédits",
    it: "Acquista Crediti",
    sq: "Merr Kredite",
    tr: "Kredi Al",
    sw: "Pokea Mikopo",
    am: "ክሬዲት ይይዙ",
    om: "Kireeditii fudhachuu",
    ig: "Nwere Kredi",
    so: "Qaado Kharash",
    ha: "Karba Kudi",
    mn: "Кредит авах",
    ff: "Karba Kudi",
    yo: "Gba Awọn Kirediti",
  },

  "Transaction ID": {
    en: "Transaction ID",
    pt: "ID da Transação",
    de: "Transaktions-ID",
    es: "ID de Transacción",
    fr: "ID de Transaction",
    it: "ID Transazione",
    sq: "ID e Transaksionit",
    tr: "İşlem Kimliği",
    sw: "Nambari ya Muamala",
    am: "የግብይት መለያ",
    om: "ID Gurguddaa",
    ig: "ID ahịa",
    so: "Aqoonsiga Traansakshanka",
    ha: "ID na'am",
    mn: "Гүйлгээний ID",
    ff: "ID na'am",
    yo: "ID iṣowo",
  },

  "Total activations": {
    en: "Total activations",
    pt: "Ativações Totais",
    de: "Gesamtaktivierungen",
    es: "Activaciones Totales",
    fr: "Activations Totales",
    it: "Attivazioni Totali",
    sq: "Aktivizime totale",
    tr: "Toplam Aktivasyon",
    sw: "Jumla ya Uwezeshaji",
    am: "ጠቅላላ አስተዳደሮች",
    om: "Gurguddaa Guutuu",
    ig: "Nchịkọta Activations",
    so: "Wadarta Hawlgallada",
    ha: "Jimlar sabunta",
    mn: "Нийт идэвхжүүлэлт",
    ff: "Jimlar sabunta",
    yo: "Iye ti awọn iṣiṣẹ",
  },
  "Trial expired": {
    en: "Trial expired",
    pt: "Período de teste expirado",
    de: "Testphase abgelaufen",
    es: "Prueba expirada",
    fr: "Essai expiré",
    it: "Prova scaduta",
    sq: "Prova ka skaduar",
    tr: "Deneme süresi dolmuş",
    sw: "Jaribio Limeisha",
    am: "ሙከራ ይቆርጣል",
    om: "Qorannoo Cufame",
    ig: "Nyocha nwụrụ anwụ",
    so: "Tijaabo ayaa dhammaatay",
    ha: "Gwaji ya ƙare",
    mn: "Туршилт дууссан",
    ff: "Gwaji ya ƙare",
    yo: "Igbeyewo ti pari",
  },

  "Trial remaining days": {
    en: "Trial remaining days",
    pt: "Dias de teste restantes",
    de: "Verbleibende Testtage",
    es: "Días de Prueba Restantes",
    fr: "Jours d'Essai Restants",
    it: "Giorni di Prova Rimasti",
    sq: "Ditët e mbetura të provës",
    tr: "Kalan deneme günleri",
    sw: "Siku Zilizobaki za Jaribio",
    am: "የቀሪ ቀን ሙከራ",
    om: "Guyyaa Kafalti Hafe",
    ig: "Ụbọchị fọdụrụ na Nyocha",
    so: "Maalmo ka harsan tijaabo",
    ha: "Rana masu yawa na gwaji",
    mn: "Туршилтын үлдэгдэл өдрүүд",
    ff: "Rana masu yawa na gwaji",
    yo: "Ọjọ to ku fun Igbeyewo",
  },

  "That's not a valid URL": {
    en: "That's not a valid URL",
    pt: "Isso não é uma URL válida",
    de: "Das ist keine gültige URL",
    es: "Esa no es una URL válida",
    fr: "Ce n'est pas une URL valide",
    it: "Questa non è un'URL valida",
    sq: "Ky nuk është një URL e vlefshme",
    tr: "Bu geçerli bir URL değil",
    sw: "Hiyo si URL halali",
    am: "ይህ የታወቀ አይነት አይደለም",
    om: "Kun URL sirrii miti",
    ig: "Nke a abụghị URL ziri ezi",
    so: "Tani maaha URL sax ah",
    ha: "Wannan ba URL mai inganci bane",
    mn: "Энэ зөв URL биш",
    ff: "Wannan ba URL mai inganci bane",
    yo: "Eyi kii ṣe URL to wulo",
  },
  "The URL is not reachable": {
    en: "The URL is not reachable.",
    pt: "A URL não está acessível.",
    de: "Die URL ist nicht erreichbar.",
    es: "La URL no es accesible.",
    fr: "L'URL n'est pas accessible.",
    it: "L'URL non è raggiungibile.",
    sq: "URL nuk është i arritshëm.",
    tr: "URL ulaşılamıyor.",
    sw: "URL haiwezi kupatikana.",
    am: "የይንታ አሁን አለመቻል",
    om: "URL'n kun hin ga'amu.",
    ig: "URL a na-enwetaghị.",
    so: "URL ma ga awoodin.",
    ha: "URL ɗin ba ya samuwa.",
    mn: "URL хүрч болохгүй.",
    ff: "URL ɗin ba ya samuwa.",
    yo: "URL naa ko ni wa ni irọrun.",
  },

  "That's not a valid host": {
    en: "That's not a valid host",
    pt: "Isso não é um host válido",
    de: "Das ist kein gültiger Host",
    es: "Eso no es un host válido",
    fr: "Ce n'est pas un hôte valide",
    it: "Questo non è un host valido",
    sq: "Ky nuk është një host i vlefshëm",
    tr: "Bu geçerli bir host değil.",
    sw: "Hiyo si mwenyeji halali",
    am: "ይህ የታወቀ አይነት አይደለም",
    om: "Kun host sirrii miti",
    ig: "Nke a abụghị ụlọ host ziri ezi",
    so: "Tani maaha marti sax ah",
    ha: "Wannan ba mai masauki mai inganci bane",
    mn: "Энэ зөв хост биш",
    ff: "Wannan ba mai masauki mai inganci bane",
    yo: "Eyi kii ṣe olugbale to wulo",
  },

  "TV Shows Categories": {
    en: "TV Shows Categories",
    pt: "Categorias de Séries",
    de: "TV-Sendungskategorien",
    es: "Categorías de Programas de TV",
    fr: "Catégories d'Émissions de Télévision",
    it: "Categorie di Programmi TV",
    sq: "Kategoritë e emisioneve televizive",
    tr: "TV Şovları Kategorileri",
    sw: "Kategoria za kipindi vya Televisheni",
    am: "የቴሌቪዚየን ስርጭቶች ክፍሎች",
    om: "Kutaa Tashooma TV",
    ig: "Nkatọ TV Shows",
    so: "Qaybaha Barnaamijyada TV",
    ha: "Rukunin shirye-shiryen talabijin",
    mn: "Телевизийн шоунуудын ангилал",
    ff: "Rukunin shirye-shiryen talabijin",
    yo: "Awọn ẹka TV Shows",
  },
  "There are 2 types of activation": {
    en: "There are 2 types of activation:",
    pt: "Existem 2 tipos de ativação:",
    de: "Es gibt 2 Arten der Aktivierung:",
    es: "Hay 2 tipos de activación:",
    fr: "Il y a 2 types d'activation :",
    it: "Ci sono 2 tipi di attivazione:",
    sq: "Ka 2 lloje të aktivizimit:",
    tr: "2 tür aktivasyon vardır:",
    sw: "Kuna aina 2 za uanzishaji:",
    am: "የአስተዳደር አይነት 2 አለ፡፡",
    om: "Ulaa 2 gurguddaa jira:",
    ig: "E nwere ụdị 2 nke ịmalite:",
    so: "Waxaa jira 2 nooc oo hawlgelin ah:",
    ha: "Akwai nau'i 2 na sabunta:",
    mn: "Идэвхжүүлэлтийн 2 төрлүүд байна:",
    ff: "Akwai nau'i 2 na sabunta:",
    yo: "Awọn iru 2 ti iṣiṣẹ wa:",
  },

  "The two passwords that you entered do not match": {
    en: "The two passwords that you entered do not match!",
    pt: "As duas senhas que você digitou não coincidem!",
    de: "Die beiden Passwörter, die Sie eingegeben haben, stimmen nicht überein!",
    es: "Las dos contraseñas que ingresó no coinciden!",
    fr: "Les deux mots de passe que vous avez saisis ne correspondent pas !",
    it: "Le due password che hai inserito non corrispondono!",
    sq: "Dy fjalëkalimet që keni shkruar nuk përputhen!",
    tr: "Girdiğiniz iki şifre eşleşmiyor!",
    sw: "Maneno mawili ya siri uliyoyaingiza hayafanani!",
    am: "የገባነው የይለፍ ቁልፍ ሁለቱ ይቅርታ አይለይም!",
    om: "Takkala lameen galchitan wal hin dhaqqabne!",
    ig: "Okwu abụọ ị debara adịghị adịghị!",
    so: "Labaad ee sirta aad gelisay isku mid ma aha!",
    ha: "Kalmar sirri biyu da kuka shigar ba su dace ba!",
    mn: "Та оруулсан хоёр нууц үг нийцэхгүй байна!",
    ff: "Kalmar sirri biyu da kuka shigar ba su dace ba!",
    yo: "Awọn ọrọigbaniwọle meji ti o tẹ ko baamu!",
  },

  "Tell us your email and we will send your password": {
    en: "Tell us your email and we will send your password.",
    pt: "Informe-nos seu e-mail e enviaremos sua senha.",
    de: "Geben Sie uns Ihre E-Mail-Adresse an, und wir senden Ihnen Ihr Passwort.",
    es: "Díganos su correo electrónico y le enviaremos su contraseña.",
    fr: "Indiquez-nous votre adresse e-mail, et nous vous enverrons votre mot de passe.",
    it: "Dicci la tua email e ti invieremo la tua password.",
    sq: "Na tregoni emailin tuaj dhe ne do t'ju dërgojmë fjalëkalimin tuaj.",
    tr: "Bize e-posta adresinizi söyleyin, şifrenizi göndereceğiz.",
    sw: "Tuambie barua pepe yako na tutakutumia nenosiri lako.",
    am: "ኢሜይልዎን ይነግሩን እና የይለፍ ቁልፍዎን እንላክዎታለን።",
    om: "Email kee nuuf himi, maaliif password kee ni erguuf.",
    ig: "Gwa anyị email gị ma anyị ga-eziga gị paswọọdụ gị.",
    so: "Naga ko emailkaaga, waxaan kuu soo diri doonnaa eraygaaga sirta ah.",
    ha: "Fada mana email dinku za mu aiko muku da kalmar sirri.",
    mn: "Таны и-мэйл хаягийг бидэнд хэлж, таны нууц үгийг явуулна.",
    ff: "Fada mana email dinku za mu aiko muku da kalmar sirri.",
    yo: "Sọ fun wa imeeli rẹ ki a le fi ọrọ igbaniwọle rẹ ranṣẹ.",
  },
  "Then you can separate and send notifications by device keys": {
    en: "Then you can separate and send notifications by device keys, by registration date, by platforms, all at once.",
    pt: "Em seguida, você pode separar e enviar notificações por chaves de dispositivo, por data de registro, por plataformas, tudo de uma vez.",
    de: "Dann können Sie Benachrichtigungen nach Geräteschlüsseln, nach Anmeldedatum, nach Plattformen oder alles auf einmal trennen und senden.",
    es: "Luego puede separar y enviar notificaciones por claves de dispositivo, por fecha de registro, por plataformas, todo a la vez.",
    fr: "Ensuite, vous pouvez séparer et envoyer des notifications par clés de périphérique, par date d'inscription, par plateformes, tout en une seule fois.",
    it: "Poi puoi separare e inviare notifiche per chiavi di dispositivo, per data di registrazione, per piattaforme, tutto in una volta.",
    sq: "Pastaj mund të ndani dhe të dërgoni njoftime sipas çelësave të pajisjes, sipas datës së regjistrimit, sipas platformave, të gjitha në njëherë.",
    tr: "Sonra, bildirimleri cihaz anahtarlarına, kayıt tarihine ve platformlara göre ayırabilir ve hepsini birden gönderebilirsiniz.",
    sw: "Kisha unaweza kutenga na kutuma arifa kwa funguo za vifaa, kwa tarehe ya usajili, kwa majukwaa, yote kwa pamoja.",
    am: "ከዚያ ይችላሉ የመሳሪያ ቁልፎች በመሠረት እንዲወድቁ እና ወይም በየቀን በመመዝገብ ወይም በየመሣሪያ ቦታ በመደምደም ይላክ።",
    om: "Erga sana, bu'uura madda, guyyaa galmeessuu fi baafata adda baasuudhaan qoodaa erguu dandeessa.",
    ig: "Mgbe ahụ, ị nwere ike ichekwa ma zipu nkwupụta site na igodo ngwaọrụ, site na ụbọchị ndebanye, site na nyiwe, niile n'otu oge.",
    so: "Kadib, waxaad kala sooci kartaa oo aad u diri kartaa digniinaha adigoo adeegsanaya furayaasha qalabka, taariikhda diiwaangelinta, madal, dhammaan hal mar.",
    ha: "Sa'an nan zaku iya raba da aika sakonnin da makullin na'ura, ta hanyar ranar rajista, ta hanyar dandamali, duka a lokaci guda.",
    mn: "Дараа нь та төхөөрөмжийн түлхүүр, бүртгэлийн огноо, платформоор мэдэгдлийг салгаж, нэг дор илгээх боломжтой.",
    ff: "Sa'an nan zaku iya raba da aika sakonnin da makullin na'ura, ta hanyar ranar rajista, ta hanyar dandamali, duka a lokaci guda.",
    yo: "Lẹhinna o le ya sọtọ ki o si fi awọn iwifunni ranṣẹ nipasẹ awọn bọtini ẹrọ, nipasẹ ọjọ iforukọsilẹ, nipasẹ awọn pẹpẹ, gbogbo rẹ ni akoko kan.",
  },
  "Then, set the notification send date and the notification expiration date": {
    en: "Then, set the notification send date and the notification expiration date.",
    pt: "Em seguida, defina a data de envio da notificação e a data de expiração da notificação.",
    de: "Legen Sie dann das Datum für den Versand der Benachrichtigung und das Ablaufdatum der Benachrichtigung fest.",
    es: "Luego, establezca la fecha de envío de la notificación y la fecha de vencimiento de la notificación.",
    fr: "Ensuite, définissez la date d'envoi de la notification et la date d'expiration de la notification.",
    it: "Poi, imposta la data di invio della notifica e la data di scadenza della notifica.",
    sq: "Pastaj, vendosni datën e dërgimit të njoftimit dhe datën e skadimit të njoftimit.",
    tr: "Sonra, bildirim gönderim tarihini ve bildirim sona erme tarihini ayarlayın.",
    sw: "Kisha, weka tarehe ya kutuma arifa na tarehe ya kumalizika kwa arifa.",
    am: "ከዚያ ይህ ነው፡ የእቅፍ መላእክት ቀንን እና የእቅፍ እንዳለው ቀን ይቀንሳሉ።",
    om: "Erga sana, guyyaa ergaa odeeffannoo fi guyyaa xumura odeeffannoo qopheessi.",
    ig: "Mgbe ahụ, setịpụ ụbọchị izipu nkwupụta na ụbọchị njedebe nkwupụta.",
    so: "Kadib, deji taariikhda dirista digniinta iyo taariikhda dhicitaanka digniinta.",
    ha: "Sannan, saita ranar aika sanarwa da ranar ƙarewar sanarwa.",
    mn: "Дараа нь мэдэгдлийг илгээх огноо болон мэдэгдлийн дуусах огноог тохируулна уу.",
    ff: "Sannan, saita ranar aika sanarwa da ranar ƙarewar sanarwa.",
    yo: "Lẹhinna, ṣeto ọjọ ikede iwifunni ati ọjọ ipari iwifunni.",
  },
  "The credit count will be updated on your profile during 5 minutes": {
    en: "The credit count will be updated on your profile during 5 minutes. If you will have any problems with the payment please contact",
    pt: "A contagem de créditos será atualizada em seu perfil durante 5 minutos. Se você tiver algum problema com o pagamento, entre em contato.",
    de: "Die Kreditanzahl wird in Ihrem Profil innerhalb von 5 Minuten aktualisiert. Wenn Sie Probleme mit der Zahlung haben, kontaktieren Sie bitte",
    es: "El recuento de créditos se actualizará en su perfil durante 5 minutos. Si tiene algún problema con el pago, póngase en contacto con",
    fr: "Le nombre de crédits sera mis à jour sur votre profil pendant 5 minutes. Si vous avez des problèmes de paiement, veuillez contacter",
    it: "Il conteggio dei crediti verrà aggiornato nel tuo profilo entro 5 minuti. Se avrai problemi con il pagamento, contatta",
    sq: "Numri i kredive do të përditësohet në profilin tuaj gjatë 5 minutave. Nëse keni ndonjë problem me pagesën, ju lutemi kontaktoni",
    tr: "Kredi sayısı profilinizde 5 dakika içinde güncellenecektir. Ödeme ile ilgili herhangi bir sorun yaşarsanız, lütfen iletişime geçin.",
    sw: "Idadi ya mikopo itasasishwa kwenye wasifu wako ndani ya dakika 5. Ikiwa utakuwa na matatizo yoyote na malipo tafadhali wasiliana",
    am: "እባክዎን ይህን ያሳያሉ። በ5 ደቂቀ መዋቅር ውስጥ እንደአስተዳደር ይሰቀልናል። ይህን አቀበው ለይ።",
    om: "Kreditiin lakkoofsi profile keessan irratti daqiiqaa 5 keessatti ni fooyya'a. Yoo kaffaltiin keessan rakkoo qabaate, quunnamaa",
    ig: "Nkwụsịrị nke kredit ga-emelite na profaịlụ gị n'ime nkeji 5. Ọ bụrụ na ịnwere nsogbu ọ bụla na ụgwọ, biko kọntaktị",
    so: "Tirinta deynta waxaa lagu cusboonaysiin doonaa profile-kaaga muddo 5 daqiiqo ah. Haddii aad wax dhibaato ah kala kulanto lacag bixinta, fadlan la xiriir",
    ha: "Adadin kuɗin zai sabunta a cikin tsarin ku na minti 5. Idan kana da wata matsala tare da biyan kuɗi, don Allah tuntuɓi",
    mn: "Кредитийн тоо таны профайл дээр 5 минутын хугацаанд шинэчлэгдэх болно. Хэрвээ танд төлбөрийн асуудал гарвал, холбогдоно уу",
    ff: "Adadin kuɗin zai sabunta a cikin tsarin ku na minti 5. Idan kana da wata matsala tare da biyan kuɗi, don Allah tuntuɓi",
    yo: "Iye ẹsan naa yoo ṣe imudojuiwọn lori profaili rẹ ni iṣẹju 5. Ti o ba ni awọn iṣoro eyikeyi pẹlu sisanwo, jọwọ kan si",
  },
  "This device is attached to you, you can find it in your device list": {
    en: "This device is attached to you, you can find it in your device list",
    pt: "Este dispositivo está ligado a si, pode encontrá-lo na sua lista de dispositivos",
    de: "Dieses Gerät ist mit Ihnen verbunden, Sie finden es in Ihrer Geräteliste",
    es: "Este dispositivo está vinculado a usted, puede encontrarlo en su lista de dispositivos",
    fr: "Cet appareil vous est attribué, vous pouvez le trouver dans votre liste d'appareils",
    it: "Questo dispositivo è collegato a te, puoi trovarlo nella tua lista di dispositivi",
    sq: "Kjo pajisje është e lidhur me ju, mund ta gjeni në listën tuaj të pajisjeve",
    tr: "Bu cihaz size bağlı, cihazlar listenizde bulabilirsiniz.",
    sw: "Kifaa hiki kimeunganishwa na wewe, unaweza kukipata kwenye orodha yako ya vifaa.",
    am: "ይህ መሣሪያ ከእርስዎ ጋር ይያያዝ፣ በመሣሪያዎ ዝርዝር ውስጥ ይገኛል።",
    om: "Kittiin kun si wajjin walqabata, meeshaalee kee irratti argachuu dandeessa.",
    ig: "Ngwá a bụ onye na-eso gị, ị nwere ike ịchọta ya na ndepụta ngwaọrụ gị.",
    so: "Qalabkan waxaa laguu xiray, waxaad ka heli kartaa liiskaaga qalabka.",
    ha: "Wannan na'ura tana tare da ku, za ku iya samun ta a cikin jerin na'urorin ku.",
    mn: "Энэ төхөөрөмж таныг холбосон бөгөөд та үүнийг төхөөрөмжийн жагсаалтдаа олох боломжтой.",
    ff: "Wannan na'ura tana tare da ku, za ku iya samun ta a cikin jerin na'urorin ku.",
    yo: "Ẹrọ yii ti so pọ pẹlu rẹ, o le rii ni atokọ ẹrọ rẹ.",
  },
  "The devices are busy with another provider": {
    en: "The devices are busy with another provider",
    pt: "Os dispositivos estão ocupados com outro fornecedor",
    de: "Die Geräte sind bei einem anderen Anbieter im Einsatz",
    es: "Los dispositivos están ocupados con otro proveedor",
    fr: "Les appareils sont occupés avec un autre fournisseur",
    it: "I dispositivi sono occupati con un altro fornitore",
    sq: "Pajisjet janë të zëna me një ofrues tjetër",
    tr: "Cihazlar başka bir sağlayıcı ile meşguldür.",
    sw: "Vifaa vinatumika na mtoa huduma mwingine.",
    am: "መሣሪያዎቹ ከሌላ አቅራቢ ጋር ተይዞ ናቸው።",
    om: "Meeshaaleen biroo tokko waliin hojjachaa jiru.",
    ig: "Ngwá ndị ahụ na-arụ ọrụ na onye na-eweta ọzọ.",
    so: "Qalabku wuxuu mashquulsan yahay adeeg bixiye kale.",
    ha: "Na'urorin suna cikin aiki tare da wani mai bayarwa.",
    mn: "Төхөөрөмжүүд өөр нийлүүлэгчийн хамт завгүй байна.",
    ff: "Na'urorin suna cikin aiki tare da wani mai bayarwa.",
    yo: "Awọn ẹrọ naa n ṣiṣẹ pẹlu olupese miiran.",
  },
  "Total: All devices count Free trial": {
    en: "Total: All devices count Free trial: Devices that are in Free Trial period Active: Active devices count Trial expired: Devices whose free trial period has been expired and they need to be activated to continue using the TV app.",
    pt: "Total: Contagem de todos os dispositivos Teste gratuito: Dispositivos que estão no período de teste gratuito Ativos: Contagem de dispositivos ativos Teste expirado: Dispositivos cujo período de teste gratuito expirou e precisam ser ativados para continuar usando o aplicativo de TV.",
    de: "Gesamt: Anzahl aller Geräte Testversion: Geräte, die sich im Testzeitraum befinden Aktiv: Anzahl der aktiven Geräte Test abgelaufen: Geräte, deren Testzeitraum abgelaufen ist und die aktiviert werden müssen, um die TV-App weiterhin zu nutzen.",
    es: "Total: Cantidad total de dispositivos Prueba gratuita: Dispositivos que están en período de prueba gratuita Activo: Cantidad de dispositivos activos Prueba vencida: Dispositivos cuyo período de prueba gratuita ha vencido y necesitan activarse para seguir utilizando la aplicación de TV.",
    fr: "Total : Nombre total de dispositifs Essai gratuit : Dispositifs en période d'essai gratuit Actif : Nombre de dispositifs actifs Essai expiré : Dispositifs dont la période d'essai gratuit a expiré et qui doivent être activés pour continuer à utiliser l'application TV.",
    it: "Totale: Conteggio totale dei dispositivi Prova gratuita: Dispositivi che si trovano in periodo di prova gratuita Attivi: Conteggio dei dispositivi attivi Prova scaduta: Dispositivi il cui periodo di prova gratuita è scaduto e devono essere attivati per continuare a utilizzare l'app TV.",
    sq: "Totali: Gjithë numërimi i pajisjeve Provë e lirë: Pajisjet që janë në periudhën e provës së lirë Aktiv: Numërimi i pajisjeve aktive Provë e skaduar: Pajisjet të cilat janë skaduar periudha e tyre e provës së lirë dhe duhet të aktivizohen për të vazhduar të përdorin aplikacionin e TV-së.",
    tr: "Toplam: Tüm cihaz sayısı Ücretsiz deneme: Ücretsiz deneme süresinde olan cihazlar Aktif: Aktif cihaz sayısı Deneme süresi dolmuş: Ücretsiz deneme süresi dolmuş cihazlar, TV uygulamasını kullanmaya devam etmek için etkinleştirilmesi gerekmektedir.",
    sw: "Jumla: Idadi ya vifaa vyote Kijaribu bure: Vifaa ambavyo viko katika kipindi cha Kijaribu bure Hai: Idadi ya vifaa vilivyo hai Kijaribu kimeisha: Vifaa ambavyo kipindi chao cha Kijaribu bure kimeisha na vinahitaji kuunganishwa ili kuendelea kutumia programu ya TV.",
    am: "ጠቅላላ: የሁሉም መሣሪያዎች ቆጣቢ ወንበር ነበር ከፍተኛ ግንባር: በነፃ ጊዜ ወቅት ውስጥ ያለው መሣሪያ አንድ ወቅታዊ ነው ቢሆንም ወንበር አንዱ: የተከፈቱ ወንበር ወንበር ወደ መላ ይህ እንደሚሆን ይፈልጋሉ።",
    om: "Waliigala: Lakkoofsa meeshaalee hunda Kijara bilisa: Meeshaaleen yeroo Kijara bilisa keessa jiran Hojii: Lakkoofsa meeshaalee hojjetan Kijarrii xumurame: Meeshaalee Kijara bilisa isaanii xumuraan ka’anii, TV app itti fufuun ni hojiin jalqabuu qabu.",
    ig: "N'ozuzu: Nọmba ngwaọrụ niile Ncheta n'eff: Ngwá ndị nọ na oge Ncheta n'efu Active: Nọmba ngwaọrụ ndị dị Active Ncheta gwụrụ: Ngwá ndị nwere oge Ncheta n'efu nke gwụrụ ma ha chọrọ ka e nwee ha ka ha na-aga n'ihu na-eji ngwa TV.",
    so: "Wadarta: Tirinta dhammaan qalabka Tijaabo bilaash ah: Qalabka ku jira muddada Tijaabada Bilaashka ah Firfircoon: Tirinta qalabka firfircoon Tijaabadu way dhammaatay: Qalabka muddada tijaabada bilaashka ah dhammaatay, waxayna u baahan yihiin in la hawlgeliyo si ay u sii wataan isticmaalka barnaamijka TV-ga.",
    ha: "Jimla: Jimlar na'urori duka free: Na'urorin da ke cikin lokacin gwaji kyauta Maimaitawa: Jimlar na'urorin da ke aiki Gwajin ya ƙare: Na'urorin da lokacin gwajinsu na kyauta ya ƙare kuma suna buƙatar a kunna su don ci gaba da amfani da manhajar TV.",
    mn: "Нийт: Бүх төхөөрөмжийн тоо Үнэгүй туршилт: Үнэгүй туршилтын хугацаанд байгаа төхөөрөмжүүд Идэвхтэй: Идэвхтэй төхөөрөмжийн тоо Туршилтын хугацаа дууссан: Үнэгүй туршилтын хугацаа дууссан төхөөрөмжүүд, ТВ програмыг үргэлжлүүлэхийн тулд идэвхжүүлэх шаардлагатай.",
    ff: "Jimla: Jimlar na'urori duka free: Na'urorin da ke cikin lokacin gwaji kyauta Maimaitawa: Jimlar na'urorin da ke aiki Gwajin ya ƙare: Na'urorin da lokacin gwajinsu na kyauta ya ƙare kuma suna buƙatar a kunna su don ci gaba da amfani da manhajar TV.",
    yo: "Lapapọ: Iye awọn ẹrọ gbogbo Idanwo ọfẹ: Awọn ẹrọ ti o wa ni akoko idanwo ọfẹ Ti n ṣiṣẹ: Iye awọn ẹrọ ti n ṣiṣẹ Idanwo ti pari: Awọn ẹrọ ti akoko idanwo ọfẹ wọn ti pari ati pe wọn nilo lati ṣiṣẹ lati tẹsiwaju lilo ohun elo TV.",
  },
  "to become a seller": {
    en: "to become a seller",
    pt: "tornar-se um vendedor",
    de: "ein Verkäufer werden",
    es: "convertirse en vendedor",
    fr: "devenir vendeur",
    it: "diventare un venditore",
    sq: "të bëheni një shitës",
    tr: "bir satıcı olmak",
    sw: "kufanyika muuzaji",
    am: "ወደ ሻጭ ለመሆን",
    om: "suuqaa ta'uu",
    ig: "ịghọ onye na-ere ahịa",
    so: "in noqoto iibiye",
    ha: "don zama mai sayarwa",
    mn: "худалдагч болох",
    ff: "don zama mai sayarwa",
    yo: "lati di onisowo",
  },
  "Two-Factor Authentication (2FA) Code Reminder": {
    en: "Please ensure that a verification code is dispatched to the email address registered for your two-factor authentication (2FA). Kindly review your mailbox for the code within the next 10 minutes.",
    pt: "Certifique-se de que um código de verificação seja enviado para o endereço de e-mail registrado para sua autenticação de dois fatores (2FA). Por favor, verifique sua caixa de entrada para o código nos próximos 10 minutos.",
    de: "Bitte stellen Sie sicher, dass ein Bestätigungscode an die für Ihre Zwei-Faktor-Authentifizierung (2FA) registrierte E-Mail-Adresse gesendet wird. Überprüfen Sie bitte Ihr Postfach auf den Code innerhalb der nächsten 10 Minuten.",
    es: "Asegúrese de que se envíe un código de verificación a la dirección de correo electrónico registrada para su autenticación de dos factores (2FA). Revise su bandeja de entrada para encontrar el código en los próximos 10 minutos.",
    fr: "Veuillez vous assurer qu'un code de vérification est envoyé à l'adresse e-mail enregistrée pour votre authentification à deux facteurs (2FA). Veuillez vérifier votre boîte de réception pour le code dans les 10 prochaines minutes.",
    it: "Assicurati che un codice di verifica venga inviato all'indirizzo email registrato per la tua autenticazione a due fattori (2FA). Controlla gentilmente la tua casella di posta per il codice entro i prossimi 10 minuti.",
    sq: "Ju lutemi sigurohuni që një kod verifikimi të dërgohet në adresën e emailit të regjistruar për autentifikimin tuaj dyfaktor (2FA). Ju lutemi, shqyrtoni postën tuaj elektronike për kodin brenda 10 minutave të ardhshme.",
    tr: "Lütfen iki faktörlü kimlik doğrulama (2FA) için kayıtlı e-posta adresinize bir doğrulama kodunun gönderildiğinden emin olun. Lütfen kodu kontrol etmek için gelen kutunuzu sonraki 10 dakika içinde gözden geçirin.",
    sw: "Tafadhali hakikisha kuwa nambari ya uthibitisho imetumwa kwa anwani ya barua pepe iliyosajiliwa kwa ajili ya uthibitishaji wako wa hatua mbili (2FA). Tafadhali angalia sanduku lako la barua kwa nambari hiyo ndani ya dakika 10 zijazo.",
    am: "እባክዎ አስታውሱ የማረጋገጫ ኮድ ወደ ሁለተኛ የዳይሬክተር መርጃ ወደ የተመዘገበ ኢሜይል አድራሻ ይታወሰ። እባክዎ የእርስዎን የኢሜይል ሳጥን ያጥብቁ ለኮድ በወቅቱ ወቅት 10 ደቂቀ ጊዜ ይጠብቁ።",
    om: "Maaloo mirkaneessi nambii mirkaneessaa marsariitii e-mail teessoo tiiknii fi 2FA keessa galmaa'ee ergame. Nambii kana sa'aa 10 keessatti e-mail keeti keessa ilaaluuf xiinxalli.",
    ig: "Biko jide n'aka na e zigara koodu nkwenye na adreesị email nke edere maka nkwenye usoro abụọ (2FA) gị. Biko nyochaa igbe ozi gị maka koodu n'ọnwa na nkeji 10 na-abịa.",
    so: "Fadlan hubi in koodhka xaqiijinta loo diro cinwaanka emaylka ee diiwaan gashan 2FA. Fadlan dib u eeg sanduuqaaga emaylka koodhkaas gudah 10 daqiiqo soo socda.",
    ha: "Don Allah ku tabbatar cewa an aika lambar tabbatarwa zuwa adireshin imel da aka yi rajista don tantancewar ku ta mataki biyu (2FA). Da fatan za a duba akwatin ku na imel don lamban a cikin mintuna 10 masu zuwa.",
    mn: "Би итгэмжлэх кодыг хоёр дахь хүчин зүйл (2FA)-ийн бүртгэлтэй имэйл хаяг руу илгээсэн эсэхийг шалгаарай. Кодыг ирэх 10 минутын дотор мэйлд шалгаарай.",
    ff: "Don Allah ku tabbatar cewa an aika lambar tabbatarwa zuwa adireshin imel da aka yi rajista don tantancewar ku ta mataki biyu (2FA). Da fatan za a duba akwatin ku na imel don lamban a cikin mintuna 10 masu zuwa.",
    yo: "Jọwọ rii daju pe koodu iṣeduro ti wa ni firanṣẹ si adirẹsi imeeli ti a forukọsilẹ fun iṣeduro iwọntunwọnsi meji rẹ (2FA). Jọwọ ṣe ayẹwo apoti ifiranṣẹ rẹ fun koodu ni awọn iṣẹju 10 to nbọ.",
  },
  "Text copied to clipboard": {
    en: "Text copied to clipboard",
    pt: "Texto copiado para a área de transferência",
    de: "Text in die Zwischenablage kopiert",
    es: "Texto copiado al portapapeles",
    fr: "Texte copié dans le presse-papiers",
    it: "Testo copiato negli appunti",
    sq: "Teksti u kopjua në shiritin e prerjes",
    tr: "Metin panoya kopyalandı",
    sw: "Maneno yamekopi kwenye ubao wa kunakili",
    am: "ጽሑፍ ወደ ክሊፕቦርድ ተቀይሯል",
    om: "Barreeffamni garagalchaa deema",
    ig: "Ede e dekọrọ na clipboard",
    so: "Qoraalka ayaa lagu nuqulay deeqda nuqulka",
    ha: "An rubuta rubutun a cikin clipboard",
    mn: "Текст хуулсан",
    ff: "Rubutun an kwafi zuwa clipboard",
    yo: "Ọrọ ti wa ni daakọ si ikọsẹ.",
  },
  "Trial expired date": {
    en: "Trial expired date",
    pt: "Data de expiração do período de avaliação",
    de: "Ablaufdatum der Testphase",
    es: "Fecha de vencimiento de la prueba",
    fr: "Date d'expiration de l'essai",
    it: "Data di scadenza della prova",
    sq: "Data e skadimit të provës së lirë",
    tr: "Deneme süresi bitiş tarihi",
    sw: "Tarehe ya kuisha kwa majaribio",
    am: "የሙከራ ጊዜ ተሰንዝሏል",
    om: "Guyyaa muuxannoo xumurame",
    ig: "Ụbọchị ikpeazụ nke nwụnye",
    so: "Taariikhda tijaabada dhamaatay",
    ha: "Ranar gwaji ta ƙare",
    mn: "Turшүүлсний хугацаа дууссан өдөр",
    ff: "Ranar gwaji ta ƙare",
    yo: "Ọjọ́ ìdánwò ti parí.",
  },

  //r
  "Refund policy": {
    en: "Refund policy",
    pt: "Política de reembolso",
    de: "Rückerstattungsrichtlinie",
    es: "Política de reembolso",
    fr: "Politique de remboursement",
    it: "Politica di rimborso",
    sq: "Politika e rimbursimit",
    tr: "İade politikası",
    sw: "Sera ya kurudisha",
    am: "የመልስ ደንብ",
    om: "Tajaajila maallaqaa",
    ig: "Okwu nkwụsịrị",
    so: "Siyaasadda lacag celinta",
    ha: "Tsarin dawo da kudi",
    mn: "Буцаах бодлого",
    ff: "Tsarin dawo da kudi",
    yo: "Ilana agbapada.",
  },
  Reset: {
    en: "Reset",
    pt: "Redefinir",
    de: "Zurücksetzen",
    es: "Restablecer",
    fr: "Réinitialiser",
    it: "Ripristina",
    sq: "Rivendos",
    tr: "Sıfırla",
    sw: "Rekebisha",
    am: "ይዘርዝሩ",
    om: "Haqulle",
    ig: "Nzọpụta",
    so: "Dib u dhig",
    ha: "Sake saiti",
    mn: "Дахин тохируулах",
    ff: "Sake saiti",
    yo: "Tunṣe.",
  },
  "Reseller name": {
    en: "Reseller name",
    pt: "Nome do Revendedor",
    de: "Wiederverkäufername",
    es: "Nombre del Revendedor",
    fr: "Nom du Revendeur",
    it: "Nome del Rivenditore",
    sq: "Emri i ri-shitësit",
    tr: "Bayii adı",
    sw: "Jina la muuzaji",
    am: "የተሻሽ ስም",
    om: "Maamila maqaa",
    ig: "Aha onye na-ere",
    so: "Magaca iibiye",
    ha: "Sunan mai sayarwa",
    mn: "Дистрибьютерийн нэр",
    ff: "Sunan mai sayarwa",
    yo: "Orukọ onisowo.",
  },
  "Reseller email": {
    en: "Reseller email",
    pt: "E-mail do Revendedor",
    de: "Wiederverkäufer-E-Mail",
    es: "Correo electrónico del Revendedor",
    fr: "E-mail du Revendeur",
    it: "Email del Rivenditore",
    sq: "Emaili i ri-shitësit",
    tr: "Bayii e-postası",
    sw: "Barua pepe ya muuzaji",
    am: "የተሻሽ ኢሜይል",
    om: "Maamila imeeli",
    ig: "Email onye na-ere",
    so: "Email-ka iibiyaha",
    ha: "Imel na mai sayarwa",
    mn: "Дистрибьютерийн имэйл",
    ff: "Imel na mai sayarwa",
    yo: "Imeeli onisowo.",
  },
  "Release date": {
    en: "Release date",
    pt: "Data de Lançamento",
    de: "Veröffentlichungsdatum",
    es: "Fecha de Lanzamiento",
    fr: "Date de Sortie",
    it: "Data di Uscita",
    sq: "Data e publikimit",
    tr: "Çıkış tarihi",
    sw: "Tarehe ya kutolewa",
    am: "የማስታወቂያ ቀን",
    om: "Guyyaa baafata",
    ig: "Ụbọchị mbipụta",
    so: "Taariikhda sii deynta",
    ha: "Ranar fitarwa",
    mn: "Нийтлэлийн огноо",
    ff: "Ranar fitarwa",
    yo: "Ọjọ́ ifilọlẹ.",
  },
  "Reset password": {
    en: "Reset password",
    pt: "Redefinir senha",
    de: "Passwort zurücksetzen",
    es: "Restablecer contraseña",
    fr: "Réinitialiser le mot de passe",
    it: "Ripristina password",
    sq: "Rivendos fjalëkalimin",
    tr: "Şifreyi sıfırla",
    sw: "Rekebisha nywila",
    am: "የይለፍ ቃል ይዘርዝሩ",
    om: "Furdaa haqulle",
    ig: "Nzọpụta okwuntughe",
    so: "Dib u dhig erayga sirta",
    ha: "Sake saiti kalmar wucewa",
    mn: "Нууц үгийг дахин тохируулах",
    ff: "Sake saiti kalmar wucewa",
    yo: "Tunṣe ọrọ igbaniwọle.",
  },
  "Reseller Config": {
    en: "Reseller Config",
    pt: "Configuração do Revendedor",
    de: "Wiederverkäuferkonfiguration",
    es: "Configuración del Revendedor",
    fr: "Configuration du Revendeur",
    it: "Configurazione del Rivenditore",
    sq: "Konfigurimi i Rishitësit",
    tr: "Bayii Konfigürasyonu",
    sw: "Mipangilio ya muuzaji",
    am: "የተሻሽ ኮንፊግ",
    om: "Maamila qindaa'aa",
    ig: "Nchekwa onye na-ere",
    so: "Hagaajinta iibiyaha",
    ha: "Tsarin mai sayarwa",
    mn: "Дистрибьютерийн тохиргоо",
    ff: "Tsarin mai sayarwa",
    yo: "Iṣeto onisowo.",
  },
  "Receiving devices": {
    en: "Receiving devices",
    pt: "Recebendo dispositivos",
    de: "Geräte empfangen",
    es: "Recepción de dispositivos",
    fr: "Réception des dispositifs",
    it: "Ricezione dei dispositivi",
    sq: "Marrja e pajisjeve",
    tr: "Alma cihazları",
    sw: "Vifaa vinavyopokea",
    am: "የሚቀበሉ መሣሪያዎች",
    om: "Qoodamni argachuu",
    ig: "Ngwa na-anata",
    so: "Qalabka qaata",
    ha: "Na'urar karɓa",
    mn: "Хүлээн авах төхөөрөмжүүд",
    ff: "Na'urar karɓa",
    yo: "Awọn ẹrọ gbigba.",
  },
  "Retype password": {
    en: "Retype password",
    pt: "Digite a senha novamente",
    de: "Passwort erneut eingeben",
    es: "Vuelva a escribir la contraseña",
    fr: "Saisissez à nouveau le mot de passe",
    it: "Ridigita la password",
    sq: "Rishkruaj fjalëkalimin",
    tr: "Şifreyi yeniden girin",
    sw: "Andika tena nywila",
    am: "ይዘርዝሩ ይይዘው ቃል",
    om: "Furdaa irra deddeebi'aa",
    ig: "Dee ntughari okwuntughe",
    so: "Mar kale qor erayga sirta",
    ha: "Sake shigar da kalmar wucewa",
    mn: "Нууц үгээ дахин оруулах",
    ff: "Sake shigar da kalmar wucewa",
    yo: "Tun kọ ọrọ igbaniwọle.",
  },
  "Reseller devices": {
    en: "Reseller devices",
    pt: "Dispositivos do Revendedor",
    de: "Wiederverkäufergeräte",
    es: "Dispositivos del Revendedor",
    fr: "Dispositifs du Revendeur",
    it: "Dispositivi del Rivenditore",
    sq: "Pajisjet e Rishitësit",
    tr: "Bayii cihazları",
    sw: "Vifaa vya muuzaji",
    am: "የተሻሽ መሣሪያዎች",
    om: "Maqaa maamila",
    ig: "Ngwa ndị na-ere",
    so: "Qalabka iibiyaha",
    ha: "Na'urar mai sayarwa",
    mn: "Дистрибьютерийн төхөөрөмжүүд",
    ff: "Na'urar mai sayarwa",
    yo: "Awọn ẹrọ onisowo.",
  },
  "Reset my password": {
    en: "Reset my password",
    pt: "Redefinir minha senha",
    de: "Mein Passwort zurücksetzen",
    es: "Restablecer mi contraseña",
    fr: "Réinitialiser mon mot de passe",
    it: "Ripristina la mia password",
    sq: "Rivendos fjalëkalimin tim",
    tr: "Şifremi sıfırla",
    sw: "Rekebisha nywila yangu",
    am: "የይለፍ ቃል ይዘርዝሩ",
    om: "Furdaa sirna kiyya",
    ig: "Nzọpụta okwuntughe m",
    so: "Dib u dhig erayga sirta",
    ha: "Sake saiti kalmar wucewa",
    mn: "Миний нууц үгийг дахин тохируулах",
    ff: "Sake saiti kalmar wucewa",
    yo: "Tunṣe ọrọ igbaniwọle mi.",
  },
  "Reseller configuration updates are successful": {
    en: "Reseller configuration updates are successful.",
    pt: "As atualizações de configuração do revendedor foram bem-sucedidas.",
    de: "Die Aktualisierungen der Wiederverkäuferkonfiguration waren erfolgreich.",
    es: "Las actualizaciones de configuración del revendedor se han realizado con éxito.",
    fr: "Les mises à jour de la configuration du revendeur ont réussi.",
    it: "Le modifiche alla configurazione del rivenditore sono state effettuate con successo.",
    sq: "Përditësimet e konfigurimit të shitësit janë kryer me sukses.",
    tr: "Bayii yapılandırma güncellemeleri başarıyla gerçekleştirildi.",
    sw: "Mabadiliko ya mipangilio ya muuzaji yamefanikiwa.",
    am: "የተሻሽ ኮንፊግ ዝርዝር ተሳክቷል።",
    om: "Qindaa'aa maamilaa haala gaarii ta'eera.",
    ig: "Nkwupụta ọnọdụ ndị na-ere ka ịga nke ọma.",
    so: "Hagaajinta iibiyaha ayaa guuleysatay.",
    ha: "Sabon tsari na mai sayarwa ya yi nasara.",
    mn: "Дистрибьютерийн тохиргоо шинэчилэлт амжилттай.",
    ff: "Sabon tsari na mai sayarwa ya yi nasara.",
    yo: "Iṣeto onisowo ti ṣaṣeyọri.",
  },
  "Reseller will need to buy credits": {
    en: "Reseller will need to buy credits",
    pt: "O revendedor precisará comprar créditos",
    de: "Der Wiederverkäufer muss Guthaben kaufen",
    es: "El revendedor deberá comprar créditos",
    fr: "Le revendeur devra acheter des crédits",
    it: "Il rivenditore dovrà acquistare crediti",
    sq: "Shitësi do të ketë nevojë të blejë kredite",
    tr: "Bayinin kredi satın alması gerekecek",
    sw: "Muuzaji atahitaji kununua mikopo",
    am: "የተሻሽ የክሬዲቶች ማስፈርስ ይኖርበታል",
    om: "Maamilni miidhaakkameera",
    ig: "Onye na-ere ga-achọ ịzụta kredit",
    so: "Iibiye wuxuu u baahan doonaa inuu iibsado lacagta.",
    ha: "Mai sayarwa zai buƙaci sayen kuɗi.",
    mn: "Дистрибьютер кредит худалдан авах шаардлагатай.",
    ff: "Mai sayarwa zai buƙaci sayen kuɗi.",
    yo: "Onisowo nilo lati ra awọn kirẹditi.",
  },
  "Registered Devices": {
    en: "Registered Devices",
    pt: "Dispositivos Registrados",
    de: "Registrierte Geräte",
    es: "Dispositivos Registrados",
    fr: "Appareils Enregistrés",
    it: "Dispositivi Registrati",
    sq: "Pajisjet e Regjistruara",
    tr: "Kayıtlı Cihazlar",
  },
  "Reseller can use my credits": {
    en: "Reseller can use my credits",
    pt: "O revendedor pode usar meus créditos",
    de: "Der Wiederverkäufer kann meine Guthaben nutzen",
    es: "El revendedor puede utilizar mis créditos",
    fr: "Le revendeur peut utiliser mes crédits",
    it: "Il rivenditore può usare i miei crediti",
    sq: "Shitësi mund të përdorë kreditë e mia",
    tr: "Bayii kredilerimi kullanabilir",
    sw: "Vifaa vilivyoregisteriwa",
    am: "የተመዘገቡ መሣሪያዎች",
    om: "Maqaa galmeeffaman",
    ig: "Ngwa ndị edebanyere",
    so: "Qalabka la diiwaangeliyey",
    ha: "Na'urar da aka yi rajista",
    mn: "Бүртгэлтэй төхөөрөмжүүд",
    ff: "Na'urar da aka yi rajista",
    yo: "Awọn ẹrọ ti a forukọsilẹ.",
  },
  "Reset requests": {
    en: "Reset requests",
    pt: "Solicitações de reset",
    de: "Reset-Anfragen",
    es: "Solicitudes de reinicio",
    fr: "Demandes de réinitialisation",
    it: "Richieste di reset",
    sq: "Kërkesat e rivendosjes",
    tr: "Sıfırlama talepleri",
    sw: "Maombi ya kurekebisha",
    am: "የማስተካከያ ቃላት",
    om: "Itti aansuu",
    ig: "Arịrịọ mgbakwunye",
    so: "Codsiyada dib-u-habaynta",
    ha: "Neman sake saiti",
    mn: "Сэргээх хүсэлтүүд",
    ff: "Neman sake saiti",
    yo: "Ibeere imularada",
  },
  //f
  from: {
    en: "from",
    pt: "de",
    de: "von",
    es: "de",
    fr: "de",
    it: "da",
    sq: "nga",
    tr: "den",
    sw: "kutoka",
    am: "ከ",
    om: "irraa",
    ig: "site",
    so: "laga soo bilaabo",
    ha: "daga",
    mn: "эсвэл",
    ff: "daga",
    yo: "lati",
  },
  Filter: {
    en: "Filter",
    pt: "Filtrar",
    de: "Filtern",
    es: "Filtrar",
    fr: "Filtrer",
    it: "Filtrare",
    sq: "Filtro",
    tr: "Filtrele",
    sw: "Chuja",
    am: "ገጽታ",
    om: "Baafata",
    ig: "Chọpụtaghachi",
    so: "Sifee",
    ha: "Filter",
    mn: "Шалгах",
    ff: "Sifee",
    yo: "Ṣatunkọ",
  },
  Favorites: {
    en: "Favorites",
    pt: "Favoritos",
    de: "Favoriten",
    es: "Favoritos",
    fr: "Favoris",
    it: "Preferiti",
    sq: "Preferencat",
    tr: "Favoriler",
    sw: "Kipenzi",
    am: "የወደደ ነገር",
    om: "Fayyadama",
    ig: "Ndị kasị amasị",
    so: "Doorashooyinka",
    ha: "Masu soyu",
    mn: "Таалагдсан",
    ff: "Masu soyu",
    yo: "Ayebaye",
  },
  "Free trial": {
    en: "Free trial",
    pt: "Teste gratuito",
    de: "Kostenlose Testversion",
    es: "Prueba gratuita",
    fr: "Essai gratuit",
    it: "Prova gratuita",
    sq: "Prova falas",
    tr: "Ücretsiz deneme",
    sw: "Jaribio la bure",
    am: "የነጻ ሙከራ",
    om: "Muddama bilisummaa",
    ig: "Njem n'efu",
    so: "Tijaabo bilaash ah",
    ha: "Bari'a kyautar",
    mn: "Үнэгүй туршилт",
    ff: "Bari'a kyautar",
    yo: "Igbiyanju ọfẹ",
  },
  "Fill the name": {
    en: "Fill the name",
    pt: "Preencha o nome",
    de: "Name ausfüllen",
    es: "Rellene el nombre",
    fr: "Remplissez le nom",
    it: "Compila il nome",
    sq: "Mbusheni emrin",
    tr: "Adı doldurun",
    sw: "Jaza jina",
    am: "ስም ይሙሉ",
    om: "Maqaa guutaa",
    ig: "Jupụta aha",
    so: "Buuxi magaca",
    ha: "Cika sunan",
    mn: "Нэрийг бөглөх",
    ff: "Cika sunan",
    yo: "Kó orúkọ",
  },
  "Forgot Your Password": {
    en: "Forgot Your Password",
    pt: "Esqueceu sua senha",
    de: "Passwort vergessen",
    es: "¿Olvidó su contraseña?",
    fr: "Mot de passe oublié",
    it: "Hai dimenticato la password",
    sq: "Keni harruar fjalëkalimin tuaj",
    tr: "Şifrenizi mi unuttunuz?",
    sw: "Umesahau nywila yako",
    am: "የእርስዎን ይለፍ ቃል አለዎት?",
    om: "Karaa sirna kootii birriinu?",
    ig: "Iwepụtaghị okwuntughe gị?",
    so: "Maalin mooshin keygaaga?",
    ha: "Ka manta kalmar wucewa",
    mn: "Нууц үгээ мартсан уу?",
    ff: "Ka manta kalmar wucewa",
    yo: "Ṣe o gbagbe ọrọ igbaniwọle rẹ?",
  },
  "Free trial expired": {
    en: "Free trial expired",
    pt: "Teste gratuito expirado",
    de: "Kostenlose Testversion abgelaufen",
    es: "Prueba gratuita caducada",
    fr: "Essai gratuit expiré",
    it: "Prova gratuita scaduta",
    sq: "Prova falas ka skaduar",
    tr: "Ücretsiz deneme süresi doldu",
    sw: "Jaribio la bure limeisha",
    am: "የነጻ ሙከራ ይወዳድር",
    om: "Muddama bilisummaa dhumateera",
    ig: "Njem n'efu kwụsịrị",
    so: "Tijaabo bilaash ah waa dhammaatay",
    ha: "Bari'a kyautar ta kare",
    mn: "Үнэгүй туршилт дууссан",
    ff: "Bari'a kyautar ta kare",
    yo: "Igbiyanju ọfẹ ti pari",
  },
  "Failed to copy text to clipboard": {
    en: "Failed to copy text to clipboard",
    pt: "Falha ao copiar texto para a área de transferência",
    de: "Text konnte nicht in die Zwischenablage kopiert werden",
    es: "Error al copiar texto al portapapeles",
    fr: "Échec de la copie du texte dans le presse-papiers",
    it: "Impossibile copiare il testo negli appunti",
    sq: "Dështoi në kopjimin e tekstit në të dhënat e transferimit",
    tr: "Metin panoya kopyalanamadı",
    sw: "Kopi ya maandiko haiwezekani",
    am: "የጽሁፍ ኮፒ አልቻልኩም",
    om: "Barruu kaawwachuuf dirqama hin arganne",
    ig: "Kopịrị ederede nke a na nkwado",
    so: "Qoraalka looma koobiyey",
    ha: "Yin yin rubutu zuwa katako",
    mn: "Текстийг хуулахад алдаа гарлаа",
    ff: "Yin yin rubutu zuwa katako",
    yo: "Ko ko ọrọ naa si isalẹ",
  },
  "Free trial days": {
    en: "Free trial days",
    pt: "Dias de teste gratuito",
    de: "Tage der kostenlosen Testversion",
    es: "Días de prueba gratuita",
    fr: "Jours d'essai gratuit",
    it: "Giorni di prova gratuita",
    sq: "Ditë prova falas",
    tr: "Ücretsiz deneme günleri",
    sw: "Siku za jaribio la bure",
    am: "የነጻ ሙከራ ቀናት",
    om: "Guyyaa bilisummaa",
    ig: "Ụbọchị nnwale n'efu",
    so: "Maalmaha tijaabada bilaashka ah",
    ha: "Kwanakin gwaji kyauta",
    mn: "Үнэгүй туршилтын өдөр",
    ff: "Kwanakin gwaji kyauta",
    yo: "Awọn ọjọ idanwo ọfẹ",
  },
  "Free trial expired date": {
    en: "Free trial expired date",
    pt: "Data de expiração do período de avaliação gratuita",
    de: "Datum des Ablaufs der kostenlosen Testversion",
    es: "Fecha de vencimiento de la prueba gratuita",
    fr: "Date d'expiration de l'essai gratuit",
    it: "Data di scadenza del periodo di prova gratuito",
    sq: "Data e skadimit të provës së falas",
    tr: "Ücretsiz deneme süresi sona erdi tarihi",
    sw: "Tarehe ya kumalizika kwa jaribio la bure",
    am: "የነጻ ሙከራ ወቅት ይወዳድር",
    om: "Guyyaa bilisummaa dhumateera",
    ig: "Ụbọchị nnwale n'efu kwụsịrị",
    so: "Taariikhda tijaabada bilaashka ah way dhammaatay",
    ha: "Ranar gwaji kyauta ya kare",
    mn: "Үнэгүй туршилтын хугацаа дууссан",
    ff: "Ranar gwaji kyauta ya kare",
    yo: "Ọjọ ipari idanwo ọfẹ",
  },
  "Forget your password? No need to worry": {
    en: "Forget your password? No need to worry.",
    pt: "Esqueceu sua senha? Não precisa se preocupar.",
    de: "Passwort vergessen? Keine Sorge.",
    es: "¿Olvidaste tu contraseña? No te preocupes.",
    fr: "Oublié votre mot de passe ? Pas besoin de s'inquiéter.",
    it: "Hai dimenticato la password? Nessun problema.",
    sq: "Keni harruar fjalëkalimin tuaj? S'ka nevojë për shqetësim.",
    tr: "Şifrenizi mi unuttunuz? Endişelenmeyin.",
    sw: "Umesahau nywila yako? Usijali.",
    am: "የእርስዎን ይለፍ ቃል አለዎት? ተመን ይባላል.",
    om: "Karaa sirna kootii birriinu? Yaada hinqabdu.",
    ig: "Kwechiri okwuntughe gị? Ekwesịrị ka ị chepụta.",
    so: "Maalin mooshin keygaaga? Ha weli na walwalin.",
    ha: "Ka manta kalmar wucewa? Kada ka damu.",
    mn: "Нууц үгээ мартсан уу? Санаа зовох хэрэггүй.",
    ff: "Kada ka damu.",
    yo: "Ṣe o gbagbe ọrọ igbaniwọle rẹ? Ma ṣe yọ ara rẹ lẹnu.",
  },
  "For every 1 activation Expired: Devices whose": {
    en: "For every 1 activation will be charged 1 credit from your account. Expired: Devices whose active 1-year period has been expired and they need to be activated to continue using the TV app.",
    pt: "Para cada ativação, será cobrado 1 crédito da sua conta. Expirado: Dispositivos cujo período ativo de 1 ano expirou e precisam ser ativados para continuar usando o aplicativo de TV.",
    de: "Für jede Aktivierung wird 1 Kredit von Ihrem Konto abgebucht. Abgelaufen: Geräte, deren aktiver 1-Jahres-Zeitraum abgelaufen ist und die aktiviert werden müssen, um die Nutzung der TV-App fortzusetzen.",
    es: "Por cada activación se cobrará 1 crédito de su cuenta. Expirado: Dispositivos cuyo período activo de 1 año ha expirado y necesitan ser activados para seguir utilizando la aplicación de TV.",
    fr: "Pour chaque activation, 1 crédit sera débité de votre compte. Expiré : Dispositifs dont la période active d'un an a expiré et qui doivent être activés pour continuer à utiliser l'application TV.",
    it: "Per ogni attivazione verrà addebitato 1 credito dal tuo account. Scaduti: Dispositivi il cui periodo attivo di 1 anno è scaduto e devono essere attivati per continuare a utilizzare l'app TV.",
    sq: "Për çdo aktivizim do të tarifizohet 1 kredit nga llogaria juaj. Skaduar: Dispositivat të cilët janë skaduar periudha aktive 1-vjeçare dhe ata duhet të aktivizohen për të vazhduar përdorimin e aplikacionit TV.",
    tr: "Her bir aktivasyon için hesabınızdan 1 kredi alınacaktır. Süresi dolmuş: Aktif 1 yıllık süresi dolmuş cihazlar, TV uygulamasını kullanmaya devam etmek için etkinleştirilmesi gerekmektedir.",
    sw: "Kila uanzishaji 1 utatozwa mkopo 1 kutoka kwa akaunti yako. Imeisha: Vifaa ambavyo kipindi chao cha mwaka 1 cha shughuli kimeisha na vinahitaji kuanzishwa tena ili kuendelea kutumia programu ya TV.",
    am: "ለእያንዳንዱ አንድ አካል ከአካውንትዎ ውስጥ አንድ ክሬዲት ይከፈል። የተረገሙ: የ1 ዓመት ጊዜ ወቅታቸው ከሚያሳይ ቀን ወይም ይቀመጡ ይኖር ይገባል።",
    om: "Haalota: Hojii 1 shor kanas 1 koottu si na iraachi si geewachi መሪ ማዋባ ወይ ይጠባል. Kuru: Dhaadhocha dhufaa fuudhi 1's jilli miitoo kandu dhaadhu faazee duniyaa.",
    ig: "N'ihi na ọ bụla 1 activation ga-efunye 1 credit si na akaụntụ gị. Kwụsịrị: Ngwa ahụ nke 1 afọ ya kpụchiri, na ọ dị mkpa ka etinye ha ọzọ ka ha na-aga n'ihu na-eji ngwa TV.",
    so: "Kii: Midabaha 1-joogto ah ayaa lagu dalacayaa xisaabtaada. Dhammay: Qalabka muddada firfircoon 1-sano uu dhamaaday waana in la firfircooni si loo sii isticmaalo app-ka TV.",
    ha: "Don kowanne 1 activation, za a caji 1 credit daga asusunka. Anan: Na'urorin da suka cika shekara 1 sun kare, kuma dole ne a kunna su don ci gaba da amfani da aikace-aikacen talabijin.",
    mn: "1 идэвхжүүлэлт бүрийн төлбөрийг таны данснаас 1 кредит авах болно. Хугацаа дууссан: 1 жилийн идэвхтэй хугацаа дууссан төхөөрөмжүүд бөгөөд TV програмыг үргэлжлүүлэхийн тулд идэвхжүүлэх шаардлагатай.",
    ff: "Kwanakin da aka yi amfani da su sun yi karshuwa da shekara 1, dole ne a canza su don ci gaba da amfani da aikace-aikacen talabijin.",
    yo: "Fun gbogbo 1 ṣiṣẹda, 1 kirẹditi yoo gba lati akọọlẹ rẹ. Iparun: Awọn ẹrọ ti akoko iṣẹ 1 ọdún ti pari, ati pe o nilo lati mu wọn ṣiṣẹ lati tẹsiwaju lilo ohun elo TV.",
  },
  "For every 1 activation will be charged 1 credit from your account": {
    en: "For every 1 activation will be charged 1 credit from your account.",
    pt: "Para cada ativação, será cobrado 1 crédito da sua conta.",
    de: "Für jede Aktivierung wird 1 Kredit von Ihrem Konto abgebucht.",
    es: "Por cada activación se cobrará 1 crédito de su cuenta.",
    fr: "Pour chaque activation, 1 crédit sera débité de votre compte.",
    it: "Per ogni attivazione verrà addebitato 1 credito dal tuo account.",
    sq: "Për çdo aktivizim do të tarifizohet 1 kredit nga llogaria juaj.",
    tr: "Her bir aktivasyon için hesabınızdan 1 kredi alınacaktır.",
    sw: "Kila uanzishaji 1 utatozwa mkopo 1 kutoka kwa akaunti yako.",
    am: "ለእያንዳንዱ አካል ከአካውንትዎ ውስጥ አንድ ክሬዲት ይከፈል።",
    om: "Haalota 1 shor kanas 1 koottu si na iraachi si geewachi.",
    ig: "N'ihi na ọ bụla 1 activation ga-efunye 1 credit si na akaụntụ gị.",
    so: "Kii: Midabaha 1-joogto ah ayaa lagu dalacayaa xisaabtaada.",
    ha: "Don kowanne 1 activation, za a caji 1 credit daga asusunka.",
    mn: "1 идэвхжүүлэлт бүрийн төлбөрийг таны данснаас 1 кредит авах болно.",
    ff: "Kwanakin da aka yi amfani da su sun yi karshuwa da shekara 1.",
    yo: "Fun gbogbo 1 ṣiṣẹda, 1 kirẹditi yoo gba lati akọọlẹ rẹ.",
  },
  "If enabled, resellers will be able to log in to their devices using your DNS":
    {
      en: "If enabled, resellers will be able to log in to their devices using your DNS.",
      pt: "Se ativado, os revendedores poderão fazer login em seus dispositivos usando seu DNS.",
      de: "Wenn aktiviert, können Wiederverkäufer sich mit ihren Geräten über Ihr DNS anmelden.",
      es: "Si está habilitado, los revendedores podrán iniciar sesión en sus dispositivos utilizando su DNS.",
      fr: "Si activé, les revendeurs pourront se connecter à leurs appareils en utilisant votre DNS.",
      it: "Se abilitato, i rivenditori potranno accedere ai loro dispositivi utilizzando il tuo DNS.",
      sq: "Nëse aktivizohet, shitësit do të jenë në gjendje të kyçen në pajisjet e tyre duke përdorur DNS-in tuaj.",
      tr: "Etkinleştirildiğinde, bayiler cihazlarına sizin DNS'iniz ile giriş yapabilecekler.",
      sw: "Ikiwa imewezeshwa, wauzaji watakuwa na uwezo wa kuingia kwenye vifaa vyao kwa kutumia DNS yako.",
      am: "እንደተቀየረ ባለው ስርዓት ይህ ይሆናል እና በዚህ መለያ ውስጥ ይገኛሉ።",
      om: "Yoo cimee, daldaltoonni DNS kee fayyadamuudhaan meeshalee isaanii seenuu danda'u.",
      ig: "Ọ bụrụ na emechara, ndị na-ere ahịa ga-enwe ike ịbanye na ngwaọrụ ha site na DNS gị.",
      so: "Haddii la daayo, iibiyeyaashu waxay ku dhici doonaan qalabkooda adiga oo isticmaalaya DNS-kaaga.",
      ha: "Idan an kunna, masu sayarwa za su iya shiga cikin na'urorinsu ta amfani da DNS ɗinka.",
      mn: "Идэвхжүүлснээр, дахин борлуулагчид таны DNS-ийг ашиглан төхөөрөмжүүддээ нэвтрэх боломжтой болно.",
      ff: "Idan an kunna, masu sayarwa za su iya shiga cikin na'urorinsu ta amfani da DNS ɗinka.",
      yo: "Ti a ba laaye, awọn alagbata yoo ni anfani lati wọle si awọn ẹrọ wọn nipa lilo DNS rẹ.",
    },
  "is equal to": {
    en: "is equal to",
    pt: "é igual a",
    de: "ist gleich",
    es: "es igual a",
    fr: "est égal à",
    it: "è uguale a",
    sq: "është e barabartë me",
    tr: "eşittir",
    sw: "ni sawa na",
    am: "ነው ወይም",
    om: "wajji",
    ig: "nke dị ka",
    so: "waa la mid ah",
    ha: "ya zama",
    mn: "тэнцүү",
    ff: "na sabowa",
    yo: "dọgba pẹlu",
  },
  "IP address": {
    en: "IP address",
    pt: "Endereço IP",
    de: "IP-Adresse",
    es: "Dirección IP",
    fr: "Adresse IP",
    it: "Indirizzo IP",
    sq: "Adresa IP",
    tr: "IP adresi",
    sw: "anwani ya IP",
    am: "የአይፒ አድራሻ",
    om: "Ipi adreesii",
    ig: "adreesị IP",
    so: "cinwa IP",
    ha: "adireshin IP",
    mn: "IP хаяг",
    ff: "adireshin IP",
    yo: "adirẹsi IP",
  },
  ID: {
    en: "ID",
    pt: "ID",
    de: "ID",
    es: "ID",
    fr: "ID",
    it: "ID",
    sq: "ID",
    tr: "ID",
    sw: "ID",
    am: "መለኪያ",
    om: "ID",
    ig: "ID",
    so: "ID",
    ha: "ID",
    mn: "ID",
    ff: "ID",
    yo: "ID",
  },
  "Is trial": {
    en: "Is trial",
    pt: "É teste",
    de: "Ist Test",
    es: "Es prueba",
    fr: "Est un essai",
    it: "È prova",
    sq: "Është provë",
    tr: "Denemedir",
    sw: "Ni jaribio",
    am: "እንደ ሙከራ ነው",
    om: "Tiyaaraa",
    ig: "Nke a bụ nnwale",
    so: "Tijaabo ma ah",
    ha: "Gwaji ne",
    mn: "Шалгалт",
    ff: "Tijabi",
    yo: "Ijẹri ni",
  },
  "Is lifetime": {
    en: "Is lifetime",
    pt: "É vitalício",
    de: "Ist lebenslang",
    es: "Es de por vida",
    fr: "Est à vie",
    it: "È a vita",
    sq: "Është përjetësi",
    tr: "Sonsuzdur",
    sw: "Ni maisha yote",
    am: "የሕይወት ነው",
    om: "Wagaa",
    ig: "Nke a bụ ndụ",
    so: "Muddada nolosha",
    ha: "Na dindindin ne",
    mn: "Тамга",
    ff: "Rayuwa",
    yo: "O jẹ igbesi aye",
  },
  "Is active": {
    en: "Is active",
    pt: "Está ativo",
    de: "Ist aktiv",
    es: "Está activo",
    fr: "Est actif",
    it: "È attivo",
    sq: "Është aktiv",
    tr: "Aktif mi",
    sw: "Ni hai",
    am: "እንደ ኢንቨስተር ነው",
    om: "Itti fayyadamuu",
    ig: "Nke a bụ nke ọma",
    so: "Firfircooni",
    ha: "Aiki",
    mn: "Идэвхтэй",
    ff: "Aiki",
    yo: "O n ṣiṣẹ",
  },
  "Is online": {
    en: "Is online",
    pt: "Está online",
    de: "Ist online",
    es: "Está en línea",
    fr: "Est en ligne",
    it: "È online",
    sq: "Është në linjë",
    tr: "Çevrimi çevrimiçi",
    sw: "Ni mtandaoni",
    am: "ኦንላይን ነው",
    om: "Online dha",
    ig: "Nke a bụ online",
    so: "Khadka",
    ha: "Ana kan layi",
    mn: "Онлайн",
    ff: "Online",
    yo: "O wa lori intanẹẹti",
  },
  "Input name": {
    en: "Input name",
    pt: "Nome de entrada",
    de: "Eingabename",
    es: "Nombre de entrada",
    fr: "Nom d'entrée",
    it: "Nome di input",
    sq: "Emri i hyrjes",
    tr: "Girdi adı",
    sw: "Ingiza jina",
    am: "የስም መግቢያ",
    om: "Maqaa galchi",
    ig: "Tinye aha",
    so: "Geli magaca",
    ha: "Shigar da suna",
    mn: "Нэр оруул",
    ff: "Shigar da suna",
    yo: "Tẹ orukọ",
  },
  "Input Host": {
    en: "Input Host",
    pt: "Host de entrada",
    de: "Eingabe-Host",
    es: "Host de entrada",
    fr: "Hôte d'entrée",
    it: "Host di input",
    sq: "Hosti i hyrjes",
    tr: "Girdi Ana Bilgisi",
    sw: "Ingiza mwenyeji",
    am: "የመነሻ መግቢያ",
    om: "Maqaa dhaa galchi",
    ig: "Tinye Host",
    so: "Geli martida",
    ha: "Shigar da Mai masauki",
    mn: "Хост оруул",
    ff: "Shigar da Mai masauki",
    yo: "Tẹ Host",
  },
  "Input API key": {
    en: "Input API key",
    pt: "Chave da API de entrada",
    de: "API-Schlüssel eingeben",
    es: "Clave de API de entrada",
    fr: "Clé API d'entrée",
    it: "Chiave API di input",
    sq: "Çelësi API i hyrjes",
    tr: "API anahtarını girin",
    sw: "Ingiza funguo ya API",
    am: "የAPI ቁልፍ መግቢያ",
    om: "Furdaa API galchi",
    ig: "Tinye igodo API",
    so: "Geli furaha API",
    ha: "Shigar da mabuɗin API",
    mn: "API түлхүүр оруул",
    ff: "Shigar da mabuɗin API",
    yo: "Tẹ bọtini API",
  },
  "Input a number": {
    en: "Input a number",
    pt: "Insira um número",
    de: "Geben Sie eine Zahl ein",
    es: "Ingrese un número",
    fr: "Entrez un numéro",
    it: "Inserisci un numero",
    sq: "Vendos një numër",
    tr: "Bir sayı girin",
    sw: "Ingiza nambari",
    am: "ቁጥር ይግቡ",
    om: "Lakkofsa galchi",
    ig: "Tinye nọmba",
    so: "Geli tiro",
    ha: "Shigar da lamba",
    mn: "Тоон оруул",
    ff: "Shigar da lamba",
    yo: "Tẹ nọmba",
  },
  "Input access code": {
    en: "Input access code",
    pt: "Código de acesso de entrada",
    de: "Zugangscode eingeben",
    es: "Código de acceso de entrada",
    fr: "Code d'accès d'entrée",
    it: "Inserisci il codice di accesso",
    sq: "Vendosni kodin e hyrjes",
    tr: "Erişim kodunu girin",
    sw: "Ingiza msimbo wa ufikiaji",
    am: "የመዳን ኮድ ይግቡ",
    om: "Akkesii banuu galchi",
    ig: "Tinye koodu nnweta",
    so: "Geli koodhka gelitaanka",
    ha: "Shigar da lambar samun shiga",
    mn: "Нэвтрэх код оруул",
    ff: "Shigar da lambar samun shiga",
    yo: "Tẹ koodu iraye",
  },
  "Insert the host of your Xtream Playlist": {
    en: "Insert the host of your Xtream Playlist",
    pt: "Insira o host da sua Playlist Xtream",
    de: "Geben Sie den Host Ihrer Xtream-Playlist ein",
    es: "Inserte el host de su lista de reproducción de Xtream",
    fr: "Insérez l'hôte de votre liste de lecture Xtream",
    it: "Inserisci l'host della tua Playlist Xtream",
    sq: "Vendosni hostin e listës së juaj Xtream",
    tr: "Xtream Oynatma Listenizin ana bilgisini girin",
    sw: "Weka mwenyeji wa Orodha ya Xtream yako",
    am: "የXtream ዝርዝርዎ የመነሻ ይኖር",
    om: "Maqaa Xtream Pileetii keeti galchi",
    ig: "Tinye onye ọ hostụ nke Xtream Playlist gị",
    so: "Geli martida ee Xtream Playlist-kaaga",
    ha: "Shigar da mai masauki na jerin Xtream",
    mn: "Xtream жагсаалтаа хост оруул",
    ff: "Shigar da mai masauki na jerin Xtream",
    yo: "Tẹ olutọju ti Xtream Playlist rẹ",
  },
  "In first, create a title and a description for your text": {
    en: "In first, create a title and a description for your text.",
    pt: "Primeiro, crie um título e uma descrição para o seu texto.",
    de: "Erstellen Sie zuerst einen Titel und eine Beschreibung für Ihren Text.",
    es: "En primer lugar, cree un título y una descripción para su texto.",
    fr: "Tout d'abord, créez un titre et une description pour votre texte.",
    it: "Innanzitutto, crea un titolo e una descrizione per il tuo testo.",
    sq: "Fillimisht, krijoni një titull dhe një përshkrim për tekstin tuaj.",
    tr: "Öncelikle, metniniz için bir başlık ve açıklama oluşturun.",
    sw: "Kwanza, tengeneza kichwa na maelezo kwa maandiko yako.",
    am: "በመጀመሪያ፣ ለጽሁፍዎ የሚሰጥ ርዕስ እና መግለጫ ይፍጠሩ።",
    om: "Jalqaba, mataduree fi ibsa barreessitootaa uumaa.",
    ig: "N'ozuzu, mepụta aha na nkọwa maka ederede gị.",
    so: "Ugu horeyn, samee cinwaanka iyo sharraxaad qoraalkaaga.",
    ha: "Da farko, ƙirƙiri taken da bayani don rubutunku.",
    mn: "Эхлээд, текстийнхээ гарчиг болон тайлбарыг үүсгэ.",
    ff: "Da farko, ƙirƙiri taken da bayanin rubutun ku.",
    yo: "Ni akọkọ, ṣẹda akọle ati apejuwe fun ọrọ rẹ.",
  },
  "It can take from few minutes to hours depending on the device count": {
    en: "It can take from a few minutes to hours depending on the device count.",
    pt: "Pode levar de alguns minutos a horas, dependendo da contagem de dispositivos.",
    de: "Es kann je nach Geräteanzahl einige Minuten bis Stunden dauern.",
    es: "Puede tomar desde unos minutos hasta horas, dependiendo de la cantidad de dispositivos.",
    fr: "Cela peut prendre quelques minutes à quelques heures en fonction du nombre de dispositifs.",
    it: "Ciò può richiedere da pochi minuti a ore, a seconda del numero di dispositivi.",
    sq: "Mund të zgjasë nga disa minuta deri në orë, në varësi të numrit të pajisjeve.",
    tr: "Cihaz sayısına bağlı olarak birkaç dakikadan saate kadar sürebilir.",
    sw: "Inaweza kuchukua kutoka dakika chache hadi masaa kadhaa kulingana na idadi ya vifaa.",
    am: "የተገናኝቷል መጠን በሚያስፈልግ ጊዜ ከምንም ገና ወቅት ወደ ሰዓት ይሆናል።",
    om: "Yoo gaarii tarree haga tokko hanga sa'aati 2 qofti yeroo qalama.",
    ig: "O nwere ike iwepụta nkeji ole na ole ruo awa, dabere na ọnụ ọgụgụ ngwaọrụ.",
    so: "Waxay qaadan kartaa daqiiqado kooban ilaa saacado badan iyadoo ku xiran tirada qalabka.",
    ha: "Zai iya ɗaukar mintuna kaɗan zuwa sa'o'i dangane da adadin na'urorin.",
    mn: "Төхөөрөмжийн тооноос хамаарч хэдэн минут, хэдэн цагийн хооронд байх боломжтой.",
    ff: "Zai iya ɗaukar mintuna kaɗan zuwa sa'o'i bisa ga adadin na'urorin.",
    yo: "O le gba lati awọn iṣẹju diẹ si awọn wakati da lori nọmba awọn ẹrọ.",
  },
  "If you want to activate the device after deactivating it, you must spend 1 credit from your account":
    {
      en: "If you want to activate the device after deactivating it, you must spend 1 credit from your account.",
      pt: "Se desejar ativar o dispositivo após desativá-lo, você deve gastar 1 crédito da sua conta.",
      de: "Wenn Sie das Gerät nach der Deaktivierung aktivieren möchten, müssen Sie 1 Guthaben von Ihrem Konto verwenden.",
      es: "Si desea activar el dispositivo después de desactivarlo, debe gastar 1 crédito de su cuenta.",
      fr: "Si vous souhaitez activer l'appareil après l'avoir désactivé, vous devez dépenser 1 crédit de votre compte.",
      it: "Se desideri attivare il dispositivo dopo averlo disattivato, devi spendere 1 credito dal tuo account.",
      sq: "Nëse dëshironi të aktivizoni pajisjen pasi ta keni çaktivizuar, duhet të shpenzoni 1 kredit nga llogaria juaj.",
      tr: "Cihazı devre dışı bıraktıktan sonra etkinleştirmek istiyorsanız, hesabınızdan 1 kredi harcamanız gerekir.",
      sw: "Ikiwa unataka kuanzisha kifaa baada ya kukifuta, lazima utumie krediti 1 kutoka akaunti yako.",
      am: "ከመነሻ ይቀመጡ ወይም ይቀመጡ ይፈልጉ ከዚያ 1 ይለጠፉ ምን አካውንት.",
      om: "Yoo qalbii makami deebi'aa fi, 1 maallaqa si'a oolmaa dhumatu.",
      ig: "Ọ bụrụ na ịchọrọ ịkpọpụ ihe nchịkọta ahụ mgbe ị kwụsịrị ya, ị ga-etinye 1 kredit site na akaụntụ gị.",
      so: "Haddii aad rabto inaad firfircoon tahay qalabka ka dib markaad ka firfircoonayso, waa inaad bixisaa 1 credit oo ka socota akoonkaaga.",
      ha: "Idan kuna son kunna na'urar bayan an kashe ta, dole ne ku kashe kudi 1 daga asusun ku.",
      mn: "Төхөөрөмжийг идэвхжүүлэхийг хүсвэл 1 кредит зарцуулах шаардлагатай.",
      ff: "Idan kuna son kunna na'urar bayan an kashe ta, dole ne ku kashe kudi 1 daga asusun ku.",
      yo: "Ti o ba fẹ lati mu ẹrọ ṣiṣẹ lẹhin ti o ti pa a, o gbọdọ lo 1 kirẹditi lati akọọlẹ rẹ.",
    },
  "If this option is enabled, resellers will have the option to automatically register with you as sub resellers. If not, you have to manually accept their requests.":
    {
      en: "If this option is enabled, resellers will have the option to automatically register with you as sub resellers. If not, you have to manually accept their requests.",
      pt: "Se esta opção estiver ativada, os revendedores terão a opção de se registrar automaticamente como sub-revendedores. Caso contrário, você terá que aceitar manualmente as solicitações deles.",
      de: "Wenn diese Option aktiviert ist, haben Wiederverkäufer die Möglichkeit, sich automatisch als Unterverkäufer bei Ihnen zu registrieren. Andernfalls müssen Sie ihre Anfragen manuell akzeptieren.",
      es: "Si esta opción está habilitada, los revendedores tendrán la opción de registrarse automáticamente contigo como subrevendedores. De lo contrario, tendrás que aceptar manualmente sus solicitudes.",
      fr: "Si cette option est activée, les revendeurs auront la possibilité de s'inscrire automatiquement chez vous en tant que sous-revendeurs. Sinon, vous devrez accepter manuellement leurs demandes.",
      it: "Se questa opzione è abilitata, i rivenditori avranno la possibilità di registrarsi automaticamente con te come sub-rivenditori. In caso contrario, dovrai accettare manualmente le loro richieste.",
      sq: "Nëse ky opsion është i aktivizuar, rishitësit do të kenë mundësinë të regjistrohen automatikisht si nën-ri-shitës me ju. Nëse jo, duhet të pranoni manualisht kërkesat e tyre.",
      tr: "Bu seçenek etkinleştirilirse, bayiler alt bayi olarak sizinle otomatik olarak kaydolma seçeneğine sahip olacaktır. Aksi takdirde, taleplerini manuel olarak onaylamanız gerekir.",
      sw: "Ikiwa chaguo hili limewezeshwa, wauzaji watakuwa na chaguo la kujiandikisha kiotomatiki kwako kama wauzaji wadogo. Ikiwa sivyo, lazima ukubali maombi yao kwa mikono.",
      am: "ይህ አማራጭ ተይዞ ከሆነ፣ የአባል ምድብ ይሰጣሉ ወይም የአማራጭ ዝርዝር አይገኝም።",
      om: "Yoo filannoon kun jiraate, kireessitoonni si waliin bifa qindaa'aa ta'een kireessiidhaan galmaayuu ni danda'u. Yoo akkas ta'e, gaafilee isaanii harka keessaan simachuu qabda.",
      ig: "Ọ bụrụ na a kwadoro nhọrọ a, ndị na-ere ahịa ga-enwe nhọrọ iji debe onwe ha dịka ndị na-ere ahịa ndị na-erughị eru. Ọ bụrụ na ọ bụghị, ị ga-enwerịrị ịnakwere arịrịọ ha n'ụzọ aka.",
      so: "Haddii ikhtiyaarkan la hawlgeliyo, iibiyeyaasha waxay yeelan doonaan ikhtiyaarka inay si otomaatig ah kuu diiwaangeliyaan sida iibiyeyaasha hoosaadka. Haddii kale, waa inaad si gacanta ah u aqbashaa codsiyadooda.",
      ha: "Idan wannan zaɓin ya kasance a kunne, masu sayarwa za su sami zaɓi na yin rajistar kansu ta atomatik a matsayin ƙananan masu sayarwa tare da ku. Idan ba haka ba, dole ne ku karɓi buƙatunsu da hannu.",
      mn: "Энэ сонголт идэвхтэй бол, дистрибьюторууд та нарыг дэд дистрибьютор болохоор автоматаар бүртгүүлэх боломжтой болно. Үгүй бол та тэдний хүсэлтүүдийг гараар хүлээн авах ёстой.",
      ff: "Idan wannan zaɓin yana aiki, masu sayarwa za su sami zaɓi na yin rajistar kansu ta atomatik a matsayin ƙananan masu sayarwa tare da ku. Idan ba haka ba, dole ne ku karɓi buƙatunsu da hannu.",
      yo: "Ti a ba aṣayan yii ni agbara, awọn olutaja yoo ni aṣayan lati forukọsilẹ laifọwọyi pẹlu rẹ gẹgẹbi awọn olutaja kekere. Ti ko ba bẹ, o ni lati gba awọn ibeere wọn ni ọwọ.",
    },
  "Incorrect password": {
    en: "Incorrect password",
    pt: "Senha incorreta",
    de: "Falsches Passwort",
    es: "Contraseña incorrecta",
    fr: "Mot de passe incorrect",
    it: "Password errata",
    sq: "Fjalëkalim i pasaktë",
    tr: "Yanlış şifre",
    sw: "Nenosiri si sahihi",
    am: "የተሳሳተ የይለፍ ቁልፍ",
    om: "Nenosiri dogoggora",
    ig: "Paswọọdụ adịghị mma",
    so: "Eeg, furaha khaladka ah",
    ha: "Kalmar sirrin ba daidai ba",
    mn: "Буруу нууц үг",
    ff: "Kalmar sirrin ba daidai ba",
    yo: "Ọrọigbani ko tọ",
  },
  "If this option is enabled, users will be able to log in to the app. If not, you will have to manually add the playlist to the device.":
    {
      en: "If this option is enabled, users will be able to log in to the app. If not, you will have to manually add the playlist to the device.",
      pt: "Se esta opção estiver ativada, os usuários poderão fazer login no aplicativo. Caso contrário, você terá que adicionar a lista de reprodução manualmente ao dispositivo.",
      de: "Wenn diese Option aktiviert ist, können sich Benutzer in der App anmelden. Andernfalls müssen Sie die Wiedergabeliste manuell zum Gerät hinzufügen.",
      es: "Si esta opción está habilitada, los usuarios podrán iniciar sesión en la aplicación. De lo contrario, deberá agregar la lista de reproducción al dispositivo manualmente.",
      fr: "Si cette option est activée, les utilisateurs pourront se connecter à l'application. Sinon, vous devrez ajouter la liste de lecture manuellement à l'appareil.",
      it: "Se questa opzione è abilitata, gli utenti potranno accedere all'app. In caso contrario, dovrai aggiungere manualmente la playlist al dispositivo.",
      sq: "Nëse ky opsion është i aktivizuar, përdoruesit do të jenë në gjendje të kyçen në aplikacion. Nëse jo, do të duhet ta shtoni manualisht listën e përgjërimeve në pajisjen.",
      tr: "Bu seçenek etkinleştirilirse, kullanıcılar uygulamaya giriş yapabileceklerdir. Aksi takdirde, oynatma listesini cihaza manuel olarak eklemeniz gerekecek.",
      sw: "Ikiwa chaguo hili limewezeshwa, watumiaji wataweza kuingia kwenye programu. Ikiwa sivyo, lazima uongeze orodha ya nyimbo kwenye kifaa kwa mikono.",
      am: "ይህ አማራጭ ተይዞ ከሆነ፣ ተጠቃሚዎች ወደ አፕ ሊገቡ ይችላሉ። አይደለም፣ ወደ ክልል ውስጥ ወይ ይወስድ ወይም ይልጅ።",
      om: "Yoo filannoon kun jiraate, fayyadamtoonni app keessa seenuu ni danda'u. Yoo akkas ta'e, viidiyoo tuqaa gara mishiitti dabaluu qabda.",
      ig: "Ọ bụrụ na a kwadoro nhọrọ a, ndị ọrụ ga-enwe ike ịbanye na ngwa. Ọ bụrụ na ọ bụghị, ị ga-enwerịrị ịgbakwunye ndepụta egwu ahụ na ngwaọrụ ahụ n'ụzọ aka.",
      so: "Haddii ikhtiyaarkan la hawlgeliyo, isticmaaleyaashu waxay awoodi doonaan inay ku galaan app-ka. Haddii kale, waa inaad si gacanta ah u darto liiska ciyaarta qalabka.",
      ha: "Idan wannan zaɓin ya kasance a kunne, masu amfani za su iya shiga cikin aikace-aikacen. Idan ba haka ba, dole ne ku ƙara jerin waƙoƙin zuwa na'urar da hannu.",
      mn: "Энэ сонголт идэвхтэй бол хэрэглэгчид апп руу нэвтрэх боломжтой болно. Хэрэв үгүй бол та жагсаалтыг төхөөрөмжид гараар нэмэх хэрэгтэй.",
      ff: "Idan wannan zaɓin yana aiki, masu amfani za su iya shiga cikin aikace-aikacen. Idan ba haka ba, dole ne ku ƙara jerin waƙoƙin zuwa na'urar da hannu.",
      yo: "Ti a ba aṣayan yii ni agbara, awọn olumulo yoo ni anfani lati buwolu wọle si ohun elo. Ti ko ba bẹ, o ni lati fi playlist kun si ẹrọ naa ni ọwọ.",
    },
  "It is available for Smart TVs and a new mobile version": {
    en: "It is available for Smart TVs and a new mobile version. You can already set a default language for the app from here, making it easier to adapt the app for different language preferences. It is available for Smart TVs and a new mobile version.",
    pt: "Está disponível para Smart TVs e uma nova versão móvel. Você já pode definir um idioma padrão para o aplicativo daqui, facilitando a adaptação para diferentes preferências de idioma. Está disponível para Smart TVs e uma nova versão móvel.",
    de: "Es ist für Smart-TVs und eine neue mobile Version verfügbar. Sie können hier bereits eine Standardsprache für die App festlegen, um die App an verschiedene Sprachpräferenzen anzupassen. Es ist für Smart-TVs und eine neue mobile Version verfügbar.",
    es: "Está disponible para Smart TVs y una nueva versión móvil. Ya puede configurar un idioma predeterminado para la aplicación desde aquí, lo que facilita la adaptación de la aplicación a diferentes preferencias de idioma. Está disponible para Smart TVs y una nueva versión móvil.",
    fr: "Il est disponible pour les Smart TV et une nouvelle version mobile. Vous pouvez déjà définir une langue par défaut pour l'application ici, ce qui facilite l'adaptation de l'application aux différentes préférences linguistiques. Il est disponible pour les Smart TV et une nouvelle version mobile.",
    it: "È disponibile per Smart TV e una nuova versione mobile. Puoi già impostare una lingua predefinita per l'app da qui, rendendo più semplice adattare l'app alle diverse preferenze linguistiche. È disponibile per Smart TV e una nuova versione mobile.",
    sq: "Është e disponueshme për Smart TV dhe një version të ri celular. Ju tashmë mund të vendosni një gjuhë të paracaktuar për aplikacionin nga këtu, duke e bërë më të lehtë përshtatjen e aplikacionit për preferencat e ndryshme gjuhësore. Është e disponueshme për Smart TV dhe një version të ri celular.",
    tr: "Akıllı TV'ler ve yeni bir mobil sürüm için kullanılabilir. Buradan uygulama için varsayılan bir dil ayarlayarak uygulamanın farklı dil tercihleri için uyarlanmasını kolaylaştırabilirsiniz. Akıllı TV'ler ve yeni bir mobil sürüm için kullanılabilir.",

    sw: "Inapatikana kwa Smart TVs na toleo jipya la rununu. Tayari unaweza kuweka lugha ya msingi ya programu kutoka hapa, na kufanya iwe rahisi kubadilisha programu kwa mapendeleo tofauti ya lugha.",
    am: "ይህ ለSmart TVs እና አዲስ የሞባይል እትም እንደሚገኝ ነው። እነሆ፣ እንደዚህ በዚህ ላይ የተመለከተ ቋንቋ መምረጥ ይችላሉ ፣ ይህም ለበለጠ በንድፈ ዋና ይልቃው ነው። ይህ ለSmart TVs እና አዲስ የሞባይል እትም እንደሚገኝ ነው።",
    om: "Inni Smart TVs fi version haaraa mobile ijaarameera. As irraa afaan bu'uura ta'e app irratti qindeessuu dandeessu, kanaan haalota afaan garaagaraa irratti app qindaa'uu salphisa.",
    ig: "Ọ dị maka Smart TVs na ụdị mobile ọhụrụ. Ị nwere ike itinye asụsụ ndabara maka ngwa ahụ site na ebe a, na-eme ka o sie ike idozi ngwa ahụ maka mmasị asụsụ dị iche iche. Ọ dị maka Smart TVs na ụdị mobile ọhụrụ.",
    so: "Waxa laga heli karaa Smart TVs iyo nooc cusub oo mobil ah. Waxaad hore u dejin kartaa luqadda asaasiga ah ee app-ka halkan, taasoo sahlaysa in la waafajiyo app-ka doorashooyinka luqadda kala duwan. Waxa laga heli karaa Smart TVs iyo nooc cusub oo mobil ah.",
    ha: "Ana Smart TVs da sabuwar sigar tafiye ta zamani. Kuna iya saita yaren da za a yi amfani da shi don aikace-aikacen daga nan, wanda zai sa a sauƙaƙe daidaita aikace-aikacen don zaɓin yare na daban. Ana Smart TVs da sabuwar sigar tafiye ta zamani.",
    mn: "Ухаалаг телевизоруудад болон шинэ гар утасны хувилбартай. Эндээс апп-ын үндсэн хэлээ тохируулах боломжтой бөгөөд энэ нь апп-ыг өөр өөр хэлний сонголтод тохируулахад хялбар болно. Ухаалаг телевизоруудад болон шинэ гар утасны хувилбартай.",
    ff: "Ana Smart TVs da sabuwar sigar tafiye ta zamani. Kuna iya saita yaren da za a yi amfani da shi don aikace-aikacen daga nan, wanda zai sa a sauƙaƙe daidaita aikace-aikacen don zaɓin yare na daban. Ana Smart TVs da sabuwar sigar tafiye ta zamani.",
    yo: "O wa fun Smart TVs ati ẹya tuntun alagbeka kan. O le ti tẹlẹ ṣeto ede aiyipada fun ohun elo lati ibi, ṣiṣe ni irọrun lati ṣatunṣe ohun elo fun awọn ayanfẹ ede oriṣiriṣi. O wa fun Smart TVs ati ẹya tuntun alagbeka.",
  },
  //y
  "Your activations count": {
    en: "Your activations count",
    pt: "Sua contagem de ativações",
    de: "Ihre Aktivierungsanzahl",
    es: "Su cuenta de activaciones",
    fr: "Votre nombre d'activations",
    it: "Il conteggio delle tue attivazioni",
    sq: "Numri i aktivizimeve tuaja",
    tr: "Aktivasyon sayınız",
    sw: "Idadi ya uanzishaji wako",
    am: "የአንቀጽዎ ቁጥር አንቀጽ",
    om: "Walitti qabaa udukenna",
    ig: "Ọnụ ọgụgụ activation gị",
    so: "Tiradaada firfircoon",
    ha: "Yawan ayyukan ku",
    mn: "Таны идэвхжүүлэлт",
    ff: "Yawan ayyukan ku",
    yo: "Iwọn ṣiṣe rẹ",
  },
  "Your subresellers activations count": {
    en: "Your subresellers activations count",
    pt: "Contagem de ativações dos seus sub-revendedores",
    de: "Aktivierungsanzahl Ihrer Unterhändler",
    es: "Su cuenta de activaciones de subrevendedores",
    fr: "Le nombre d'activations de vos sous-revendeurs",
    it: "Conteggio delle attivazioni dei tuoi sub-rivenditori",
    sq: "Numri i aktivizimeve të nën-shitësve tuaj",
    tr: "Alt bayilerinizin aktivasyon sayısı",
    sw: "Idadi ya uanzishaji wa wachuuzi wako",
    am: "የእንደአንቀጽዎ የተወሰኑ ቁጥር",
    om: "Maqaa filadhu fiiluu galu",
    ig: "Ọnụ ọgụgụ activation nke ndị ahịa gị",
    so: "Tirada firfircoon ee ka qaybqaatahaaga",
    ha: "Yawan ayyukan 'yan kasuwa ku",
    mn: "Таны дэд борлуулагчдын идэвхжүүлэлт",
    ff: "Yawan ayyukan 'yan kasuwa ku",
    yo: "Iwọn ṣiṣe awọn subresellers rẹ",
  },
  "Your subresellers devices": {
    en: "Your subresellers devices",
    pt: "Dispositivos dos seus sub-revendedores",
    de: "Geräte Ihrer Unterhändler",
    es: "Dispositivos de sus subrevendedores",
    fr: "Les appareils de vos sous-revendeurs",
    it: "Dispositivi dei tuoi sub-rivenditori",
    sq: "Pajisjet e nën-shitësve tuaj",
    tr: "Alt bayilerinizin cihazları",
    sw: "Vifaa vya wachuuzi wako",
    am: "የእንደአንቀጽዎ የተወሰኑ መሣሪያዎች",
    om: "Hama laas ildi li haalu",
    ig: "Ngwa ndị ahịa gị",
    so: "Qalabka ka qaybqaatahaaga",
    ha: "Na'urorin 'yan kasuwa ku",
    mn: "Таны дэд борлуулагчдын төхөөрөмж",
    ff: "Na'urorin 'yan kasuwa ku",
    yo: "Awọn ẹrọ awọn subresellers rẹ",
  },
  "Your Free Trial Has Ended": {
    en: "Your Free Trial Has Ended",
    pt: "Seu Período de Teste Gratuito Terminou",
    de: "Ihre kostenlose Testversion ist abgelaufen",
    es: "Su prueba gratuita ha finalizado",
    fr: "Votre essai gratuit est terminé",
    it: "Il tuo periodo di prova gratuito è terminato",
    sq: "Periudha juaj falas e provës ka përfunduar",
    tr: "Ücretsiz deneme süreniz sona erdi",
    sw: "Jaribio lako la bure limeisha",
    am: "የእንደአንቀጽዎ ነፃ መርምር ተወው",
    om: "Jaarmaafi ni ishoobaafake",
    ig: "Nnwale gị efu agwụla",
    so: "Tijaabadaadu way dhammaatay",
    ha: "Gwajin ku ya kare",
    mn: "Таны үнэгүй туршилт дууссан",
    ff: "Gwajin ku ya kare",
    yo: "Igbasilẹ ọfẹ rẹ ti pari",
  },
  Yes: {
    en: "Yes",
    pt: "Sim",
    de: "Ja",
    es: "Sí",
    fr: "Oui",
    it: "Sì",
    sq: "Po",
    tr: "Evet",
    sw: "Ndio",
    am: "አዎ",
    om: "Eeyyee",
    ig: "Ee",
    so: "Haa",
    ha: "Eh",
    mn: "Тийм",
    ff: "Eh",
    yo: "Bẹẹni",
  },
  "Your guide": {
    en: "Your guide",
    pt: "Seu guia",
    de: "Ihr Leitfaden",
    es: "Tu guía",
    fr: "Votre guide",
    it: "La tua guida",
    sq: "Udhëzuesi juaj",
    tr: "Rehberiniz",
    sw: "Mwongozo wako",
    am: "መምሪያዎ",
    om: "Gorsa kee",
    ig: "Ntuziaka gị",
    so: "Hagahaaga",
    ha: "Jagoran ku",
    mn: "Таны гарын авлага",
    ff: "Jagoran ku",
    yo: "Itọsọna rẹ",
  },
  "Your devices": {
    en: "Your devices",
    pt: "Seus dispositivos",
    de: "Ihre Geräte",
    es: "Tus dispositivos",
    fr: "Vos appareils",
    it: "I tuoi dispositivi",
    sq: "Pajisjet tuaja",
    tr: "Cihazlarınız",
    sw: "Vifaa vyako",
    am: "የእንደአንቀጽዎ መሣሪያዎች",
    om: "Baqa dhugaa",
    ig: "Ngwa gị",
    so: "Qalabkaaga",
    ha: "Na'urorin ku",
    mn: "Таны төхөөрөмж",
    ff: "Na'urorin ku",
    yo: "Awọn ẹrọ rẹ",
  },
  "You cannot use branding": {
    en: "You cannot use branding!",
    pt: "Você não pode usar marca!",
    de: "Sie können keine Marken verwenden!",
    es: "¡No puedes usar la marca!",
    fr: "Vous ne pouvez pas utiliser de marque !",
    it: "Non puoi usare il marchio!",
    sq: "Nuk mund të përdorni markën!",
    tr: "Marka kullanamazsınız!",
    sw: "Huwezi kutumia alama ya biashara!",
    am: "በቃል መዝገቦች ላይ መጠቀም አትችሉ!",
    om: "Maaloo dandeessuu mul'ata!",
    ig: "Ị gaghị eji akara ngosi!",
    so: "Ma waxaad isticmaali kartaa summad!",
    ha: "Ba za ku yi amfani da alamomin!",
    mn: "Брэнд ашиглаж болохгүй!",
    ff: "Ba za ku yi amfani da alamomin!",
    yo: "O ko le lo iyasọtọ!",
  },
  "You will get email to email": {
    en: "You will get email to email",
    pt: "Você receberá um e-mail para o e-mail",
    de: "Sie erhalten eine E-Mail an die E-Mail-Adresse",
    es: "Recibirás un correo electrónico a tu correo electrónico",
    fr: "Vous recevrez un e-mail à l'adresse e-mail",
    it: "Riceverai un'e-mail all'indirizzo e-mail",
    sq: "Do të merrni email në email",
    tr: "E-posta almak için e-posta alacaksınız",
    sw: "Utapokea barua pepe kwa barua pepe",
    am: "የእንደአንቀጽዎ ኢሜይል ይቀበሉ",
    om: "Maaloo e-mail irratti argachuu!",
    ig: "I nwere email na email",
    so: "Waxaad heli doontaa iimayl ku saabsan iimayl",
    ha: "Za ku karbi imel din cikin imel",
    mn: "Танд имэйл хүлээн авах болно",
    ff: "Za ku karbi imel din cikin imel",
    yo: "Iwọ yoo gba imeeli si imeeli",
  },
  "Your account has been blocked": {
    en: "Your account has been blocked!",
    pt: "Sua conta foi bloqueada!",
    de: "Ihr Konto wurde gesperrt!",
    es: "Tu cuenta ha sido bloqueada!",
    fr: "Votre compte a été bloqué !",
    it: "Il tuo account è stato bloccato!",
    sq: "Llogaria juaj është bllokuar!",
    tr: "Hesabınız engellendi!",
    sw: "Akaunti yako imezuiliwa!",
    am: "የእንደአንቀጽዎ መለያ ተከስቷል!",
    om: "Akaawuntii kee dhaabbataa!",
    ig: "Akwụkwọ gị mechiri!",
    so: "Akaawntigaaga waa la xayiray!",
    ha: "An karɓi asusun ku!",
    mn: "Таны данс хаагдсан!",
    ff: "An karɓi asusun ku!",
    yo: "Iwọ ti dina akọọlẹ rẹ!",
  },

  "Your old password and new password are same": {
    en: "Your old password and new password are the same",
    pt: "Sua senha antiga e nova são iguais",
    de: "Ihr altes Passwort und Ihr neues Passwort sind identisch",
    es: "Tu antigua contraseña y nueva contraseña son iguales",
    fr: "Votre ancien mot de passe et votre nouveau mot de passe sont identiques",
    it: "La tua vecchia password e la nuova password sono uguali",
    sq: "Fjalëkalimi i vjetër dhe i ri janë të njëjtë",
    tr: "Eski şifreniz ve yeni şifreniz aynıdır",
    sw: "Neno lako la zamani na neno lako jipya ni sawa",
    am: "የእንደአንቀጽዎ የዚህ ዝርዝር እና አዲሱ ተመሳሳይ ነው",
    om: "Dhokumee si tarreechi",
    ig: "Okwuntughi ochie na ọhụrụ bụ otu",
    so: "Ereyadaadii hore iyo ereyada cusub waxay yihiin isku mid",
    ha: "Tsohon kalmar ku da sabo tana zabi",
    mn: "Хуучин нууц үг ба шинэ нууц үг ижил байна",
    ff: "Tsohon kalmar ku da sabo tana zabi",
    yo: "Ọrọ igbaniwọle atijọ rẹ ati tuntun ni ibè!",
  },
  "Your playlist will be autogenerated": {
    en: "Your playlist will be autogenerated",
    pt: "Sua lista de reprodução será gerada automaticamente",
    de: "Ihre Wiedergabeliste wird automatisch generiert",
    es: "Su lista de reproducción se generará automáticamente",
    fr: "Votre playlist sera générée automatiquement",
    it: "La tua playlist verrà generata automaticamente",
    sq: "Lista juaj e përgjegjësive do të gjenerohet automatikisht",
    tr: "Oynatma listeniz otomatik olarak oluşturulacaktır",
    sw: "Orodha yako itajengwa moja kwa moja",
    am: "የእንደአንቀጽዎ የማይነበር",
    om: "Galma bahiikoon beeluu",
    ig: "A ga-emepụta egwu gị na-akpaghị aka",
    so: "Liiskaaga waxa si toos ah loo soo saari doonaa",
    ha: "Za ku gina jerin ka'idar",
    mn: "Таны тоглолт автоматаар үүсгэгдэх болно",
    ff: "Za ku gina jerin ka'idar",
    yo: "Akọsilẹ rẹ yoo ṣẹda laifọwọyi",
  },
  "Your administrator restricted your permissions": {
    en: "Your administrator restricted your permissions",
    pt: "Seu administrador restringiu suas permissões",
    de: "Ihr Administrator hat Ihre Berechtigungen eingeschränkt",
    es: "Su administrador restringió sus permisos",
    fr: "Votre administrateur a restreint vos permissions",
    it: "Il tuo amministratore ha limitato le tue autorizzazioni",
    sq: "Administruesi juaj ka kufizuar lejet tuaja",
    tr: "Yönetici, izinlerinizi kısıtladı",
    sw: "Msimamizi wako amepunguza ruhusa zako",
    am: "የእንደአንቀጽዎ አስተዳደር የአካል ትዕዛዞችን ቀንሰው ይህ ይኖር",
    om: "Madda qooroo murteessaa ta'e",
    ig: "Onye nchịkwa gị gbochiri ikike gị",
    so: "Maamulahaaga ayaa xaddiday rukhsadahaaga",
    ha: "Mai kula da izinin ku",
    mn: "Таны администратор таны зөвшөөрлүүдийг хязгаарласан",
    ff: "Mai kula da izinin ku",
    yo: "Alakoso rẹ ti dènà awọn ẹtọ rẹ",
  },

  "You can use this tool to send notifications to your users": {
    en: "You can use this tool to send notifications to your users.",
    pt: "Você pode usar esta ferramenta para enviar notificações aos seus usuários.",
    de: "Sie können dieses Tool verwenden, um Benachrichtigungen an Ihre Benutzer zu senden.",
    es: "Puede utilizar esta herramienta para enviar notificaciones a sus usuarios.",
    fr: "Vous pouvez utiliser cet outil pour envoyer des notifications à vos utilisateurs.",
    it: "Puoi utilizzare questo strumento per inviare notifiche ai tuoi utenti.",
    sq: "Mund të përdorni këtë mjet për të dërguar njoftime tek përdoruesit tuaj.",
    tr: "Bu aracı kullanıcılarınıza bildirim göndermek için kullanabilirsiniz.",
    sw: "Unaweza kutumia chombo hiki kutuma arifa kwa watumiaji wako.",
    am: "ይህ መሳሪያ ወደ ተጠቃሚዎች መልእክቶችን ለማስተላለፍ ሊጠቀም ይችላል።",
    om: "Maqaa kanaan jalaaltoo guyyaatiif tokkichoo kan jedhamuu dha.",
    ig: "Ị nwere ike iji ngwá ọrụ a zipu ndị ọrụ gị ozi.",
    so: "Waxaad isticmaali kartaa qalabkan si aad ugu dirto digniino isticmaalayaashaaga.",
    ha: "Zaku iya amfani da wannan kayan aiki don aikawa da sanarwa ga masu amfani da ku.",
    mn: "Энэ хэрэгслийг таны хэрэглэгчдэд мэдэгдлүүд илгээхэд ашиглаж болно.",
    ff: "Zaku iya amfani da wannan kayan aiki don aikawa da sanarwa ga masu amfani da ku.",
    yo: "O le lo irinṣẹ yii lati fi awọn akiyesi ranṣẹ si awọn olumulo rẹ.",
  },
  "You have to wait until migration will be ended successfully": {
    en: "You have to wait until migration will be ended successfully.",
    pt: "Você precisa esperar até que a migração seja concluída com sucesso.",
    de: "Sie müssen warten, bis die Migration erfolgreich abgeschlossen ist.",
    es: "Debe esperar hasta que la migración se haya completado con éxito.",
    fr: "Vous devez attendre que la migration se termine avec succès.",
    it: "Devi aspettare che la migrazione sia completata con successo.",
    sq: "Duhet të prisni derisa migrimi të përfundojë me sukses.",
    tr: "Göç başarılı bir şekilde tamamlanana kadar beklemelisiniz.",
    sw: "Unapaswa kusubiri hadi uhamishaji ukamilike kwa mafanikio.",
    am: "እባኮትን እስከ ስለሆነ በድርሻው ይሞሉ ድረስ ይቆዩ።",
    om: "Gara dhufaa qaama galuwwaniif hanga milkeerra ni dandeessa.",
    ig: "I nwere ike ichere ruo mgbe ndenye ga-emecha nke ọma.",
    so: "Waa inaad sugto ilaa guuritaanka uu si guul leh u dhammaado.",
    ha: "Dole ne ku jira har yanzu har yanzu idan ana ƙare ku.",
    mn: "Шилжүүлэг амжилттай дуусах хүртэл хүлээх хэрэгтэй.",
    ff: "Dole ne ku jira har yanzu har yanzu idan ana ƙare ku.",
    yo: "O gbọdọ duro de nigbati gbigbe ba ti pari ni aṣeyọri.",
  },
  "You can set up devices to be activated at once, after logging in": {
    en: "You can set up devices to be activated at once, after logging in.",
    pt: "Você pode configurar dispositivos para serem ativados imediatamente após o login.",
    de: "Sie können Geräte so einrichten, dass sie sofort nach dem Einloggen aktiviert werden.",
    es: "Puede configurar dispositivos para que se activen al instante después de iniciar sesión.",
    fr: "Vous pouvez configurer des appareils pour être activés immédiatement après la connexion.",
    it: "Puoi configurare i dispositivi per essere attivati immediatamente dopo il login.",
    sq: "Mund të konfiguroni pajisjet që të aktivizohen menjëherë, pas hyrjes në llogari.",
    tr: "Giriş yaptıktan sonra birden fazla cihazı etkinleştirmek için ayarlayabilirsiniz.",
    sw: "Unaweza kuweka vifaa kuanzishwa mara moja, baada ya kuingia.",
    am: "ሁሉንም በአንድ ጊዜ ይቀጥላሉ በድምጽ ማቀነት ይቀመጡ።",
    om: "Yaa sakhyoonotaa gama hunda sagale ijaartaa.",
    ig: "I nwere ike ịhazi ngwaọrụ ka etinye na otu oge, mgbe ịbanye.",
    so: "Waxaad dejin kartaa qalabka si loo hawlgeliyo hal mar, ka dib markii aad gasho.",
    ha: "Zaka za a saita na'urorin don a kunna su duka bayan shiga.",
    mn: "Нэвтэрсэний дараа төхөөрөмжүүдийг нэг дор идэвхжүүлэхээр тохируулах боломжтой.",
    ff: "Zaka za a saita na'urorin don a kunna su duka bayan shiga.",
    yo: "O le ṣeto awọn ẹrọ lati ṣiṣẹ ni ẹẹkan, lẹhin ti o wọle.",
  },
  "You must become a Partner in order to use the functionality on this page": {
    en: "You must become a Partner in order to use the functionality on this page.",
    pt: "Você deve se tornar um Parceiro para usar a funcionalidade nesta página.",
    de: "Sie müssen Partner werden, um die Funktion auf dieser Seite nutzen zu können.",
    es: "Debe convertirse en un Socio para poder utilizar la funcionalidad de esta página.",
    fr: "Vous devez devenir un Partenaire pour utiliser les fonctionnalités de cette page.",
    it: "Devi diventare un Partner per utilizzare le funzionalità di questa pagina.",
    sq: "Duhet të bëheni Partner për të përdorur funksionalitetin në këtë faqe.",
    tr: "Bu sayfadaki işlevselliği kullanmak için Partner olmalısınız.",
    sw: "Lazima uwe mshirika ili uweze kutumia kazi kwenye ukurasa huu.",
    am: "ከዚህ በላይ እንደ ባለሙያ መሆን አለብዎት ለማገናኘት።",
    om: "Faayida argachuuf miseensota ta'uu qabda.",
    ig: "Ị ga-emerịrị onye mmekọ ka i nwee ike iji ọrụ dị na ibe a.",
    so: "Waa inaad noqotaa Wadaag si aad u isticmaasho shaqada boggan.",
    ha: "Dole ne ka zama abokin tarayya don amfani da wannan shafin.",
    mn: "Энэхүү хуудас дахь функцийг ашиглахын тулд та түнш болох ёстой.",
    ff: "Dole ne ka zama abokin tarayya don amfani da wannan shafin.",
    yo: "O gbọdọ di Alabaṣepọ lati lo iṣẹ-ṣiṣe ni oju-iwe yii.",
  },
  "You can give Free trial to your users. Free trial can be between 1 and 7 days":
    {
      en: "You can give Free trial to your users. Free trial can be between 1 and 7 days.",
      pt: "Você pode oferecer um teste gratuito aos seus usuários. O teste gratuito pode ter duração de 1 a 7 dias.",
      de: "Sie können Ihren Benutzern eine kostenlose Testversion anbieten. Die kostenlose Testversion kann zwischen 1 und 7 Tagen liegen.",
      es: "Puede ofrecer una prueba gratuita a sus usuarios. La prueba gratuita puede ser de 1 a 7 días.",
      fr: "Vous pouvez offrir un essai gratuit à vos utilisateurs. L'essai gratuit peut durer de 1 à 7 jours.",
      it: "Puoi offrire una prova gratuita ai tuoi utenti. La prova gratuita può durare da 1 a 7 giorni.",
      sq: "Ju mund të jepni provë falas për përdoruesit tuaj. Prova falas mund të jetë nga 1 deri në 7 ditë.",
      tr: "Kullanıcılarınıza ücretsiz deneme verebilirsiniz. Ücretsiz deneme süresi 1 ila 7 gün arasında olabilir.",
      sw: "Unaweza kuwapa watumiaji wako jaribio la bure. Jaribio la bure linaweza kuwa kati ya siku 1 na 7.",
      am: "እባኮት ለተጠቃሚዎችዎ ነፃ ሙከራ ይሰጡ። ነፃ ሙከራው በአንድ እስከ 7 ቀን መካከል ይሆናል።",
      om: "Taja hiri tokkicha bilisaa ta'ee dandeessi.",
      ig: "Ị nwere ike inye ndị ọrụ gị nwa oge n'efu. Nwa oge n'efu nwere ike ịdị n'etiti ụbọchị 1 na 7.",
      so: "Waxaad siisaa tijaabo bilaash ah isticmaalayaashaada. Tijaabo bilaash ah waxay noqon kartaa inta u dhaxaysa 1 ilaa 7 maalmood.",
      ha: "Zaka ka ba da alfarma ga alfarma daga 1 zuwa 7.",
      mn: "Таны хэрэглэгчдэд үнэ төлбөргүй туршилт санал болгох боломжтой. Үнэгүй туршилт нь 1-7 хоногийн хооронд байж болно.",
      ff: "Zaka ka ba da alfarma ga alfarma daga 1 zuwa 7.",
      yo: "O le fun awọn olumulo rẹ ni idanwo ọfẹ. Idanwo ọfẹ le wa laarin ọjọ 1 si 7.",
    },

  "You will get email message about the results of the migration in the end of the process":
    {
      en: "You will get email message about the results of the migration in the end of the process.",
      pt: "Você receberá uma mensagem de e-mail sobre os resultados da migração no final do processo.",
      de: "Sie erhalten eine E-Mail-Nachricht über die Ergebnisse der Migration am Ende des Prozesses.",
      es: "Recibirá un mensaje de correo electrónico sobre los resultados de la migración al final del proceso.",
      fr: "Vous recevrez un message électronique concernant les résultats de la migration à la fin du processus.",
      it: "Riceverai un messaggio di posta elettronica sui risultati della migrazione alla fine del processo.",
      sq: "Ju do të merrni një mesazh emaili për rezultatet e migrimit në fund të procesit.",
      tr: "Göç sonuçları hakkında bir e-posta mesajı alacaksınız, işlemin sonunda.",
      sw: "Utapokea ujumbe wa barua pepe kuhusu matokeo ya uhamishaji mwishoni mwa mchakato.",
      am: "እንደዚህ ይቀርባሉ የይዘው ማስታወቂያ እና የይዘው የሚከተሉት ይህ ነው የመላው መልኩ ይኖራል.",
      om: "Gabaasa imaammata suuraa guutuu fi gorsaaf e-mail ni argatta.",
      ig: "Ị ga-enweta ozi email banyere nsonaazụ nnyefe na njedebe nke usoro ahụ.",
      so: "Waxaad heli doontaa fariin email ah oo ku saabsan natiijooyinka guuritaanka dhammaadka habka.",
      ha: "Za ka sami imel game da sakamakon ƙaura a ƙarshen aikin.",
      mn: "Шилжүүлгийн үр дүнгийн талаар имэйл хүлээн авна.",
      ff: "Za ka sami imel game da sakamakon ƙaura a ƙarshen aikin.",
      yo: "Iwọ yoo gba ifiranṣẹ imeeli nipa awọn abajade ti gbigbe ni ipari ilana.",
    },
  "You can choose: Give the device free trial": {
    en: "You can choose: Give the device free trial or activate without a free trial. Free trial can be from 1 to 7 days.",
    pt: "Você pode escolher: Dar ao dispositivo um teste gratuito ou ativá-lo sem um teste gratuito. O teste gratuito pode ser de 1 a 7 dias.",
    de: "Sie können wählen: Geben Sie dem Gerät eine kostenlose Testversion oder aktivieren Sie es ohne kostenlose Testversion. Die kostenlose Testversion kann von 1 bis 7 Tagen dauern.",
    es: "Puede elegir: Dar al dispositivo una prueba gratuita o activarlo sin una prueba gratuita. La prueba gratuita puede ser de 1 a 7 días.",
    fr: "Vous pouvez choisir : Donner un essai gratuit à l'appareil ou l'activer sans essai gratuit. L'essai gratuit peut durer de 1 à 7 jours.",
    it: "Puoi scegliere: Dare al dispositivo una prova gratuita o attivarlo senza una prova gratuita. La prova gratuita può durare da 1 a 7 giorni.",
    sq: "Ju mund të zgjidhni: Dhënia e provës falas të pajisjes ose aktivizimi pa një provë falas. Prova falas mund të jetë nga 1 deri në 7 ditë.",
    tr: "Şunu seçebilirsiniz: Cihaza ücretsiz deneme verin veya ücretsiz deneme olmadan aktive edin. Ücretsiz deneme süresi 1 ila 7 gün arasında olabilir.",
    sw: "Unaweza kuchagua: Mpe kifaa jaribio la bure au uamue bila jaribio la bure. Jaribio la bure linaweza kuwa kutoka siku 1 hadi 7.",
    am: "ማለት ነው በበግድ የእናቱን ሂደት አለዎት። ነፃ ሙከራ በ1 ወይም 7 ቀን መካከል ይሆናል.",
    om: "Filadhu: Kifaaf sa'aa tokkicha bilisaa ta'ee jalqaba dhufu ni dandeessu.",
    ig: "Ị nwere ike ịhọrọ: Nye ngwaọrụ ahụ nwa oge n'efu ma ọ bụ gbanwee ya na-enweghị nwa oge n'efu. Nwa oge n'efu nwere ike ịdị site na ụbọchị 1 ruo 7.",
    so: "Waxaad dooran kartaa: Siinta qalabka tijaabo bilaash ah ama dhaqaajiso iyada oo aan tijaabo bilaash ah. Tijaabada bilaashka ah waxay noqon kartaa 1 ilaa 7 maalmood.",
    ha: "Za ka zaɓi: Ba da na'urar gwaji kyauta ko kunna ta ba tare da gwaji ba. Gwaji kyauta na iya zama daga kwanaki 1 zuwa 7.",
    mn: "Та сонгож болно: Төхөөрөмжид үнэгүй туршилт өгөх эсвэл үнэгүй туршилтгүйгээр идэвхжүүлэх. Үнэгүй туршилт нь 1-7 хоногийн хооронд байж болно.",
    ff: "Zaka zaɓi: Ba da na'urar gwaji kyauta ko kunna ta ba tare da gwaji ba. Gwaji kyauta na iya zama daga kwanaki 1 zuwa 7.",
    yo: "O le yan: Fun ẹrọ naa idanwo ọfẹ tabi mu ṣiṣẹ laisi idanwo ọfẹ. Idanwo ọfẹ le wa lati ọjọ 1 si 7.",
  },
  "You can click on the checkbox and let our system auto renew your device activation after one year from activation date.":
    {
      en: "You can click on the checkbox and let our system auto renew your device activation after one year from activation date.",
      pt: "Você pode clicar na caixa de seleção e permitir que nosso sistema renove automaticamente a ativação do seu dispositivo após um ano a partir da data de ativação.",
      de: "Sie können auf das Kontrollkästchen klicken und unser System automatisch die Aktivierung Ihres Geräts nach einem Jahr ab dem Aktivierungsdatum erneuern lassen.",
      es: "Puede hacer clic en la casilla de verificación e permitir que nuestro sistema renueve automáticamente la activación de su dispositivo después de un año desde la fecha de activación.",
      fr: "Vous pouvez cliquer sur la case à cocher et laisser notre système renouveler automatiquement l'activation de votre appareil après un an à compter de la date d'activation.",
      it: "Puoi fare clic sulla casella di controllo e permettere al nostro sistema di rinnovare automaticamente l'attivazione del tuo dispositivo dopo un anno dalla data di attivazione.",
      sq: "Ju mund të klikoni në kutinë e zgjedhjes dhe lejoni sistemin tonë të riaftësojë automatikisht aktivizimin e pajisjes suaj pas një viti nga data e aktivizimit.",
      tr: "Onay kutusuna tıklayabilir ve sistemimizin cihazınızın aktivasyonunu, aktivasyon tarihinden bir yıl sonra otomatik olarak yenilemesine izin verebilirsiniz.",
      sw: "Unaweza kubonyeza kisanduku cha kuchagua na kuacha mfumo wetu ujiandikishe kiotomatiki tena kwa uhamishaji kifaa chako baada ya mwaka mmoja tangu tarehe ya uhamishaji.",
      am: "በአንድ ዓመት የአስተናጋጅ ቀን ይዘው ይህ የዝርዝር ይህ ነው የአውትሃረ ሂደት እንዴት ይደርሳል.",
      om: "Kasa jijjiirama dhumata barreeffama miidhuunii, galma isaa laaftuu isee kamta raawwachuuf, jalqaba isa armaan gadii kun jiraa.",
      ig: "Ị nwere ike pịa igbe nhọrọ ma mee ka usoro anyị gbanwee akpụkpọ ụkwụ gị na-akpọrịta mgbe otu afọ gasịrị site na ụbọchị ịmalite.",
      so: "Waxaad gujin kartaa sanduuqa xulashada oo aad u ogolaan kartaa nidaamkayaga inuu si otomaatig ah u cusboonaysiiyo hawlgalka qalabkaaga hal sano kadib taariikhda hawlgalka.",
      ha: "Kuna iya danna akwati kuma barin tsarinmu ya sabunta aikin na'urarka ta atomatik bayan shekara guda daga ranar kunna.",
      mn: "Танд харах хайрцаг дээр дарж, манай системд идэвхжүүлсэн огнооноос нэг жилийн дараа таны төхөөрөмжийг автоматжуулан идэвхжүүлэхийг зөвшөөрч болно.",
      ff: "Zaka danna akwatin zaɓi kuma ka bar tsarinmu ya sabunta aikin na'urarka ta atomatik bayan shekara guda daga ranar kunna.",
      yo: "O le tẹ lori apoti aṣayan ki o jẹ ki eto wa tun mu ẹrọ rẹ ṣiṣẹ laifọwọyi lẹhin ọdun kan lati ọjọ ti a mu ṣiṣẹ.",
    },
  "You can click on checkbox and let our system": {
    en: "You can click on the checkbox and let our system",
    pt: "Você pode clicar na caixa de seleção e deixar nosso sistema",
    de: "Sie können das Kontrollkästchen aktivieren und unser System",
    es: "Puede hacer clic en la casilla de verificación y dejar que nuestro sistema",
    fr: "Vous pouvez cliquer sur la case à cocher et laisser notre système",
    it: "Puoi fare clic sulla casella di controllo e lasciare che il nostro sistema",
    sq: "Mund të klikoni në kutinë e zgjedhjes dhe lejoni sistemin tonë",
    tr: "Onay kutusuna tıklayabilir ve sistemimizin",
    sw: "Unaweza kubonyeza kisanduku cha kuchagua na kuacha mfumo wetu",
    am: "እባክዎ በምልክት ቦታ ይታገዙ እና የእኛን ስርዓት እንዲወደድ ይፈቅዱ።",
    om: "Maqaa filadhu fiiluu galu",
    ig: "Ị nwere ike pịa igbe nhọrọ ma mee ka usoro anyị",
    so: "Waxaad gujin kartaa sanduuqa xulashada oo aad u ogolaan kartaa nidaamkayaga",
    ha: "Kuna iya danna akwati kuma barin tsarinmu",
    mn: "Танд харах хайрцаг дээр дарж, манай системд",
    ff: "Zaka danna akwatin zaɓi kuma ka bar tsarinmu",
    yo: "O le tẹ lori apoti aṣayan ki o jẹ ki eto wa",
  },
  "You can make setup for the activation of new devices in this section": {
    en: "You can make setup for the activation of new devices in this section. If you activate the checkbox of 'Allow users to login from application,' your users can be auto-activated and login to TV by themselves.",
    pt: "Você pode configurar a ativação de novos dispositivos nesta seção. Se você ativar a caixa de seleção 'Permitir que os usuários façam login pelo aplicativo', seus usuários podem ser ativados automaticamente e fazer login na TV por conta própria.",
    de: "Sie können in diesem Abschnitt die Aktivierung neuer Geräte einrichten. Wenn Sie das Kontrollkästchen 'Benutzern das Anmelden von der Anwendung erlauben' aktivieren, können Ihre Benutzer automatisch aktiviert und sich selbst in TV einloggen.",
    es: "Puede configurar la activación de nuevos dispositivos en esta sección. Si activa la casilla 'Permitir que los usuarios inicien sesión desde la aplicación', sus usuarios pueden ser activados automáticamente e iniciar sesión en la TV por sí mismos.",
    fr: "Vous pouvez configurer l'activation de nouveaux appareils dans cette section. Si vous activez la case à cocher 'Autoriser les utilisateurs à se connecter depuis l'application', vos utilisateurs peuvent être activés automatiquement et se connecter à la TV par eux-mêmes.",
    it: "Puoi configurare l'attivazione di nuovi dispositivi in questa sezione. Se attivi la casella di controllo 'Consenti agli utenti di effettuare l'accesso dall'applicazione', i tuoi utenti possono essere attivati automaticamente e accedere alla TV da soli.",
    sq: "Ju mund të bëni konfigurimin për aktivizimin e pajisjeve të reja në këtë seksion. Nëse aktivizoni kutinë e zgjedhjes 'Lejo përdoruesit të kyçen nga aplikacioni,' përdoruesit tuaj mund të aktivizohen automatikisht dhe të kyçen në televizor vetë.",
    tr: "Bu bölümde yeni cihazların aktivasyonu için ayarlama yapabilirsiniz. 'Kullanıcıların uygulamadan giriş yapmasına izin ver' onay kutusunu etkinleştirirseniz, kullanıcılarınız otomatik olarak etkinleştirilebilir ve televizyona kendileri giriş yapabilirler.",
    sw: "Unaweza kuunda mipangilio ya kuanzisha vifaa vipya katika sehemu hii. Ikiwa unawasha kisanduku cha 'Ruhusu watumiaji kuingia kutoka kwa programu,' watumiaji wako wanaweza kuanzishwa moja kwa moja na kuingia kwenye TV wenyewe.",
    am: "በዚህ ክፍል አዲስ መሣሪያዎችን ለአንድ የተዋሕዶ እንዲከፈት መረጃ ይዘው ምልክት ይጨምሩ። ይህ ምልክት ይወስዳል የዚህ መረጃ ይባል በይዘት የሚታይ ይሆናል።",
    om: "Kun ajaja bifa gulimoo ija tokkoof shiraa qopheessuu dandeessa. Yoo 'Balaa fiigicha keessaa seenuu hayyamuu' malayaa dhiyoosonii, fayyadamtoonni keetii bilisaan seenuu fi TV'tti ofii isaanii akka jiran.",
    ig: "Ị nwere ike ịmeziwanye maka ịkụnye ngwaọrụ ọhụrụ na ngalaba a. Ọ bụrụ na ị na-eme ka igbe nhọrọ 'Kwe ka ndị ọrụ banye na ngwa,' ndị ọrụ gị nwere ike ịkụnye onwe ha na banye na TV.",
    so: "Waxaad ku samayn kartaa dejinta firfircoonida qalabka cusub qeybtaan. Haddii aad hawsha ka dhigto 'Ogolaada isticmaalayaasha inay ka galan barnaamijka,' isticmaalayaashaadu waxay si otomaatig ah u firfircoonaan karaan oo si madax-bannaan ugu galaa TV.",
    ha: "Kuna iya yin shirin don ƙaddamar da sabbin na'urori a wannan ɓangaren. Idan ka kunna akwatin zaɓi na 'Bari masu amfani su shiga daga aikace-aikacen,' masu amfani da ku za su iya samun aikin kai tsaye da shiga TV da kansu.",
    mn: "Та шинэ төхөөрөмжүүдийг идэвхжүүлэх тохиргоо хийх боломжтой. Хэрэв та 'Програмынхаа тусламжтайгаар нэвтэрч орохыг хэрэглэгчдэд зөвшөөрөх' сонголтыг идэвхжүүлбэл, таны хэрэглэгчид автомат идэвхжүүлэлт хийж, TV-д өөрсдөө нэвтрэх боломжтой.",
    ff: "Zaka shirya sabbin na'urori a wannan ɓangaren. Idan ka kunna akwatin zaɓi na 'Bari masu amfani su shiga daga aikace-aikacen,' masu amfani za su iya samun aikin kai tsaye da shiga TV da kansu.",
    yo: "O le ṣe eto fun iṣẹ-ṣiṣe ti awọn ẹrọ tuntun ni apakan yii. Ti o ba mu ṣiṣẹ apoti ayẹwo ti 'Gba awọn olumulo laaye lati wọle lati ohun elo,' awọn olumulo rẹ le jẹ auto-ṣiṣe ati wọle si TV wọn.",
  },
  "Your account is not approved yet by your admin. Contact your admin to get approved.":
    {
      en: "Your account is not approved yet by your admin. Contact your admin to get approved.",
      pt: "Sua conta ainda não foi aprovada pelo seu administrador. Entre em contato com seu administrador para ser aprovado.",
      de: "Ihr Konto wurde noch nicht von Ihrem Administrator genehmigt. Kontaktieren Sie Ihren Administrator, um genehmigt zu werden.",
      es: "Tu cuenta aún no ha sido aprobada por tu administrador. Contacta a tu administrador para ser aprobado.",
      fr: "Votre compte n'a pas encore été approuvé par votre administrateur. Contactez votre administrateur pour être approuvé.",
      it: "Il tuo account non è ancora stato approvato dal tuo amministratore. Contatta il tuo amministratore per essere approvato.",
      sq: "Llogaria juaj ende nuk është aprovuar nga administratori juaj. Kontaktoni administratorin tuaj për të qenë i aprovuar.",
      tr: "Hesabınız henüz yöneticiniz tarafından onaylanmamıştır. Onay almak için yöneticinizle iletişime geçin.",
      sw: "Hesabu yako bado haijakubaliwa na msimamizi wako. Wasiliana na msimamizi wako ili upate kibali.",
      am: "አካውንትዎ እንደ አስተዳዳሪዎ አልተረገምም። እባኮትን ወይም አስተዳዳሪዎን ያግኙ እንዲቀበሉዎት።",
      om: "Akkaawwan kee hanga ammaatti maanguddoo keetiin hin mirkanaa’in. Maanguddoo keeti siif mirkaneessuuf quunnamaa.",
      ig: "Akara gị edereghị tupu onye nchịkwa gị. Kpọtụrụ onye nchịkwa gị ka o kwadoro gị.",
      so: "Akawntigaagu walii waybalsan yahay maamulkaaga. La xiriir maamulkaaga si aad u hesho oggolaansho.",
      ha: "Asusun ku ba'a tabbaci ba a amince da ku ta hanyar mai gudanarwa ku ba. Tuntuɓi mai gudanarwa ku don a amince.",
      mn: "Таны данс одоогоор администратороор батлагдаагүй байна. Баталгаажуулахын тулд администратортой холбоо барина уу.",
      ff: "Asusun ku ba'a amince da ku daga mai gudanarwa ku. Tuntuɓi mai gudanarwa ku don samun amincewa.",
      yo: "Akọọlẹ rẹ ko ti jẹri nipasẹ alakoso rẹ. Kan si alakoso rẹ ki o gba aami.",
    },
  "You can't select yourself": {
    en: "You can't select yourself",
    pt: "Você não pode se selecionar",
    de: "Du kannst dich nicht selbst auswählen",
    es: "No puedes seleccionarte a ti mismo",
    fr: "Vous ne pouvez pas vous sélectionner vous-même",
    it: "Non puoi selezionarti da solo",
    sq: "Nuk mund të zgjidhni veten",
    tr: "Kendinizi seçemezsiniz",
    sw: "Haiwezi kuchagua mwenyewe.",
    am: "አንድ ራስዎን አላችሁም።",
    om: "Siin ofi hin filatamtu.",
    ig: "Ị nweghị ike ịhọrọ onwe gị.",
    so: "Ma waxaad dooran kartaa naftaada.",
    ha: "Ba za ka iya zaɓar kanka ba.",
    mn: "Та өөрийгөө сонгох боломжгүй.",
    ff: "Ba za ka iya zaɓar kanka ba.",
    yo: "O ko le yan ara rẹ.",
  },
  "You can sync playlist by clicking the 'Sync' button or wait for the device to be logged in, after which it will sync automatically.":
    {
      en: "You can sync playlist by clicking the 'Sync' button or wait for the device to be logged in, after which it will sync automatically.",
      pt: "Você pode sincronizar a playlist clicando no botão 'Sincronizar' ou aguardar o dispositivo fazer login, após o qual ele será sincronizado automaticamente.",
      de: "Sie können die Playlist synchronisieren, indem Sie auf die Schaltfläche 'Synchronisieren' klicken oder darauf warten, dass das Gerät angemeldet ist, woraufhin es automatisch synchronisiert wird.",
      es: "Puede sincronizar la lista de reproducción haciendo clic en el botón 'Sincronizar' o esperar a que el dispositivo inicie sesión, después de lo cual se sincronizará automáticamente.",
      fr: "Vous pouvez synchroniser la playlist en cliquant sur le bouton 'Synchroniser' ou attendre que le périphérique se connecte, après quoi il se synchronisera automatiquement.",
      it: "Puoi sincronizzare la playlist cliccando sul pulsante 'Sincronizza' o aspettare che il dispositivo effettui l'accesso, dopodiché si sincronizzerà automaticamente.",
      sq: "Mund të sinkronizoni listën e përgjegjësive duke klikuar në butonin 'Sinkronizo' ose prisni që pajisja të hyjë në sistem, pas së cilës do të sinkronizohet automatikisht.",
      tr: "Oynatma listesini 'Senkrone' düğmesine tıklayarak senkronize edebilir veya cihazın oturum açmasını bekleyebilirsiniz; ardından otomatik olarak senkronize olacaktır.",
      sw: "Unaweza kuunganisha orodha ya nyimbo kwa kubofya kitufe cha 'Sawa' au kungojea kifaa kiingie, baada ya hapo kitaunganishwa kiotomatiki.",
      am: "የጨዋታዎች ዝርዝር እባኮትን እንዲያወጣ ወይም በመለያ ሂደት ይገኙ በሚቀጥሉ ይበዛል።",
      om: "Dorgaa  'Sync' jedhamuun cuqaasuu dandeessa ykn deeggartuu seeruu dandeessa.",
      ig: "I nwere ike ịkekọrịta playlists site na pịa bọtịnụ 'Sync' ma ọ bụ ichere ka ngwaọrụ ahụ banye, mgbe nke ahụ gasịrị, ọ ga-eme ka ọ na-ejikọta onwe ya.",
      so: "Waxaad ku dhajin kartaa liiska adigoo gujinaya batoonka 'Sync' ama sugista qalabka in la galo, ka dibna si otomaatig ah ayay isu geyneysaa.",
      ha: "Za ku iya haɗa jerin kiɗa ta danna maɓallin 'Sync' ko jira na'urar ta shiga, bayan haka za ta haɗu da kanta.",
      mn: "Та 'Синхрон' товчийг дарж тоглолтын жагсаалтыг синхрончлох эсвэл төхөөрөмжийг нэвтрэхийг хүлээж, дараа нь автоматаар синхрончлох боломжтой.",
      ff: "Za ku iya haɗa jerin kiɗa ta danna maɓallin 'Sync' ko jira na'urar ta shiga, bayan haka za ta haɗu da kanta.",
      yo: "O le ṣatunkọ akojọ orin nipasẹ titẹ bọtini 'Sync' tabi duro de ẹrọ naa lati forukọsilẹ, lẹhin eyiti o yoo ṣatunkọ laifọwọyi.",
    },
  //o
  "Open for devices": {
    en: "Open for devices",
    pt: "Aberto para dispositivos",
    de: "Offen für Geräte",
    es: "Abierto para dispositivos",
    fr: "Ouvert pour les appareils",
    it: "Aperto per dispositivi",
    sq: "I hapur për pajisjet",
    tr: "Cihazlar için açık",
    sw: "Fungua kwa vifaa",
    am: "ለመሣሪያዎች ክፈት",
    om: "Vifaa banuu",
    ig: "Mepee maka ngwaọrụ",
    so: "Fur fur qalabka",
    ha: "Buɗe don na'urori",
    mn: "Төхөөрөмжүүдэд нээгдэх",
    ff: "Buɗe don na'urori",
    yo: "Ṣii fun awọn ẹrọ",
  },
  or: {
    en: "or",
    pt: "ou",
    de: "oder",
    es: "o",
    fr: "ou",
    it: "o",
    sq: "ose",
    tr: "veya",
    sw: "au",
    am: "ወይም",
    om: "yookaan",
    ig: "ma ọ bụ",
    so: "ama",
    ha: "ko",
    mn: "эсвэл",
    ff: "ko",
    yo: "tabi",
  },
  Os: {
    en: "Os",
    pt: "Sistema Operacional",
    de: "Betriebssystem",
    es: "Sistema Operativo",
    fr: "Système d'exploitation",
    it: "Sistema Operativo",
    sq: "Sistemi Operativ",
    tr: "İşletim Sistemi",
    sw: "Os",
    am: "ኦኤስ",
    om: "Os",
    ig: "Os",
    so: "Os",
    ha: "Os",
    mn: "Os",
    ff: "Os",
    yo: "Os",
  },
  Online: {
    en: "Online",
    pt: "Online",
    de: "Online",
    es: "En línea",
    fr: "En ligne",
    it: "Online",
    sq: "Online",
    tr: "Çevrimiçi",
    sw: "Mtandaoni",
    am: "በመረብ",
    om: "Online",
    ig: "N'ịntanetị",
    so: "Khadka",
    ha: "Kan layi",
    mn: "Онлайн",
    ff: "Kan layi",
    yo: "Nẹtiwọọki",
  },
  "Old password": {
    en: "Old password",
    pt: "Senha antiga",
    de: "Altes Passwort",
    es: "Contraseña antigua",
    fr: "Ancien mot de passe",
    it: "Vecchia password",
    sq: "Fjalëkalimi i vjetër",
    tr: "Eski şifre",
    sw: "Nywila ya zamani",
    am: "የወቅታዊ የእንቅስቃሴ ዳይሬክተር",
    om: "Jecha durii",
    ig: "Okwu ochie",
    so: "Fur fur",
    ha: "Tsohon kalmar wucewa",
    mn: "Хуучин нууц үг",
    ff: "Tsohon kalmar wucewa",
    yo: "Ọjọ́ àtijọ́",
  },
  "OTP Config": {
    en: "OTP Config",
    pt: "Configuração de OTP",
    de: "OTP-Konfiguration",
    es: "Configuración de OTP",
    fr: "Configuration OTP",
    it: "Configurazione OTP",
    sq: "Konfigurimi OTP",
    tr: "OTP Yapılandırması",
    sw: "Mipangilio ya OTP",
    am: "የውሂብ ዝርዝር",
    om: "OTP Qindaa",
    ig: "Ntọala OTP",
    so: "Dejinta OTP",
    ha: "Tsarin OTP",
    mn: "OTP тохиргоо",
    ff: "Tsarin OTP",
    yo: "Ipo OTP",
  },

  "On the 'Reset Requests' page, there are reset requests that are pending. To make use of this functionality, please reset or cancel them":
    {
      en: "On the 'Reset Requests' page, there are reset requests that are pending. To make use of this functionality, please reset or cancel them.",
      pt: "Na página 'Solicitações de Redefinição', há solicitações de redefinição pendentes. Para utilizar esta funcionalidade, por favor redefina ou cancele-as.",
      de: "Auf der Seite 'Zurücksetzungsanfragen' gibt es ausstehende Anfragen. Um diese Funktionalität zu nutzen, setzen Sie sie bitte zurück oder stornieren Sie sie.",
      es: "En la página 'Solicitudes de Restablecimiento', hay solicitudes de restablecimiento pendientes. Para utilizar esta funcionalidad, por favor restablézcalas o cancélelas.",
      fr: "Sur la page 'Demandes de réinitialisation', il y a des demandes en attente. Pour utiliser cette fonctionnalité, veuillez les réinitialiser ou les annuler.",
      it: "Nella pagina 'Richieste di Ripristino', ci sono richieste di ripristino in sospeso. Per utilizzare questa funzionalità, ripristina o annulla tali richieste.",
      sq: "Në faqen 'Kërkesat për Rivendosje', ka kërkesa të pa përfunduara. Për të përdorur këtë funksionalitet, ju lutemi rivendosni ose anuloni ato.",
      tr: "'Sıfırlama Talepleri' sayfasında bekleyen sıfırlama talepleri var. Bu işlevi kullanmak için lütfen talepleri sıfırlayın veya iptal edin.",
      sw: "Katika ukurasa wa 'Maombi ya Kufuta', kuna maombi ya kufuta ambayo yanakusubiri. Ili kutumia kazi hii, tafadhali futa au ghairi maombi hayo.",
      am: "በ'የተሻሻለ ጥያቄዎች' ገፅ ውስጥ ያሉ የተሻሻለ ጥያቄዎች በግምት አለ። ይህን የሥራ ክፍል ለማጠቃለይ እባኮትን ይሻሻሉ ወይም ይሰርዙ።",
      om: "‘Reset Requests’ fuula irratti, erga deebi’anii galuuf caalaatti tajaajila kanniin mana buufata keenyaan ka’an. Qorannoon kun humna kan ilaalu taasisuuf, maa’inkootni isaanii galmeeffamuun isaa danda’ama.",
      ig: "Na ibe ‘Nchọta Nnyocha’ ibe, e nwere ndenye nchọpụta nke na-atụ anya. Iji jiri ọrụ a, biko mee ka ha nwee ike ịhazi ma ọ bụ hichapụ ha.",
      so: "Bogga 'Codsiga Dib-u-habaynta' waxaa ku yaal codsiyo dib u habayn ah oo sugeysa. Si aad u isticmaasho shaqadan, fadlan dib u habeyn ama baabi'inta codsiyadan.",
      ha: "A kan shafin 'Buƙatun Sake saiti', akwai buƙatun sake saiti da ke jiran. Don amfani da wannan aiki, don Allah sake saiti ko soke su.",
      mn: "'Дахин тохируулах хүсэлтүүд' хуудас дээр хүлээгдэж буй дахин тохируулах хүсэлтүүд байна. Энэ үйлчилгээг ашиглахын тулд, дахин тохируулах эсвэл цуцлах хэрэгтэй.",
      ff: "A cikin shafin 'Sake Tika', akwai buƙatun sake tashi da ke jiran. Don amfani da wannan aiki, don Allah a sake ko a soke su.",
      yo: "Ninu oju-iwe 'Ìbéèrè Ìmúdàgba', awọn ìbéèrè ìmúdàgba ti o wa ni pẹlẹpẹlẹ. Lati lo iṣẹ yii, jọwọ tunṣe tabi fagilee wọn.",
    },

  // x
  xui: {
    en: "xui",
    pt: "xui",
    de: "xui",
    es: "xui",
    fr: "xui",
    it: "xui",
    sq: "xui",
    tr: "xui",
    sw: "xui",
    am: "xui",
    om: "xui",
    ig: "xui",
    so: "xui",
    ha: "xui",
    mn: "xui",
    ff: "xui",
    yo: "xui",
  },
  "X Cloud Admin Panel": {
    en: "X Cloud Admin Panel",
    pt: "Painel de Administração XCloud",
    de: "X Cloud Admin-Panel",
    es: "Panel de Administración de X Cloud",
    fr: "Panneau d'administration X Cloud",
    it: "Pannello di amministrazione di X Cloud",
    sq: "Paneli i Administratorit të X Cloud",
    tr: "X Cloud Yönetim Paneli",
    sw: "Dashibodi ya X Cloud",
    am: "የX ክላውድ አድሚን ፓነል",
    om: "X Cloud Admin Panel",
    ig: "X Cloud Admin Panel",
    so: "X Cloud Admin Panel",
    ha: "X Cloud Admin Panel",
    mn: "X Cloud Админ Панел",
    ff: "X Cloud Admin Panel",
    yo: "X Cloud Admin Panel",
  },
  //v
  "Videos must be less than ten": {
    en: "Videos must be less than ten",
    es: "Los videos deben ser menos de diez",
    fr: "Les vidéos doivent être inférieures à dix",
    de: "Videos müssen weniger als zehn sein",
    pt: "Os vídeos devem ser menos de dez",
    it: "I video devono essere meno di dieci",
    sq: "Videot duhet të jenë më pak se dhjetë",
    tr: "Videolar ondan az olmalıdır",
    sw: "Video zinahitaji kuwa chini ya kumi",
    am: "ቪዲዮዎች ከአስር በታች መሆን አለባቸው",
    om: "Viidiyoonni 10 irraa xiqqaa ta'uu qabu",
    ig: "Vidiyo ga-esi kwụsịrị nke iri",
    so: "Muuqaaladu waa inay ka yar yihiin toban",
    ha: "Bidiyo guda daya a ka yi kadan",
    mn: "Видеогийн тоо 10-аас бага байх ёстой",
    ff: "Bidiyo yẹ ki o jẹ kere ju mẹwa lọ",
    yo: "Awọn fidio gbọdọ jẹ kere ju mẹwa lọ",
  },
  "View more": {
    en: "View more",
    pt: "Ver mais",
    de: "Mehr anzeigen",
    es: "Ver más",
    fr: "Voir plus",
    it: "Visualizza altro",
    sq: "Shiko më shumë",
    tr: "Daha fazla görüntüle",
    sw: "Tazama zaidi",
    am: "በተጨማሪ ይመልከቱ",
    om: "Faayila dabalataa ilaalu",
    ig: "Lee ọzọ",
    so: "Eeg wax badan",
    ha: "Duba ƙarin",
    mn: "Илүү ихийг үзэх",
    ff: "Wo diẹ sii",
    yo: "Wo siwaju sii",
  },
  Video: {
    en: "Video",
    es: "Vídeo",
    fr: "Vidéo",
    de: "Video",
    pt: "Vídeo",
    it: "Video",
    sq: "Video",
    tr: "Video",
    sw: "Video",
    am: "ቪዲዮ",
    om: "Viidiyoo",
    ig: "Vidiyo",
    so: "Muuqaal",
    ha: "Bidiyo",
    mn: "Видеo",
    ff: "Vidiyo",
    yo: "Fidio",
  },
  "View the guide created by images": {
    en: "View the guide created by images",
    pt: "Visualize o guia criado por imagens",
    de: "Ansehen des von Bildern erstellten Leitfadens",
    es: "Ver la guía creada por imágenes",
    fr: "Voir le guide créé par des images",
    it: "Visualizza la guida creata dalle immagini",
    sq: "Shiko udhëzuesin e krijuar nga imazhet",
    tr: "Görüntülerle oluşturulan kılavuzu görüntüle",
    sw: "Tazama mwongozo ulioandaliwa kwa picha",
    am: "በምስሎች የተፍጠረውን መመሪያ ይመልከቱ",
    om: "Sawwan muraa qabiyyee mul'atu ilaalu",
    ig: "Lee ntuziaka nke e kere site n'ọtụtụ",
    so: "Eeg hagaha laga sameeyay sawirro",
    ha: "Duba jagorar da aka ƙirƙira ta hanyar hotuna",
    mn: "Зурагуудаар үүсгэсэн зааврыг үзэх",
    ff: "Wo itọnisọna ti a ṣẹda nipasẹ awọn aworan",
    yo: "Wo itọsọna ti a ṣẹda nipasẹ awọn aworan",
  },
  "Verify code must be 6 characters": {
    en: "Verify code must be 6 characters",
    pt: "O código de verificação deve ter 6 caracteres",
    de: "Der Überprüfungscode muss 6 Zeichen lang sein",
    es: "El código de verificación debe tener 6 caracteres",
    fr: "Le code de vérification doit comporter 6 caractères",
    it: "Il codice di verifica deve essere lungo 6 caratteri",
    sq: "Kodi i verifikimit duhet të jetë 6 karaktere",
    tr: "Doğrulama kodu 6 karakter olmalıdır",
    sw: "Nambari ya kuthibitisha lazima iwe na herufi 6",
    am: "የማረጋገጫ ኮድ 6 ፊደል መኖር አለበት",
    om: "Koodiin mirkaneeffamuu 6 barreeffama qabaachuu qaba",
    ig: "Nyocha koodu ga-abụrịrị mkpụrụ edemede 6",
    so: "Koodhka xaqiijinta waa inuu ahaadaa 6 xaraf",
    ha: "Lambar tabbatarwa dole ne ya zama haruffa 6",
    mn: "Баталгаажуулах код нь 6 тэмдэгт байх ёстой",
    ff: "Koodu idaniloju gbọdọ jẹ awọn ohun kikọ 6",
    yo: "Koodu idaniloju gbọdọ jẹ awọn ohun kikọ 6",
  },
  //u
  "Unsupported file type": {
    en: "Unsupported file type",
    es: "Tipo de archivo no compatible",
    fr: "Type de fichier non pris en charge",
    de: "Nicht unterstützter Dateityp",
    pt: "Tipo de arquivo não suportado",
    it: "Tipo di file non supportato",
    sq: "Lloji i skedarit i pambështetur",
    tr: "Desteklenmeyen dosya türü",
    sw: "Aina ya faili haiwezi kusaidiwa",
    am: "የዋነኛ ዓይነት ፋይል አይደገፍም",
    om: "Faayiliin hin deeggaramu",
    ig: "Uru nke faịlụ na-akwado",
    so: "Nooca faylka aan la taageerin",
    ha: "Nau'in fayil din ba'a goyon baya",
    mn: "Дэмжигддэг файл төрөл биш",
    ff: "Iru faili ti a ko ṣe atilẹyin",
    yo: "Iru faili ti ko ni atilẹyin",
  },
  Upload: {
    en: "Upload",
    es: "Subir",
    fr: "Téléverser",
    de: "Hochladen",
    pt: "Carregar",
    it: "Carica",
    sq: "Ngarko",
    tr: "Yükle",
    sw: "Pakia",
    am: "ማስቀመጥ",
    om: "Galmeessaa",
    ig: "Tinye",
    so: "Soo rar",
    ha: "Sanya",
    mn: "Ачуулах",
    ff: "Gbe",
    yo: "Gbe",
  },
  "Upload image": {
    en: "Upload image",
    es: "Subir imagen",
    fr: "Télécharger l'image",
    de: "Bild hochladen",
    pt: "Carregar imagem",
    it: "Carica immagine",
    sq: "Ngarko imazh",
    tr: "Görüntü yükle",
    sw: "Pakia picha",
    am: "ምስል ይምረጡ",
    om: "Suuraa galchuu",
    ig: "Tinye foto",
    so: "Soo rar sawir",
    ha: "Hoto shigar",
    mn: "Зургийг ачих",
    ff: "Gbe aworan",
    yo: "Gbe aworan",
  },
  "Upload video": {
    en: "Upload video",
    es: "Subir video",
    fr: "Télécharger la vidéo",
    de: "Video hochladen",
    pt: "Carregar vídeo",
    it: "Carica video",
    sq: "Ngarko video",
    tr: "Video yükle",
    sw: "Pakia video",
    am: "ቪዲዮ ይምረጡ",
    om: "Viidiyoo galchuu",
    ig: "Tinye vidiyo",
    so: "Soo rar muuqaal",
    ha: "Bidiyo shigar",
    mn: "Видеогийг ачих",
    ff: "Gbe fidio",
    yo: "Gbe fidio",
  },
  UUID: {
    en: "UUID",
    pt: "UUID",
    de: "UUID",
    es: "UUID",
    fr: "UUID",
    it: "UUID",
    sq: "UUID",
    tr: "UUID",
    sw: "UUID",
    am: "UUID",
    om: "UUID",
    ig: "UUID",
    so: "UUID",
    ha: "UUID",
    mn: "UUID",
    ff: "UUID",
    yo: "UUID",
  },
  User: {
    en: "User",
    pt: "Usuário",
    de: "Benutzer",
    es: "Usuario",
    fr: "Utilisateur",
    it: "Utente",
    sq: "Përdoruesi",
    tr: "Kullanıcı",
    sw: "Mtumiaji",
    am: "ተጠቃሚ",
    om: "Barsiisuu",
    ig: "User",
    so: "Isticmaalaha",
    ha: "Mai amfani",
    mn: "Хэрэглэгч",
    ff: "Olumulo",
    yo: "Olumulo",
  },
  Unblock: {
    en: "Unblock",
    pt: "Desbloquear",
    de: "Entsperren",
    es: "Desbloquear",
    fr: "Débloquer",
    it: "Sblocca",
    sq: "Çaktivizo bllokimin",
    tr: "Engeli kaldır",
    sw: "Fungua",
    am: "አንደርብ",
    om: "Banuu",
    ig: "Kwụsịrị",
    so: "Fur fur",
    ha: "Bude",
    mn: "Татах",
    ff: "Yọ",
    yo: "Yọ",
  },
  Upload: {
    en: "Upload",
    pt: "Carregar",
    de: "Hochladen",
    es: "Cargar",
    fr: "Télécharger",
    it: "Carica",
    sq: "Ngarko",
    tr: "Yükle",
    sw: "Pakia",
    am: "ማስቀመጥ",
    om: "Galmeessaa",
    ig: "Tinye",
    so: "Soo rar",
    ha: "Sanya",
    mn: "Ачуулах",
    ff: "Gbe",
    yo: "Gbe",
  },

  "Updated date": {
    en: "Updated date",
    pt: "Data atualizada",
    de: "Aktualisiertes Datum",
    es: "Fecha actualizada",
    fr: "Date mise à jour",
    it: "Data aggiornata",
    sq: "Data e përditësimit",
    tr: "Güncellenme tarihi",
    sw: "Tarehe iliyosasishwa",
    am: "የተሻሻለ ቀን",
    om: "Guyyaa haaraa",
    ig: "Ụbọchị emelitere",
    so: "Taariikhda la cusbooneysiiyay",
    ha: "Ranar sabuntawa",
    mn: "Шинэчилсэн огноо",
    ff: "Ọjọ ti a ṣe imudojuiwọn",
    yo: "Ọjọ imudojuiwọn",
  },

  Username: {
    en: "Username",
    pt: "Nome de usuário",
    de: "Benutzername",
    es: "Nombre de usuario",
    fr: "Nom d'utilisateur",
    it: "Nome utente",
    sq: "Emri i përdoruesit",
    tr: "Kullanıcı adı",
    sw: "Jina la mtumiaji",
    am: "የተጠቃሚ ስም",
    om: "Maqaa fayyadamtootaa",
    ig: "Aha onye ọrụ",
    so: "Magaca isticmaale",
    ha: "Sunan mai amfani",
    mn: "Хэрэглэгчийн нэр",
    ff: "Orukọ olumulo",
    yo: "Orukọ olumulo",
  },
  "Upload logo": {
    en: "Upload logo",
    pt: "Carregar logotipo",
    de: "Logo hochladen",
    es: "Cargar logotipo",
    fr: "Télécharger le logo",
    it: "Carica logo",
    sq: "Ngarko logon",
    tr: "Logo yükle",
    sw: "Pakia nembo",
    am: "አርምዋ ማስቀመጥ",
    om: "Albeedha galmeessaa",
    ig: "Tinye logo",
    so: "Soo rar logo",
    ha: "Loda tambarin",
    mn: "Лого ачуул",
    ff: "Loda logo",
    yo: "Gbe logo",
  },
  "Resize logo": {
    en: "Resize logo",
    pt: "Redimensionar logotipo",
    de: "Logo anpassen",
    es: "Redimensionar logotipo",
    fr: "Redimensionner le logo",
    it: "Ridimensiona logo",
    sq: "Ndrysho përmasat e logos",
    tr: "Logoyu yeniden boyutlandır",
    sw: "Punguza saizi ya nembo",
    am: "የአርምዋ መጠን አስቀምጥ",
    om: "Albeedha bal'ina",
    ig: "Gbanwee logo",
    so: "Cabir logo",
    ha: "Canja girman tambari",
    mn: "Логогийн хэмжээг өөрчлөх",
    ff: "Ṣe atunṣe logo",
    yo: "Ṣe iwọn logo",
  },
  "Upload background": {
    en: "Upload background",
    pt: "Carregar Plano de Fundo",
    de: "Hintergrund hochladen",
    es: "Cargar fondo",
    fr: "Télécharger l'arrière-plan",
    it: "Carica sfondo",
    sq: "Ngarko sfondin",
    tr: "Arka plan yükle",
    sw: "Pakia mandharinyuma",
    am: "የወረቀት መርጠ እንደ ማስቀመጥ",
    om: "Faka asha galmeessaa",
    ig: "Tinye ndabere",
    so: "Soo rar asalka",
    ha: "Loda bayanin",
    mn: "Фон ачуул",
    ff: "Loda abẹ́lẹ̀",
    yo: "Gbe abẹ́lẹ̀",
  },
  "Users will be able to reset the device automatically if this field is enabled. If it isn't, they won't be able to reset it unless you confirm it from the Devices page / Reset requests":
    {
      en: "Users will be able to reset the device automatically if this field is enabled. If it isn't, they won't be able to reset it unless you confirm it from the Devices page / Reset requests.",
      pt: "Os usuários poderão redefinir o dispositivo automaticamente se este campo estiver habilitado. Caso contrário, eles não poderão redefini-lo a menos que você o confirme na página Dispositivos / Solicitações de redefinição.",
      de: "Benutzer können das Gerät automatisch zurücksetzen, wenn dieses Feld aktiviert ist. Andernfalls können sie es nicht zurücksetzen, es sei denn, Sie bestätigen es auf der Seite Geräte / Zurücksetzungsanfragen.",
      es: "Los usuarios podrán restablecer el dispositivo automáticamente si este campo está habilitado. De lo contrario, no podrán restablecerlo a menos que lo confirme desde la página de Dispositivos / Solicitudes de restablecimiento.",
      fr: "Les utilisateurs pourront réinitialiser le périphérique automatiquement si ce champ est activé. Sinon, ils ne pourront pas le réinitialiser à moins que vous ne le confirmiez depuis la page Appareils / Demandes de réinitialisation.",
      it: "Gli utenti potranno ripristinare automaticamente il dispositivo se questo campo è abilitato. In caso contrario, non potranno ripristinarlo a meno che tu non lo confermi dalla pagina Dispositivi / Richieste di ripristino.",
      sq: "Përdoruesit do të jenë në gjendje të rivendosin automatikisht pajisjen nëse ky fushë është e aktivizuar. Nëse nuk është, ata nuk do të jenë në gjendje ta rivendosin atë përveç nëse ju e konfirmoni atë nga faqja e Pajisjeve / Kërkesat për rivendosje.",
      tr: "Kullanıcılar, bu alan etkinse cihazı otomatik olarak sıfırlayabileceklerdir. Değilse, cihazı sıfırlayamazlar, cihazı Sıfırlama talepleri / Cihazlar sayfasından onaylamadığınız sürece.",
      sw: "Watumiaji wataweza kufuta kifaa kiotomatiki ikiwa uwanja huu umewezeshwa. Ikiwa haujawawezesha, hawawezi kufuta isipokuwa uthibitishe kutoka kwenye ukurasa wa Vifaa / Maombi ya Kurekebisha.",
      am: "ተጠቃሚዎች ይወካይ እንደ ይዛ ያረጋገጡ በዚህ መስክ ይቀየር ይችላሉ። እንዲሁ ነገር በመሠረቱ ይህ አልተገኘም ይውድ ወይም ተጨማሪ ይሆናል ወይ እንደ ይቀይይይ ሂደት ስለሚሞላ ይህ አይደለም።",
      om: "Fayyadamtoonni aakkayyi kan bifa akkamiitiin walin kuusaa dalaga tokko daangaa alaa gochuun ni danda'ama. Yoo hin taane, fuula qaree/daangaa tokkorraa dhugaa yeroo dabalata deemsisuu hin dandeenyu.",
      ig: "Ebe a ga na-agba onye ọrụ ga-enyere ha aka na-agba ngwa ngwa ma ọ bụrụ na a kpaliri mpaghara a. Ọ bụrụ na ọ bụghị, ha agaghị enwe ike ịchọpụta ya ma ị gaghị ekwenye ya site na ibe ngwaọrụ / arịrịọ ndị na-eweghachi.",
      so: "Isticmaalayaasha ayaa si otomaatig ah u dejin kara qalabka haddii meelaha lagu dhajiyay la furo. Haddii aysan ahayn, ma awoodi doonaan inay hagaajiyaan ilaa aad ka xaqiijiso bogga qalabka / codsiyada dib u dejiya.",
      ha: "Idan aka ba da izini, masu amfani za su iya sake saita na'urar ta atomatik. Idan ba haka ba, ba za su iya sake saita ta sai ka tabbatar da hakan daga shafin Na'urorin / Bukatun Sake Saita.",
      mn: "Хэрэглэгчид энэ талбарыг идэвхжүүлбэл төхөөрөмжийг автоматаар дахин тохируулах боломжтой. Хэрэв биш бол, төхөөрөмжийн хуудас / дахин тохируулах хүсэлтээс баталгаажуулахгүйгээр дахин тохируулах боломжгүй.",
      ff: "Awọn olumulo yoo ni anfani lati tunto ẹrọ laifọwọyi ti aaye yii ba ti mu ṣiṣẹ. Ti ko ba ṣe bẹ, wọn ko ni anfani lati tunto ayafi ti o ba jẹrisi lati inu Oju-iwe Awọn ẹrọ / Awọn ibeere Tunto.",
      yo: "Awọn olumulo yoo ni anfani lati tunto ẹrọ naa laifọwọyi ti a ba mu aaye yii ṣiṣẹ. Ti kii ba ṣe bẹ, wọn kii yoo ni anfani lati tunto ayafi ti o ba jẹrisi rẹ lati oju-iwe Awọn ẹrọ / Awọn ibeere atunṣe.",
    },
  "Use port": {
    en: "Use port",
    pt: "Usar porta",
    de: "Port verwenden",
    es: "Usar puerto",
    fr: "Utiliser le port",
    it: "Usa porta",
    sq: "Përdorni portin",
    tr: "Portu kullan",
    sw: "Tumia bandari",
    am: "የመሬት ተወላጅ",
    om: "Bu'uura fayyadamaa",
    ig: "Jiri ọdụ",
    so: "Isticmaal deked",
    ha: "Yi amfani da tashar",
    mn: "Порт ашиглах",
    ff: "Yi amfani da tashar",
    yo: "Lo ibudo",
  },
  //v
  Visibly: {
    en: "Visibly",
    pt: "Visivelmente",
    de: "Sichtbar",
    es: "Visiblemente",
    fr: "Visiblement",
    it: "Visibilmente",
    sq: "Vizualisht",
    tr: "Görünür şekilde",
    sw: "Kwa uwazi",
    am: "በአይነት",
    om: "Haasaa",
    ig: "N'anya",
    so: "Si cad",
    ha: "A fili",
    mn: "Тодорхой",
    ff: "A fili",
    yo: "Ni kedere",
  },
  Viewed: {
    en: "Viewed",
    pt: "Visualizado",
    de: "Angesehen",
    es: "Visto",
    fr: "Vu",
    it: "Visualizzato",
    sq: "Shikuar",
    tr: "Görüntülendi",
    sw: "Imeangaliwa",
    am: "ተመልከት",
    om: "Ittiga",
    ig: "E lele",
    so: "Laga daawaday",
    ha: "An kalli",
    mn: "Үзэгдсэн",
    ff: "An kalli",
    yo: "Ti wo",
  },
  Version: {
    en: "Version",
    pt: "Versão",
    de: "Version",
    es: "Versión",
    fr: "Version",
    it: "Versione",
    sq: "Versioni",
    tr: "Sürüm",
    sw: "Toleo",
    am: "ቅጽ",
    om: "Gosa",
    ig: "Ụdị",
    so: "Nooca",
    ha: "Nau'in",
    mn: "Хувилбар",
    ff: "Nau'in",
    yo: "Eya",
  },
  //w
  "We’ve identified a technical issue with your account. Please contact us as soon as possible to resolve it and prevent any service disruptions":
    {
      en: "We’ve identified a technical issue with your account. Please contact us as soon as possible to resolve it and prevent any service disruptions.",
      pt: "Identificamos um problema técnico com sua conta. Por favor, entre em contato conosco o mais rápido possível para resolvê-lo e evitar qualquer interrupção de serviço.",
      de: "Wir haben ein technisches Problem mit Ihrem Konto festgestellt. Bitte kontaktieren Sie uns so schnell wie möglich, um es zu beheben und Unterbrechungen des Dienstes zu vermeiden.",
      es: "Hemos identificado un problema técnico con su cuenta. Póngase en contacto con nosotros lo antes posible para resolverlo y evitar cualquier interrupción del servicio.",
      fr: "Nous avons identifié un problème technique avec votre compte. Veuillez nous contacter dès que possible pour le résoudre et éviter toute interruption de service.",
      it: "Abbiamo identificato un problema tecnico con il tuo account. Contattaci il prima possibile per risolverlo ed evitare interruzioni del servizio.",
      sq: "Ne kemi identifikuar një problem teknik me llogarinë tuaj. Ju lutemi na kontaktoni sa më shpejt të jetë e mundur për ta zgjidhur atë dhe për të parandaluar ndërprerjet e shërbimit.",
      tr: "Hesabınızda teknik bir sorun tespit ettik. Sorunu çözmek ve hizmet kesintilerini önlemek için lütfen en kısa sürede bizimle iletişime geçin.",
      sw: "Tumegundua tatizo la kiufundi na akaunti yako. Tafadhali wasiliana nasi haraka iwezekanavyo ili kulitatua na kuzuia usumbufu wowote wa huduma.",
      am: "የእኛ አካውንት ጋር ተዳርገናል። ይቅርታ አንድ ወዲያውኑ እባኮት ተጠይቁ።",
      om: "Maalawwan teknika akkaataa keewwata keetii ifa galfatan. Sila dhaqqabi yaada guyyu siif quba qabu, saadhuf ittiin furuu ni dandeessa.",
      ig: "Anyị chọpụtara nsogbu teknụzụ na akaụntụ gị. Biko kpọtụrụ anyị ozugbo iji dozie ya ma zere nkwụsị ọ bụla n'ọrụ.",
      so: "Waxaan aqoonsanay dhibaato farsamo oo ku saabsan akoonkaaga. Fadlan nala soo xiriir sida ugu dhakhsaha badan si aan u xallino arintaas oo aan uga hortagno waxyeelo adeeg.",
      ha: "Mun gano wata matsala ta fasaha tare da asusunka ku. Don Allah ku tuntubi mu cikin gaggawa don warware ta da hana duk wani katsewar sabis.",
      mn: "Таны аккаунттай холбоотой техникийн асуудал илэрсэн. Мөн ямар ч үйлчилгээний тасалдал гарахаас сэргийлэхийн тулд аль болох хурдан бидэнтэй холбогдоно уу.",
      ff: "Mun gano wata matsala ta fasaha tare da asusunka ku. Don Allah ku tuntubi mu cikin gaggawa don warware ta da hana duk wani katsewar sabis.",
      yo: "A ti ṣe idanimọ iṣoro imọ-ẹrọ pẹlu akọọlẹ rẹ. Jowo kan si wa ni kete bi o ti ṣee lati yanju rẹ ki o si yago fun eyikeyi awọn idalọwọduro iṣẹ.",
    },
  WARNING: {
    en: "WARNING",
    pt: "AVISO",
    de: "WARNUNG",
    es: "ADVERTENCIA",
    fr: "AVERTISSEMENT",
    it: "AVVERTENZA",
    sq: "KUJDES",
    tr: "UYARI",
    sw: "ONYO",
    am: "እንቁላል",
    om: "Eeggadhu",
    ig: "NKWUPỤ",
    so: "DIGNIIN",
    ha: "GARGADI",
    mn: "АНХААРУУЛГА",
    ff: "GARGADI",
    yo: "IKILỌRUN",
  },
  "Welcome to the": {
    en: "Welcome to the",
    pt: "Bem-vindo ao",
    de: "Willkommen bei",
    es: "Bienvenido a",
    fr: "Bienvenue sur",
    it: "Benvenuti su",
    sq: "Mirë se vini në",
    tr: "Hoş geldiniz",
    sw: "Karibu kwenye",
    am: "እንኳን ወደ",
    om: "Baga gara",
    ig: "Nnọọ na",
    so: "Ku soo dhawaada",
    ha: "Barka zuwa",
    mn: "Тавтай морилно уу",
    ff: "Barka zuwa",
    yo: "Kaabọ si",
  },
  welcome: {
    en: "Welcome",
    pt: "Bem-vindo",
    de: "Willkommen",
    es: "Bienvenido",
    fr: "Bienvenue",
    it: "Benvenuto",
    sq: "Mirë se vini",
    tr: "Hoş geldiniz",
    sw: "Karibu",
    am: "እንኳን ወደ",
    om: "Baga",
    ig: "Nnọọ",
    so: "Ku soo dhawaada",
    ha: "Barka",
    mn: "Тавтай морилно уу",
    ff: "Barka",
    yo: "Kaabọ",
  },
  "when migration will be finished": {
    en: "when migration will be finished!",
    pt: "quando a migração estiver concluída!",
    de: "wenn die Migration abgeschlossen ist!",
    es: "cuando la migración haya terminado!",
    fr: "quand la migration sera terminée !",
    it: "quando la migrazione sarà completata!",
    sq: "kur migrimi do të përfundojë!",
    tr: "göç tamamlandığında!",
    sw: "wakati uhamishaji utakapokamilika!",
    am: "ምን ጊዜ ምርት ይደርሳል!",
    om: "yeroo qubannoo xumurame!",
    ig: "oge mbenata a ga-emecha!",
    so: "marka wareejinta la dhammeeyo!",
    ha: "lokacin da canja wurin zai kammala!",
    mn: "шилжүүлэг дуусах үед!",
    ff: "lokacin da canja wurin zai kammala!",
    yo: "nigba ti gbigbe ti pari!",
  },
  "With save button you can save your changes": {
    en: "With save button you can save your changes.",
    pt: "Com o botão de salvar, você pode salvar suas alterações.",
    de: "Mit der Speichertaste können Sie Ihre Änderungen speichern.",
    es: "Con el botón de guardar, puede guardar sus cambios.",
    fr: "Avec le bouton de sauvegarde, vous pouvez enregistrer vos modifications.",
    it: "Con il pulsante Salva puoi salvare le tue modifiche.",
    sq: "Me butonin ruaj, mund të ruani ndryshimet tuaja.",
    tr: "Kaydet butonuyla değişikliklerinizi kaydedebilirsiniz.",
    sw: "Kwa kitufe cha kuhifadhi unaweza kuokoa mabadiliko yako.",
    am: "የአስተካክል አውታር ጋር ማስተካከል ይቻላል።",
    om: "Save bitaana yookan yookan kee booda teessoo keetii.",
    ig: "Na bọtịnụ nchekwa, ị nwere ike ịchekwa mgbanwe gị.",
    so: "Iyada badhanka kaydinta, waxaad kaydin kartaa isbeddeladaada.",
    ha: "Tare da maɓallin adana za ku iya adana canje-canje ku.",
    mn: "Хадгалах товчлууртай, та өөрчлөлтөө хадгалах боломжтой.",
    ff: "Tare da maɓallin adana za ku iya adana canje-canje ku.",
    yo: "Pẹlu bọtini fipamọ, o le fipamọ awọn ayipada rẹ.",
  },
  "With save button you can save your changes and the new device will be added successfully":
    {
      en: "With save button you can save your changes and the new device will be added successfully.",
      pt: "Com o botão de salvar, você pode salvar suas alterações e o novo dispositivo será adicionado com sucesso.",
      de: "Mit der Speichertaste können Sie Ihre Änderungen speichern, und das neue Gerät wird erfolgreich hinzugefügt.",
      es: "Con el botón de guardar, puede guardar sus cambios y el nuevo dispositivo se agregará con éxito.",
      fr: "Avec le bouton de sauvegarde, vous pouvez enregistrer vos modifications et le nouveau dispositif sera ajouté avec succès.",
      it: "Con il pulsante Salva puoi salvare le tue modifiche e il nuovo dispositivo verrà aggiunto con successo.",
      sq: "Me butonin ruaj, mund të ruani ndryshimet tuaja dhe pajisja e re do të shtohet me sukses.",
      tr: "Kaydet butonuyla değişikliklerinizi kaydedebilir ve yeni cihaz başarıyla eklenecektir.",
      sw: "Kwa kitufe cha kuhifadhi unaweza kuokoa mabadiliko yako na kifaa kipya kitajumuishwa kwa mafanikio.",
      am: "የአስተካክል አውታር ጋር ማስተካከል ይቻላል እና አዲስ መሣሪያ በስኬት ይታይባል።",
      om: "Save bitaana yookan yookan kee booda teessoo keetii; suura haaraa ni dabalata.",
      ig: "Na bọtịnụ nchekwa, ị nwere ike ịchekwa mgbanwe gị ma ngwaọrụ ọhụrụ ga-etinye nke ọma.",
      so: "Iyada badhanka kaydinta, waxaad kaydin kartaa isbeddeladaada iyo qalabka cusub ayaa si guul leh loo dari doonaa.",
      ha: "Tare da maɓallin adana za ku iya adana canje-canje ku kuma sabon na'ura za a ƙara da nasara.",
      mn: "Хадгалах товчлууртай, та өөрчлөлтөө хадгалах боломжтой бөгөөд шинэ төхөөрөмжийг амжилттай нэмэх болно.",
      ff: "Tare da maɓallin adana za ku iya adana canje-canje ku kuma sabon na'ura za a ƙara da nasara.",
      yo: "Pẹlu bọtini fipamọ, o le fipamọ awọn ayipada rẹ ati pe ẹrọ tuntun yoo fi kun ni aṣeyọri.",
    },
  "We have sent a verification code to your provided email. Please verify the confirmation code you have received in your email.":
    {
      en: "We have sent a verification code to your provided email. Please verify the confirmation code you have received in your email.",
      pt: "Enviamos um código de verificação para o seu e-mail fornecido. Por favor, verifique o código de confirmação que você recebeu em seu e-mail.",
      de: "Wir haben einen Bestätigungscode an Ihre angegebene E-Mail-Adresse gesendet. Bitte überprüfen Sie den Bestätigungscode, den Sie in Ihrer E-Mail erhalten haben.",
      es: "Hemos enviado un código de verificación a su correo electrónico proporcionado. Por favor, verifique el código de confirmación que ha recibido en su correo electrónico.",
      fr: "Nous avons envoyé un code de vérification à votre adresse e-mail fournie. Veuillez vérifier le code de confirmation que vous avez reçu dans votre e-mail.",
      it: "Abbiamo inviato un codice di verifica alla tua email fornita. Verifica il codice di conferma che hai ricevuto nella tua email.",
      sq: "Kemi dërguar një kod verifikimi në emailin tuaj të dhënë. Ju lutemi verifikoni kodin e konfirmimit që keni marrë në emailin tuaj.",
      tr: "Sağladığınız e-posta adresinize bir doğrulama kodu gönderdik. Lütfen e-posta kutunuzda aldığınız onay kodunu doğrulayın.",
      sw: "Tumetuma msimbo wa uthibitisho kwa barua pepe uliyoitoa. Tafadhali thibitisha msimbo wa uthibitisho uliopokea katika barua pepe yako.",
      am: "እንደተለመደው እቅፍ ለመስጠት ኮድ ወደ እንደሚታወቀው እቅፍ ተላክቷል። በኢሜይል ውስጥ ወደ ተቀባዩ ኮድ ይምረጡ።",
      om: "Email keetiin ergaa mirkaneessaa siif ergameera. Maqaa mirkaneessaa siif ergame mirkaneessi.",
      ig: "Anyị ezigaala koodu nkwenye na email ị nyere. Biko kwadoro koodu nkwenye ị natara na email gị.",
      so: "Waxaan ku soo dirnay koodh xaqiijin ah emailka aad bixisay. Fadlan xaqiiji koodhka xaqiijinta ee aad ku heshay emailkaaga.",
      ha: "Mun aika maka lambar tabbatarwa zuwa adireshin imel da ka bayar. Don Allah tabbatar da lambar tabbatarwa da ka karba a cikin imel dinka.",
      mn: "Бид таны өгсөн и-мэйл хаягт баталгаажуулах код явуулсан. Та и-мэйлд хReceivedсэн баталгаажуулах кодаа шалгаарай.",
      ff: "Mun aika maka lambar tabbatarwa zuwa adireshin imel da ka bayar. Don Allah tabbatar da lambar tabbatarwa da ka karba a cikin imel dinka.",
      yo: "A ti fi koodu ìmúdájú ranṣẹ si imeeli ti o pese. Jọwọ jẹrisi koodu ìmúdájú ti o ti gba ninu imeeli rẹ.",
    },
  "We have sent a verification code to your email.": {
    en: "We have sent a verification code to your email.",
    pt: "Enviamos um código de verificação para o seu e-mail.",
    de: "Wir haben einen Bestätigungscode an Ihre E-Mail gesendet.",
    es: "Hemos enviado un código de verificación a su correo electrónico.",
    fr: "Nous avons envoyé un code de vérification à votre adresse e-mail.",
    it: "Abbiamo inviato un codice di verifica alla tua email.",
    sq: "Ju kemi dërguar një kod verifikimi në email-in tuaj.",
    tr: "E-posta adresinize bir doğrulama kodu gönderdik.",
    sw: "Tumetuma msimbo wa uthibitisho kwa barua pepe yako.",
    am: "እኛ ወደ ኢሜይልዎ የማረጋገጫ ኮድ ተላክቷል።",
    om: "Email keetiin ergaa mirkaneessaa siif ergameera.",
    ig: "Anyị ezigaala koodu nkwenye na email gị.",
    so: "Waxaan ku soo dirnay koodh xaqiijin ah emailkaaga.",
    ha: "Mun aika maka lambar tabbatarwa zuwa adireshin imel dinka.",
    mn: "Бид таны и-мэйл хаягт баталгаажуулах код явуулсан.",
    ff: "Mun aika maka lambar tabbatarwa zuwa adireshin imel da ka bayar.",
    yo: "A ti fi koodu ìmúdájú ranṣẹ si imeeli rẹ.",
  },
  "Wrong password": {
    en: "Wrong password",
    pt: "Senha incorreta",
    de: "Falsches Passwort",
    es: "Contraseña incorrecta",
    fr: "Mot de passe incorrect",
    it: "Password errata",
    sq: "Fjalëkalim i gabuar",
    tr: "Yanlış şifre",
    sw: "Neno la siri sio sahihi.",
    am: "የይለፍ ቃል ወረርሽት ነው።",
    om: "Yeroo sirrii miti.",
    ig: "Okwu mbido adịghị mma.",
    so: "Furaha khaladka ah.",
    ha: "Kalmar wucewa ba ta dace ba.",
    mn: "Нууц үг буруу байна.",
    ff: "Kalmar wucewa ba ta dace ba.",
    yo: "Ọrọ igbaniwọle jẹ aṣiṣe.",
  },
  Week: {
    en: "Week",
    es: "Semana",
    fr: "Semaine",
    de: "Woche",
    pt: "Semana",
    it: "Settimana",
    sq: "Java",
    tr: "Hafta",
    sw: "Wiki",
    am: "ሳምንት",
    om: "Torban",
    ig: "Izu",
    so: "Usbuuc",
    ha: "Mako",
    mn: "Долоо хоног",
    ff: "Mako",
    yo: "Ọsẹ",
  },
};

export default translations;
