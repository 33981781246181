import { useEffect } from "react";
import { menuBrandingIconsCollection } from "../../../../config/data";
import IMAGES from "../../../../config/images";
import { returnColor } from "../../../../config/utils";

export default function MenuRmsButtons({ primaryColor, secondaryColor, menu }) {
  const svgBackground = `<svg xmlns="http://www.w3.org/2000/svg" width="296" height="297" viewBox="0 0 296 297" fill="none">
<g filter="url(#filter0_f_4913_8293)">
<circle cx="147.906" cy="148.671" r="84.9062" transform="rotate(-90 147.906 148.671)" fill="url(#paint0_linear_4913_8293)"/>
</g>
<defs>
<filter id="filter0_f_4913_8293" x="0.106495" y="0.871143" width="295.6" height="295.6" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
<feGaussianBlur stdDeviation="31.4468" result="effect1_foregroundBlur_4913_8293"/>
</filter>
<linearGradient id="paint0_linear_4913_8293" x1="147.906" y1="63.7647" x2="147.906" y2="233.577" gradientUnits="userSpaceOnUse">
<stop stop-color="${
    menu?.menu_icons_collection === 1
      ? returnColor(primaryColor, 10)
      : returnColor(menu.menu_items_active_color, 10)
  }"/>
<stop offset="1" stop-color="${
    menu?.menu_icons_collection === 1
      ? returnColor(secondaryColor, 10)
      : returnColor(menu.menu_items_color, 10)
  }"/>
</linearGradient>
</defs>
</svg>`;

  const returnMenuItemsBoxForm = () => {
    switch (menu.menu_items_box_form) {
      case "round":
        return "50%";
      case "quadrate":
        return "0px";
      case "halfcircle":
        return "15px";
      default:
        return "50%";
    }
  };

  const returnMenuItemsBoxBG = () => {
    switch (menu.menu_items_box_form) {
      case "round":
        return IMAGES.ROUND;
      case "quadrate":
        return IMAGES.QUADRATE;
      case "halfcircle":
        return IMAGES.HALFCIRCLE;
      default:
        return IMAGES.ROUND;
    }
  };

  return (
    <div className="rms-menu-items">
      {Object.entries(
        menuBrandingIconsCollection[menu?.menu_icons_collection - 1]
      ).map(([key, value]) => (
        <div className="rms-items-wrap" key={key}>
          <div
            className="rms-item"
            style={{
              backgroundImage: `url(${
                menu?.menu_icons_collection == 1
                  ? IMAGES.ROUND
                  : returnMenuItemsBoxBG()
              })`,

              color:
                menu?.menu_icons_collection == 1
                  ? "#fff"
                  : menu.menu_icons_color,

              backgroundColor:
                key == "live"
                  ? menu?.menu_icons_collection == 1
                    ? returnColor(primaryColor, 10)
                    : menu.menu_items_active_color &&
                      menu?.menu_icons_collection !== 1
                    ? returnColor(menu.menu_items_active_color, 10)
                    : "transparent"
                  : "transparent",

              boxShadow:
                key == "live"
                  ? menu?.menu_icons_collection == 1
                    ? `0.49px 0.49px 20.97px 0px ${primaryColor}`
                    : menu?.menu_icons_collection !== 1 &&
                      menu.menu_items_active_color
                    ? `0.49px 0.49px 20.97px 0px ${menu.menu_items_active_color}`
                    : `0px 6.99px 6.99px 0px #FFFFFF40 inset`
                  : `0px 6.99px 6.99px 0px #FFFFFF40 inset`,

              borderRadius:
                menu?.menu_icons_collection == 1
                  ? "50%"
                  : returnMenuItemsBoxForm(),
            }}
          >
            {key == "live" && (
              <img
                src={`data:image/svg+xml,${encodeURIComponent(svgBackground)}`}
                className="light-effect"
              />
            )}
            {value}
          </div>

          <p
            className="rms-item-title"
            style={{
              color:
                menu?.menu_icons_collection == 1
                  ? "#fff"
                  : menu.menu_icons_color,
            }}
          >
            {key == "live" ? "LIVE TV" : key}
          </p>
        </div>
      ))}
    </div>
  );
}
