import { useState, useEffect, useRef } from "react";

import { Table, Tooltip, message } from "antd";

import REQUESTS from "../../../api/requests";

import useTranslate from "../../../hooks/translator";

import { ICONS, IMAGES } from "../../../config";
import {
  getColumnSearchProps,
  getQueryDate,
  getColumnDateProps,
} from "../../../config/filters";

import { parseFullDate } from "../../../config/formats";

import DevicesDrawer from "../DevicesDrawer";

import {
  Tags,
  ActionTable,
  confirmModal,
  LongText,
  CopyText,
} from "../../../components";

export default function Deactivated({
  activeTab,
  tab,
  getDeviceInfo,
  getProfile,
  platforms,
  selected,
  setSelected,
  isDrawerOpen,
  setIsDrawerOpen,
  myActivate,
  setMyActivate,
}) {
  const translate = useTranslate();
  const searchInputRef = useRef(null);

  const [loading, setLoading] = useState(false);

  const [devices, setDevices] = useState([]);

  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [sort, setSort] = useState(["createdAt", "DESC"]);
  const [sortedInfo, setSortedInfo] = useState({});

  const [search, setSearch] = useState({
    deviceKey: null,
    country: null,
    ipAddress: null,
  });

  const [filtersTb, setFiltersTb] = useState({
    date: null,
    updatedAt: null,
    expired: null,
    freeTrialExpired: null,
    platform: null,
  });

  useEffect(() => {
    if (activeTab == "Deactivated" || tab == "Deactivated") {
      getDeactiveDevices();
    }
  }, [search, filtersTb, currentPage, limit, sort, tab, activeTab]);

  const columns = [
    {
      title: "#",
      width: 40,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      align: "center",
      render: (text, record, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },
    {
      title: translate("Device key"),
      dataIndex: "device_key",
      key: "device_key",
      align: "center",
      fixed: "left",
      ...getColumnSearchProps(searchInputRef),
      render: (text, record, index) =>
        text ? <CopyText text={text} /> : "N/A",
    },

    {
      title: translate("Platform"),
      key: "platform name",
      align: "center",
      filters: platforms,
      render: (text, record, index) => {
        return (
          (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-around",
              }}
            >
              <Tooltip
                title={
                  record?.platform?.name == "Antroid Mobile"
                    ? "Android Mobile"
                    : record?.platform?.name
                }
              >
                <img
                  src={record?.platform?.icon}
                  style={{
                    width: "37px",
                    height: "23px",
                    marginRight: "10px",
                    objectFit: "contain",
                  }}
                  alt="platform"
                  onError={(e) => (e.target.src = IMAGES.ERROR)}
                />
              </Tooltip>
            </div>
          ) || <Tags />
        );
      },
    },
    {
      title: translate("Country"),
      dataIndex: "country",
      key: "country",
      align: "center",
      ...getColumnSearchProps(searchInputRef),
      render: (text, record, index) =>
        record?.country ? record?.country : "N/A",
    },
    {
      title: translate("IP address"),
      dataIndex: "ip_address",
      key: "ip_address",
      align: "center",
      ...getColumnSearchProps(searchInputRef),
      render: (text, record, index) =>
        record?.ip_address ? (
          <CopyText text={<LongText children={record?.ip_address} />} />
        ) : (
          "N/A"
        ),
    },
    {
      title: translate("Updated date"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      sorter: true,
      sortOrder: sortedInfo.columnKey === "updatedAt" ? sortedInfo.order : null,
      ...getColumnDateProps((e, dateString) =>
        setFiltersTb({ ...filtersTb, updatedAt: dateString })
      ),
      render: (text, record, index) => parseFullDate(text),
    },
    {
      title: translate("Created date"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      sorter: true,
      sortOrder: sortedInfo.columnKey === "createdAt" ? sortedInfo.order : null,
      ...getColumnDateProps((e, dateString) =>
        setFiltersTb({ ...filtersTb, date: dateString })
      ),
      render: (text, record, index) => parseFullDate(text),
    },
    {
      title: translate("Expired date"),
      dataIndex: "expired_date",
      key: "expired_date",
      align: "center",
      sorter: true,
      sortOrder:
        sortedInfo.columnKey === "expired_date" ? sortedInfo.order : null,
      ...getColumnDateProps((e, dateString) =>
        setFiltersTb({ ...filtersTb, expired: dateString })
      ),
      render: (text, record, index) => (text ? parseFullDate(text) : "N/A"),
    },

    {
      title: translate("Free trial expired"),
      dataIndex: "free_trial_expired",
      key: "free_trial_expired",
      align: "center",
      sorter: true,
      sortOrder:
        sortedInfo.columnKey === "free_trial_expired" ? sortedInfo.order : null,
      ...getColumnDateProps((e, dateString) =>
        setFiltersTb({ ...filtersTb, freeTrialExpired: dateString })
      ),
      render: (text, record, index) => (text ? parseFullDate(text) : "N/A"),
    },
    {
      title: "",
      key: "row__actions",
      fixed: "right",
      width: 60,
      align: "center",
      render: (text, record, index) => (
        <ActionTable
          handleMenuClick={(e) => handleMenuClick(e, record)}
          buttons={[
            {
              key: "activate",
              label: translate("Activate"),
              icon: ICONS.TRUE,
            },
            {
              key: "block",
              label: translate("Block"),
              icon: ICONS.BLOCK,
            },
          ]}
        />
      ),
    },
  ];

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "activate":
        setSelected(item);
        setMyActivate(true);
        setIsDrawerOpen(true);
        break;
      case "block":
        confirmModal({
          title: translate("Do you want to blocked this device"),
          okText: translate("Yes"),
          cancelText: translate("Cancel"),
          action: () => {
            REQUESTS.DEVICE_BLACK_LIST.POST({ device_id: item.id })
              .then((res) => {
                message.success(res);
                getDeactiveDevices();
                getProfile();
                getDeviceInfo();
              })
              .catch((err) => {
                message.error(err);
              });
          },
        });
        break;
      default:
        break;
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setSortedInfo(sorter);
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    setFiltersTb((prevFilters) => ({
      ...prevFilters,
      platform: filters["platform name"] || null,
    }));

    setSearch((prevSearch) => ({
      ...prevSearch,
      deviceKey: filters["device_key"] || null,
      ipAddress: filters["ip_address"] || null,
      country: filters["country"] || null,
    }));

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  const getDeactiveDevices = () => {
    setLoading(true);

    const query = {
      limit,
      page: currentPage,
      pagination: 1,
      sort,
      filter: { is_active: false, is_trial: false },
    };

    if (search) {
      const list = Object.values(search);
      for (let i = 0; i < list.length; i++) {
        if (list[i]) {
          if (!query.search) {
            query.search = {};
          }
          break;
        }
        delete query.search;
      }

      if (search.deviceKey) {
        query.search["device_key"] = search.deviceKey[0];
      }
      if (search.ipAddress) {
        query.search["ip_address"] = search.ipAddress[0];
      }
      if (search.country) {
        query.search["country"] = search.country[0];
      }
    } else {
      delete query.search;
    }

    const queryDate = getQueryDate(filtersTb.date);
    const queryUpdatedAt = getQueryDate(filtersTb.updatedAt);
    const queryExpired = getQueryDate(filtersTb.expired);
    const queryFreeTrialExpired = getQueryDate(filtersTb.freeTrialExpired);

    if (queryDate) {
      query.between = queryDate;
    }

    if (queryUpdatedAt) {
      query.between = queryUpdatedAt;
    }

    if (queryExpired) {
      query.between = queryExpired;
    }

    if (queryFreeTrialExpired) {
      query.between = queryFreeTrialExpired;
    }

    if (filtersTb.platform) {
      query["filter"] = {};

      if (filtersTb.platform) {
        query["filter"]["platform_id"] = filtersTb.platform;
      }
    }

    REQUESTS.DEVICES({ query: JSON.stringify(query) })
      .then((data) => {
        const divicesList = data?.rows?.filter((el) => el.black_list == null);
        setLoading(false);
        setDevices(divicesList);
        setTotal(data.total);
        setLimit(data.limit);
        setCurrentPage(data.page);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Table
        rowKey="id"
        size={"small"}
        onChange={handleTableChange}
        dataSource={devices}
        columns={columns}
        loading={loading}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
        }}
        scroll={{
          x: "max-content",
          y: null,
        }}
      />
      {(activeTab == "Deactivated" || tab == "Deactivated") && (
        <DevicesDrawer
          open={isDrawerOpen}
          setOpen={() => setIsDrawerOpen(false)}
          getData={getDeactiveDevices}
          getDeviceInfo={getDeviceInfo}
          selected={selected}
          myActivate={myActivate}
        />
      )}
    </>
  );
}
