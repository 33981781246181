import { UndoOutlined } from "@ant-design/icons";
import {
  menuBrandingIconsCollection,
  BRANDING_TV_ICONS,
} from "../../../../config/data";
import IMAGES from "../../../../config/images";
import "../../styles/menu-classic.scss";

export default function MenuClassic({ colors, logo, backgroundImage, menu }) {
  const returnMenuItemsBoxForm = () => {
    switch (menu.menu_items_box_form) {
      case "round":
        return "50%";
      case "quadrate":
        return "0px";
      case "halfcircle":
        return "15px";
      default:
        return "50%";
    }
  };

  return (
    <div
      className="bg"
      style={{
        backgroundImage: `url(${backgroundImage.url})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
        backgroundRepeat: " no-repeat",
        position: "relative",
        backgroundColor: "#000",
      }}
    >
      <div className="tv-logo-container">
        <img
          src={logo.url}
          onError={(e) => (e.target.src = IMAGES.ERROR)}
          alt=""
          className="tv-logo"
          style={{ width: logo.size ? `${logo.size * 5}px` : "137px" }}
        />
      </div>

      {menu.menu_icons_collection == 1 ? (
        <div className="tv-icons-container">
          {BRANDING_TV_ICONS.map((item, i) => {
            return (
              (item.name === "Settings" ||
                item.name === "Live TV" ||
                item.name === "Movies" ||
                item.name === "Series") && (
                <div
                  key={item.id}
                  className="tv-icon-item"
                  style={{
                    border: `2px solid ${
                      i === 0 ? colors.secondary_color : "#707071"
                    }`,
                    borderRadius: "50%",
                  }}
                >
                  <div
                    style={{
                      background: `${
                        i === 0 ? colors.secondary_color : "#313131"
                      }`,
                      borderRadius: "50%",
                      width: "65px",
                      height: "65px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img src={item.icon} alt="" className="tv-icon" />
                  </div>
                  <span style={{ color: "#fff" }}>{item.name}</span>
                </div>
              )
            );
          })}
        </div>
      ) : (
        <div className="tv-icons-container">
          <div
            className="tv-icon-item tv-icon-custom"
            style={{
              border: `2px solid ${menu.menu_items_active_color}`,
              borderRadius: returnMenuItemsBoxForm(),
              color: `${menu.menu_icons_color}`,
              backgroundColor: `rgba(23, 23, 23, 0.5)`,
              transform: `scale(1.1)`,
            }}
          >
            {menuBrandingIconsCollection[menu.menu_icons_collection - 1]?.live}

            <span style={{ color: menu.menu_icons_color }}>Live TV</span>
          </div>
          <div
            className="tv-icon-item tv-icon-custom"
            style={{
              border: `2px solid ${menu.menu_items_color}`,
              borderRadius: returnMenuItemsBoxForm(),
              color: `${menu.menu_icons_color}`,
              backgroundColor: `rgba(29, 29, 29, 0.5)`,
            }}
          >
            {
              menuBrandingIconsCollection[menu.menu_icons_collection - 1]
                ?.movies
            }

            <span style={{ color: menu.menu_icons_color }}>Movies</span>
          </div>
          <div
            className="tv-icon-item tv-icon-custom"
            style={{
              border: `2px solid ${menu.menu_items_color}`,
              borderRadius: returnMenuItemsBoxForm(),
              color: `${menu.menu_icons_color}`,
              backgroundColor: `rgba(29, 29, 29, 0.5)`,
            }}
          >
            {
              menuBrandingIconsCollection[menu.menu_icons_collection - 1]
                ?.series
            }

            <span style={{ color: menu.menu_icons_color }}>Series</span>
          </div>

          <div
            className="tv-icon-item tv-icon-custom"
            style={{
              border: `2px solid ${menu.menu_items_color}`,
              borderRadius: returnMenuItemsBoxForm(),
              color: `${menu.menu_icons_color}`,
              backgroundColor: `rgba(29, 29, 29, 0.5)`,
            }}
          >
            {
              menuBrandingIconsCollection[menu.menu_icons_collection - 1]
                ?.settings
            }

            <span style={{ color: menu.menu_icons_color }}>Settings</span>
          </div>
        </div>
      )}

      <div
        className="reload-app"
        style={{
          border: `1.3px solid rgb(112, 112, 113)`,
        }}
      >
        <UndoOutlined
          style={{
            color:
              menu.menu_icons_collection == 1 ? "#fff" : menu.menu_icons_color,
          }}
        />
        <span
          style={{
            color:
              menu.menu_icons_collection == 1 ? "#fff" : menu.menu_icons_color,
          }}
        >
          Reload app
        </span>
      </div>
      <div className="tv-footer-container">
        <p>
          <span> Device Key: 3GK689</span>
        </p>
        <p>App version: 1.1.5</p>
      </div>
    </div>
  );
}
