import { useEffect, useState } from "react";
import { Input } from "antd";

import { Form, Divider, Switch, message } from "antd";

import useTranslate from "../../hooks/translator";

import { ButtonComponent } from "../../components";
import REQUESTS from "../../api/requests";

export default function ContactsPage() {
  const translate = useTranslate();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [currentData, setCurrentData] = useState(null);

  useEffect(() => {
    getCurrentData();
  }, []);

  const getCurrentData = () => {
    REQUESTS.SETTINGS_CONTACTS.GET({})
      .then((data) => {
        setCurrentData(data);
        if (data) {
          form.setFields([
            { name: "is_visibale", value: data.is_visibale },
            { name: "phone", value: data.phone },
            { name: "email", value: data.email },
            { name: "web_page", value: data.web_page },
            { name: "whatsapp", value: data.whatsapp },
            { name: "telegram", value: data.telegram },
            { name: "facebook", value: data.facebook },
            { name: "tiktok", value: data.tiktok },
            { name: "join_telegram_group", value: data.join_telegram_group },
            { name: "join_whatsapp_group", value: data.join_whatsapp_group },
            { name: "notes", value: data.notes },
          ]);
        }
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const onFinish = (values) => {
    setLoading(true);
    const body = {
      is_visibale: values.is_visibale,
      email: values.email || "",
      phone: values.phone || "",
      web_page: values.web_page || "",
      whatsapp: values.whatsapp || "",
      telegram: values.telegram || "",
      facebook: values.facebook || "",
      tiktok: values.tiktok || "",
      join_telegram_group: values.join_telegram_group || "",
      join_whatsapp_group: values.join_whatsapp_group || "",
      notes: values.notes || "",
    };

    if (currentData) {
      body.id = currentData.id;

      REQUESTS.SETTINGS_CONTACTS.PUT(body)
        .then((res) => {
          setLoading(false);
          getCurrentData();
          message.success(translate("success"));
        })
        .catch((error) => {
          setLoading(false);
          message.error(error);
        });
    }
  };

  const handleKeyPress = (event) => {
    event.stopPropagation();
    if (event.key === "Enter") {
      form.submit();
    }
  };

  const submitBtn = (e) => {
    e.stopPropagation();
    form.submit();
  };

  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          is_visibale: true,
        }}
        onKeyPress={(e) => handleKeyPress(e)}
      >
        <Divider
          orientation="left"
          style={{ fontSize: "15px", display: "flex", alignItems: "baseline" }}
        >
          <div style={{ display: "flex", gap: "12px", lineHeight: 2 }}>
            <h4> {translate("Contacts")}</h4>
            <Form.Item name="is_visibale" valuePropName="checked">
              <Switch />
            </Form.Item>
          </div>
        </Divider>

        <div className="settings-contacts">
          <Form.Item label={translate("Phone")} name="phone">
            <Input />
          </Form.Item>
          <Form.Item
            label={translate("Email")}
            name="email"
            rules={[
              {
                required: false,
                message: translate(""),
              },
              {
                type: "email",
                message: translate("E-mail isn't valid"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={translate("Web page")}
            name="web_page"
            rules={[
              {
                required: false,
                message: translate(""),
              },
              {
                type: "url",
                message: translate("That's not a valid URL"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="WhatsApp"
            name="whatsapp"
            rules={[
              {
                required: false,
                message: translate(""),
              },
              {
                type: "url",
                message: translate("That's not a valid URL"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Telegram"
            name="telegram"
            rules={[
              {
                required: false,
                message: translate(""),
              },
              {
                type: "url",
                message: translate("That's not a valid URL"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Facebook"
            name="facebook"
            rules={[
              {
                required: false,
                message: translate(""),
              },
              {
                type: "url",
                message: translate("That's not a valid URL"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="TikTok"
            name="tiktok"
            rules={[
              {
                required: false,
                message: translate(""),
              },
              {
                type: "url",
                message: translate("That's not a valid URL"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={translate("Join Telegram group")}
            name="join_telegram_group"
            rules={[
              {
                required: false,
                message: translate(""),
              },
              {
                type: "url",
                message: translate("That's not a valid URL"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={translate("Join WhatsApp group")}
            name="join_whatsapp_group"
            rules={[
              {
                required: false,
                message: translate(""),
              },
              {
                type: "url",
                message: translate("That's not a valid URL"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>
        <Form.Item label={translate("Notes")} name="notes">
          <Input.TextArea rows={4} style={{ maxWidth: "350px" }} />
        </Form.Item>
        <Form.Item>
          <ButtonComponent
            style={{ maxWidth: "350px" }}
            loading={loading}
            onClick={(e) => submitBtn(e)}
          >
            {translate("Save")}
          </ButtonComponent>
        </Form.Item>
      </Form>
    </>
  );
}
