import React from "react";
import { Drawer } from "antd";

import useTranslate from "../../hooks/translator";

import {ResellerConfig} from "../../components";

export default function ResellerConfigDrawer({ open, onClose }) {
  const translate = useTranslate();

  return (
    <Drawer
      forceRender
      open={open}
      onClose={onClose}
      placement="right"
      title={translate("Reseller Config")}
    >
      <ResellerConfig showTitle={false} onClose={onClose}/>
    </Drawer>
  );
}
