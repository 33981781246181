import React, { useState, useEffect } from "react";
import { Tabs } from "antd";

import { useSelector, useDispatch } from "react-redux";
import { setProfile } from "../../store/features/profileSlice";

import REQUESTS from "../../api/requests";
import useTranslate from "../../hooks/translator";

import { PageComponent, DeviceConfig, ResellerConfig } from "../../components";

import BecomeASeller from "./BecomeASeller";
import ContactsPage from "./Contacts";

import OTPConfigs from "./OTP/OTPConfigs";

import "../../styles/settings.scss";
import { APP_FOR } from "../../config/themesConfigs";

export default function SettingsPage() {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const { profile } = useSelector((state) => state.profile);
  const [isMobile, setIsMobile] = useState(false);

  const returnBecomeASeller = () => {
    switch (APP_FOR) {
      case "selling":
        return false;
      case "uniplay":
        return false;
      case "tvs":
        return false;
      case "wapp":
        return false;
      default:
        return true;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getProfile = () => {
    REQUESTS.PROFILE()
      .then((data) => {
        dispatch(setProfile(data));
      })
      .catch((err) => {});
  };

  const items = [
    {
      key: 0,
      label: translate("Device Config"),
      children: <DeviceConfig showTitle={true} style={{ maxWidth: 350 }} />,
    },
    (profile?.is_partner || (profile?.parent_id && !profile?.is_partner)) && {
      key: 1,
      label: translate("Reseller Config"),
      children: <ResellerConfig showTitle={true} style={{ maxWidth: 350 }} />,
    },

    profile?.is_partner &&
      returnBecomeASeller() && {
        key: 2,
        label: translate("Become a seller"),
        children: <BecomeASeller />,
      },
    {
      key: 3,
      label: translate("OTP Config"),
      children: <OTPConfigs profile={profile} getProfile={getProfile} />,
    },
    profile && {
      key: 4,
      label: translate("Contacts"),
      children: <ContactsPage profile={profile} />,
    },
  ];
  return (
    <PageComponent>
      <div className="page">
        <h3>{translate("Settings")}</h3>
      </div>

      <Tabs
        tabPosition={isMobile ? "top" : "left"}
        defaultActiveKey={0}
        items={items}
      />
    </PageComponent>
  );
}
