import React, { useEffect, useState } from "react";
import { Modal, Steps } from "antd";

import AttachAccount from "./AttachAccount";
import ConfirmationCode from "./ConfirmationCode";
import PasswordConfirm from "./PasswordConfirm";

export default function AccountModal({ open, onCancel, getData }) {

  const [step, setStep] = useState(0);
  const [percent, setPercent] = useState(0);
  const [code, setCode] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (percent > 100) {
      setPercent(0);
    } 
    if(open){
      setStep(0);
      setPercent(0);
      setEmail(null)
      setCode(null)
    }
  }, [open]);

  return (
    <div>
      <Modal
        open={open}
        onCancel={onCancel}
        footer={null}
        maskClosable={false}
        destroyOnClose={true}
        className="account-modal"
      >
        <div className="steps">
          <Steps
            current={step}
            percent={percent}
            items={[{}, {}, {}]}
            responsive={false}
          />
        </div>
        <div className="attach-modal-container">
          {step == 0 && (
            <AttachAccount 
              setStep={setStep} 
              setPercent={setPercent} 
              email={email}
              setEmail={setEmail}
            />
          )}
          {step == 1 && ( 
            <ConfirmationCode
              setStep={setStep}
              setPercent={setPercent}
              code={code}
              setCode={setCode}
            />
          )} 

          {step == 2 && (
            <PasswordConfirm
              code={code}
              setStep={setStep}
              setPercent={setPercent}
              onCancel={onCancel}
              getData={getData}
            />
          )}
        </div>
      </Modal>
    </div>
  );
}
