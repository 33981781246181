import translations from "./translates";

import store from "../store/store";

let selectedLanguage = store.getState().profile.selectedLanguage;

export function parseFullDate(date) {
  if (!date) return "N/A";

  const dateObj = new Date(date);

  if (dateObj.getFullYear() > 2100) {
    return translations?.lifetime?.[selectedLanguage];
  }

  let year = dateObj.getFullYear();
  let day = dateObj.getDate();
  let month = dateObj.getMonth() + 1;
  let minutes = dateObj.getMinutes();
  let hours = dateObj.getHours();

  day = day < 10 ? "0" + day : day;
  month = month < 10 ? "0" + month : month;
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

export function parseDate(date) {
  const dateObj = new Date(date);

  let year = dateObj.getFullYear();
  let day = dateObj.getDate();
  let month = dateObj.getMonth() + 1;

  day = day < 10 ? "0" + day : day;
  month = month < 10 ? "0" + month : month;

  return `${day}/${month}/${year}`;
}

export function parseDateByYearMonthDay(date) {
  const dateObj = new Date(date);

  let year = dateObj.getFullYear();
  let day = dateObj.getDate();
  let month = dateObj.getMonth() + 1;

  day = day < 10 ? "0" + day : day;
  month = month < 10 ? "0" + month : month;

  return `${year}/${month}/${day}`;
}

export function convertToReadableString(inputString) {
  // Replace underscores with spaces
  let stringWithSpaces = inputString.replace(/_/g, " ");

  // Convert to lowercase
  stringWithSpaces = stringWithSpaces.toLowerCase();

  // Capitalize the first letter of the string
  stringWithSpaces =
    stringWithSpaces.charAt(0).toUpperCase() + stringWithSpaces.slice(1);

  return stringWithSpaces;
}
