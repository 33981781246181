import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import useTranslate from "../../../hooks/translator";
import { ICONS } from "../../../config";
import { themesForProvider, APP_FOR } from "../../../config/themesConfigs";

export default function AddNewDevice() {
  const translate = useTranslate();
  const navigate = useNavigate();

  const goAddNewDevice = () => {
    navigate("/dashboard/devices", { state: "from-dashboard" });
  };
  return (
    <div style={{ marginTop: 30 }}>
      <Button
        onClick={goAddNewDevice}
        className="dashboard-new-device"
        style={{ backgroundColor: themesForProvider[APP_FOR]?.btnColor }}
      >
        {ICONS.PLUS} {translate("Add new device")}
      </Button>
    </div>
  );
}
