import React from "react";
import { Tooltip } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export default function InfoTooltip({ placement = "top", title = "" }) {
  return (
    <Tooltip placement={placement} title={title} arrow>
      <ExclamationCircleOutlined />
    </Tooltip>
  );
}
