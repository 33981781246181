import { Input, DatePicker, Space, Button } from "antd";
import { SearchOutlined, CalendarOutlined } from "@ant-design/icons";

const { RangePicker } = DatePicker;

export const getQueryDate = (date) => {
  if (date && date[0]) {
    let to = new Date(date[1]);
    to.setDate(to.getDate() + 1);

    return {
      createdAt: {
        from: new Date(date[0]),
        to: new Date(to),
      },
    };
  }
  return null;
};
export const getColumnSearchProps = (_ref) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
    close,
  }) => (
    <div
      style={{
        padding: 8,
      }}
      onKeyDown={(e) => e.stopPropagation()}
    >
      <Input
        ref={_ref}
        placeholder="Search ..."
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => confirm()}
        style={{
          marginBottom: 8,
          display: "block",
        }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
          }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            clearFilters();
            confirm({ closeDropdown: false });
          }}
          size="small"
          style={{
            width: 90,
          }}
        >
          Reset
        </Button>
        <Button type="link" size="small" onClick={() => close()}>
          Close
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined
      style={{
        color: filtered ? "#1677ff" : undefined,
      }}
    />
  ),
  onFilterDropdownOpenChange: (visible) => {
    if (visible) {
      setTimeout(() => _ref?.current?.select(), 100);
    }
  },
  render: (text) => text,
});

export const getColumnDateProps = (onChange) => ({
  filterDropdown: ({}) => (
    <div
      style={{
        padding: 8,
      }}
    >
      <RangePicker
        onChange={onChange}
        renderExtraFooter={() => "extra footer"}
      />
    </div>
  ),
  filterIcon: (filtered) => (
    <CalendarOutlined
      style={{
        color: filtered ? "#1890ff" : undefined,
      }}
    />
  ),
});
