import { Typography } from "antd";
import { useState, useEffect } from "react";

export default function PageComponent({ children, title, className }) {
  const [blured, setBlured] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setBlured(false);
    }, 500);
  }, []);
  return (
    <div
      className={`${className} ${blured && "page-blured"}`}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 10,
        }}
      >
        {title && <Typography.Title level={4}>{title}</Typography.Title>}
      </div>
      {children}
    </div>
  );
}
