import React from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "antd";

export default function TooltipLongText({ children, to, isMobile }) {
  return (
    <Tooltip placement="right" title={(!isMobile && children?.length > 17) ? children : null}>
      <Link to={to}>
        <p
          style={{
            maxWidth: isMobile ? "max-content" : "150px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            padding: "0px",
            margin: "0px",
          }}
        >
          {children}
        </p>
      </Link>
    </Tooltip>
  );
}
