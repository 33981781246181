import React from "react";
import { useSelector } from "react-redux";
import useTranslate from "../../../hooks/translator";
import { ICONS, IMAGES } from "../../../config";

export default function GuideSector() {
    const translate = useTranslate();
    const { profile } = useSelector((state) => state.profile);

  return (
    <div className="guide-sector" style={{backgroundImage: `url(${IMAGES.GUIDE_SECTOR})`}}>
      <h1 className="guide-sector-title">{translate("welcome")} {profile.brand_name} {translate("panel")}!</h1>
      <h3>{translate("Your guide")}</h3>

    <div className="guide-body">
      <p>{translate("Get credits")}</p>
      <div className="arrow-left">{ICONS.ARROW_DOWN}</div>
      <p>{translate("Activate devices")}</p>
      <div className="arrow-left">{ICONS.ARROW_DOWN}</div>
      <p>{translate("Earn money")}</p>
    </div>
  </div>
  )
}
