import React from "react";
import useTranslate from "../../hooks/translator";
import DNS_INFO from "../../videos/dns-info.mov";

export default function DNSInfo() {
  const translate = useTranslate();
  return (
    <div>
      <p>
        1. {translate("Fill the name")} <br />
        2. {translate("Insert the host of your Xtream Playlist")}
      </p>
      <video width="320" height="240" controls style={{marginTop: "-40px"}}>
        <source src={DNS_INFO} type="video/quicktime" />
        <source src={DNS_INFO} type="video/mp4" />
      </video>
    </div>
  );
}
