import React, { useState } from "react";
import axios from "axios";

import { Drawer, Form, Select, message } from "antd";

import REQUESTS from "../../api/requests";

import useTranslate from "../../hooks/translator";
import { useHostsOptions } from "../../hooks/selectOptions";

import { ButtonComponent, InfoPopover } from "../../components";

import MIGRATION_INFO from "../../videos/migration-info.mov";
import URLS from "../../api/urls";

export default function MigrationDrawer({ selected, onClose, getData }) {
  const translate = useTranslate();
  const toServerList = useHostsOptions();

  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const filterArr = toServerList?.filter((el) => el.id !== selected?.item?.id);
  const token = localStorage.getItem("TOKEN");

  const migrationDNS = async (url, data) => {
    try {
      const response = await axios.put(url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        timeout: 10 * 60 * 1000,
      });
      if (response.data) {
        setLoading(false);
        message.success(response.data);
        getData();
        onClose();
      }
    } catch (error) {
      if (error) {
        setLoading(false);
        message.error(error);
      }
    }
  };

  const onFinish = (values) => {
    setLoading(true);

    const body = {
      from: selected?.item?.id,
      to: values.to,
    };

    migrationDNS(URLS.MIGRATE, body);

    // REQUESTS.MIGRATION.PUT(body)
    //   .then((res) => {
    //     setLoading(false);
    //     message.success(res);
    //     getData();
    //     onClose();
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     message.error(err);
    //   });
  };
  const infoContent = (
    <div style={{ width: 300 }}>
      <p>
        {translate("Migration system allows you to move all")}
        <br />
        {translate("server migration info")} <br />
        {translate(
          "You have to wait until migration will be ended successfully"
        )}
        <br />
        <span style={{ color: "red" }}> {translate("WARNING")}: </span>{" "}
        {translate(
          "It can take from few minutes to hours depending on the device count"
        )}
        <br />
        {translate(
          "You will get email message about the results of the migration in the end of the process"
        )}
      </p>
      <video width="100%" height="auto" controls>
        <source src={MIGRATION_INFO} type="video/quicktime" />
        <source src={MIGRATION_INFO} type="video/mp4" />
      </video>
    </div>
  );

  return (
    <Drawer
      forceRender
      open={selected?.open}
      onClose={onClose}
      placement="right"
      title={
        <>
          {translate("Migration")}
          <InfoPopover
            content={infoContent}
            title={translate("Migration system")}
          />
        </>
      }
    >
      <h2 style={{ fontSize: "14px", color: "gray" }}>{`${translate(
        "Migrate your users from"
      )} ${selected?.item?.host}`}</h2>

      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item
          label={translate("Pick new server to migrate your users")}
          name="to"
          rules={[
            {
              required: true,
              message: translate("Select DNS"),
            },
          ]}
        >
          <Select options={filterArr} placeholder={translate("Select DNS")} />
        </Form.Item>

        <Form.Item>
          <ButtonComponent loading={loading} onClick={form.submit}>
            <span>{translate("Save")}</span>
          </ButtonComponent>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
