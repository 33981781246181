import React, { useState } from "react";
import { Button, message } from "antd";

import axios from "axios";
import useTranslate from "../hooks/translator";

import { IMAGES } from "../config";

export default function AmeriabankPayment({
  credit,
  price,
  provider_id,
  isMobile,
}) {
  const translate = useTranslate();

  const [loading, setLoading] = useState(false);

  const returnPricingId = (credit) => {
    let val = Number(credit);
    switch (val) {
      case 20:
        return "X-C-696035974";
      case 100:
        return "X-C-885952427";
      case 500:
        return "X-C-98995839";
      case 1000:
        return "X-C-970851541";
      case 200:
        return "X-C-905903668";
      case 10:
        return "X-C-866251019";
      default:
        return;
    }
  };

  const onPay = () => {
    setLoading(true);

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://pay.inorain.com/api/v2/ameria/payment_link",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        pricing_id: returnPricingId(credit),
        client_id: JSON.stringify(provider_id),
        custom_data: `{"credit":${credit}, "price":${price}}`,
      },
    };
    axios
      .request(config)
      .then((response) => {
        setLoading(false);
        if (response.data) {
          window.open(response.data, "_self");
        } else {
          message.error(translate("Something went wrong"));
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(error);
      });
  };
  return isMobile ? (
    <Button size="large" className="ameria" loading={loading} onClick={onPay}>
      <img src={IMAGES.PAY_WITH_CARD} alt="" width={80} />
      <span>{translate("Pay with Card")}</span>
    </Button>
  ) : (
    <Button size="large" className="ameria" loading={loading} onClick={onPay}>
      <img src={IMAGES.PAY_WITH_CARD} alt="" width={80} />
      {translate("Pay with Card")}
    </Button>
  );
}
