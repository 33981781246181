import React, { useEffect, useState, useRef } from "react";

import { Table, Image } from "antd";

import REQUESTS from "../../../api/requests";

import useTranslate from "../../../hooks/translator";

import { getColumnSearchProps } from "../../../config/filters";
import IMAGES from "../../../config/images";

import PageComponent from "../../../components/PageComponent";
import { GoBackComponent, LongText, TableCSV } from "../../../components";

export default function ViewMoreLivesStatistics() {
  const translate = useTranslate();
  const searchInputRef = useRef(null);

  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState(["id", "DESC"]);

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState({});

  const columns = [
    {
      title: "#",
      width: 40,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      align: "center",
      render: (text, record, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },
    {
      title: "",
      dataIndex: "icon",
      key: "icon",
      align: "center",
      width: 75,
      render: (text, record) => {
        return (
          <Image
            src={record?.icon || IMAGES.ERROR}
            alt="icon"
            onError={(e) => (e.target.src = IMAGES.ERROR)}
            preview={record?.icon ? true : false}
          />
        );
      },
    },
    {
      title: translate("Name"),
      dataIndex: "name",
      key: "name",
      align: "center",
      ...getColumnSearchProps(searchInputRef),
      render: (text, record) => <LongText children={text} />,
    },

    {
      title: translate("Viewed"),
      dataIndex: "viewed",
      key: "viewed",
      align: "center",
      sorter: true,
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      getLives();
    }, 500);
    return () => clearTimeout(timeout);
  }, [sort, currentPage, limit, search]);

  const getLives = () => {
    setLoading(true);
    const query = {
      sort,
      limit: limit,
      page: currentPage,
      pagination: 1,
    };

    if (search.name) {
      if (!query.search) {
        query.search = {};
      }
      query.search["name"] = search.name[0];
    }

    REQUESTS.GET_LIVES({ query: JSON.stringify(query) })
      .then((data) => {
        setTableData(data.rows);
        setTotal(data.total);
        setLimit(data.limit);
        setCurrentPage(data.page);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);

    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
    setSearch(filters);
  };

  return (
    <PageComponent>
      <div className="head-page">
        <GoBackComponent children={translate("Top Lives")} />
        {tableData && <TableCSV dataForExport={tableData} />}
      </div>

      <Table
        size={"small"}
        columns={columns}
        dataSource={tableData}
        loading={loading}
        onChange={handleTableChange}
        scroll={{
          x: "max-content",
        }}
        pagination={{
          position: ["bottomCenter"],
          current: currentPage,
          total: total,
          pageSize: limit,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50, 100, total],
        }}
      />
    </PageComponent>
  );
}
