import { Layout } from "antd";
import { themesForProvider, APP_FOR } from "../config/themesConfigs";
import MenuContent from "./MenuContent";
import "../styles/sidebar.scss";

export default function Sidebar({ collapsed, setCollapsed, isMobile }) {
  return (
    <Layout.Sider
      className="sidebar"
      style={{
        height: "100vh",
        left: 0,
        top: 0,
        bottom: 0,
        marginRight: isMobile ? "0px" : "250px",
        transition: "all 0.1s",
        overflow: "hidden",
        position: "fixed",
        backgroundColor: themesForProvider[APP_FOR]?.primaryColor,
      }}
      breakpoint="md"
      collapsedWidth="0"
    >
      <MenuContent />
    </Layout.Sider>
  );
}
