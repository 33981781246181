import React, { useState, useEffect } from "react";
import { Table } from "antd";

import REQUESTS from "../../api/requests";

import useTranslate from "../../hooks/translator";
import { parseFullDate } from "../../config/formats";
import { getColumnDateProps, getQueryDate } from "../../config/filters";

import { CopyText } from "../../components";

export default function HistoryDeactivated({ filterBy }) {
  const translate = useTranslate();

  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  const [filtersTb, setFiltersTb] = useState({
    date: null,
    isActive: null,
    isTrial: null,
    istype: null,
  });

  const [sort, setSort] = useState(["id", "DESC"]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedInfo, setSortedInfo] = useState({});

  const columns = [
    {
      width: 60,
      title: "#",
      fixed: "left",
      dataIndex: "id",
      key: "id",
      align: "center",
      sorter: true,
      render: (text, record, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },
    {
      title: translate("Device key"),
      dataIndex: "device_key",
      key: "device_key",
      align: "center",
      fixed: "left",
      render: (text, record, index) =>
        text ? <CopyText text={text} /> : "N/A",
    },
    {
      title: translate("Platform"),
      dataIndex: "device_os",
      key: "device_os",
      align: "center",
      render: (text, record, index) => text,
    },
    {
      title: translate("Deactivated by"),
      dataIndex: "type",
      key: "type",
      align: "center",
      filters: [
        {
          text: "By device",
          value: "by_device",
        },
        {
          text: "By provider",
          value: "by_provider",
        },
      ],
      render: (text, record, index) =>
        (
          text?.charAt(0)?.toUpperCase() + text?.slice(1)?.toLowerCase()
        )?.replaceAll("_", " ") || "N/A",
    },

    {
      title: translate("Deactivated date"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      sorter: true,
      sortOrder: sortedInfo.columnKey === "createdAt" ? sortedInfo.order : null,
      render: (text, record, index) => parseFullDate(text),
      ...getColumnDateProps((e, dateString) =>
        setFiltersTb({ ...filtersTb, date: dateString })
      ),
    },
  ];

  const getData = (filterBy) => {
    setLoading(true);

    const query = {
      limit,
      page: currentPage,
      pagination: 1,
      sort,
      filter: { device_key: filterBy },
    };

    const queryDate = getQueryDate(filtersTb.date);

    if (queryDate) query.between = queryDate;

    if (filtersTb.isActive || filtersTb.isTrial || filtersTb.istype) {
      query["filter"] = {};

      if (filtersTb.istype) {
        query.filter = {
          type: filtersTb.istype,
          device_key: filterBy,
        };
      }
    }

    REQUESTS.DEACTIVATED_DEVICES_LIST({ query: JSON.stringify(query) })
      .then((data) => {
        setLoading(false);
        setDataSource(data.rows);
        setTotal(data.total);
        setLimit(data.limit);
        setCurrentPage(data.page);
        if (data.rows.length === 0 && currentPage > 1) {
          setCurrentPage((prev) => prev - 1);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);

    setLimit(pagination.pageSize);

    setSortedInfo(sorter);

    setFiltersTb((prevFilters) => ({
      ...prevFilters,
      istype: filters["type"] || null,
    }));

    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (filterBy) {
        getData(filterBy);
      }
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [filtersTb, currentPage, filterBy]);

  return dataSource.length > 0 ? (
    <Table
      rowKey="id"
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      onChange={handleTableChange}
      size="small"
      pagination={{
        position: ["bottomCenter"],
        current: currentPage,
        total: total,
        pageSize: limit,
        showSizeChanger: true,
      }}
      scroll={{
        x: "max-content",
        y: null,
      }}
      title={() => <h2>{translate("Deactivated history")}</h2>}
    />
  ) : (
    <></>
  );
}
