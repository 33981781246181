import React from "react";
import { message } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import useTranslate from "../hooks/translator";

export default function CopyText({ text, style }) {
    const translate = useTranslate();
    let txt = "";

    const copyToClipboard = (e) => {
        e.stopPropagation();
        if (typeof text == "object") {
            txt = text?.props?.children;
        } else {
            txt = text;
        }

        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard
                .writeText(txt)
                .then(() => {
                    message.success(translate("Text copied to clipboard"));
                })
                .catch(() => {
                    message.error(translate("Failed to copy text to clipboard"));
                });
        } else {
            if (txt) {
                const tempInput = document.createElement("input");
                tempInput.setAttribute("value", txt);
                document.body.appendChild(tempInput);
                tempInput.select();
                document.execCommand("copy");
                document.body.removeChild(tempInput);
                message.success(translate("Text copied to clipboard"));
            }
        }
    };

    return (
        <span
            onClick={(e) => copyToClipboard(e)}
            style={{
                cursor: "pointer",
                color: "#1890ff",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
                margin: "0",
            }}
        >
            {text} <CopyOutlined style={style} />
        </span>
    );
}
