import React from "react";
import { Radio } from "antd";
import { menuBrandingIconsCollection } from "../../../../config/data";
import ColorsPicker from "../../../../components/ColorsPicker";
import useTranslate from "../../../../hooks/translator";
import { InfoTooltip } from "../../../../components";
import MenuRms from "./MenuRms";
import MenuClassic from "./MenuClassic";

import "../../styles/menu.scss";
export default function MenuPage({
  colors,
  logo,
  backgroundImage,
  menu,
  setMenu,
}) {
  const translate = useTranslate();
  return (
    <div className="menu-page">
      <div className="choose-wrap">
        <div className="bt_border_choose">
          <p className="panel-title">{translate("Choose menu")}</p>
          <Radio.Group
            value={menu?.menu_option}
            className="radio-group"
            onChange={(e) => setMenu({ ...menu, menu_option: e.target.value })}
          >
            <Radio value="rms">{translate("Version")} 1</Radio>
            <Radio value="classic">{translate("Version")} 2</Radio>
          </Radio.Group>
        </div>
        <div className="bt_border_choose">
          <p className="panel-title">
            {translate("Choose items box form")}{" "}
            {menu?.menu_icons_collection == 1 && (
              <InfoTooltip
                title={translate(
                  "If you have selected Collection 1, you cannot select any edit tools. If you wish to use the edit tools, please choose a different collection"
                )}
              />
            )}
          </p>
          <Radio.Group
            value={menu?.menu_items_box_form}
            className="radio-group"
            onChange={(e) =>
              setMenu({ ...menu, menu_items_box_form: e.target.value })
            }
            disabled={menu?.menu_icons_collection == 1}
          >
            <Radio value="round">Round</Radio>
            <Radio value="quadrate">Quadrate</Radio>
            <Radio value="halfcircle">Halfcircle</Radio>
          </Radio.Group>
        </div>
        <div className="bt_border_choose">
          <p className="panel-title">
            {translate("Choose icons color")}{" "}
            {menu?.menu_icons_collection == 1 && (
              <InfoTooltip
                title={translate(
                  "If you have selected Collection 1, you cannot select any edit tools. If you wish to use the edit tools, please choose a different collection"
                )}
              />
            )}
          </p>
          <ColorsPicker
            color={menu?.menu_icons_color}
            onChange={(color) =>
              setMenu({
                ...menu,
                menu_icons_color: color ? color.hex : "#ffffff",
              })
            }
            disabled={menu?.menu_icons_collection == 1}
          />
        </div>
        {menu?.menu_option == "classic" && (
          <div className="bt_border_choose">
            <p className="panel-title">
              {translate("Choose items color")}{" "}
              {menu?.menu_icons_collection == 1 && (
                <InfoTooltip
                  title={translate(
                    "If you have selected Collection 1, you cannot select any edit tools. If you wish to use the edit tools, please choose a different collection"
                  )}
                />
              )}
            </p>
            <ColorsPicker
              color={menu?.menu_items_color}
              onChange={(color) =>
                setMenu({
                  ...menu,
                  menu_items_color: color ? color.hex : "#707071",
                })
              }
              disabled={menu?.menu_icons_collection == 1}
            />
          </div>
        )}
        <div className="bt_border_choose">
          <p className="panel-title">
            {translate("Choose items active color")}{" "}
            {menu?.menu_icons_collection == 1 && (
              <InfoTooltip
                title={translate(
                  "If you have selected Collection 1, you cannot select any edit tools. If you wish to use the edit tools, please choose a different collection"
                )}
              />
            )}
          </p>
          <ColorsPicker
            color={menu?.menu_items_active_color}
            onChange={(color) =>
              setMenu({
                ...menu,
                menu_items_active_color: color ? color.hex : "",
              })
            }
            disabled={menu?.menu_icons_collection == 1}
          />
        </div>
        <div className="choose-list bt_border_choose">
          <p className="panel-title">{translate("Choose your icons")}</p>
          <Radio.Group
            value={menu?.menu_icons_collection}
            onChange={(e) =>
              setMenu({ ...menu, menu_icons_collection: e.target.value })
            }
            className="radio-group"
          >
            <Radio value={1} className="radio-collections">
              <p>{translate("Collection")} 1</p>

              <div className="collection">
                {menuBrandingIconsCollection[0].live}
                {menuBrandingIconsCollection[0].movies}
                {menuBrandingIconsCollection[0].series}
                {menuBrandingIconsCollection[0].settings}
              </div>
            </Radio>
            <Radio value={2}>
              <p>{translate("Collection")} 2</p>

              <div className="collection">
                {menuBrandingIconsCollection[1].live}
                {menuBrandingIconsCollection[1].movies}
                {menuBrandingIconsCollection[1].series}
                {menuBrandingIconsCollection[1].settings}
              </div>
            </Radio>
            <Radio value={3}>
              <p>{translate("Collection")} 3</p>

              <div className="collection">
                {menuBrandingIconsCollection[2].live}
                {menuBrandingIconsCollection[2].movies}
                {menuBrandingIconsCollection[2].series}
                {menuBrandingIconsCollection[2].settings}
              </div>
            </Radio>
            <Radio value={4}>
              <p>{translate("Collection")} 4</p>

              <div className="collection">
                {menuBrandingIconsCollection[3].live}
                {menuBrandingIconsCollection[3].movies}
                {menuBrandingIconsCollection[3].series}
                {menuBrandingIconsCollection[3].settings}
              </div>
            </Radio>
          </Radio.Group>
        </div>
      </div>
      <div className="menu-wrap">
        {menu?.menu_option == "rms" ? (
          <MenuRms
            colors={colors}
            logo={logo}
            backgroundImage={backgroundImage}
            menu={menu}
          />
        ) : (
          <MenuClassic
            colors={colors}
            logo={logo}
            backgroundImage={backgroundImage}
            menu={menu}
          />
        )}
      </div>
    </div>
  );
}
