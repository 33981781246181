import { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import { setProfile } from "../../store/features/profileSlice";

import { Form, Checkbox, Select, message, Divider } from "antd";
import { WarningOutlined, InfoCircleOutlined } from "@ant-design/icons";

import REQUESTS from "../../api/requests";

import useTranslate from "../../hooks/translator";

import { IMAGES, ICONS } from "../../config";

import { freeTrialOptions, languages } from "../../config/data";

import { SubInfoTexts, ButtonComponent } from "../index";

import DeviceConfigInfo from "./DeviceConfigInfo";

export default function DeviceConfig({
  showTitle,
  onClose,
  style,
  pendingCount,
}) {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const { profile } = useSelector((state) => state.profile);

  const [isCanSetupActivation, setIsCanSetupActivation] = useState(false);

  const [form] = Form.useForm();

  useEffect(() => {
    if (profile) {
      form.setFields([
        {
          name: "app_login",
          value: profile?.app_login,
        },
        {
          name: "app_auto_reset",
          value: profile?.app_auto_reset,
        },
        {
          name: "app_activation_type",
          value: profile?.app_activation_type,
        },
        {
          name: "app_trial_days",
          value: profile?.app_trial_days,
        },
        {
          name: "app_auto_renew",
          value: profile?.app_auto_renew,
        },
        {
          name: "sync_playlist",
          value: profile?.sync_playlist,
        },
        {
          name: "app_default_language",
          value: profile?.app_default_language,
        },
      ]);

      if (!profile.is_partner && !profile.parent_id) {
        setIsCanSetupActivation(true);
        return;
      }
    }
  }, [profile]);

  const getProfile = () => {
    REQUESTS.PROFILE()
      .then((data) => {
        dispatch(setProfile(data));
      })
      .catch((err) => {});
  };

  const appActivationTypeOptions = [
    {
      label: "Activate",
      value: "active",
    },
    {
      label: "Free trial",
      value: "free_trial",
    },
  ];

  // const appDefaultLanguages = [
  //   {
  //     label: (
  //       <span className="icon-label">
  //         <img src={IMAGES.EN} /> English
  //       </span>
  //     ),
  //     value: "en",
  //   },

  //   {
  //     label: (
  //       <span className="icon-label">
  //         <img src={IMAGES.PT} /> Português
  //       </span>
  //     ),
  //     value: "pt",
  //   },

  //   {
  //     label: (
  //       <span className="icon-label">
  //         <img src={IMAGES.ES} /> Español
  //       </span>
  //     ),
  //     value: "es",
  //   },

  //   {
  //     label: (
  //       <span className="icon-label">
  //         <img src={IMAGES.IT} /> Italiano
  //       </span>
  //     ),
  //     value: "it",
  //   },

  //   {
  //     label: (
  //       <span className="icon-label">
  //         <img src={IMAGES.SQ} /> Albanian{" "}
  //       </span>
  //     ),
  //     value: "sq",
  //   },

  //   {
  //     label: (
  //       <span className="icon-label">
  //         <img src={IMAGES.FR} /> Français
  //       </span>
  //     ),
  //     value: "fr",
  //   },

  //   {
  //     label: (
  //       <span className="icon-label">
  //         <img src={IMAGES.DE} /> German
  //       </span>
  //     ),
  //     value: "de",
  //   },

  //   {
  //     label: (
  //       <span className="icon-label">
  //         <img src={IMAGES.ZH} /> Chinese
  //       </span>
  //     ),
  //     value: "zh",
  //   },
  //   {
  //     label: (
  //       <span className="icon-label">
  //         <img src={IMAGES.TR} /> Turkish
  //       </span>
  //     ),
  //     value: "tr",
  //   },
  // ];

  const onFinish = (values) => {
    const {
      app_login,
      app_auto_reset,
      app_activation_type,
      app_auto_renew,
      app_trial_days,
      sync_playlist,
      app_default_language,
    } = values;

    const body = {
      app_login,
      app_auto_reset,
    };

    if (
      profile?.is_partner ||
      (profile?.parent_id && profile?.status === "CAN_USE_FREE_PARTNER_CREDITS")
    ) {
      body.app_activation_type = "sync";
    } else {
      body.app_activation_type = app_activation_type;
      body.app_auto_renew =
        !app_login || app_trial_days ? false : app_auto_renew;
      body.app_trial_days =
        !app_login || app_auto_renew ? 7 : values.app_trial_days;
    }

    if (profile?.is_partner) {
      body.sync_playlist = sync_playlist;
    }
    if (profile.app_default_language !== app_default_language) {
      body.app_default_language = app_default_language;
    }

    REQUESTS.EDIT_PROFILE(body)
      .then((data) => {
        setLoading(false);
        getProfile();
        onClose && onClose();
        message.success(
          translate("Device configuration updates are successful")
        );
      })
      .catch((err) => {
        setLoading(false);

        if (typeof err.message === "string") {
          message.error(err.message);
        } else {
          message.error(translate("Something went wrong"));
        }
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const onSubmit = () => {
    setLoading(true);
    form.submit();
  };

  if (profile) {
    return (
      <Form
        form={form}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        layout="vertical"
      >
        {showTitle && (
          <Divider orientation="left" style={{ fontSize: "15px" }}>
            {translate("Device Config")} <DeviceConfigInfo />
          </Divider>
        )}

        {isCanSetupActivation && (
          <>
            <Form.Item shouldUpdate noStyle>
              {() => {
                const { app_login } = form.getFieldsValue();

                return (
                  <Form.Item
                    label={translate("Activation type")}
                    name="app_activation_type"
                  >
                    <Select
                      options={appActivationTypeOptions}
                      placeholder={translate("Select activation type")}
                      disabled={!app_login}
                      style={{ maxWidth: "350px" }}
                    />
                  </Form.Item>
                );
              }}
            </Form.Item>

            <Form.Item shouldUpdate noStyle>
              {() => {
                const { app_activation_type, app_login } =
                  form.getFieldsValue();
                if (
                  app_activation_type == "free_trial" ||
                  (!app_activation_type &&
                    profile?.app_activation_type == "free_trial")
                ) {
                  return (
                    <Form.Item
                      label={translate("Free trial days")}
                      name="app_trial_days"
                    >
                      <Select
                        options={freeTrialOptions}
                        defaultValue={profile?.app_trial_days}
                        disabled={!app_login}
                        style={{ maxWidth: "350px" }}
                      />
                    </Form.Item>
                  );
                }

                if (
                  app_activation_type == "active" ||
                  (!app_activation_type &&
                    profile?.app_activation_type == "active")
                ) {
                  return (
                    <div className="app-login">
                      <Form.Item
                        label=""
                        name="app_auto_renew"
                        valuePropName="checked"
                      >
                        <Checkbox disabled={!app_login}>
                          {translate("Auto renew")}
                        </Checkbox>
                      </Form.Item>
                    </div>
                  );
                }
              }}
            </Form.Item>
          </>
        )}

        <Form.Item label="" name="app_login" valuePropName="checked">
          <Checkbox>
            {translate("Allow users to login from application")}
          </Checkbox>
        </Form.Item>

        <SubInfoTexts
          info={translate(
            "If this option is enabled, users will be able to log in to the app. If not, you will have to manually add the playlist to the device"
          )}
        />

        <Form.Item
          label={
            pendingCount > 0 ? " Allow users to reset the application" : ""
          }
          name="app_auto_reset"
          valuePropName="checked"
          tooltip={{
            title: translate(
              "On the 'Reset Requests' page, there are reset requests that are pending. To make use of this functionality, please reset or cancel them"
            ),
            icon: <WarningOutlined color="#7f6e15" />,
          }}
        >
          <Checkbox disabled={pendingCount > 0}>
            {translate("Allow users to reset the application")}
          </Checkbox>
        </Form.Item>

        <SubInfoTexts
          info={translate(
            "Users will be able to reset the device automatically if this field is enabled. If it isn't, they won't be able to reset it unless you confirm it from the Devices page / Reset requests"
          )}
        />

        {profile?.is_partner && (
          <Form.Item label="" name="sync_playlist" valuePropName="checked">
            <Checkbox>{translate("Sync playlist")}</Checkbox>
          </Form.Item>
        )}

        <Form.Item
          label={
            <div className="new">
              {ICONS.NEW}
              <span>{translate("Applications default language")}</span>
            </div>
          }
          name="app_default_language"
          tooltip={{
            title: translate(
              "It is available for Smart TVs and a new mobile version"
            ),
            icon: <InfoCircleOutlined />,
          }}
        >
          <Select options={languages} style={{ maxWidth: "350px" }} />
        </Form.Item>

        <Form.Item>
          <ButtonComponent
            style={style}
            onClick={() => onSubmit()}
            loading={loading}
          >
            {translate("Save")}
          </ButtonComponent>
        </Form.Item>
      </Form>
    );
  }
}
