import { Typography } from "antd";

import "../styles/components.scss";

export default function MessageComponent({
  msgtype = "error",
  message=null,
  style,
}) {
  return (
    <div className={message? "message open":"message"} style={style}>
        { msgtype === "success" && (
          <Typography.Text type="success">{message}</Typography.Text>
        )}

        { msgtype == "error" && (
          <Typography.Text type="danger">{message}</Typography.Text>
        )}
        
        {
         msgtype == "warning" && (
          <Typography.Text type="warning">{message}</Typography.Text>
         )
        }
    </div>
  );
}
