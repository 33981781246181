import React, { useEffect, useState } from "react";

import { Table, Tag } from "antd";

import REQUESTS from "../../api/requests";

import { parseDate } from "../../config/formats";

import useTranslate from "../../hooks/translator";
import {
  Check,
  LongText,
  CopyText
} from "../../components";

import InfoDrawer from "./InfoDrawer";


import "../../styles/components.scss";

export default function MultiSubResellers({ subId, tab }) {

  const translate = useTranslate();

  const [loading, setLoading] = useState(false);

  const [onRow, setOnRow] = useState({ show: false, item: null });

  const [dataSource, setDataSource] = useState([]);

  const columns = [
    {
      width: 60,
      title: "#",
      fixed: "left",
      dataIndex: "id",
      key: "id",
      align: "center",
      render: (text, record, index) =>  index + 1
    },
    {
      title: translate("Brand name"),
      dataIndex: "brand_name",
      key: "brand_name",
      fixed: "left",
      align: "center",
      render: (text, record, index) => text ? <CopyText text={text}/> : "N/A",
    },

    {
      title: translate("Name"),
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (text, record, index) => record.name ? <LongText children={record.name}/> : "N/A",
    },
    {
      title: translate("Surname"),
      dataIndex: "surname",
      key: "surname",
      align: "center",
      render: (text, record, index) => record.surname ? <LongText children={record.surname}/> : "N/A",
    },

    {
      title: translate("Email"),
      dataIndex: "email",
      key: "email",
      align: "center",
      render: (text, record, index) => record.email ? <CopyText text={<LongText children={record.email}/>}/> : "N/A",
    },
    {
      title: "Credit",
      dataIndex: "credit",
      key: "credit",
      align: "center",
      sort: true,
    },
    {
      title: translate("Devices count"),
      dataIndex: "device_count",
      key: "device_count",
      align: "center",
    },

    {
      title: translate("Status"),
      dataIndex: "blocked",
      key: "blocked",
      align: "center",
      render: (text, record, index) => {
        return record.blocked ? (
          <Tag color="red">Block</Tag>
        ) : (
          <Tag color="green">Active</Tag>
        );
      },
    },
    {
      title: translate("Branding"),
      dataIndex: "branding",
      key: "branding",
      align: "center",
      render: (text, record, index) => <Check check={record.branding} />,
    },
    {
      title: translate("Updated date"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      render: (text, record, index) => parseDate(text),
    },
  
    {
      title: translate("Created date"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text, record, index) => parseDate(text),
    },
    
  ];

  const getData = () => {
    setLoading(true);

    const query = {
      id: subId,
    };

    REQUESTS.SUBRESELLERS.INFO(query)
      .then((data) => {

        const sortApproved = data?.rows

        sortApproved?.sort((a, b) => {
          if (!a.approved && b.approved !== false) {
            return -1;
          }
          if (!b.approved && a.approved !== false) {
            return 1;
          }
          return 0;
        });

        setDataSource(sortApproved);
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if(tab == "Sub resellers" && subId){
      getData();
    }
  }, [subId, tab]);

  useEffect(() => {
    if (!onRow) {
      setOnRow({ show: false, item: null });
    }
  }, [onRow]);

  return (
    <>
      <Table
        rowClassName={(record, index) => {
          if (record.blocked) {
            return "table-row-red";
          }
        }}
        size={"small"}
        columns={columns}
        loading={loading}
        dataSource={dataSource}
        pagination={false}
        rowKey="id"
        scroll={{
          x: "max-content",
          y: null,
        }}
        onRow={(record, rowIndex) => {
          return {
            className: "active-row",
            onClick: (event) => {
              setOnRow({ show: true, item: record });
            },
          };
        }}
      />

      <InfoDrawer
        open={onRow}
        onClose={() => setOnRow({ show: false, item: null })}
      />

    </>
  );
}
