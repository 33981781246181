import React, { useState, useEffect, useRef } from "react";
import { Table } from "antd";

import REQUESTS from "../../../api/requests";

import useTranslate from "../../../hooks/translator";
import { parseFullDate } from "../../../config/formats";
import {
  getColumnDateProps,
  getColumnSearchProps,
  getQueryDate,
} from "../../../config/filters";

import { CopyText } from "../../../components";

export default function DeactivatedHistory({ activeTab }) {
  const translate = useTranslate();
  const searchInputRef = useRef(null);

  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  const [search, setSearch] = useState({
    deviceKey: null,
  });

  const [filtersTb, setFiltersTb] = useState({
    date: null,
    isActive: null,
    isTrial: null,
    istype: null,
  });

  const [sort, setSort] = useState(["id", "DESC"]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedInfo, setSortedInfo] = useState({});

  const columns = [
    {
      width: 60,
      title: "#",
      fixed: "left",
      dataIndex: "id",
      key: "id",
      align: "center",
      sorter: true,
      render: (text, record, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },
    {
      title: translate("Device key"),
      dataIndex: "device_key",
      key: "device_key",
      align: "center",
      fixed: "left",
      ...getColumnSearchProps(searchInputRef),
      render: (text, record, index) =>
        text ? <CopyText text={text} /> : "N/A",
    },
    {
      title: translate("Platform"),
      dataIndex: "device_os",
      key: "device_os",
      align: "center",
      render: (text, record, index) => text,
    },
    {
      title: translate("Deactivated by"),
      dataIndex: "type",
      key: "type",
      align: "center",
      filters: [
        {
          text: "By device",
          value: "by_device",
        },
        {
          text: "By provider",
          value: "by_provider",
        },
      ],
      render: (text, record, index) =>
        (
          text?.charAt(0)?.toUpperCase() + text?.slice(1)?.toLowerCase()
        )?.replaceAll("_", " ") || "N/A",
    },

    {
      title: translate("Deactivated date"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      sorter: true,
      sortOrder: sortedInfo.columnKey === "createdAt" ? sortedInfo.order : null,
      ...getColumnDateProps((e, dateString) =>
        setFiltersTb({ ...filtersTb, date: dateString })
      ),
      render: (text, record, index) => parseFullDate(text),
    },
  ];

  const getData = () => {
    setLoading(true);

    const query = {
      limit,
      page: currentPage,
      pagination: 1,
      sort,
    };

    if (search) {
      const list = Object.values(search);
      for (let i = 0; i < list.length; i++) {
        if (list[i]) {
          if (!query.search) {
            query.search = {};
          }
          break;
        }
        delete query.search;
      }

      if (search.deviceKey) {
        query.search["device_key"] = search.deviceKey[0];
      }
      if (search.deviceOs) {
        query.search["device_os"] = search.deviceOs[0];
      }
    } else {
      delete query.search;
    }

    const queryDate = getQueryDate(filtersTb.date);

    if (queryDate) query.between = queryDate;

    if (filtersTb.isActive || filtersTb.isTrial || filtersTb.istype) {
      query["filter"] = {};

      if (filtersTb.isActive) {
        query["filter"]["is_active"] = filtersTb.isActive;
      }

      if (filtersTb.isTrial) {
        query["filter"]["is_trial"] = filtersTb.isTrial;
      }

      if (filtersTb.istype) {
        query["filter"]["type"] = filtersTb.istype;
      }
    } else {
      delete query.filter;
    }

    REQUESTS.DEACTIVATED_DEVICES_LIST({ query: JSON.stringify(query) })
      .then((data) => {
        setLoading(false);
        setDataSource(data.rows);
        setTotal(data.total);
        setLimit(data.limit);
        setCurrentPage(data.page);
        if (data.rows.length === 0 && currentPage > 1) {
          setCurrentPage((prev) => prev - 1);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setCurrentPage(pagination.current);

    setLimit(pagination.pageSize);

    setSortedInfo(sorter);

    setSearch((prevSearch) => ({
      ...prevSearch,
      deviceKey: filters["device_key"] || null,
    }));

    setFiltersTb((prevFilters) => ({
      ...prevFilters,
      isTrial: filters["is_trial"] || null,
      isActive: filters["is_active"] || null,
      istype: filters["type"] || null,
    }));

    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      if (activeTab == "Deactivated history") {
        getData();
      }
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [search, filtersTb, currentPage, activeTab]);

  return (
    <Table
      rowKey="id"
      loading={loading}
      columns={columns}
      dataSource={dataSource}
      onChange={handleTableChange}
      size="small"
      pagination={{
        position: ["bottomCenter"],
        current: currentPage,
        total: total,
        pageSize: limit,
        showSizeChanger: true,
      }}
      scroll={{
        x: "max-content",
        y: null,
      }}
    />
  );
}
