import React, { useEffect, useState } from "react";
import { Drawer, Tabs } from "antd";
import useTranslate from "../../hooks/translator";

import BillingHistory from "./BillingHistory";

import DevicesSubreseller from "./DevicesSubreseller";
import MultiSubResellers from "./MultiSubResellers";

export default function InfoDrawer({ open, onClose }) {

  const translate = useTranslate();
  
  const [item, setItem] = useState("");
  const [activeTab, setActiveTab] = useState("Devices");


  const canUseFree = item?.parent_id && item?.status === "CAN_USE_FREE_PARTNER_CREDITS";

  const onChangeTab = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    if (open.show) {
      setItem(open.item);
      setActiveTab("Devices");
    }
  }, [open.show]);

  const items = [
    {
      key: "Devices",
      label: translate("Devices"),
      children: <DevicesSubreseller itemId={item?.id} tab={activeTab} />,
    },
    {
      key: "Sub resellers",
      label: translate("Subresellers"),
      children: <MultiSubResellers subId={item?.id} tab={activeTab} />,
    },
    !canUseFree && {
      key: "Billing history",
      label: translate("Billing history"),
      children: (
        <BillingHistory itemId={item?.id} tab={activeTab} open={open.show} />
      ),
    },
  ];

  return (
    <Drawer
      forceRender
      size="large"
      open={open.show}
      onClose={onClose}
      placement="right"
      title={translate("Sub-Reseller Info")}
    >
      <div className="sub-reseller-info">
        <ul>
          <li>{translate("Reseller name")}: {item?.name || "N/A"}</li>
          <li>{translate("Reseller email")}: {item?.email || "N/A"} </li>
          <li style={{ color: "grey" }}>
            {translate("Activated devices")}: {item?.device_count || 0}
          </li>
      
          {item?.credit > 0 && <li>{translate("Credit")}: {item?.credit}</li>}
        </ul>
      </div>
      <Tabs
        defaultActiveKey={activeTab}
        activeKey={activeTab}
        items={items}
        onChange={onChangeTab}
      />
    </Drawer>
  );
}
