import React, { useState } from "react";

import { Form, Drawer, message } from "antd";

import REQUESTS from "../api/requests";

import useTranslate from "../hooks/translator";

import {ButtonComponent, InputComponent, MessageComponent} from "../components";

import "../styles/header.scss";

export default function ResetProfilePasswordDrawer({
  onChildrenDrawerClose,
  childrenDrawer
}) {
  const translate = useTranslate();

  const [loading, setLoading] = useState(false);
  const [messageErr, setMessageErr] = useState(null);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    REQUESTS.CHANGE_PASSWORD(values)
      .then((data) => {
        setLoading(false);
        onChildrenDrawerClose(form);
        message.success(translate("Password changed"));
      })
      .catch((err) => {
        setLoading(false);
        if (typeof err === "string") {
          if(err == "Wrong password" || err == "Your old password and new password are same"){
            setMessageErr(translate(err));
          }else {
            setMessageErr(err);
          }
        } 
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const onSubmit = () => {
    setMessageErr(null);
    form.submit();
  };

  const keyPressHandler = (e) => {
    if (e.key === "Enter") {
      form.submit();
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    setMessageErr("");
  };
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onSubmit();
    }
  };
  return (
    <div>
      <Drawer
        title={translate("Reset password")}
        width={350}
        onClose={() => {
          onChildrenDrawerClose(form);
          setMessageErr("");
        }}
        open={childrenDrawer}
      >
        <Form
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          layout="vertical"
          onValuesChange={onValuesChange}
          onKeyPress={(e) => handleKeyPress(e)}
        >
          <Form.Item
            name="password"
            label={translate("Old password")}
            rules={[
              {
                required: true,
                message: translate("Please input your password")
              },
              {
                min: 8,
                message: translate("Password must be minimum 8 characters")
              }
            ]}
          >
            <InputComponent
              type="password"
              placeholder={translate("Password")}
              onPressEnter={keyPressHandler}
            />
          </Form.Item>
          
          <Form.Item
            name="newPassword"
            label={translate("New password")}
            rules={[
              {
                required: true,
                message: translate("Please input your new password")
              },
              {
                min: 8,
                message: translate("Password must be minimum 8 characters")
              }
            ]}
          >
            <InputComponent
              type="password"
              placeholder={translate("Password")}
              onPressEnter={keyPressHandler}
            />
          </Form.Item>

          <MessageComponent message={messageErr} />

          <Form.Item>
            <ButtonComponent onClick={onSubmit} loading={loading}>
              {translate("Reset my password")}
            </ButtonComponent>
          </Form.Item>
        </Form>
      </Drawer>
    </div>
  );
}
