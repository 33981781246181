import React from "react";

export default function ErrorMassage({ err }) {
  return (
    <span
      style={{ fontSize: "14px", color: "red", transition: "all 0.5s ease" }}
    >
      {err}
    </span>
  );
}
