import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setAppBranding } from "../../store/features/profileSlice";

import { Tag, Space, Tabs, message, Button, Popconfirm } from "antd";

import REQUESTS from "../../api/requests";

import useTranslate from "../../hooks/translator";

import { PageComponent, InfoPopover, ButtonComponent } from "../../components";

import { infoModal } from "./components/InfoModal";
import GeneralInfo from "./components/GeneralInfo";
import LiveTv from "./components/LiveTv";
import Media from "./components/Media";
import PlayerPage from "./components/player/PlayerPage";
import MenuPage from "./components/app-menu/MenuPage";

import "./styles/branding.scss";

export default function AppBrandingPage() {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const { profile, appBranding } = useSelector((store) => store.profile);

  const defaultBg = useRef(null);
  const [selectedBg, setSelectedBg] = useState(null);

  const [logo, setLogo] = useState({
    file: null,
    url: "",
    size: 20,
  });

  const [backgroundImage, setBackgroundImage] = useState({
    file: null,
    url: "",
  });

  const [colors, setColors] = useState({
    primary_color: "",
    secondary_color: "",
  });

  const [menu, setMenu] = useState({
    menu_option: "classic",
    menu_icons_collection: 1,
    menu_items_box_form: "round",
    menu_items_active_color: "",
    menu_icons_color: "",
    menu_items_color: "",
  });

  const [player, setPlayer] = useState({
    player_icons_collection: 1,
    player_items_color: "",
    player_version: 1,
    progress_bar_color: "",
  });

  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("General Info");

  const getAppBranding = () => {
    REQUESTS.APP_BRANDINGS.GET()
      .then((data) => {
        if (data) {
          dispatch(setAppBranding(data));
        }
      })
      .catch((err) => {});
  };

  async function getStaticImageUrl() {
    if (selectedBg == backgroundImage.url) {
      const url = backgroundImage.url;

      const segments = url?.split("/");
      const filename = segments?.pop();

      // Fetch the image as a Blob
      const response = await fetch(url);
      const blob = await response.blob();

      // Convert Blob to File object
      const file = new File([blob], filename, {
        type: blob.type,
      });

      // Return the file object with the originFileObj property
      return { originFileObj: file };
    } else {
      return;
    }
  }

  useEffect(() => {
    setLogo({
      ...logo,
      url: appBranding?.logo,
      size: appBranding?.logo_size,
    });

    setBackgroundImage({
      ...backgroundImage,
      url: appBranding?.background_image,
    });

    setMenu({
      ...menu,
      menu_option: appBranding?.menu_option,
      menu_icons_collection: appBranding?.menu_icons_collection || 1,
      menu_items_box_form: appBranding?.menu_items_box_form,
      menu_icons_color: appBranding?.menu_icons_color || "",
      menu_items_color: appBranding?.menu_items_color || "",
      menu_items_active_color: appBranding?.menu_items_active_color || "",
    });

    setColors({
      ...colors,
      primary_color: appBranding?.primary_color || "",
      secondary_color: appBranding?.secondary_color || "",
    });

    setPlayer({
      player_icons_collection: appBranding?.player_icons_collection || 1,
      player_items_color: appBranding?.player_items_color || "#ffffff",
      player_version: appBranding?.player_version || 1,
      progress_bar_color:
        appBranding?.progress_bar_color || appBranding?.primary_color,
    });
  }, [appBranding]);

  useEffect(() => {
    if (backgroundImage.file && selectedBg == backgroundImage.url) {
      setSelectedBg(null);
    }

    if (selectedBg && backgroundImage.url && !backgroundImage.file) {
      setBackgroundImage({ ...backgroundImage, url: selectedBg });
    }

    if (selectedBg == backgroundImage.url) {
      getStaticImageUrl().then((result) => {
        defaultBg.current = result.originFileObj;
      });
    }
  }, [selectedBg, backgroundImage.url, backgroundImage?.file]);

  const items = [
    {
      key: "General Info",
      label: translate("General Info"),
      children: (
        <GeneralInfo
          logo={logo}
          setLogo={setLogo}
          backgroundImage={backgroundImage}
          setBackgroundImage={setBackgroundImage}
          colors={colors}
          setColors={setColors}
          selectedBg={selectedBg}
          setSelectedBg={setSelectedBg}
        />
      ),
    },
    {
      key: "Menu",
      label: translate("Menu"),
      children: (
        <MenuPage
          colors={colors}
          logo={logo}
          backgroundImage={backgroundImage}
          menu={menu}
          setMenu={setMenu}
        />
      ),
    },

    {
      key: "Live TV",
      label: translate("Live TV"),
      children: <LiveTv backgroundImage={backgroundImage} colors={colors} />,
    },
    {
      key: "Media",
      label: translate("Media"),
      children: <Media backgroundImage={backgroundImage} colors={colors} />,
    },
    {
      key: "Player",
      label: translate("Player"),
      children: <PlayerPage player={player} setPlayer={setPlayer} />,
    },
  ];

  const onSubmit = () => {
    setLoading(true);

    const formData = new FormData();

    if (logo?.file) {
      formData.append("logo", logo?.file);
    }

    if (logo?.size && logo?.size > 0) {
      formData.append("logo_size", logo?.size);
    }

    if (backgroundImage?.file) {
      formData.append("background_image", backgroundImage?.file);
    }

    if (selectedBg == backgroundImage.url && !backgroundImage?.file) {
      formData.append("background_image", defaultBg.current);
    }

    if (colors?.primary_color) {
      formData.append("primary_color", colors?.primary_color);
    }
    if (colors?.secondary_color) {
      formData.append("secondary_color", colors?.secondary_color);
    }

    formData.append("menu_option", menu?.menu_option);
    formData.append("menu_icons_collection", menu?.menu_icons_collection);
    if (menu?.menu_icons_color) {
      formData.append("menu_icons_color", menu?.menu_icons_color);
    }
    if (menu?.menu_items_color) {
      formData.append("menu_items_color", menu?.menu_items_color);
    }
    if (menu?.menu_items_active_color) {
      formData.append("menu_items_active_color", menu?.menu_items_active_color);
    }
    formData.append("menu_items_box_form", menu?.menu_items_box_form);
    if (player?.player_icons_collection) {
      formData.append(
        "player_icons_collection",
        player?.player_icons_collection
      );
    }

    if (player?.player_items_color) {
      formData.append("player_items_color", player?.player_items_color);
    }
    if (player?.progress_bar_color) {
      formData.append("progress_bar_color", player?.progress_bar_color);
    }
    if (player?.player_version) {
      formData.append("player_version", Number(player?.player_version));
    }
    REQUESTS.APP_BRANDINGS.PUT(formData)
      .then((res) => {
        setLoading(false);
        message.success(res);
        // setIsLogoChange(!isLogoChange);
        getAppBranding();
      })
      .catch((err) => {
        setLoading(false);
        message.error(err);
      });
  };

  const onReset = () => {
    REQUESTS.BRAND_RESET()
      .then((res) => {
        message.success(res);
        getAppBranding();
        setSelectedBg(null);
        dispatch(setAppBranding(null));
      })
      .catch((err) => {
        message.error(err);
      });
  };

  return (
    <PageComponent>
      <div className="head-page">
        <h3>
          {translate("Branding")}
          <InfoPopover content={infoModal()} title={null} />
        </h3>

        {profile?.branding ? (
          <Space direction="horizontal">
            <Popconfirm
              title={translate("Are you sure to reset this branding")}
              onConfirm={onReset}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Button
                disabled={!profile?.branding ? true : false}
                type="default"
                className="btn-dashed"
                danger
              >
                {translate("Reset")}
              </Button>
            </Popconfirm>
            <ButtonComponent
              type="primary"
              onClick={onSubmit}
              loading={loading}
              disabled={!profile?.branding ? true : false}
            >
              {translate("Save")}
            </ButtonComponent>
          </Space>
        ) : (
          <Tag color="gold">{translate("You cannot use branding")}</Tag>
        )}
      </div>

      <Tabs
        activeKey={activeTab}
        items={items}
        onChange={(key) => setActiveTab(key)}
      />
    </PageComponent>
  );
}
