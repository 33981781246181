import React from "react";
import { Tooltip } from "antd";
import useTranslate from "../../hooks/translator";

import {
  APP_VERSION,
  TELEGRAM,
  SUPPORT,
  JOIN_COMMUNITY,
} from "../../config/config";
import ICONS from "../../config/icons";
import { IMAGES } from "../../config";

export default function SidebarFooter() {
  const translate = useTranslate();
  return (
    <div className="sidebar-footer">
      {/* <div className="social-links">
        <Tooltip title="Telegram">
          <a
            href={TELEGRAM}
            target="_blank"
            className="social-anim telegram"
            rel="noreferrer"
          >
            {ICONS.TELEGRAM}
          </a>
        </Tooltip>
        <Tooltip title={translate("Support")}>
          <a
            href={SUPPORT}
            target={"_blank"}
            className="social-anim message-social-anim"
            rel="noreferrer"
          >
            <img src={IMAGES.MESSAGE} alt="MESSAGE" />
          </a>
        </Tooltip>
      </div> */}

      {/* <div
        className="join-community"
        onClick={() => window.open(JOIN_COMMUNITY, "_blank")}
      >
        <img src={IMAGES.JOIN_COMMUNITY} alt="join" width={"32px"} />
        <span>{translate("Join community")}</span>
      </div> */}

      <div className="sidebar-bottom">
        <span style={{ marginLeft: "-5px" }}>
          {ICONS.VERSION} {translate("App version")}:
        </span>{" "}
        <span>{APP_VERSION}</span>
      </div>
    </div>
  );
}
