import React, { useState } from "react";
import { Radio } from "antd";
import { PLAYER_ICONS_COLLECTION } from "../../../../config/data";
import useTranslate from "../../../../hooks/translator";
import ColorsPicker from "../../../../components/ColorsPicker";

import PlayerFirst from "./PlayerFirst";
import PlayerSecond from "./PlayerSecond";
import PlayerThree from "./PlayerThree";

import "../../styles/player.scss";

export default function PlayerPage({ player, setPlayer }) {
  const translate = useTranslate();

  const returnSelectedPlayer = () => {
    switch (player.player_version) {
      case 2:
        return (
          <PlayerSecond
            currentIcons={
              PLAYER_ICONS_COLLECTION[player.player_icons_collection - 1]
            }
            playerItemsColor={player.player_items_color}
            progressBarColor={player.progress_bar_color}
          />
        );
      case 3:
        return (
          <PlayerThree
            currentIcons={
              PLAYER_ICONS_COLLECTION[player.player_icons_collection - 1]
            }
            playerItemsColor={player.player_items_color}
            progressBarColor={player.progress_bar_color}
          />
        );
      default:
        return (
          <PlayerFirst
            currentIcons={
              PLAYER_ICONS_COLLECTION[player.player_icons_collection - 1]
            }
            playerItemsColor={player.player_items_color}
            progressBarColor={player.progress_bar_color}
          />
        );
    }
  };

  return (
    <div className="page-player">
      <div className="collections-list">
        <div>
          <p className="panel-title">{translate("Choose player")}</p>
          <Radio.Group
            value={player.player_version || 1}
            className="radio-group"
            onChange={(e) =>
              setPlayer({ ...player, player_version: e.target.value })
            }
          >
            <Radio value={1}>{translate("Player")} 1</Radio>
            <Radio value={2}>{translate("Player")} 2</Radio>
            <Radio value={3}>{translate("Player")} 3</Radio>
          </Radio.Group>
        </div>

        <div>
          <p className="panel-title">{translate("Choose items color")}</p>
          <ColorsPicker
            color={player.player_items_color}
            onChange={(color) =>
              setPlayer({
                ...player,
                player_items_color: color ? color.hex : "#dbdbdb"
              })
            }
          />
        </div>

        <div>
          <p className="panel-title">
            {translate("Choose progress bar color")}
          </p>
          <ColorsPicker
            color={player.progress_bar_color}
            onChange={(color) =>
              setPlayer({
                ...player,
                progress_bar_color: color ? color.hex : "#ff0000"
              })
            }
          />
        </div>

        <div>
          <p className="panel-title">{translate("Choose your icons")}</p>

          <Radio.Group
            value={player.player_icons_collection}
            onChange={(e) =>
              setPlayer({ ...player, player_icons_collection: e.target.value })
            }
            className="radio-group"
          >
            <Radio value={1} className="radio-collections">
              <p>{translate("Collection")} 1</p>

              <div className="collection">
                {PLAYER_ICONS_COLLECTION[0].back}
                {PLAYER_ICONS_COLLECTION[0].pause}
                {PLAYER_ICONS_COLLECTION[0].play}
                {PLAYER_ICONS_COLLECTION[0].backward}
                {PLAYER_ICONS_COLLECTION[0].forward}
                {PLAYER_ICONS_COLLECTION[0].skip}
                {PLAYER_ICONS_COLLECTION[0].subtitles}
              </div>
            </Radio>

            <Radio value={2}>
              <p>{translate("Collection")} 2</p>

              <div className="collection">
                {PLAYER_ICONS_COLLECTION[1].back}
                {PLAYER_ICONS_COLLECTION[1].pause}
                {PLAYER_ICONS_COLLECTION[1].play}
                {PLAYER_ICONS_COLLECTION[1].backward}
                {PLAYER_ICONS_COLLECTION[1].forward}
                {PLAYER_ICONS_COLLECTION[1].skip}
                {PLAYER_ICONS_COLLECTION[1].subtitles}
              </div>
            </Radio>

            <Radio value={3}>
              <p>{translate("Collection")} 3</p>

              <div className="collection">
                {PLAYER_ICONS_COLLECTION[2].back}
                {PLAYER_ICONS_COLLECTION[2].pause}
                {PLAYER_ICONS_COLLECTION[2].play}
                {PLAYER_ICONS_COLLECTION[2].backward}
                {PLAYER_ICONS_COLLECTION[2].forward}
                {PLAYER_ICONS_COLLECTION[2].skip}
                {PLAYER_ICONS_COLLECTION[2].subtitles}
              </div>
            </Radio>
          </Radio.Group>
        </div>
      </div>

      <div>{returnSelectedPlayer()}</div>
    </div>
  );
}
