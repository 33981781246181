import React from "react";
import ICONS from "../config/icons";

export default function Check({ check }) {
  return check ? (
    <p className="true-icon" style={{ padding: 0, margin: 0 }}>
      {ICONS.TRUE}
    </p>
  ) : (
    <p className="false-icon" style={{ padding: 0, margin: 0 }}>
      {ICONS.FALSE}
    </p>
  );
}
