import { useEffect, useState } from "react";
import { Form, Checkbox, Divider, message } from "antd";

import { useSelector, useDispatch } from "react-redux";
import { setProfile } from "../../store/features/profileSlice";

import REQUESTS from "../../api/requests";

import useTranslate from "../../hooks/translator";

import { ButtonComponent, SubInfoTexts } from "../index";

export default function ResellerConfig({ showTitle, onClose, style }) {
  const translate = useTranslate();
  const dispatch = useDispatch();

  const { profile } = useSelector((state) => state.profile);

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (profile?.is_partner) {
      form.setFields([
        {
          name: "approval_permission",
          value: profile?.approval_permission
        },
        {
          name: "use_partner_dns",
          value: profile?.use_partner_dns
        }
      ]);
    }
  }, [profile]);

  const getProfile = () => {
    REQUESTS.PROFILE()
      .then((data) => {
        dispatch(setProfile(data));
      })
      .catch((err) => {});
  };

  const onFinish = (values) => {
    const { approval_permission, use_partner_dns } = values;

    const body = {};

    if (profile?.is_partner) {
      body.approval_permission = approval_permission;
    }

    if (profile?.is_seller) {
      body.use_partner_dns = use_partner_dns;
    }

    REQUESTS.EDIT_PROFILE(body)
      .then((data) => {
        setLoading(false);
        getProfile();
        onClose && onClose();
        message.success(
          translate("Reseller configuration updates are successful")
        );
      })
      .catch((err) => {
        setLoading(false);
        if (typeof err.message === "string") {
          message.error(err.message);
        } else {
          message.error(translate("Something went wrong"));
        }
      });
  };

  const onFinishFailed = () => {
    setLoading(false);
  };

  const onSubmit = () => {
    setLoading(true);
    form.submit();
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      layout="vertical"
    >
      {showTitle && (
        <Divider orientation="left" style={{ fontSize: "15px" }}>
          {translate("Reseller Config")}{" "}
        </Divider>
      )}
      <div className="app-login">
        {(profile?.is_partner ||
          (profile?.parent_id && !profile?.is_partner)) && (
          <Form.Item
            label=""
            name="approval_permission"
            valuePropName="checked"
          >
            <Checkbox>{translate("Auto approve")}</Checkbox>
          </Form.Item>
        )}
        <SubInfoTexts
          info={translate(
            "If this option is enabled, resellers will have the option to automatically register with you as sub resellers. If not, you have to manually accept his requests"
          )}
        />
      </div>
      <div className="app-login">
        {profile?.is_seller && (
          <>
            <Form.Item
              label=""
              name="use_partner_dns"
              valuePropName="checked"
            >
              <Checkbox>{translate("Allow resellers to make use of DNS")}</Checkbox>
            </Form.Item>

            <SubInfoTexts
              info={translate(
                "If enabled, resellers will be able to log in to their devices using your DNS"
              )}
            />
          </>
        )}
      </div>

      <Form.Item>
        <ButtonComponent
          onClick={() => onSubmit()}
          loading={loading}
          style={style}
        >
          {translate("Save")}
        </ButtonComponent>
      </Form.Item>
    </Form>
  );
}
