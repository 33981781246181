import React from "react";
import IMAGES from "../../../../config/images";

export default function PlayerFirst({
  currentIcons,
  playerItemsColor,
  progressBarColor,
}) {
  return (
    <div
      className="player"
      style={{ backgroundImage: `url(${IMAGES.PLAYER_1})` }}
    >
      <div className="wrap" style={{ color: playerItemsColor }}>
        <div className="header-first-player">
          <div className="header-wrap">
            <div className="df-row">
              {currentIcons?.back}
              Media name
            </div>
          </div>
        </div>

        <div className="player-footer-fp">
          <div className="footer-wrap">
            <div className="df-sb">
              <span style={{ color: "#c3bdbd" }}>00:20:02</span>
              <span style={{ color: "#c3bdbd" }}>01:35:05</span>
            </div>

            <div
              className="progress-wrap-fp"
              style={{ backgroundColor: "#e4e7e587" }}
            >
              <div
                className="progress"
                style={{ backgroundColor: progressBarColor }}
              >
                <div className="point" style={{ backgroundColor: "#fff" }}>
                  <div
                    className="in"
                    style={{ backgroundColor: progressBarColor }}
                  ></div>
                </div>
              </div>
            </div>

            <div className="df-sb footer-actions-fp">
              <div>
                <span style={{ transform: "scale(1.5)" }}>
                  {currentIcons?.pause}
                </span>
                <span>{currentIcons?.backward}</span>
                <span>{currentIcons?.forward}</span>
              </div>
              <div>
                <span>{currentIcons?.skip}</span>
                <span>{currentIcons?.subtitles}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mask"></div>
    </div>
  );
}
