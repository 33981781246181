import useTranslate from "../../hooks/translator";
import Platform from "./components/Platform";
import "./styles/platform.scss";

export default function PlatformsStatistics({ platforms, availablePlatforms }) {
  const translate = useTranslate();

  return (
    <>
      <div className="title">{translate("Platforms")}</div>

      <div className="platforms-section">
        {/* Available now */}
        <div> 
          <div className="sub-title">{translate("Available now")}</div>
          <div className="platforms-list">
             {platforms && 
              platforms?.map((item) => {
                if (item.is_released) { 
                  return (
                    <Platform
                      key={item.id}
                      id={item.id}
                      item={item}
                      availablePlatforms={availablePlatforms}
                    />
                  )
                }
              })} 
          </div>
        </div>
        {/* Coming soon */}
        <div>
          <div className="sub-title">{translate("Coming soon")}</div>
          <div className="platforms-list">
            {platforms &&
              platforms?.map((item) => {
                if (!item.is_released) {  
                  return (
                    <Platform
                      key={item.id}
                      id={item.id}
                      item={item}
                      availablePlatforms={availablePlatforms}
                    />
                  )
                }
              })}
          </div>
        </div>
      </div>
    </>
  );
}
