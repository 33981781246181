import React, { useState } from "react";

import { Form, Input, message } from "antd";

import REQUESTS from "../../../api/requests";

import useTranslate from "../../../hooks/translator";
import { ButtonComponent } from "../../../components";

export default function EmailStep({ setStep }) {
  const translate = useTranslate();
  const [form] = Form.useForm();

  const [loading, setLoading] = useState(false);

  const onFinish = (values) => {
    const body = {
      two_fa_email: values.two_fa_email,
    };
    setLoading(true);
    REQUESTS.OTP.TWO_FA_POST(body)
      .then((res) => {
        setStep("code");
        setLoading(false);
        message.success(res);
      })
      .catch((err) => {
        setLoading(false);
        if (err == "Code exists") {
          message.warning(err);
          setStep("code");
        } else {
          message.error(err);
        }
      });
  };

  const submitBtn = (e) => {
    e.stopPropagation();
    form.submit();
  };

  return (
    <Form
      form={form}
      onFinish={onFinish}
      layout="vertical"
    >
      <Form.Item
        label={translate("Email")}
        name="two_fa_email"
        rules={[
          {
            required: true,
            message: translate("Please input your 2FA email address"),
          },
          {
            type: "email",
            message: translate("E-mail isn't valid"),
          },
        ]}
      >
        <Input placeholder={translate("Enter 2FA email address")} />
      </Form.Item>

      <Form.Item>
        <ButtonComponent
          style={{ maxWidth: "370px" }}
          onClick={(e) => submitBtn(e)}
          loading={loading}
        >
          {translate("Get code")}
        </ButtonComponent>
      </Form.Item>
    </Form>
  );
}
