import React from "react";
import { Popover } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export default function InfoPopover({ content, title, className }) {
  return (
    <Popover
      content={content}
      title={
        title ? <h3 style={{ textTransform: "capitalize" }}>{title}</h3> : null
      }
      placement="rightTop"
      overlayClassName={`info-popover ${className}`}
    >
      <ExclamationCircleOutlined
        style={{ color: "#258ffb", paddingLeft: 10, cursor: "pointer" }}
      />
    </Popover>
  );
}
