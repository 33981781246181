import React from "react";
import { ImageUpload } from "../../../components";
import BackgroundList from "./BackgroundList";
import useTranslate from "../../../hooks/translator";

export default function Background({
  backgroundImage,
  setBackgroundImage,
  selectedBg,
  setSelectedBg,
}) {
  const translate = useTranslate();

  return (
    <div className="wrap-background">
      <div>
        <p className="lable">{translate("Choose background from list")}</p>
        <BackgroundList
          setBackgroundImage={setBackgroundImage}
          selectedBg={selectedBg}
          setSelectedBg={setSelectedBg}
        />
      </div>
      <div>
        <p className="lable">{translate("Upload background")}</p>
        <ImageUpload
          setFileUrl={setBackgroundImage}
          fileUrl={backgroundImage}
          width="550px"
          height="300px"
        />
      </div>
    </div>
  );
}
