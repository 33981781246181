import React from "react";
import { useSelector } from "react-redux";
import { Divider } from "antd";
import useTranslate from "../../hooks/translator";

export default function BecomeASeller() {
  const translate = useTranslate();

  const { profile } = useSelector((state) => state.profile);
  if (profile?.is_partner) {
    if (profile?.is_seller) {
      return (
        <div>
          <Divider orientation="left" style={{ fontSize: "15px" }}>
            {" "}
            {translate("Become a seller")}
          </Divider>
          <p>{translate("become_seller_option")}</p>
          <p>
            {translate("Contact us on")}{" "}
            <a
              href="https://t.me/xtreamcloud"
              target="_blank"
              rel="noreferrer"
              style={{ cursor: "pointer" }}
            >
              Telegram
            </a>{" "}
            {translate("to remove seller status")}.
          </p>
        </div>
      );
    } else {
      return (
        <div>
          <Divider orientation="left" style={{ fontSize: "15px" }}>
            {translate("Become a seller")}
          </Divider>
          <p>{translate("become_seller_option")}</p>
          <p>
            {translate("Contact us on")}{" "}
            <a
              href="https://t.me/xtreamcloud"
              target="_blank"
              rel="noreferrer"
              style={{ cursor: "pointer" }}
            >
              Telegram
            </a>{" "}
            {translate("to become a seller")}.
          </p>
        </div>
      );
    }
  }
}
