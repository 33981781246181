import React, { useState, useEffect } from "react";
import { Table, Tag } from "antd";

import REQUESTS from "../../api/requests";

import useTranslate from "../../hooks/translator";

import { ICONS } from "../../config";
import { parseDate } from "../../config/formats";

import { LongText } from "../../components";

export default function BillingHistory({ open, itemId, tab }) {
  const translate = useTranslate();

  const [loading, setLoading] = useState(false);
  const [sort, setSort] = useState(["id", "DESC"]);
  const [total, setTotal] = useState(0);
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [dataSource, setDataSource] = useState([]);

  const [search, setSearch] = useState(null);

  const [filtersTb, setFiltersTb] = useState({
    date: null,
    paymentMethod: null,
    status: null,
  });

  const columns = [
    {
      title: "#",
      width: 40,
      dataIndex: "id",
      key: "id",
      fixed: "left",
      align: "center",
      render: (text, record, index) => {
        return limit * (currentPage - 1) + index + 1;
      }
    },
    {
      title: translate("Payment method"),
      dataIndex: "payment_method",
      key: "payment_method",
      align: "center",
      render: (text, record, index) => {
        let str = record.payment_method.replace("_", " ");
        return str.charAt(0).toUpperCase() + str.slice(1);
      },
    },
  
    {
      title: translate("Credit"),
      dataIndex: "credit",
      key: "credit",
      align: "center",
      render: (text, record, index) => {
        if (record.credit > 0 && record.payment_method !== "activation") {
          return (
            <p className="arrow">
              <span> {record.credit}</span>
              <span style={{ color: "green" }}>{ICONS.DOWN}</span>
            </p>
          );
        }
        if (record.credit <= 0 || record.payment_method == "activation") {
          return (
            <p className="arrow">
              <span> {record.credit}</span>
              <span style={{ color: "red" }}>{ICONS.UP}</span>
            </p>
          );
        }
      },
    },

    {
      title: translate("Amount"),
      dataIndex: "amount",
      key: "amount",
      align: "center",
      render: (text, record, index) => record.amount || "N/A",
    },
    {
      title: translate("Transaktion ID"),
      dataIndex: "transaktion_id",
      key: "transaktion_id",
      align: "center",
      render: (text, record, index) =>
        record?.transaktion_id ? record?.transaktion_id : "N/A",
    },
    {
      title: translate("Status"),
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text, record, index) =>
        record.status == "COMPLETED" ? (
          <Tag color="green">{record.status}</Tag>
        ) : (
          <Tag color="gold">{record.status}</Tag>
        ),
    }, 
    {
      title: translate("Description"),
      dataIndex: "description",
      key: "description",
      align: "center",
      render: (text, record, index) => record.description ?
        <LongText children={record.description} />
        : "N/A"
    },
    {
      title: translate("Created date"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text, record, index) => parseDate(text),
    },
  ];

  useEffect(() => {
    if (open && tab == "Billing history" && itemId) {
      getCreditHistory();
    }
  }, [itemId, tab, open, search, filtersTb, currentPage, limit, sort]);

  const getCreditHistory = () => {
    setLoading(true);

    const query = {
      limit,
      page: currentPage,
      pagination: 1,
      sort,
      filter: { provider_id: itemId },   
    };

    REQUESTS.SUB_RESELLER_BILLING({ query: JSON.stringify(query) })
      .then((data) => {
        setDataSource(data.rows);
        setLoading(false);
        setTotal(data.total);
        setLimit(data.limit);
        setCurrentPage(data.page);
      })
      .catch((e) => {
        setLoading(false);
      });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter.field) {
      setSort([sorter.field, sorter.order === "ascend" ? "ASC" : "DESC"]);
    }

    setFiltersTb((prevFilters) => ({
      ...prevFilters,
      status: filters["status"] || null,
      paymentMethod: filters["payment_method"] || null,
    }));

    if (filters["transaktion_id"]) {
      setSearch(filters);
    } else {
      setSearch(null);
    }

    setCurrentPage(pagination.current);
    setLimit(pagination.pageSize);
  };

  return (
    <Table
      size="small"
      columns={columns}
      loading={loading}
      dataSource={dataSource}
      onChange={handleTableChange}
      pagination={{
        position: ["bottomCenter"],
        current: currentPage,
        total: total,
        pageSize: limit,
        showSizeChanger: true,
      }}
      rowKey="id"
      scroll={{
        x: "max-content",
        y: null,
      }}
    />
  );
}
