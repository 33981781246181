import { InfoCircleOutlined } from "@ant-design/icons";
import useTranslate from "../../../hooks/translator";
import { SUPPORT } from "../../../config/config";

function InfoSector({}) {
  const translate = useTranslate();

  return (
    <div className="buy-credit-info">
      <InfoCircleOutlined
        style={{
          color: "#7f6e15",
          fontSize: "20px"
        }}
      />{" "}
      {translate(
        "The credit count will be updated on your profile during 5 minutes"
      )}{" "}
      <a href={`mailto:${SUPPORT}`} target="_blank" rel="noreferrer">
        {SUPPORT}
      </a>
    </div>
  );
}

export default InfoSector;
