import React from "react";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";


export default function GoBackComponent({children}) {
  const navigate = useNavigate();

  return (
    <div onClick={() => navigate(-1)} className="go-back">
     <h3> <ArrowLeftOutlined/>{" "}
       {children}</h3> 
    </div>
  );
}
